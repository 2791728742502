import { ReactComponent as SortIcon } from '@assets/icons/V4/sort-icon.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import './Table.scss';
import Pagination, { PaginationProps } from '../Pagination';
import { createContext, useContext } from 'react';
import SkeletonBox from '../SkeletonBox';

const TableContext = createContext({
  isLoading: false,
  isUserView: false,
  textAlignment: 'left',
});

interface TableProps extends BasicProps {
  tableClassName?: string;
  size?: 'small' | 'default';
  textAlignment?: 'left' | 'center';
  isLoading?: boolean;
  isUserView?: boolean;
  pagination?: PaginationProps;
}

const Table = (props: TableProps) => {
  const {
    className,
    tableClassName,
    children,
    pagination,
    isLoading = false,
    isUserView = false,
    textAlignment = 'left',
    size = 'default',
  } = props;

  return (
    <TableContext.Provider value={{ isLoading, isUserView, textAlignment }}>
      <div className={mergeClassNames(className)}>
        <table
          className={mergeClassNames(
            'min-w-full border-collapse table-auto bg-transparent text-customGrey font-bold text-xs',
            {
              '[&_td]:p-4 [&_th]:p-4': size === 'default',
              '[&_td]:p-2.5 [&_th]:p-2.5': size === 'small',
              '[&_td]:p-0': isLoading,
              'border-separate border-spacing-y-4': isUserView,
            },
            tableClassName
          )}
        >
          {children}
        </table>
        {!!pagination && <Pagination size={size} {...pagination} />}
      </div>
    </TableContext.Provider>
  );
};

const Header = (props: BasicProps) => {
  const { className, children } = props;

  return (
    <thead className={mergeClassNames('bg-transparent text-left font-semibold ', className)}>
      {children}
    </thead>
  );
};

const Body = (props: BasicProps) => {
  const { className, children } = props;
  const { isLoading } = useContext(TableContext);

  return (
    <tbody className={mergeClassNames('', className)}>
      {isLoading
        ? [...Array(10).keys()].map((__dirname, index) => (
            <Row key={index}>
              <Cell colSpan={100} className="rounded-2xl">
                <SkeletonBox className="h-18 w-full" />
              </Cell>
            </Row>
          ))
        : children}
    </tbody>
  );
};

interface RowProps extends BasicProps {
  isHeader?: boolean;
  isUserView?: boolean;
  isHoverable?: boolean;
}

const Row = (props: RowProps) => {
  const { className, children, isHeader, isHoverable = true } = props;
  const { isUserView } = useContext(TableContext);

  return (
    <tr
      className={mergeClassNames(
        'border-b',
        {
          'hover:bg-white hover:bg-opacity-15': !isHeader && !isUserView && isHoverable,
          'border-b-0 ': isUserView,
          'bg-surfaceObject ': !isHeader && isUserView,
        },
        className
      )}
    >
      {children}
    </tr>
  );
};

export type SortOrder = 'asc' | 'desc' | null;

interface HeaderCellProps extends BasicProps {
  sortOrder?: SortOrder;
  onSort?: (order: SortOrder) => void;
}

const HeaderCell = (props: HeaderCellProps) => {
  const { className, children, sortOrder = null, onSort, ...rest } = props;
  const { textAlignment } = useContext(TableContext);

  const clickHandler = () => {
    if (!onSort) return;

    let newOrder: SortOrder;

    switch (sortOrder) {
      case null:
        newOrder = 'desc';
        break;
      case 'desc':
        newOrder = 'asc';
        break;
      case 'asc':
        newOrder = null;
        break;
    }

    onSort(newOrder);
  };

  return (
    <th
      {...rest}
      className={mergeClassNames(
        'text-left align-middle',
        {
          'cursor-pointer': !!onSort,
        },
        className
      )}
      onClick={clickHandler}
    >
      <div
        className={mergeClassNames('flex items-center select-none', {
          'justify-center': textAlignment === 'center',
        })}
      >
        {children}
        {!!onSort && (
          <span className="flex-col gap-0.5 ml-2 text-white">
            <SortIcon
              className={mergeClassNames({
                'text-customPrimary': sortOrder === 'asc',
              })}
            />
            <SortIcon
              className={mergeClassNames('rotate-180', {
                'text-customPrimary': sortOrder === 'desc',
              })}
            />
          </span>
        )}
      </div>
    </th>
  );
};

interface CellProps extends BasicProps {
  colSpan?: number;
  onClick?: () => void;
}

const Cell = (props: CellProps) => {
  const { className, colSpan, children, onClick } = props;
  const { textAlignment } = useContext(TableContext);

  return (
    <td
      colSpan={colSpan}
      className={mergeClassNames(
        {
          'underline cursor-pointer': !!onClick,
          'text-center': textAlignment === 'center',
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </td>
  );
};

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.HeaderCell = HeaderCell;
Table.Cell = Cell;

export default Table;
