import { ReactComponent as ActiveFilterIcon } from '@assets/icons/V4/active-filter-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/V4/close-icon.svg';
import { ReactComponent as InactiveFilterIcon } from '@assets/icons/V4/inactive-filter-icon.svg';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import IconButton from '@components/V4/IconButton';
import Select from '@components/V4/Select/Select';
import { SortOrder } from '@components/V4/Table/Table';
import { SearchIcon } from '@heroicons/react/outline';
import { useGetAllCoursesByMentor } from '@actions';
import { SearchMentorEnrollmentsParams } from '@shared/clientTypes';
import moment from 'moment';
import { useState } from 'react';

type Props = {
  searchParams: SearchMentorEnrollmentsParams;
  updateSearchParams: (searchParams: Partial<SearchMentorEnrollmentsParams>) => void;
  setStudentSearch: React.Dispatch<React.SetStateAction<string>>;
  courseHandler: (course: string | number | null) => void;
  gradYearHandler: (year: string | number | null) => void;
  enrollmentStatusHandler: (status: string | number | null) => void;
  studentStatusHandler: (status: string | number | null) => void;
  sortHandler: (field: string, order: SortOrder) => void;
  debouncedStudentSearch: string;
  studentSearch: string;
};

const MyStudentsFilters = ({
  searchParams,
  updateSearchParams,
  courseHandler,
  setStudentSearch,
  studentSearch,
  enrollmentStatusHandler,
  studentStatusHandler,
  gradYearHandler,
  sortHandler,
}: Props) => {
  const { data: courses, isLoading } = useGetAllCoursesByMentor();

  const [isFilterDropdownActive, setIsFilterDropdownActive] = useState(false);

  const enrollmentsStatusOptions = [
    { label: 'All Enrollments', value: null },
    { label: 'Upcoming Enrollments', value: 'upcoming' },
    { label: 'Active Enrollments', value: 'active' },
    { label: 'Ended Enrollments', value: 'ended' },
  ];

  const studentStatusOptions = [
    { label: 'All Students', value: null },
    { label: 'Active Students', value: 'active' },
    { label: 'Inactive Students', value: 'inactive' },
  ];

  const sortOptions = [
    {
      label: 'Course Name (Asc)',
      value: 'nameAsc',
      sort: 'course',
      order: 'asc',
    },
    {
      label: 'Course Name (Desc)',
      value: 'nameDesc',
      sort: 'course',
      order: 'desc',
    },
    {
      label: 'Sessions (Asc)',
      value: 'sessionsAsc',
      sort: 'noOfCompletedSessions',
      order: 'asc',
    },
    {
      label: 'Sessions (Desc)',
      value: 'sessionsDesc',
      sort: 'noOfCompletedSessions',
      order: 'desc',
    },
    {
      label: 'Graduation Year (Asc)',
      value: 'gradYearAsc',
      sort: 'gradYear',
      order: 'asc',
    },
    {
      label: 'Graduation Year (Desc)',
      value: 'gradYearDesc',
      sort: 'gradYear',
      order: 'desc',
    },
    {
      label: 'Enrollment Status (Asc)',
      value: 'enrollmentStatusAsc',
      sort: 'enrollmentStatus',
      order: 'asc',
    },
    {
      label: 'Enrollment Status (Desc)',
      value: 'enrollmentStatusDesc',
      sort: 'enrollmentStatus',
      order: 'desc',
    },
    {
      label: 'Student Status (Asc)',
      value: 'studentStatusAsc',
      sort: 'studentStatus',
      order: 'asc',
    },
    {
      label: 'Student Status (Desc)',
      value: 'studentStatusDesc',
      sort: 'studentStatus',
      order: 'desc',
    },
  ];

  let coursesOptions: { label: string; value: string | null }[] = [];

  if (courses) {
    coursesOptions = [
      { label: 'All Courses', value: null },
      ...courses.map((course) => ({ label: course.title, value: course._id })),
    ];
  }

  const getSelectedOption = (
    sortBy: string | null | undefined,
    sortOrder: SortOrder | undefined
  ) => {
    return sortOptions.find((option) => option.sort === sortBy && option.order === sortOrder);
  };

  const selectedOption = getSelectedOption(searchParams.sortBy, searchParams.sortOrder);
  const selectedLabel = selectedOption ? selectedOption.label : 'Select Sort Option';

  const handleSortChange = (value: string | number | null) => {
    if (typeof value === 'string') {
      const selectedOption = sortOptions.find((option) => option.value === value);
      if (selectedOption) {
        sortHandler(selectedOption.sort, selectedOption.order as SortOrder);
      }
    }
  };

  return (
    <section className="flex flex-col gap-8">
      <div className="flex gap-4">
        <div className="relative w-full flex items-center bg-surfaceObject rounded-3xl">
          <input
            type="text"
            className="bg-transparent border-0 w-full py-2 px-6 text-white placeholder:text-customGrey placeholder:opacity-50"
            placeholder="Search by Student Name"
            value={studentSearch}
            onChange={(e) => setStudentSearch(e.target.value)}
          />

          <SearchIcon className="w-6 h-6 absolute right-6" />
        </div>
        <div className="flex items-center gap-3 tablet:hidden">
          {(!!searchParams.enrollmentStatus ||
            !!searchParams.studentStatus ||
            !!searchParams.gradYear ||
            !!searchParams.course) && (
            <IconButton
              onClick={() =>
                updateSearchParams({
                  page: 1,
                  limit: 10,
                  enrollmentStatus: undefined,
                  studentStatus: undefined,
                  gradYear: undefined,
                  course: undefined,
                })
              }
              icon={<CloseIcon />}
            />
          )}
          <IconButton
            size="large"
            onClick={() => setIsFilterDropdownActive((prev) => !prev)}
            icon={
              isFilterDropdownActive ? (
                <ActiveFilterIcon className="w-9 h-9" />
              ) : (
                <InactiveFilterIcon className="w-9 h-9" />
              )
            }
          />
        </div>
      </div>

      <div className="hidden tablet:flex gap-6 w-4/5">
        <Select
          label="Course"
          options={coursesOptions}
          placeholder="Select Status"
          optionFilterProp="children"
          showSearch
          loading={isLoading}
          value={searchParams.course}
          onSelect={(value) => courseHandler(value)}
          variant="secondary"
        />

        <Select
          label="Enrollment Status"
          options={enrollmentsStatusOptions}
          placeholder="Select Enrollment Status"
          value={searchParams.enrollmentStatus}
          onSelect={(value) => enrollmentStatusHandler(value)}
          variant="secondary"
        />

        <Select
          label="Student Status"
          options={studentStatusOptions}
          placeholder="Select Student Status"
          value={searchParams.studentStatus}
          onSelect={(value) => studentStatusHandler(value)}
          variant="secondary"
        />

        <AgoraDatePicker
          label="Student Graduation Year"
          placeholder="Select Year"
          picker={'year'}
          allowClear
          value={searchParams.gradYear ? moment(searchParams.gradYear) : undefined}
          variant="secondary"
          onChange={(value) => gradYearHandler(moment(value).year())}
          size="default"
        />
      </div>

      {isFilterDropdownActive && (
        <div className="flex tablet:hidden gap-4 flex-col">
          <div className="flex gap-4">
            <Select
              label="Course"
              options={coursesOptions}
              placeholder="Select Course"
              optionFilterProp="children"
              showSearch
              loading={isLoading}
              value={searchParams.course}
              onSelect={(value) => courseHandler(value as string)}
              variant="secondary"
            />
            <AgoraDatePicker
              label="Student Graduation Year"
              placeholder="Select Year"
              picker={'year'}
              allowClear
              value={searchParams.gradYear ? moment(searchParams.gradYear) : undefined}
              variant="secondary"
              onChange={(value) => gradYearHandler(moment(value).year())}
              size="default"
            />
          </div>
          <div className="flex gap-4">
            <Select
              label="Enrollment Status"
              options={enrollmentsStatusOptions}
              placeholder="Select Enrollment Status"
              value={searchParams.enrollmentStatus}
              onSelect={(value) => enrollmentStatusHandler(value)}
              variant="secondary"
            />

            <Select
              label="Student Status"
              options={studentStatusOptions}
              placeholder="Select Student Status"
              value={searchParams.studentStatus}
              onSelect={(value) => studentStatusHandler(value)}
              variant="secondary"
            />
          </div>
          <Select
            label="Sort By"
            options={sortOptions}
            placeholder="Select Sort By"
            value={selectedLabel}
            onSelect={handleSortChange}
            variant="secondary"
            allowClear={false}
          />
        </div>
      )}
    </section>
  );
};

export default MyStudentsFilters;
