import { ReactComponent as EditIcon } from '@assets/icons/profile-icons/edit-icon.svg';
import { ReactComponent as LocationIcon } from '@assets/icons/profile-icons/location-icon.svg';
import IconButton from '@components/V4/IconButton';
import { useContext, useState } from 'react';
import { ProfileContext } from '../../AccountProfile';
import StudentAsideInfo from '../ProfileAsideInfo';
import { City } from '@shared/serverTypes';
import ProfileAsideLocationForm from './ProfileAsideLocationForm';

interface StudentAsideLocationProps {
  location: string;
  city: string;
  cityRef?: City;
  country: string;
  timezone: string;
  languages: string[];
  userId: string;
  hasEditPermissions: boolean;
}

const ProfileAsideLocation = (props: StudentAsideLocationProps) => {
  const { location, timezone, languages, userId, city, cityRef, country, hasEditPermissions } =
    props;

  const { isLoading: isProfileLoading } = useContext(ProfileContext);

  const [isEditMode, setIsEditMode] = useState(false);

  const editButtonHandler = () => {
    setIsEditMode((prev) => !prev);
  };

  return isEditMode ? (
    <ProfileAsideLocationForm
      city={city}
      cityRef={cityRef}
      country={country}
      timezone={timezone}
      languages={languages}
      userId={userId}
      onEditClose={editButtonHandler}
    />
  ) : (
    <div className="flex gap-6 py-4 px-6">
      <div className="flex gap-4 w-full">
        <LocationIcon />
        <div className="flex flex-col gap-6 w-full">
          <h3 className="font-raleway font-bold">Location & Language</h3>
          <StudentAsideInfo label="Location" value={location} isLoading={isProfileLoading} />
          <StudentAsideInfo label="Timezone" value={timezone} isLoading={isProfileLoading} />
          <StudentAsideInfo
            label="Spoken Languages"
            value={languages}
            isLoading={isProfileLoading}
          />
        </div>
      </div>

      {hasEditPermissions && (
        <IconButton
          onClick={editButtonHandler}
          className="min-h-6 min-w-6"
          icon={<EditIcon className="min-w-3 min-h-3" />}
        />
      )}
    </div>
  );
};

export default ProfileAsideLocation;
