import RateIcon from '@assets/icons/Icon-material-rate-review.png';
import PromotersFeedbackImage from '@assets/icons/feedback-matters-promoters.png';
import { useSubmitNPS, useSubmitParentNPS } from '@actions';
import AgoraModal from '@utils/AgoraModal';
import SecondaryButton from '@utils/SecondaryButton';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useAnalytics } from '@contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './nps.style.css';
import './nps-modal.style.scss';
import { useAuthState } from '@contexts/AuthContext';
import useToast from 'apps/agora/src/hooks/useToast';

type Params = {
  studentId: string;
};

type Props = {
  visible: boolean;
  firstUpgradeNPS: boolean;
  handleCurrentModal?: (modalName: string) => void;
  parentView?: boolean;
  onClose?: () => void;
};

const MonthlyNPSModal = ({
  visible,
  firstUpgradeNPS,
  handleCurrentModal,
  parentView,
  onClose,
}: Props) => {
  const { studentId } = useParams<Params>();
  const { isMentor } = useAuthState();
  const [width, setWidth] = useState(window.innerWidth);
  const [submitted, setSubmitted] = useState(false);
  const [upgradeNPS, setUpgradeNPS] = useState(0);
  const [platformNPS, setPlatformNPS] = useState(0);
  const [showModal, setShowModal] = useState(visible && !submitted);
  const [modalPage, setModalPage] = useState('nps');
  const [showUpgradeNPSReasonMentor, setShowUpgradeNPSReasonMentor] = useState(false);
  const [showUpgradeNPSReasonStudent, setShowUpgradeNPSReasonStudent] = useState(false);
  const [showPlatformNPSReason, setShowPlatformNPSReason] = useState(false);

  const [upgradeNPSRequired, setUpgradeNPSRequired] = useState(false);
  const [platformNPSRequired, setPlatformNPSRequired] = useState(false);

  const [reasons, setReasons] = useState<any>();

  const { trackEvent } = useAnalytics();

  const toast = useToast();

  const [checkboxValues, setCheckboxValues] = useState({
    education: {
      // MENTOR UPGRADE REASONS
      mentorManagementSupport: false,
      billing: false,
      qualityOfCourseResources: false,
      usabilityOfCourseResources: false,
      compensationBenefits: false,
      trainingOnboarding: false,
      qualityOfStudents: false,
      schedulingFlexibility: false,
      numberOfStudentAllocations: false,

      // STUDENT UPGRADE REASONS
      educationalResourcesQuality: false,
      mentorCompatibility: false,
      admissionResults: false,
      customerSupport: false,
      learningProgressOverview: false,
      feedbackQuality: false,
      courseStructure: false,
    },

    platform: {
      // PLATFORM REASONS
      userInterfaceExperience: false,
      performance: false,
      functionality: false,
      compatibilityAccessibility: false,
      documentationSupport: false,
      securityPrivacy: false,
      trainingOnboarding: false,
      customizationPersonalization: false,
    },
  });

  const checkboxLabels: any = {
    education: {
      // MENTOR UPGRADE REASONS
      mentorManagementSupport: 'Mentor Management and Support',
      billing: 'Billing',
      qualityOfCourseResources: 'Quality of Course Resources',
      usabilityOfCourseResources: 'Usability of Course Resources',
      compensationBenefits: 'Compensation and Benefits',
      trainingOnboarding: 'Training and Onboarding',
      qualityOfStudents: 'Quality of Students',
      schedulingFlexibility: 'Scheduling and Flexibility',
      numberOfStudentAllocations: 'Number of Student Allocations',

      // STUDENT UPGRADE REASONS
      educationalResourcesQuality: 'Educational Resources Quality',
      mentorCompatibility: 'Mentor Compatibility',
      admissionResults: 'Admission Results',
      customerSupport: 'Customer Support',
      learningProgressOverview: 'Learning Progress Overview',
      feedbackQuality: 'Feedback Quality',
      courseStructure: 'Course Structure',
    },

    platform: {
      // PLATFORM REASONS
      userInterfaceExperience: 'User Interface and Experience',
      performance: 'Performance',
      functionality: 'Functionality',
      compatibilityAccessibility: 'Compatibility or Accessibility',
      documentationSupport: 'Documentation and Support',
      securityPrivacy: 'Security and Privacy',
      trainingOnboarding: 'Training and Onboarding',
      customizationPersonalization: 'Customization and Personalization',
    },
  };

  const [otherReasonUpgrade, setOtherReasonUpgrade] = useState('');
  const [otherReasonPlatform, setOtherReasonPlatform] = useState('');

  const handleCheckboxChange = (name: string, checked: boolean, platform?: boolean) => {
    if (checkboxLabels.education[`${name}`] !== undefined && !platform) {
      setCheckboxValues((prevState) => ({
        ...prevState,
        education: {
          ...prevState['education'],
          [name]: checked,
        },
      }));
      updateNPSReasons();
    }
    if (checkboxLabels.platform[`${name}`] !== undefined && platform) {
      setCheckboxValues((prevState) => ({
        ...prevState,
        platform: {
          ...prevState['platform'],
          [name]: checked,
        },
      }));
      updateNPSReasons();
    }
  };

  const resetEducationCheckboxes = () => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      education: {
        // MENTOR UPGRADE REASONS
        mentorManagementSupport: false,
        billing: false,
        qualityOfCourseResources: false,
        usabilityOfCourseResources: false,
        compensationBenefits: false,
        trainingOnboarding: false,
        qualityOfStudents: false,
        schedulingFlexibility: false,
        numberOfStudentAllocations: false,

        // STUDENT UPGRADE REASONS
        educationalResourcesQuality: false,
        mentorCompatibility: false,
        admissionResults: false,
        customerSupport: false,
        learningProgressOverview: false,
        feedbackQuality: false,
        courseStructure: false,
      },
    }));
  };

  const resetPlatformCheckboxes = () => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      platform: {
        // PLATFORM REASONS
        userInterfaceExperience: false,
        performance: false,
        functionality: false,
        compatibilityAccessibility: false,
        documentationSupport: false,
        securityPrivacy: false,
        trainingOnboarding: false,
        customizationPersonalization: false,
      },
    }));
  };

  const handleTextareaChangeUpgrade = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReasonUpgrade(event.target.value);
    updateNPSReasons();
  };

  const handleTextareaChangePlatform = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReasonPlatform(event.target.value);
    updateNPSReasons();
  };

  const convertKeyToType = (key: string) => {
    const words = key.split(' ');
    const filteredWords = words
      .filter((word) => word !== 'and' && word !== 'or')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    return filteredWords.join('');
  };

  const updateNPSReasons = () => {
    const reasons = {
      education: [] as any,
      platform: [] as any,
      otherEducation: '',
      otherPlatform: '',
    };

    for (const [key, value] of Object.entries(checkboxValues.education)) {
      if (value) {
        reasons.education.push(convertKeyToType(checkboxLabels.education[`${key}`]));
      }
    }

    for (const [key, value] of Object.entries(checkboxValues.platform)) {
      if (value) {
        reasons.platform.push(convertKeyToType(checkboxLabels.platform[`${key}`]));
      }
    }

    if (otherReasonUpgrade.trim() !== '') {
      reasons.otherEducation = otherReasonUpgrade;
    }

    if (otherReasonPlatform.trim() !== '') {
      reasons.otherPlatform = otherReasonPlatform;
    }
    setReasons(reasons);
  };

  const { mutate, isLoading } = useSubmitNPS({
    onSuccess: () => {
      if (upgradeNPS >= 9 && platformNPS >= 9) {
        setModalPage('promoters');
      } else {
        setShowModal(false);
        if (onClose) {
          onClose();
        }
      }
      toast.success('Thank you for your feedback!');
    },
    onError: () => {
      if (upgradeNPS >= 9 && platformNPS >= 9) {
        setModalPage('promoters');
      } else {
        setShowModal(false);
        if (onClose) {
          onClose();
        }
      }
    },
  });

  const { mutate: mutateParentNps, isLoading: isLoadingSubmitParentNps } = useSubmitParentNPS({
    onSuccess: () => {
      if (upgradeNPS >= 9) {
        setModalPage('promoters');
      } else {
        setShowModal(false);
      }
      toast.success('Thank you for your feedback!');
    },
    onError: () => {
      if (upgradeNPS >= 9) {
        setModalPage('promoters');
      } else {
        setShowModal(false);
      }
    },
  });

  const submitFeedback = async () => {
    trackEvent(ANALYTICS_EVENT_NAMES.NPS_MODAL_SUBMIT);

    if (studentId && !isLoadingSubmitParentNps) {
      setSubmitted(true);
      mutateParentNps({
        rating: upgradeNPS,
        parentOf: studentId,
        reasons,
      });
      return;
    }
    if (!isLoading) {
      setSubmitted(true);
      mutate({
        rating: upgradeNPS,
        platformRating: platformNPS,
        reasons,
      });
      return;
    }
  };

  const NPScolors = [
    '#D85E62',
    '#D85E62',
    '#D85E62',
    '#D85E62',
    '#D85E62',
    '#D85E62',
    '#D7B037',
    '#D7B037',
    '#36B366',
    '#36B366',
  ];

  const handleUpgradeNPS = (value: number) => {
    setUpgradeNPS(value);
    if (isMentor) {
      if (value < 9) {
        setShowUpgradeNPSReasonMentor(true);
      } else {
        setReasons(undefined);
        resetEducationCheckboxes();
        setShowUpgradeNPSReasonMentor(false);
      }
    } else {
      if (value < 9) {
        setShowUpgradeNPSReasonStudent(true);
      } else {
        setReasons(undefined);
        resetEducationCheckboxes();
        setShowUpgradeNPSReasonStudent(false);
      }
    }
  };

  const handlePlatformNPS = (value: number) => {
    setPlatformNPS(value);
    if (value < 9) {
      setShowPlatformNPSReason(true);
    } else {
      setReasons(undefined);
      resetPlatformCheckboxes();
      setShowPlatformNPSReason(false);
    }
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    updateNPSReasons();
  }, [checkboxValues, otherReasonUpgrade, otherReasonPlatform]);

  useEffect(() => {
    // UPGRADE NPS
    const eduReasons = reasons?.education;
    const platReasons = reasons?.platform;

    if (
      upgradeNPS <= 8 &&
      !(
        eduReasons?.includes('MentorManagementSupport') ||
        eduReasons?.includes('EducationalResourcesQuality') ||
        eduReasons?.includes('MentorCompatibility') ||
        eduReasons?.includes('AdmissionResults') ||
        eduReasons?.includes('CustomerSupport') ||
        eduReasons?.includes('LearningProgressOverview') ||
        eduReasons?.includes('FeedbackQuality') ||
        eduReasons?.includes('CourseStructure') ||
        // NEW
        eduReasons?.includes('Billing') ||
        eduReasons?.includes('QualityOfCourseResources') ||
        eduReasons?.includes('UsabilityOfCourseResources') ||
        eduReasons?.includes('CompensationBenefits') ||
        eduReasons?.includes('TrainingOnboarding') ||
        eduReasons?.includes('QualityOfStudents') ||
        eduReasons?.includes('SchedulingFlexibility') ||
        eduReasons?.includes('NumberOfStudentAllocations') ||
        otherReasonUpgrade.length > 0
      )
    ) {
      setUpgradeNPSRequired(true);
    } else {
      setUpgradeNPSRequired(false);
    }

    // PLATFORM NPS
    if (
      platformNPS <= 8 &&
      !(
        platReasons?.includes('UserInterfaceExperience') ||
        platReasons?.includes('Performance') ||
        platReasons?.includes('Functionality') ||
        platReasons?.includes('CompatibilityAccessibility') ||
        platReasons?.includes('DocumentationSupport') ||
        platReasons?.includes('SecurityPrivacy') ||
        platReasons?.includes('TrainingOnboarding') ||
        platReasons?.includes('CustomizationPersonalization') ||
        otherReasonPlatform.length > 0
      )
    ) {
      setPlatformNPSRequired(true);
    } else {
      setPlatformNPSRequired(false);
    }
  }, [reasons, upgradeNPS, platformNPS]);

  useEffect(() => {
    if (!showModal && handleCurrentModal) {
      handleCurrentModal('mentor-capacity-modal');
    }
  }, [showModal]);

  const closeModalHandler = () => {
    onClose?.();

    if (modalPage === 'nps') {
      trackEvent(ANALYTICS_EVENT_NAMES.NPS_MODAL_CLOSE);
      return;
    }

    if (isMentor) {
      trackEvent(ANALYTICS_EVENT_NAMES.GLASSDOOR_REVIEW_MODAL_CLOSE);
      return;
    }
    trackEvent(ANALYTICS_EVENT_NAMES.GOOGLE_REVIEW_MODAL_CLOSE);
  };

  const thirdPartyReviewClickHandler = () => {
    setShowModal(false);

    if (isMentor) {
      trackEvent(ANALYTICS_EVENT_NAMES.GLASSDOOR_REVIEW_MODAL_BUTTON_CLICK);
      return;
    }

    trackEvent(ANALYTICS_EVENT_NAMES.GOOGLE_REVIEW_MODAL_BUTTON_CLICK);
  };

  return (
    <AgoraModal
      isModalOpen={showModal}
      width={width < 640 ? '90%' : '70%'}
      className="nps-modal max-h-[calc(100%-72px)] mt-14 overflow-hidden laptop:mt-0 laptop:max-h-none"
      footer={null}
      closable={
        parentView && submitted && upgradeNPS >= 9
          ? true
          : parentView && !submitted && upgradeNPS >= 9
          ? false
          : !(parentView && upgradeNPS < 9)
      }
      maskClosable={false}
      setModalOpen={() => handleShowModal()}
      mobile={width < 640}
      onClose={closeModalHandler}
    >
      {modalPage === 'nps' ? (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '24px',
            }}
            id="nps-div"
          >
            {/*FIRST QUESTION*/}
            <h2
              style={{
                color: COLORS.TEXT_PRIMARY,
                marginBottom: '48px',
              }}
              className="nps-question-text"
            >
              {isMentor
                ? 'How likely are you to recommend working as a mentor at Upgrade Education?'
                : 'How likely are you to recommend Upgrade Education to a friend or colleague?'}
            </h2>
            <div className="flex flex-col mb-12 w-full laptop:px-0 laptop:w-4/5">
              <div className="grid grid-cols-5 justify-between gap-2 m-auto sm:flex sm:w-full">
                {NPScolors.map((color, index) => (
                  <div
                    key={`${color}-${index}`}
                    style={{
                      backgroundColor: index + 1 === upgradeNPS ? color : '#252525',
                      color: index + 1 === upgradeNPS ? COLORS.BLACK : color,
                    }}
                    className="flex items-center justify-center cursor-pointer rounded-2xl w-9 max-w-14 aspect-square sm:w-14"
                    onClick={() => handleUpgradeNPS(index + 1)}
                  >
                    <span className="nps-card-text">&nbsp;{index + 1}&nbsp;</span>
                  </div>
                ))}
              </div>
              {showUpgradeNPSReasonMentor || showUpgradeNPSReasonStudent || width < 640 ? null : (
                <div
                  id="labels"
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '10px',
                    margin: '10px auto 0 auto',
                  }}
                >
                  <span
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                    }}
                    className="nps-label"
                  >
                    Not at all <br /> likely
                  </span>
                  <span
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                    }}
                    className="nps-label"
                  >
                    Extremely <br /> likely
                  </span>
                </div>
              )}
            </div>

            {/*MENTOR UPGRADE NPS REASON*/}
            {isMentor && showUpgradeNPSReasonMentor ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <p
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    marginBottom: '24px',
                  }}
                  className="nps-reason-text"
                >
                  We're sorry you had a less than ideal experience. Please select reasons for your
                  low score.
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginBottom: '24px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '50%',
                    }}
                  >
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{
                          marginRight: '12px',
                        }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.mentorManagementSupport}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('mentorManagementSupport', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Mentor Management & Support
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.billing}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('billing', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Billing
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.qualityOfCourseResources}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('qualityOfCourseResources', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Quality of Course Resources
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.usabilityOfCourseResources}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('usabilityOfCourseResources', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Usability of Course Resources
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.compensationBenefits}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('compensationBenefits', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Compensation and Benefits
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.trainingOnboarding}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('trainingOnboarding', e.target.checked, false)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Training and Onboarding
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.qualityOfStudents}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('qualityOfStudents', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Quality of Students
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.schedulingFlexibility}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('schedulingFlexibility', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Scheduling and Flexibility
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.numberOfStudentAllocations}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('numberOfStudentAllocations', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Number of Student Allocations
                      </span>
                    </div>
                  </div>
                </div>
                {/*OTHER*/}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '48px',
                  }}
                >
                  <span
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      fontSize: '16px',
                      lineHeight: '16px',
                      fontWeight: 'bold',
                    }}
                    className="banner-description"
                  >
                    Other:
                  </span>
                  <textarea
                    value={otherReasonUpgrade}
                    onChange={handleTextareaChangeUpgrade}
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: '6px',
                      color: COLORS.TEXT_PRIMARY,
                      marginTop: '12px',
                      borderWidth: '2px',
                    }}
                    className="border border-white placeholder-white custom-textarea"
                    placeholder="Please let us know about any other areas of concern"
                  />
                </div>
              </div>
            ) : null}

            {/*STUDENT UPGRADE NPS REASON*/}
            {!isMentor && showUpgradeNPSReasonStudent ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <p
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    marginBottom: '24px',
                  }}
                  className="nps-reason-text"
                >
                  We’re sorry you had a less than ideal experience. Please select reasons for your
                  low score.
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginBottom: '24px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '50%',
                    }}
                  >
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.educationalResourcesQuality}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('educationalResourcesQuality', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Educational Resources Quality
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.mentorCompatibility}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('mentorCompatibility', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Mentor Compatibility
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.admissionResults}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('admissionResults', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Admission Results
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.customerSupport}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('customerSupport', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Customer Support
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.learningProgressOverview}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('learningProgressOverview', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Learning Progress Overview
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.feedbackQuality}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('feedbackQuality', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Feedback Quality
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        style={{ marginRight: '12px' }}
                        className="custom-ant-checkbox"
                        checked={checkboxValues.education.courseStructure}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleCheckboxChange('courseStructure', e.target.checked)
                        }
                      />
                      <span
                        style={{ color: COLORS.TEXT_PRIMARY }}
                        className="nps-custom-checkbox-label"
                      >
                        Course Structure
                      </span>
                    </div>
                  </div>
                </div>
                {/*OTHER*/}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '48px',
                  }}
                >
                  <span
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      fontSize: width < 640 ? '14px' : '16px',
                      lineHeight: '16px',
                      fontWeight: 'bold',
                    }}
                    className="banner-description"
                  >
                    Other:
                  </span>
                  <textarea
                    value={otherReasonUpgrade}
                    onChange={handleTextareaChangeUpgrade}
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: '6px',
                      color: COLORS.TEXT_PRIMARY,
                      marginTop: '12px',
                      borderWidth: '2px',
                    }}
                    className="border border-white placeholder-white custom-textarea"
                    placeholder="Please let us know about any other areas of concern"
                  />
                </div>
              </div>
            ) : null}

            {!(parentView && parentView) && (
              <>
                {/*SECOND QUESTION*/}
                <h2
                  style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '48px' }}
                  className="nps-question-text"
                >
                  How satisfied are you with the Agora platform?
                </h2>
                <div className="flex flex-col mb-12 w-full laptop:px-0 laptop:w-4/5">
                  <div className="grid grid-cols-5 justify-between gap-2 m-auto sm:flex sm:w-full">
                    {NPScolors.map((color, index) => (
                      <div
                        key={`${color}-${index}`}
                        style={{
                          backgroundColor: index + 1 === platformNPS ? color : '#252525',
                          color: index + 1 === platformNPS ? COLORS.BLACK : color,
                        }}
                        className="flex items-center justify-center cursor-pointer rounded-2xl w-9 max-w-14 aspect-square sm:w-14"
                        onClick={() => handlePlatformNPS(index + 1)}
                      >
                        <span className="nps-card-text">&nbsp;{index + 1}&nbsp;</span>
                      </div>
                    ))}
                  </div>
                  {width < 640 ||
                    (!showPlatformNPSReason && (
                      <div
                        id="labels"
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: '10px',
                          margin: '10px auto 0 auto',
                        }}
                      >
                        <span
                          style={{
                            color: COLORS.TEXT_SECONDARY,
                          }}
                          className="nps-label"
                        >
                          Not at all <br /> likely
                        </span>
                        <span
                          style={{
                            color: COLORS.TEXT_SECONDARY,
                          }}
                          className="nps-label"
                        >
                          Extremely <br /> likely
                        </span>
                      </div>
                    ))}
                </div>
                {/*PLATFORM NPS REASON*/}
                {showPlatformNPSReason ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <p
                      style={{
                        color: COLORS.TEXT_SECONDARY,
                        marginBottom: '24px',
                      }}
                      className="nps-reason-text"
                    >
                      Sorry you encountered issues. Please pick reasons for your low score.
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '24px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          width: '50%',
                        }}
                      >
                        <div style={{ display: 'flex', marginBottom: '16px' }}>
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.userInterfaceExperience}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange(
                                'userInterfaceExperience',
                                e.target.checked,
                                true
                              )
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            User Interface and Experience
                          </span>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '16px' }}>
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.performance}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange('performance', e.target.checked, true)
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            Performance
                          </span>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '16px' }}>
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.functionality}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange('functionality', e.target.checked, true)
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            Functionality
                          </span>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.compatibilityAccessibility}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange(
                                'compatibilityAccessibility',
                                e.target.checked,
                                true
                              )
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            Compatibility / Accessibility
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '50%',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ display: 'flex', marginBottom: '16px' }}>
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.documentationSupport}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange('documentationSupport', e.target.checked, true)
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            Documentation and Support
                          </span>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '16px' }}>
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.securityPrivacy}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange('securityPrivacy', e.target.checked, true)
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            Security and Privacy
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '16px',
                          }}
                        >
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.trainingOnboarding}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange('trainingOnboarding', e.target.checked, true)
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            Training and Onboarding
                          </span>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Checkbox
                            style={{ marginRight: '12px' }}
                            className="custom-ant-checkbox"
                            checked={checkboxValues.platform.customizationPersonalization}
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCheckboxChange(
                                'customizationPersonalization',
                                e.target.checked,
                                true
                              )
                            }
                          />
                          <span
                            style={{ color: COLORS.TEXT_PRIMARY }}
                            className="nps-custom-checkbox-label"
                          >
                            Customization and Personalization
                          </span>
                        </div>
                      </div>
                    </div>
                    {/*OTHER*/}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: '48px',
                      }}
                    >
                      <span
                        style={{
                          color: COLORS.TEXT_PRIMARY,
                          fontSize: '16px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Other:
                      </span>
                      <textarea
                        value={otherReasonPlatform}
                        onChange={handleTextareaChangePlatform}
                        style={{
                          backgroundColor: 'transparent',
                          borderRadius: '6px',
                          color: COLORS.TEXT_PRIMARY,
                          marginTop: '12px',
                          borderWidth: '2px',
                        }}
                        className="border border-white placeholder-white custom-textarea"
                        placeholder="Please let us know about any other areas of concern"
                      />
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
            className="mb-6"
          >
            <SecondaryButton
              onClick={() => {
                if (
                  (!!parentView && !!upgradeNPS && !upgradeNPSRequired) ||
                  (!!upgradeNPS && !!platformNPS && !upgradeNPSRequired && !platformNPSRequired)
                ) {
                  submitFeedback();
                }
              }}
              backgroundColor={COLORS.WHITE}
              width="50%"
              disabled={
                !(
                  (!!parentView && !!upgradeNPS && !upgradeNPSRequired) ||
                  (!!upgradeNPS && !!platformNPS && !upgradeNPSRequired && !platformNPSRequired)
                )
              }
            >
              <span style={{ color: COLORS.BLACK }}>Submit</span>
            </SecondaryButton>
          </div>
        </>
      ) : (
        <div
          style={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // marginLeft: '56px',
            marginLeft: width < 640 ? 'inherit' : '56px',
          }}
        >
          <div style={{ marginBottom: '24px' }}>
            <img
              style={{ maxWidth: '120px', minWidth: '120px', height: 'auto' }}
              src={PromotersFeedbackImage}
              alt="feedback-image"
            />
          </div>
          <div style={{ marginBottom: '24px' }}>
            <h2 style={{ color: COLORS.TEXT_PRIMARY }} className="nps-promoters-title">
              Your Feedback Matters!
            </h2>
          </div>
          <div style={{ marginBottom: '48px' }}>
            <p style={{ color: COLORS.TEXT_SECONDARY }} className="nps-promoters-description">
              Thank you for sharing your feedback with us! We truly value your opinion and use it to
              improve our services. Would you mind leaving us a Google review to help others
              discover our mentoring program?
            </p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '48px',
            }}
          >
            <a
              style={{ width: width < 640 ? '100%' : '50%' }}
              href={
                isMentor
                  ? 'https://www.glassdoor.com/mz-survey/employer/collectReview_input.htm?i=3073929&j=true&y=&c=PAGE_INFOSITE_TOP'
                  : 'https://g.page/r/CWeejQtaG5bgEBM/review'
              }
              target="_blank"
              onClick={thirdPartyReviewClickHandler}
              rel="noreferrer"
            >
              <SecondaryButton
                backgroundColor={COLORS.PRIMARY}
                borderColor={COLORS.PRIMARY}
                height="56px"
                width="calc(100% - 16px)"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{
                      minWidth: '21px',
                      maxWidth: '21px',
                      marginRight: '12px',
                    }}
                    src={RateIcon}
                    alt="rate"
                  />
                  {isMentor ? (
                    <span>Write us a Glassdoor Review</span>
                  ) : (
                    <span>Write us a Google Review</span>
                  )}
                </div>
              </SecondaryButton>
            </a>
          </div>
          <div style={{ marginBottom: '48px' }}>
            <p style={{ color: COLORS.TEXT_SECONDARY }} className="nps-promoters-description">
              Your review helps others make informed decisions and enables us to help out more
              students.
            </p>
          </div>
          <div style={{ marginBottom: '48px' }}>
            <p style={{ color: COLORS.TEXT_SECONDARY }} className="nps-promoters-description">
              Thank you for your support!
            </p>
          </div>
        </div>
      )}
    </AgoraModal>
  );
};

export default MonthlyNPSModal;
