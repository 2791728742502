import Tabs from '@components/V4/Tabs/Tabs';
import { BasicProps } from 'apps/agora/src/utils/types';
import AdmissionRequirementsTab from './AdmissionRequirementsTab/AdmissionRequirementsTab';
import AdmissionRoundsTab from './AdmissionRoundsTab/AdmissionRoundsTab';
import RankingsTab from './RankingsTab/RankingsTab';

export type UniversityTab = 'admission-rounds' | 'admission-requirements' | 'rankings';

interface UniversityTabsSectionProps extends BasicProps {
  tab?: UniversityTab;
}

const UniversityTabsSection = (props: UniversityTabsSectionProps) => {
  const { tab = 'admission-rounds' } = props;

  const tabs: { label: string; value: UniversityTab }[] = [
    { label: 'Admission Rounds', value: 'admission-rounds' },
    { label: 'Admission Requirements', value: 'admission-requirements' },
    { label: 'Rankings', value: 'rankings' },
  ];

  const renderContent = (currentTab: UniversityTab) => {
    switch (currentTab) {
      case 'admission-rounds':
        return <AdmissionRoundsTab />;
      case 'admission-requirements':
        return <AdmissionRequirementsTab />;
      case 'rankings':
        return <RankingsTab />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-6">
      <Tabs tabs={tabs} initialTab={tab} renderContent={renderContent} />
    </div>
  );
};

export default UniversityTabsSection;
