import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { BasicProps } from '../utils/types';
import { useAuthState } from './AuthContext';

interface NewRelicContextProps extends BasicProps {
  newRelicAgent: BrowserAgent;
  children?: ReactNode;
}

const NewRelicContext = createContext<{ newRelicAgent: BrowserAgent } | undefined>(undefined);

export const NewRelicProvider = (props: NewRelicContextProps) => {
  const { newRelicAgent, children } = props;

  const { userId } = useAuthState();

  useEffect(() => {
    if (userId) {
      newRelicAgent.setUserId(userId);
    }
  }, [userId]);

  return <NewRelicContext.Provider value={{ newRelicAgent }}>{children}</NewRelicContext.Provider>;
};

export const useNewRelic = () => {
  const context = useContext(NewRelicContext);

  if (context === undefined) {
    throw new Error('useNewRelic must be used within a NewRelicProvider');
  }

  return context;
};
