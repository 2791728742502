import { useEndMeeting } from '@actions';
import RescheduleModal from '@components/V4/RescheduleModal';
import { useAnalytics } from '@contexts/AnalyticsContext';
import { useAuthState } from '@contexts/AuthContext';
import {
  END_MEETING_NON_BREAKABLE_ERRORS_TYPE,
  EndMeetingNonBreakableErrors,
} from '@shared/constants';
import { ErrorCodes, MeetingStatus } from '@shared/enums';
import { MediaDevice } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CancelMeetingModal from './CancelMeetingModal';
import ConfirmNoShowModal from './ConfirmNoShowModal';
import MeetingStatusModal from './MeetingStatusModal';
import MentorEndMeetingModal from './MentorEndMeetingModal';
import SettingsModal from './SettingsModal/SettingsModal';
import StudentEndMeetingModal from './StudentEndMeetingModal';

export type VideoMeetingModal =
  | 'end-meeting'
  | 'settings'
  | 'meeting-status'
  | 'cancel-meeting'
  | 'confirm-no-show'
  | 'reschedule'
  | undefined;

interface VideoMeetingModalsProps extends BasicProps {
  micList: MediaDevice[];
  cameraList: MediaDevice[];
  backgroundSuppression: boolean;
  virtualBackground?: string;
  activeModal: VideoMeetingModal;
  setActiveModal: (activeModal: VideoMeetingModal) => void;
  setBackgroundSuppression: React.Dispatch<React.SetStateAction<boolean>>;
  setVirtualBackground: React.Dispatch<React.SetStateAction<string | undefined>>;
  onCloseModal: () => void;
}

const VideoMeetingModals = (props: VideoMeetingModalsProps) => {
  const {
    micList,
    cameraList,
    virtualBackground,
    activeModal,
    backgroundSuppression,
    onCloseModal,
    setActiveModal,
    setBackgroundSuppression,
    setVirtualBackground,
  } = props;

  const { isMentor } = useAuthState();

  const toast = useToast();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const { meetingId } = useParams<{ meetingId: string }>();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const { mutate: endMeeting } = useEndMeeting(meetingId, {
    onSuccess: (data) => {
      trackEvent(ANALYTICS_EVENT_NAMES.MEETING_LEAVE);

      if (
        typeof data !== 'string' &&
        'error' in data &&
        data.error.code === EndMeetingNonBreakableErrors.COULDNT_SEND_EMAIL
      ) {
        toast.error(
          END_MEETING_NON_BREAKABLE_ERRORS_TYPE[EndMeetingNonBreakableErrors.COULDNT_SEND_EMAIL]
        );
      }
      onCloseModal();
      history.replace('/home');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.error?.message ??
        error?.data?.message ??
        error.message ??
        'An error occurred';

      if (error.response.data.error.code === ErrorCodes.MEETING_INVALID_STATUS) {
        history.replace('/home');

        return;
      }

      trackEvent(ANALYTICS_EVENT_NAMES.MEETING_LEAVE_FAIL, errorMessage);
    },
  });

  const leaveMeetingHandler = async (
    status?:
      | MeetingStatus.ENDED
      | MeetingStatus.CANCELLED
      | MeetingStatus.NO_SHOW
      | MeetingStatus.RESCHEDULED,
    reason?: string
  ) => {
    if (isMentor) {
      if (status) {
        if (status === MeetingStatus.RESCHEDULED) {
          trackEvent(ANALYTICS_EVENT_NAMES.MEETING_RESCHEDULE);

          history.replace('/home');

          return;
        }

        endMeeting({ status, endMeetingReason: reason });

        if (status === MeetingStatus.CANCELLED) {
          trackEvent(ANALYTICS_EVENT_NAMES.MEETING_CANCEL);
        }
      }
    } else {
      const redirectURL =
        params.get('inMeeting') === 'true' ? `/home?finishedMeetingId=${meetingId}` : '/home';

      trackEvent(ANALYTICS_EVENT_NAMES.MEETING_LEAVE);
      history.replace(redirectURL);
    }
  };

  const rescheduleMeetingClickHandler = () => {
    setActiveModal('reschedule');
    trackEvent(ANALYTICS_EVENT_NAMES.MEETING_RESCHEDULE_BUTTON_CLICK);
  };

  switch (activeModal) {
    case 'end-meeting':
      return isMentor ? (
        <MentorEndMeetingModal
          isOpen
          onClose={onCloseModal}
          onPrimaryButtonClick={() => leaveMeetingHandler(MeetingStatus.ENDED)}
          onSecondaryButtonClick={() => setActiveModal('meeting-status')}
        />
      ) : (
        <StudentEndMeetingModal
          isOpen
          onClose={onCloseModal}
          onPrimaryButtonClick={() => leaveMeetingHandler()}
          onSecondaryButtonClick={onCloseModal}
        />
      );
    case 'meeting-status':
      return (
        <MeetingStatusModal
          isOpen
          onClose={onCloseModal}
          onStudentCancelledClick={() => setActiveModal('cancel-meeting')}
          onStudentNoShowClick={() => setActiveModal('confirm-no-show')}
        />
      );
    case 'cancel-meeting':
      return (
        <CancelMeetingModal
          isOpen
          onClose={onCloseModal}
          onPrimaryButtonClick={rescheduleMeetingClickHandler}
          onSecondaryButtonClick={() => leaveMeetingHandler(MeetingStatus.CANCELLED)}
        />
      );
    case 'confirm-no-show':
      return (
        <ConfirmNoShowModal
          isOpen
          onClose={onCloseModal}
          onPrimaryButtonClick={(reason?: string) =>
            leaveMeetingHandler(MeetingStatus.NO_SHOW, reason)
          }
          onSecondaryButtonClick={onCloseModal}
        />
      );
    case 'reschedule':
      return (
        <RescheduleModal
          isOpen
          meetingId={meetingId}
          onClose={onCloseModal}
          onReschedule={() => leaveMeetingHandler(MeetingStatus.RESCHEDULED)}
        />
      );
    case 'settings':
      return (
        <SettingsModal
          isOpen
          onClose={onCloseModal}
          backgroundSuppression={backgroundSuppression}
          virtualBackground={virtualBackground}
          onBackgroundSuppressionChange={(value) => setBackgroundSuppression(value)}
          onVirtualBackgroundChange={(value) => setVirtualBackground(value)}
          micList={micList}
          cameraList={cameraList}
        />
      );
    default:
      return null;
  }
};

export default VideoMeetingModals;
