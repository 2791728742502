import { UserRole } from '@shared/enums';
import { useMobileState } from '../MobileContext';
import { useNavigationState } from '../NavigationContext';
import * as tabs from '../tabs';
import { useAuthState } from '@contexts/AuthContext';

const MenuTabs = () => {
  const { roles } = useAuthState();
  const { menuType } = useNavigationState();
  const isMobile = useMobileState();
  return (
    <>
      {menuType === UserRole.Student && tabs.studentTabs(isMobile)}
      {menuType === UserRole.Mentor && tabs.mentorTabs(isMobile)}
      {menuType === UserRole.Admin && tabs.adminTabs(roles)}
    </>
  );
};

export default MenuTabs;
