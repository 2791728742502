import SkeletonBox from '@components/V4/SkeletonBox';

interface ProfileAsideInfoProps {
  label?: string;
  value: string | string[];
  isLoading?: boolean;
}

const ProfileAsideInfo = (props: ProfileAsideInfoProps) => {
  const { label, value, isLoading = false } = props;
  return (
    <div>
      {!!label && <p className="text-sm text-customGrey">{label}</p>}

      {isLoading ? (
        <SkeletonBox className="h-3.5 my-0.75 w-2/3" />
      ) : (
        <p className="text-sm">{Array.isArray(value) ? value.join(', ') : value}</p>
      )}
    </div>
  );
};

export default ProfileAsideInfo;
