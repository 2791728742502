import { useAddUniversityAdmissionRound, useUpdateAdmissionRoundInUniversity } from '@actions';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import ModalFormWrapper from '@modules/Admin/components/ModalFormWrapper';
import { RESULTS_TIMELINE_PERIODS } from '@shared/constants';
import { UniversityAdmissionRound } from '@shared/serverTypes';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';

interface AdmissionRoundsFormProps extends BasicProps {
  universityId: string;
  isModeAddNew?: boolean;
  year?: number;
  admissionRoundData?: UniversityAdmissionRound;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  year: z.number().min(1, { message: 'Please select a year.' }),
  name: z.string().min(1, { message: 'Please enter a valid name.' }),
  deadline: z.string({ message: 'Please select a start date' }),
  resultsTimelinePeriod: z.string().optional(),
  resultsTimelineMonth: z.string().min(1, { message: 'Please select a month.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const AdmissionRoundsForm = (props: AdmissionRoundsFormProps) => {
  const { universityId, year, admissionRoundData, isModeAddNew, onCloseEditForm } = props;

  const toast = useToast();

  const { mutate: addAdmissionRound, isLoading: isAddingAdmissionRound } =
    useAddUniversityAdmissionRound(universityId, {
      onSuccess: () => {
        toast.success('Successfully added admission round');
        onCloseEditForm();
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error?.message ?? 'There was an error, please try again later.';

        toast.error(errorMessage);
      },
    });

  const { mutate: updateAdmissionRound, isLoading: isUpdatingAdmissionRound } =
    useUpdateAdmissionRoundInUniversity({
      onSuccess: () => {
        toast.success('Successfully updated admission round');
        onCloseEditForm();
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error?.message ?? 'There was an error, please try again later.';

        toast.error(errorMessage);
      },
    });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      year: year,
      name: admissionRoundData?.name,
      deadline: admissionRoundData?.deadline.toString(),
      resultsTimelinePeriod: admissionRoundData?.resultsTimeline.period,
      resultsTimelineMonth: admissionRoundData?.resultsTimeline.month,
    },
  });

  const { field: yearField, fieldState: yearFieldState } = useController({
    name: 'year',
    control,
  });

  const { field: deadlineField, fieldState: deadlineFieldState } = useController({
    name: 'deadline',
    control,
  });

  const { field: resultsTimelinePeriodField, fieldState: resultsTimelinePeriodFieldState } =
    useController({
      name: 'resultsTimelinePeriod',
      control,
    });

  const { field: resultsTimelineMonthField, fieldState: resultsTimelineMonthFieldState } =
    useController({
      name: 'resultsTimelineMonth',
      control,
    });

  const touchAllFields = (
    fields: Partial<Record<keyof FormData, string | number | { _id: string; fullName: string }>>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();

    if (isValid && universityId) {
      if (isModeAddNew) {
        addAdmissionRound({
          year: data.year,
          name: data.name,
          deadline: data.deadline,
          resultsTimeline: {
            period: data.resultsTimelinePeriod,
            month: data.resultsTimelineMonth,
          },
        });

        return;
      }

      if (admissionRoundData?._id && year) {
        updateAdmissionRound([universityId, admissionRoundData?._id || ''], {
          year: year,
          name: data.name,
          deadline: data.deadline,
          resultsTimeline: {
            period: data.resultsTimelinePeriod,
            month: data.resultsTimelineMonth,
          },
        });
      }
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <ModalFormWrapper
      title={`${isModeAddNew ? 'Add' : 'Edit'} Admission Round`}
      submitButtonText={`${isModeAddNew ? 'Create' : 'Edit'} Admission Round`}
      isLoading={isAddingAdmissionRound || isUpdatingAdmissionRound}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
    >
      {isModeAddNew && (
        <AgoraDatePicker
          value={yearField.value ? moment(yearField.value.toString()) : undefined}
          onChange={(value) => yearField.onChange(moment(value).year())}
          onBlur={yearField.onBlur}
          allowClear={false}
          picker="year"
          isRequired
          label="Year"
          isValid={!yearFieldState.error}
          isTouched={yearFieldState.isTouched}
          errorText={yearFieldState.error?.message}
        />
      )}

      <div className="flex justify-between gap-4">
        <Input
          isRequired
          type="text"
          id="name"
          label="Name"
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          {...register('name', { required: true })}
        />
        <AgoraDatePicker
          isRequired
          label="Deadline"
          allowClear={false}
          value={deadlineField.value ? moment(deadlineField.value) : undefined}
          onChange={(value) => deadlineField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={deadlineField.onBlur}
          isValid={!deadlineFieldState.error}
          isTouched={deadlineFieldState.isTouched}
          errorText={deadlineFieldState.error?.message}
        />
      </div>
      <div className="flex justify-between gap-4">
        <Select
          size="large"
          label="Results Timeline Period"
          options={RESULTS_TIMELINE_PERIODS.map((subject) => ({
            label: subject,
            value: subject,
          }))}
          value={resultsTimelinePeriodField.value}
          onSelect={(value) => resultsTimelinePeriodField.onChange(value)}
          onClear={() => resultsTimelinePeriodField.onChange('')}
          onBlur={resultsTimelinePeriodField.onBlur}
          isValid={!resultsTimelinePeriodFieldState.error}
          isTouched={resultsTimelinePeriodFieldState.isTouched}
          errorText={resultsTimelinePeriodFieldState.error?.message}
        />
        <Select
          size="large"
          label="Results Timeline Month"
          options={moment.months().map((month) => ({
            label: month,
            value: month,
          }))}
          isRequired
          value={resultsTimelineMonthField.value}
          onSelect={(value) => resultsTimelineMonthField.onChange(value)}
          onClear={() => resultsTimelineMonthField.onChange('')}
          onBlur={resultsTimelineMonthField.onBlur}
          isValid={!resultsTimelineMonthFieldState.error}
          isTouched={resultsTimelineMonthFieldState.isTouched}
          errorText={resultsTimelineMonthFieldState.error?.message}
        />
      </div>
    </ModalFormWrapper>
  );
};

export default AdmissionRoundsForm;
