import { useGetExam } from '@actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import moment from 'moment';
import { useState } from 'react';
import ExamDetailsForm from './ExamDetailsForm';
import { formatTestDuration, formatValidityPeriod } from 'apps/agora/src/utils/helpers';

interface ExamModalDetailsProps {
  examId: string;
}

const ExamModalDetails = (props: ExamModalDetailsProps) => {
  const { examId } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const { data: exam, isLoading } = useGetExam(examId);

  const examDetailsArray = [
    [
      { label: 'Exam Name:', value: exam?.name },
      {
        label: 'Exam Date:',
        value: exam?.examDate ? moment(exam?.examDate).format('DD.MM.YYYY') : 'n/a',
      },
      {
        label: 'Registration Deadline:',
        value: exam?.registrationDeadline
          ? moment(exam?.registrationDeadline).format('DD.MM.YYYY')
          : 'n/a',
      },
      {
        label: 'Test Duration:',
        value: exam?.testDuration ? formatTestDuration(exam?.testDuration) : 'n/a',
      },
      {
        label: 'Validity Period:',
        value: exam?.validityPeriod ? formatValidityPeriod(exam?.validityPeriod) : 'n/a',
      },
    ],
    [
      {
        label: 'Scoring Scale:',
        value: exam?.scoringScale ? `${exam.scoringScale.min} - ${exam.scoringScale.max}` : 'n/a',
      },
      { label: 'Target Score:', value: exam?.targetScore },
      {
        label: 'Test Fee:',
        value: exam?.testFee ? `${exam.testFee.value} ${exam.testFee.currency}` : 'n/a',
      },
      { label: 'Retake Policy:', value: exam?.retakePolicy ?? 'n/a' },
      { label: 'Mode of Registration:', value: exam?.modeOfRegistration ?? 'n/a' },
    ],
    [
      {
        label: 'Updated At:',
        value: exam?.updatedAt ? moment(exam?.updatedAt).format('DD.MM.YYYY') : 'n/a',
      },
      {
        label: 'Modified By:',
        value: exam?.modifiedBy?.fullName,
      },
      {
        label: 'Created At:',
        value: exam?.createdAt ? moment(exam?.createdAt).format('DD.MM.YYYY') : 'n/a',
      },
      { label: 'Added By:', value: exam?.addedBy?.fullName },
    ],
  ];

  const editModeChangeHandler = () => {
    setIsEditMode((editMode) => !editMode);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isEditMode) return <ExamDetailsForm exam={exam} onCloseEditMode={editModeChangeHandler} />;

  return (
    <>
      <div className="flex justify-between mb-2">
        <p className="text-xs font-bold">Exam Details</p>
        <Button buttonText="Edit" onClick={editModeChangeHandler} />
      </div>
      <div className="w-full flex gap-x-8 justify-between">
        {examDetailsArray.map((examDetails, index) => (
          <div key={`column-${index}`} className="flex flex-col gap-2 ">
            {examDetails.map((examDetail, index) => (
              <span key={`row-${index}`} className="flex text-customGrey font-raleway text-xs">
                <span className="min-w-max">{examDetail.label}</span>
                <span className="text-white ml-2 break-words">{examDetail.value ?? 'n/a'}</span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
export default ExamModalDetails;
