import { ApiData, ApiDescription, RequestsMethod, TriState } from '@shared/clientTypes';
import {
  GetUniversitiesDTO,
  University,
  UniversityLight,
} from '@shared/clientTypes/DTOs/UniversityDTO';
import { UniversityAdmissionRound } from '@shared/serverTypes';

export const addUniversitiesFromCSV: ApiDescription = {
  endpoint: '/add-universities-from-csv',
  method: RequestsMethod.Post,
};

export type AddUniversitiesFromCSV = ApiData<unknown, unknown>;

export const deleteLastAddedUniversities: ApiDescription = {
  endpoint: '/delete-last-added-universities/:importBatchId',
  method: RequestsMethod.Delete,
};

export type DeleteLastAddedUniversities = ApiData<unknown, unknown>;

export const getUniversities: ApiDescription = {
  endpoint: '/universities',
  method: RequestsMethod.Get,
};

export type GetUniversities = ApiData<
  {
    year: number;
    page?: number;
    limit?: number;
    sortOrder?: 'asc' | 'desc';
    sortBy?: string;
    region?: string;
    country?: string;
    search?: string;
    isActive: TriState;
    isUniversityQSSource: TriState;
    isRankedForSelectedYear: TriState;
  },
  GetUniversitiesDTO
>;

export const getUniversitiesLight: ApiDescription = {
  endpoint: '/universities-light',
  method: RequestsMethod.Get,
};

export type GetUniversitiesLight = ApiData<
  {
    search: string;
    limit: number;
  },
  UniversityLight[]
>;

export type GetUniversity = ApiData<never, University>;

export const getUniversity: ApiDescription = {
  endpoint: '/university/:id',
  method: RequestsMethod.Get,
};

export type GetUniversityAdmissionRounds = ApiData<
  { id: string; graduationYear: number },
  { year?: number; admissionRounds?: UniversityAdmissionRound[] }
>;

export const getUniversityAdmissionRounds: ApiDescription = {
  endpoint: '/university/admission-rounds',
  method: RequestsMethod.Get,
};

export type GetUniversityCountriesAndRegions = ApiData<
  never,
  { countries: string[]; regions: string[] }
>;

export const getUniversityCountriesAndRegions: ApiDescription = {
  endpoint: '/university-countries-and-regions',
  method: RequestsMethod.Get,
};

export type AddUniversityAdmissionRound = ApiData<
  {
    year: number;
    name: string;
    deadline: string;
    resultsTimeline: {
      period?: string;
      month?: string;
    };
  },
  University
>;

export const addUniversityAdmissionRound: ApiDescription = {
  endpoint: '/university/:id/admission-round',
  method: RequestsMethod.Post,
};

export type UpdateAdmissionRoundInUniversity = ApiData<
  {
    year: number;
    name: string;
    deadline: string;
    resultsTimeline: {
      period?: string;
      month?: string;
    };
  },
  University
>;

export const updateAdmissionRoundInUniversity: ApiDescription = {
  endpoint: '/university/:id/admission-round/:roundId',
  method: RequestsMethod.Patch,
};

export type DeleteAdmissionRoundFromUniversity = ApiData<unknown, string>;

export const deleteAdmissionRoundFromUniversity: ApiDescription = {
  endpoint: '/university/:id/admission-round/:roundId',
  method: RequestsMethod.Delete,
};

export type AddUniversityAdmissionRequirement = ApiData<
  {
    year: number;
    notationSystemName: string;
    minGPA: string;
    exam: string;
    minExamScore: string;
  },
  University
>;

export const addUniversityAdmissionRequirement: ApiDescription = {
  endpoint: '/university/:id/admission-requirement',
  method: RequestsMethod.Post,
};

export type UpdateAdmissionRequirementInUniversity = ApiData<
  {
    year: number;
    notationSystemName: string;
    minGPA: string;
    exam: string;
    minExamScore: string;
  },
  University
>;

export const updateAdmissionRequirementInUniversity: ApiDescription = {
  endpoint: '/university/:id/admission-requirement/:requirementId',
  method: RequestsMethod.Patch,
};

export type DeleteAdmissionRequirementFromUniversity = ApiData<unknown, string>;

export const deleteAdmissionRequirementFromUniversity: ApiDescription = {
  endpoint: '/university/:id/admission-requirement/:requirementId',
  method: RequestsMethod.Delete,
};

export type AddUniversity = ApiData<
  {
    name: string;
    country: string;
    region: string;
    size?: string;
    focus?: string;
    researchIntensity?: string;
  },
  University
>;

export const addUniversity: ApiDescription = {
  endpoint: '/university',
  method: RequestsMethod.Post,
};

export type DeleteUniversity = ApiData<unknown, string>;

export const deleteUniversity: ApiDescription = {
  endpoint: '/university/:id',
  method: RequestsMethod.Delete,
};

export type RestoreUniversity = ApiData<unknown, string>;

export const restoreUniversity: ApiDescription = {
  endpoint: '/university/restore/:id',
  method: RequestsMethod.Patch,
};

export type UpdateUniversityDetails = ApiData<
  {
    name: string;
    country: string;
    region: string;
    size?: string;
    focus?: string;
    researchIntensity?: string;
  },
  University
>;

export const updateUniversityDetails: ApiDescription = {
  endpoint: '/university/:id',
  method: RequestsMethod.Patch,
};
