import {
  ActivityType,
  EmploymentType,
  ExperienceType,
  ExtraServiceStatus,
  FlagStatus,
  LmsCourseAccess,
  MeetingStatus,
  SessionStatus,
} from '../enums';

export const UPLOAD_INVOICE_STATES = {
  NOT_GENERATED: 'NOT_GENERATED',
  NOTHING_TO_UPLOAD: 'NOTHING_TO_UPLOAD',
  AWAITING_UPLOAD: 'AWAITING_UPLOAD',
  UPLOADED: 'UPLOADED',
  INVOICE_ACCEPTED: 'INVOICE_ACCEPTED',
  REJECTED: 'REJECTED',
  INVALID_E_INVOICE: 'INVALID_E_INVOICE',
  ERROR: 'ERROR',
};

export const FLAG_STATUS: { [key: string]: any } = {
  [FlagStatus.ACTIVE]: 'Active',
  [FlagStatus.CLOSED]: 'Closed',
};

export const SESSION_STATUSES: { [key: string]: any } = {
  [SessionStatus.PENDING]: 'Pending',
  [SessionStatus.SCHEDULED]: 'Scheduled',
  [SessionStatus.ONGOING]: 'Ongoing',
  [SessionStatus.NEEDS_FEEDBACK]: 'Needs feedback',
  [SessionStatus.COMPLETED]: 'Completed',
  [SessionStatus.PENDING_REVIEW]: 'Pending review',
  [SessionStatus.PAID]: 'Paid',
  [SessionStatus.UNPAYABLE]: 'Unpayable',
};

export const GET_SESSION_COMPLETED_STATUSES = (includePendingReviewCheck = false) => [
  SessionStatus.NEEDS_FEEDBACK,
  SessionStatus.COMPLETED,
  SessionStatus.PAID,
  SessionStatus.UNPAYABLE,
  ...(includePendingReviewCheck ? [SessionStatus.PENDING_REVIEW] : []),
];

export const LMS_COURSE_ACCESS: { [key: string]: any } = {
  [LmsCourseAccess.FREE]: 'Free',
  [LmsCourseAccess.DRAFT]: 'Draft',
};

export const EXTRA_SERVICE_STATUS: { [key: string]: any } = {
  [ExtraServiceStatus.CREATED]: 'Created',
  [ExtraServiceStatus.PAID]: 'Paid',
};

export const MEETING_STATUSES: { [key: string]: any } = {
  [MeetingStatus.SCHEDULED]: 'Scheduled',
  [MeetingStatus.RESCHEDULED]: 'Rescheduled',
  [MeetingStatus.CANCELLED]: 'Cancelled',
  [MeetingStatus.MISSED]: 'Missed',
  [MeetingStatus.STARTED]: 'Started',
  [MeetingStatus.ENDED]: 'Ended',
  [MeetingStatus.NO_SHOW]: 'No Show',
};

export const IRRELEVANT_MEETING_STATUSES = [
  MeetingStatus.CANCELLED,
  MeetingStatus.RESCHEDULED,
  MeetingStatus.MISSED,
  MeetingStatus.NO_SHOW,
];

export const STUDENT_STATUS = [
  // 'Waiting for payment',
  // 'Active',
  'No courses assigned',
  'Taking courses',
  'All courses completed',
  // 'Alumni',
];
