import ProfileIconPrimary from '@assets/icons/profile-icon-primary.png';
import ProfileIcon from '@assets/icons/profile-icon-white.png';
import { MENTOR_STATUS_V3 } from '@shared/constants';
import { CoursesCanTeach, MentorWithRate } from '@shared/clientTypes';
import { Tooltip } from 'antd';
import moment from 'moment';
import { useMemo, useState } from 'react';
import './mentors-table-row.styles.scss';
import Select from '@components/V4/Select/Select';
import { useUpdateMentorCapacity, useUpdateMentorData } from '@actions';

type Props = {
  key?: string | number;
  mentorData: MentorWithRate;
  refetchSearchMentors: () => void;
  refetchMentorStatistics: () => void;
  onProfileModalOpen: () => void;
};

const MentorsTableRow = ({
  key,
  mentorData,
  refetchSearchMentors,
  refetchMentorStatistics,
  onProfileModalOpen,
}: Props) => {
  const { mutate: updateMentorCapacity } = useUpdateMentorCapacity(mentorData.mentor._id, {
    onSuccess: () => {
      refetchMentorStatistics();
    },
  });

  const [maxCap, setMaxCap] = useState(
    Number(mentorData.mentor.mentorData.mentorCapacity?.capacity)
  );

  const [universities, majors] = useMemo(() => {
    const majors: Set<string> = new Set();
    const universities: Set<string> = new Set();

    mentorData?.mentor.educationalBackground?.forEach((background) => {
      const trimmedMajor = background.major?.trim();
      const trimmedEducationalInstitution = background.educationalInstitution?.trim();

      if (!!trimmedMajor) {
        majors.add(trimmedMajor);
      }

      if (!!trimmedEducationalInstitution) {
        universities.add(trimmedEducationalInstitution);
      }
    });

    mentorData.mentor.mentorData.universities?.forEach((university) => {
      const cleanUniversities = university
        .split(',')
        .map((splittedUniversity) => splittedUniversity.trim());

      cleanUniversities.forEach((cleanUniversity) => {
        if (!!cleanUniversity) {
          universities.add(cleanUniversity);
        }
      });
    });

    mentorData.mentor.studySubjects?.forEach((studySubject) => {
      const cleanStudySubjects = studySubject
        .split(',')
        .map((cleanStudySubject) => cleanStudySubject.trim());

      cleanStudySubjects.forEach((cleanStudySubject) => {
        if (!!cleanStudySubject) {
          majors.add(cleanStudySubject);
        }
      });
    });

    return [[...universities], [...majors]];
  }, [
    mentorData?.mentor.educationalBackground,
    mentorData.mentor.studySubjects,
    mentorData.mentor.mentorData.universities,
  ]);

  const { mutate: updateMentorData } = useUpdateMentorData(mentorData.mentor._id, {
    onSuccess: () => {
      refetchSearchMentors();
    },
  });

  const courses: CoursesCanTeach[] = mentorData.mentor.mentorData.coursesCanTeach ?? [];

  return (
    <tr>
      {/*MENTOR NAME*/}
      <td className="mentors-table-row-text">
        <span>{mentorData.mentor.fullName ?? '-'}</span>
      </td>

      {/*STATUS*/}
      <td style={{}} className="mentors-table-row-text min-w-44">
        <Select
          onSelect={(value) => {
            updateMentorData({
              status: value as string | undefined,
            });
          }}
          value={mentorData.mentor.mentorData.status ?? 'N/A'}
          options={MENTOR_STATUS_V3.map((option) => ({
            value: option,
            label: option,
          }))}
          allowClear={false}
          variant="ghost"
        />
      </td>

      {/*CAPACITY*/}
      <td className="mentors-table-row-text">
        <span>{mentorData?.currentCapacity ?? '-'}</span>
      </td>

      {/*MAX CAPACITY*/}
      <td style={{}} className="mentors-table-row-text">
        <span>
          <input
            className="block w-full rounded-md border-0 py-1.5 px-0 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:px-1.5 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
            style={{
              backgroundColor: 'transparent',
              width: '64px',
              height: '26px',
              marginBottom: '0px',
              fontSize: '12px',
              lineHeight: '16px',
            }}
            type="number"
            value={maxCap}
            min={0}
            onChange={(e) => setMaxCap(Number(e.target.value))}
            onBlur={() => {
              updateMentorCapacity({
                capacity: maxCap,
              });
            }}
          />
          <span className="h-0 w-0 block absolute text-0">{maxCap}</span>
        </span>
      </td>

      {/*MAX CAPACITY UPDATED AT*/}
      <td style={{}} className="mentors-table-row-text">
        <span>
          <input
            className="block w-full rounded-md border-0 py-1.5 px-0 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:px-1.5 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
            style={{
              backgroundColor: 'transparent',
              width: '88px',
              height: '26px',
              marginBottom: '0px',
              fontSize: '12px',
              lineHeight: '16px',
            }}
            type="text"
            value={
              mentorData.mentor.mentorData.mentorCapacity?.updatedAt
                ? moment(mentorData.mentor.mentorData.mentorCapacity?.updatedAt).format(
                    'DD.MM.YYYY'
                  )
                : '-'
            }
          />
          <span className="h-0 w-0 block absolute text-0">
            {mentorData.mentor.mentorData.mentorCapacity?.updatedAt
              ? moment(mentorData.mentor.mentorData.mentorCapacity?.updatedAt).format('DD.MM.YYYY')
              : '-'}
          </span>
        </span>
      </td>

      {/*PAY RATE*/}
      <td className="mentors-table-row-text">
        <span>
          {mentorData?.rate?.amount !== undefined && mentorData?.rate?.currency !== undefined
            ? `${mentorData?.rate?.amount} ${mentorData?.rate?.currency}/h`
            : '-'}
        </span>
      </td>

      {/*UNIVERSITIES*/}
      <td style={{}} className="mentors-table-row-text">
        {/*TOOLTIP*/}
        {universities.length > 1 ? (
          <Tooltip
            zIndex={1}
            color="#333333"
            title={
              <div>
                {universities.length > 1 &&
                  universities?.map((university: string) => (
                    <>
                      <span>{university};</span>
                      <br />
                    </>
                  ))}
              </div>
            }
          >
            <span>
              {Array.isArray(universities) && universities.length > 1
                ? universities[0] + '...'
                : Array.isArray(universities) && universities.length > 0
                ? universities[0] ?? '-'
                : '-'}
            </span>
          </Tooltip>
        ) : (
          <span>
            {Array.isArray(universities) && universities.length > 1
              ? universities[0] + '...'
              : Array.isArray(universities) && universities.length > 0
              ? universities[0] ?? '-'
              : '-'}
          </span>
        )}
      </td>

      {/*COURSES*/}
      <td className="mentors-table-row-text">
        {/*TOOLTIP*/}
        {courses.length > 1 ? (
          <Tooltip
            zIndex={1}
            color="#333333"
            title={
              <div>
                {courses.length > 1 &&
                  courses?.map((course: any) => (
                    <>
                      <span>{course?.title};</span>
                      <br />
                    </>
                  ))}
              </div>
            }
          >
            <span>
              {Array.isArray(courses) && courses.length > 1
                ? courses?.[0]?.title + '...'
                : Array.isArray(courses) && courses.length > 0
                ? courses?.[0]?.title ?? '-'
                : '-'}
            </span>
          </Tooltip>
        ) : (
          <span>
            {Array.isArray(courses) && courses.length > 1
              ? courses?.[0]?.title + '...'
              : Array.isArray(courses) && courses.length > 0
              ? courses?.[0]?.title ?? '-'
              : '-'}
          </span>
        )}
      </td>

      {/*STUDY SUBJECTS*/}
      <td className="mentors-table-row-text">
        <span>{majors?.join(', ')}</span>
      </td>

      {/*CREATED AT*/}
      <td className="mentors-table-row-text">
        <span>{moment(mentorData?.mentor?.createdAt).format('DD.MM.YYYY')}</span>
      </td>

      {/*ACCESS*/}
      <td>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '25%', display: 'flex' }}>
            <div
              onClick={onProfileModalOpen}
              style={{
                display: 'flex',
                marginRight: '12px',
                cursor: 'pointer',
              }}
              className="enrollments-action"
            >
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon"
                src={ProfileIcon}
                alt="enrollments"
              />
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon-primary"
                src={ProfileIconPrimary}
                alt="enrollments"
              />
              <span className="mentors-table-access-button">Profile</span>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default MentorsTableRow;
