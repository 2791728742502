import { useDeleteExam, useRestoreExam } from '@actions';
import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import { PaginationProps } from '@components/V4/Pagination';
import Table, { SortOrder } from '@components/V4/Table/Table';
import { SearchExamsDTO } from '@shared/clientTypes/DTOs/ExamsDTO';
import { Popconfirm } from 'antd';
import useToast from 'apps/agora/src/hooks/useToast';
import { formatTestDuration, formatValidityPeriod } from 'apps/agora/src/utils/helpers';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';

interface ExamsTableProps {
  exams?: SearchExamsDTO['exams'];
  areExamsLoading: boolean;
  pagination: PaginationProps;
  sortBy: string | null;
  sortOrder: SortOrder | null;
  setSearchParams: (data: Partial<any>) => void;
  openExamModalHandler: (examId: string) => void;
}

const ExamsTable = (props: ExamsTableProps) => {
  const {
    exams,
    areExamsLoading,
    setSearchParams,
    pagination,
    sortBy,
    sortOrder,
    openExamModalHandler,
  } = props;

  const toast = useToast();

  const { mutate: deleteExam } = useDeleteExam({
    onSuccess: async () => {
      toast.success('Exam deleted successfully.');
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message || 'Exam failed to delete.');
    },
  });

  const { mutate: restoreExam } = useRestoreExam({
    onSuccess: async () => {
      toast.success('Exam restored successfully.');
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message || 'Exam failed to restore.');
    },
  });

  const sortHandler = (field: string, order: SortOrder) => {
    setSearchParams({
      sortBy: order ? field : null,
      sortOrder: order || null,
      page: 1,
    });
  };

  const deleteExamHandler = (id?: string) => {
    if (!id) return;
    deleteExam([id], {});
  };

  const restoreExamHandler = (id?: string) => {
    if (!id) return;
    restoreExam([id], {});
  };

  return areExamsLoading ? (
    <div className="h-24 flex items-center justify-center text-customGrey">Loading...</div>
  ) : (
    <Table pagination={pagination} className="px-18 mb-20">
      <Table.Header>
        <Table.Row isHeader>
          <Table.HeaderCell
            onSort={(order) => sortHandler('name', order)}
            sortOrder={sortBy === 'name' ? sortOrder : undefined}
          >
            Exam Name
          </Table.HeaderCell>
          <Table.HeaderCell>Scoring Scale</Table.HeaderCell>
          <Table.HeaderCell>Target Score</Table.HeaderCell>
          <Table.HeaderCell>Test Duration</Table.HeaderCell>
          <Table.HeaderCell>Validity Period</Table.HeaderCell>
          <Table.HeaderCell>Exam Date</Table.HeaderCell>
          <Table.HeaderCell>Registration Deadline</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!areExamsLoading && !exams?.length ? (
          <tr>
            <td colSpan={8} className="h-64">
              <EmptyStateCard
                icon={<BlockIcon className="text-customGrey" />}
                text={'No results.'}
              />
            </td>
          </tr>
        ) : (
          exams?.map((exam) => (
            <Table.Row key={exam._id}>
              <Table.Cell>{exam.name}</Table.Cell>
              <Table.Cell>
                {exam.scoringScale.min} - {exam.scoringScale.max}
              </Table.Cell>
              <Table.Cell>{exam.targetScore}</Table.Cell>
              <Table.Cell>
                {exam.testDuration ? formatTestDuration(exam.testDuration) : 'n/a'}
              </Table.Cell>
              <Table.Cell>
                {exam.validityPeriod ? formatValidityPeriod(exam.validityPeriod) : 'n/a'}
              </Table.Cell>
              <Table.Cell>
                {exam.examDate ? moment(exam.examDate).format('DD.MM.YYYY') : 'n/a'}
              </Table.Cell>
              <Table.Cell>
                {exam.registrationDeadline
                  ? moment(exam.registrationDeadline).format('DD.MM.YYYY')
                  : 'n/a'}
              </Table.Cell>
              <Table.Cell>
                <div className="flex gap-2">
                  {!exam?.isDeleted && (
                    <span
                      className="cursor-pointer underline"
                      onClick={() => openExamModalHandler(exam?._id ?? '')}
                    >
                      View
                    </span>
                  )}
                  {!exam?.isDeleted ? (
                    <Popconfirm
                      onConfirm={() => deleteExamHandler(exam?._id)}
                      title={
                        <section className="max-w-110 flex flex-col gap-6">
                          <p>Are you sure you want to delete this Exam?</p>
                        </section>
                      }
                    >
                      <span className="cursor-pointer underline text-customRed">Delete</span>
                    </Popconfirm>
                  ) : (
                    <span
                      onClick={() => restoreExamHandler(exam?._id)}
                      className="cursor-pointer underline"
                    >
                      Restore
                    </span>
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          ))
        )}
        {}
      </Table.Body>
    </Table>
  );
};

export default ExamsTable;
