import Button from '@components/V4/Button';
import { MajorWithUser } from '@shared/clientTypes/DTOs/MajorsDTO';
import moment from 'moment';
import { useState } from 'react';
import MajorDetailsForm from './MajorDetailsForm';
import { useGetMajor } from 'apps/agora/src/actions/major.actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

interface MajorModalDetailsProps {
  majorId: string;
}

const MajorModalDetails = (props: MajorModalDetailsProps) => {
  const { majorId } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const { data: major, isLoading } = useGetMajor(majorId);

  const majorDetailsArray = [
    [
      { label: 'Major Name:', value: major?.name },
      {
        label: 'Created At:',
        value: major?.createdAt ? moment(major?.createdAt).format('DD.MM.YYYY') : 'n/a',
      },
      { label: 'Added By:', value: major?.addedBy?.fullName },
    ],
    [
      {
        label: 'Updated At:',
        value: major?.updatedAt ? moment(major?.updatedAt).format('DD.MM.YYYY') : 'n/a',
      },
      {
        label: 'Modified By:',
        value: major?.modifiedBy?.fullName,
      },
    ],
  ];

  const editModeChangeHandler = () => {
    setIsEditMode((editMode) => !editMode);
  };

  if (isLoading) return <LoadingSpinner />;

  if (isEditMode) return <MajorDetailsForm major={major} onCloseEditMode={editModeChangeHandler} />;

  return (
    <>
      <div className="flex justify-between mb-2">
        <p className="text-xs font-bold">Major Details</p>
        <Button buttonText="Edit" onClick={editModeChangeHandler} />
      </div>
      <div className="w-full flex gap-x-8 justify-between">
        {majorDetailsArray.map((majorDetails, index) => (
          <div key={`column-${index}`} className="flex flex-col gap-2 ">
            {majorDetails.map((majorDetail, index) => (
              <span key={`row-${index}`} className="flex text-customGrey font-raleway text-xs">
                <span className="min-w-max">{majorDetail.label}</span>
                <span className="text-white ml-2 break-words">{majorDetail.value ?? 'n/a'}</span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
export default MajorModalDetails;
