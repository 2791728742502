import { PaginationProps } from '@components/V4/Pagination';
import { SortOrder } from '@components/V4/Table/Table';
import { useDebounce, useQueryParamsState } from '@hooks';
import { TriState } from '@shared/common';
import { useSearchMajors } from 'apps/agora/src/actions/major.actions';
import { getValidMoment } from 'apps/agora/src/utils/helpers';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MajorModal from './components/MajorModal/MajorModal';
import MajorsHeader from './components/MajorsHeader';
import MajorsTable from './components/MajorsTable';

const useQuery = () => new URLSearchParams(useLocation().search);

const Majors = () => {
  const [majorSearch, setMajorSearch] = useState('');
  const debouncedMajorSearch = useDebounce(majorSearch, 500);
  const [showMajorModal, setShowMajorModal] = useState(false);
  const [majorId, setMajorId] = useState<string>();

  const query = useQuery();
  const limit = query.get('limit') || '10';
  const page = query.get('page') || '1';
  const rangeStart = getValidMoment(query.get('rangeStart'));
  const rangeEnd = getValidMoment(query.get('rangeEnd'));
  const user = query.get('user') || null;
  const sortBy = query.get('sortBy') || null;
  const sortOrder = (query.get('sortOrder') || null) as SortOrder;
  const showDeleted = (query.get('showDeleted') || 'unchecked') as TriState;

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    page: page,
    limit: limit,
    rangeStart: rangeStart,
    rangeEnd: rangeEnd,
    user: user,
    sortBy: sortBy,
    sortOrder: sortOrder,
    showDeleted: showDeleted,
  });

  const { data: majors, isLoading: areMajorsLoading } = useSearchMajors({
    limit: searchParams.limit,
    page: searchParams.page,
    rangeStart: searchParams.rangeStart,
    rangeEnd: searchParams.rangeEnd,
    user: searchParams.user,
    sortBy: searchParams.sortBy,
    sortOrder: searchParams.sortOrder,
    search: debouncedMajorSearch,
    showDeleted: searchParams.showDeleted,
  });

  const currentPageChangeHandler = (page: number) => {
    setSearchParams({
      page,
    });
  };

  const pageSizeChangeHandler = (size: number) => {
    setSearchParams({
      limit: size,
    });
  };

  const pagination: PaginationProps = {
    pageSize: limit !== null ? parseInt(limit) : 10,
    total: majors?.total,
    currentPage: page !== null ? parseInt(page) : 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  const closeMajorModalHandler = () => {
    setShowMajorModal(false);
  };

  const openMajorModalHandler = (majorId?: string) => {
    setMajorId(majorId);
    setShowMajorModal(true);
  };

  return (
    <section className="flex flex-col gap-8">
      <MajorsHeader
        showDeleted={showDeleted}
        majorSearch={majorSearch}
        setMajorSearch={setMajorSearch}
        createdByOptions={majors?.filters?.users}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        openMajorModalHandler={openMajorModalHandler}
      />

      <MajorsTable
        majors={majors?.majors}
        areMajorsLoading={areMajorsLoading}
        pagination={pagination}
        sortBy={searchParams.sortBy}
        sortOrder={searchParams.sortOrder}
        setSearchParams={setSearchParams}
        openMajorModalHandler={openMajorModalHandler}
      />
      {showMajorModal && <MajorModal majorId={majorId} onClose={closeMajorModalHandler} />}
    </section>
  );
};

export default Majors;
