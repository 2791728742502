import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import SessionModalFormWrapper from '@modules/Admin/components/DetailsModal/DetailsModalFormWrapper';
import { useGetAllExamNames, useGetExam, useSaveUserProfile } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';
import { UserExamWithName } from '@shared/clientTypes/DTOs/UserDTO';
import { UserExam } from '@shared/common';
import ExamInfoBar from '@components/V4/ExamInfoBar';
import Select from '@components/V4/Select/Select';

interface PastExamsSectionFormProps extends BasicProps {
  isModeAddNew?: boolean;
  pastExam?: UserExamWithName;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  exam: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  prevGrade: z.string().optional().default(''),
  date: z
    .string()
    .min(1, { message: 'Please enter a valid date.' })
    .refine((val) => moment(val).isSameOrBefore(moment(), 'day'), {
      message: 'Date cannot be in the future.',
    }),
});

type FormData = z.infer<typeof formDataSchema>;

const PastExamsSectionForm = (props: PastExamsSectionFormProps) => {
  const { isModeAddNew = false, pastExam, className, onCloseEditForm } = props;
  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const toast = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(studentId, {
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');
      onCloseEditForm();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const hasNewExamType = !!pastExam?.newExam?._id;

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      exam: hasNewExamType ? pastExam?.newExam._id : pastExam?.exam,
      prevGrade: pastExam?.prevGrade ?? '',
      date: pastExam?.date,
    },
  });

  const { field: dateField, fieldState: dateFieldState } = useController({
    name: 'date',
    control,
  });

  const { field: examField, fieldState: examFieldState } = useController({
    name: 'exam',
    control,
  });

  const { data: examOptions } = useGetAllExamNames();
  const { data: selectedExam } = useGetExam(examField.value, {
    enabled: (!!examField.value && isModeAddNew) || hasNewExamType,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const exam: Partial<UserExam> = {
        ...data,
        ...((isModeAddNew || pastExam?.newExam?._id) && { exam: undefined, newExam: data.exam }),
        _id: pastExam?._id,
      };

      updateUserProfile({
        exams: [exam],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Past Exam' : 'Edit Past Exam'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText="Create Past Exam"
    >
      {isModeAddNew || !!pastExam?.newExam ? (
        <Select
          value={examField.value}
          onSelect={(value) => examField.onChange(value)}
          onBlur={examField.onBlur}
          allowClear={false}
          options={examOptions?.map((exam) => ({
            label: exam.name,
            value: exam._id ?? '',
          }))}
          label="Exam"
          size="large"
          isRequired
          isValid={!examFieldState.error}
          isTouched={examFieldState.isTouched}
          errorText={examFieldState.error?.message}
        />
      ) : (
        <Input
          isRequired
          type="text"
          id="exam"
          label="Exam"
          isTouched={touchedFields.exam}
          isValid={!errors.exam}
          errorText={errors.exam?.message}
          autoFocus
          {...register('exam')}
        />
      )}

      {!!selectedExam && <ExamInfoBar exam={selectedExam} />}

      <AgoraDatePicker
        value={dateField.value ? moment(dateField.value) : undefined}
        onChange={(value) => dateField.onChange(moment(value).format('YYYY-MM-DD'))}
        disabledDate={(date) => date > moment(moment().format('YYYY-MM-DD'))}
        onBlur={dateField.onBlur}
        allowClear={false}
        isRequired
        label="Date"
        isValid={!dateFieldState.error}
        isTouched={dateFieldState.isTouched}
        errorText={dateFieldState.error?.message}
      />
      <Input type="text" id={'score'} placeholder={''} label={'Score'} {...register('prevGrade')} />
    </SessionModalFormWrapper>
  );
};

export default PastExamsSectionForm;
