import { mentorApis, usersApis } from '@shared/apis';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';
import { UserRole } from '@shared/enums';

export const useUpdateMyMentorData = genericMutationWithParams<usersApis.UpdateMyMentorData>(
  usersApis.updateMyMentorData,
  mentorApis.getAllCoursesCanTeach.endpoint
);

export const useUpdateMentorData = genericMutationWithId<usersApis.UpdateMentorData>(
  usersApis.updateMentorData
);

export const useStudentDetails = genericQueryWithId<usersApis.GetStudentDetails>(
  usersApis.getStudentDetails
);

export const useSearchUsers = genericQueryWithParams<usersApis.SearchUsers>(usersApis.searchUsers);

export const useSearchUsersByFullName = genericQueryWithParams<usersApis.SearchUsersByFullName>(
  usersApis.searchUsersByFullName
);

export const useMentors = (extraSearchQuery: any) => {
  return useSearchUsers({
    limit: 500,
    roles: [UserRole.Mentor],
    ...extraSearchQuery,
  });
};

export const useUserDetails = genericQueryWithId<usersApis.GetUserDetails>(
  usersApis.getUserDetails
);

export const useUserNotes = genericQueryWithId<usersApis.GetUserNotes>(usersApis.getUserNotes);

export const useUpdateUserNote = genericMutationWithId<usersApis.UpdateUserNote>(
  usersApis.updateUserNote,
  usersApis.getUserNotes.endpoint
);

export const useUpdateUser = genericMutationWithId<usersApis.UpdateUser>(
  usersApis.updateUser,
  usersApis.getStudentDetails.endpoint
);

export const useGetAllMentors = genericQueryWithParams<usersApis.GetAllMentors>(
  usersApis.getAllMentors
);

export const useGetAllStudents = genericQueryWithParams<usersApis.GetAllStudents>(
  usersApis.getAllStudents
);

export const useSearchStudents = genericQueryWithParams<usersApis.SearchStudents>(
  usersApis.searchStudents
);

export const useGetUserProfile = genericQueryWithId<usersApis.GetUserProfile>(
  usersApis.getUserProfile
);

export const useSaveUserProfile = genericMutationWithId<usersApis.SaveUserProfile>(
  usersApis.saveUserProfile,
  '/users/:id/profile',
  mentorApis.searchMentors.endpoint,
  usersApis.searchStudents.endpoint
);

export const useGetStudentStatistics = genericQuery<usersApis.GetStudentStatistics>(
  usersApis.getStudentStatistics
);

export const useSearchUsersV2 = genericQueryWithParams<usersApis.SearchUsersV2>(
  usersApis.searchUsersV2
);

export const useEditUserPermissions = genericMutationWithId<usersApis.EditUserPermissions>(
  usersApis.editUserPermissions
);

export const useGetUserPermissions = genericQueryWithId<usersApis.GetUserPermissions>(
  usersApis.getUserPermissions
);

export const useCheckIfUserExists = genericQueryWithParams<usersApis.IsUserOrParentEmailRegistered>(
  usersApis.isUserOrParentEmailRegistered
);

export const useAddVirtualBackground = genericMutation<usersApis.AddVirtualBackground>(
  usersApis.addVirtualBackground,
  usersApis.addVirtualBackground.endpoint
);

export const useDeleteVirtualBackground = genericMutation<usersApis.DeleteVirtualBackground>(
  usersApis.deleteVirtualBackground,
  usersApis.deleteVirtualBackground.endpoint
);

export const useGetVirtualBackgrounds = genericQuery<usersApis.GetVirtualBackgrounds>(
  usersApis.getVirtualBackgrounds
);

export const useGetVideoSettings = genericQuery<usersApis.GetVideoSettings>(
  usersApis.getVideoSettings
);

export const useUpdateVideoSettings = genericMutation<usersApis.UpdateVideoSettings>(
  usersApis.updateVideoSettings
);

export const useGetTimezonesList = genericQuery<usersApis.GetTimezonesList>(
  usersApis.getTimezonesList
);

export const useDeleteAdmissionDocument = genericMutationWithId<usersApis.DeleteAdmissionDocument>(
  usersApis.deleteAdmissionDocument,
  usersApis.deleteAdmissionDocument.endpoint,
  usersApis.getUserProfile.endpoint
);
