import { getTimestampFromMeetingTime } from 'apps/agora/src/utils/helpers';
import React from 'react';

interface ChatEntryProps {
  name: string;
  timestamp: string;
  message: string;
  meetingScheduledDate?: string;
}

const ChatEntry = (props: ChatEntryProps) => {
  const { name, timestamp, message, meetingScheduledDate } = props;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <p className="text-customGrey text-xs font-bold">{name}</p>
        {timestamp && meetingScheduledDate ? (
          <p className="text-customGrey text-xs leading-4 font-light">
            {getTimestampFromMeetingTime(timestamp, meetingScheduledDate)}
          </p>
        ) : (
          'n/a'
        )}
      </div>
      <p className="text-white text-xs leading-5 break-words">{message}</p>
    </div>
  );
};

export default ChatEntry;
