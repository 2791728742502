import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import AgoraInput from '@utils/AgoraInput';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';
import { PhoneNumber } from '@shared/serverTypes';
import Select from '@components/V4/Select/Select';
import { countries } from 'country-codes-flags-phone-codes';
import Input from '@components/V4/Inputs/Input';

type StudentDetails = {
  fullName: string;
  phoneNumber: PhoneNumber;
  parentName: string;
  parentEmail: string;
  parentPhoneNumber: PhoneNumber;
  city?: string;
  cityRef?: string;
  country: string;
  highschool: string;
  graduationYear: string;
  gpa: string;
  universities: string[];
  countriesToStudyIn: string[];
  major: string;
};

type Props = {
  handleCurrentPage: (page: string) => void;
  studentDetails: StudentDetails;
  handleInputChangeStudent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setStudentDetails: React.Dispatch<React.SetStateAction<StudentDetails>>;
};

const CreateNewUserStudentStepOne = ({
  handleCurrentPage,
  studentDetails,
  handleInputChangeStudent,
  setStudentDetails,
}: Props) => {
  const [validEmail, setValidEmail] = useState<undefined | boolean>();
  const [loadingHubspot, setLoadingHubspot] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [touched, setTouched] = useState<Record<keyof StudentDetails, boolean>>({
    fullName: false,
    phoneNumber: false,
    parentName: false,
    parentEmail: false,
    parentPhoneNumber: false,
    city: false,
    cityRef: false,
    country: false,
    highschool: false,
    graduationYear: false,
    gpa: false,
    universities: false,
    countriesToStudyIn: false,
    major: false,
  });

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const areDetailsFilled = !!(
    studentDetails.fullName &&
    studentDetails.phoneNumber.countryCode &&
    studentDetails.phoneNumber.number &&
    studentDetails.parentName &&
    studentDetails.parentEmail &&
    studentDetails.parentPhoneNumber.countryCode &&
    studentDetails.parentPhoneNumber.number
  );

  const getColor = (name: keyof typeof studentDetails) => {
    if (!touched[name] && !studentDetails[name]) {
      return COLORS.TEXT_SECONDARY;
    } else {
      return studentDetails[name] ? COLORS.PRIMARY : COLORS.ERROR;
    }
  };

  const handleEmailValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validEmail) {
      const isValid = validateEmail(e.target.value);
      if (isValid) {
        setValidEmail(isValid);
      }
    } else {
      const isValid = validateEmail(e.target.value);
      if (!isValid) {
        setValidEmail(isValid);
      }
    }
  };

  const colorAfterEmailValidation = !touched.parentEmail
    ? COLORS.TEXT_SECONDARY
    : validEmail === true
    ? getColor('parentEmail')
    : COLORS.ERROR;

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
              // marginTop: '32px',
            }}
            className="modal-page-section-title"
          >
            Basic Info/ Contact Info:
          </h3>

          <AgoraInput
            htmlFor="fullName"
            type="text"
            name="fullName"
            id="fullName"
            placeholder=""
            label="Full Name"
            color={getColor('fullName')}
            labelColor={getColor('fullName')}
            borderColor={getColor('fullName')}
            labelMargin="0 0 0 8px"
            value={studentDetails?.fullName}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeStudent}
            onBlur={handleBlur}
            isRequired
          />

          <div className="flex flex-row gap-2">
            <Select
              className="max-w-[15%]"
              value={studentDetails.phoneNumber.countryCode}
              onSelect={(value) =>
                setStudentDetails((prevStudentDetails) => ({
                  ...prevStudentDetails,
                  phoneNumber: {
                    countryCode: String(value),
                    number: prevStudentDetails.phoneNumber.number,
                  },
                }))
              }
              options={countries.map((country) => ({
                label: `(${country.dialCode}) ${country.name}`,
                value: country.dialCode,
              }))}
              label="Country Code"
              isRequired
              size="large"
            />

            <Input
              type="tel"
              id="phone"
              label="Phone Number"
              name="phoneNumber.number"
              prefix={studentDetails.phoneNumber.countryCode}
              value={studentDetails.phoneNumber.number}
              onChange={(e) =>
                setStudentDetails((prevStudentDetails) => ({
                  ...prevStudentDetails,
                  phoneNumber: {
                    countryCode: prevStudentDetails.phoneNumber.countryCode,
                    number: String(e.target.value),
                  },
                }))
              }
              isRequired
            />
          </div>

          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '16px',
              marginTop: '32px',
            }}
            className="modal-page-section-title"
          >
            Parent Info:
          </h3>

          <div className="flex gap-4">
            <AgoraInput
              htmlFor="parentName"
              type="text"
              name="parentName"
              id="parentName"
              placeholder=""
              label="Parent Name"
              color={getColor('parentName')}
              labelColor={getColor('parentName')}
              borderColor={getColor('parentName')}
              labelMargin="0 0 0 8px"
              value={studentDetails?.parentName}
              margin="0px 0px 16px 0px"
              onChange={(e) => {
                handleInputChangeStudent(e);
              }}
              onBlur={handleBlur}
              isRequired
            />

            <AgoraInput
              htmlFor="parentEmail"
              type="email"
              name="parentEmail"
              id="parentEmail"
              placeholder=""
              label="Parent Email"
              color={colorAfterEmailValidation}
              labelColor={colorAfterEmailValidation}
              borderColor={colorAfterEmailValidation}
              labelMargin="0 0 0 8px"
              value={studentDetails?.parentEmail}
              margin="0px 0px 16px 0px"
              onChange={(e) => {
                handleInputChangeStudent(e);
                handleEmailValidation(e);
              }}
              onBlur={handleBlur}
              isRequired
            />
          </div>

          <div className="flex flex-row gap-2">
            <Select
              className="max-w-[15%]"
              value={studentDetails.parentPhoneNumber.countryCode}
              onSelect={(value) =>
                setStudentDetails((prevStudentDetails) => ({
                  ...prevStudentDetails,
                  parentPhoneNumber: {
                    countryCode: String(value),
                    number: prevStudentDetails.parentPhoneNumber.number,
                  },
                }))
              }
              options={countries.map((country) => ({
                label: `(${country.dialCode}) ${country.name}`,
                value: country.dialCode,
              }))}
              isTouched={touched.parentPhoneNumber}
              isValid={!!getColor('parentPhoneNumber')}
              label="Country Code"
              isRequired
              size="large"
            />

            <Input
              type="tel"
              id="phone"
              label="Phone Number"
              name="phoneNumber.number"
              prefix={studentDetails.parentPhoneNumber.countryCode}
              value={studentDetails.parentPhoneNumber.number}
              onChange={(e) =>
                setStudentDetails((prevStudentDetails) => ({
                  ...prevStudentDetails,
                  parentPhoneNumber: {
                    countryCode: prevStudentDetails.parentPhoneNumber.countryCode,
                    number: String(e.target.value),
                  },
                }))
              }
              isRequired
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '24px',
            }}
          >
            <span style={{ marginRight: '16px' }} className="create-new-user-modal-steps-text">
              Step 1 of 2
            </span>
            <div
              style={{
                width: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>

              <div
                style={{
                  backgroundColor: COLORS.TEXT_SECONDARY,
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                }}
              ></div>
            </div>
          </div>
          <SecondaryButton
            onClick={() => {
              if (areDetailsFilled) handleCurrentPage('create-new-user-student-step-two');
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="0 0 0 0"
            width="100%"
            disabled={!areDetailsFilled}
          >
            <span className="create-new-user-modal-submit-button" style={{ color: COLORS.BLACK }}>
              Continue
            </span>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserStudentStepOne;
