import { GetCitiesLightDTO } from '@shared/clientTypes/DTOs/CityDTO';
import { ApiData, ApiDescription, RequestsMethod } from '@shared/common';

export const getCitiesLight: ApiDescription = {
  endpoint: '/cities-light',
  method: RequestsMethod.Get,
};

export type GetCitiesLight = ApiData<
  {
    search: string;
    limit: number;
  },
  GetCitiesLightDTO[]
>;
