import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import AgoraInput from '@utils/AgoraInput';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';
import { PhoneNumber } from '@shared/serverTypes';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { countries } from 'country-codes-flags-phone-codes';

type AdminDetails = {
  fullName: string;
  phoneNumber: PhoneNumber;
};

type Props = {
  email: string;
  role: 'STUDENT' | 'MENTOR' | 'ADMIN' | undefined;
  handleCurrentPage: (page: string) => void;
  adminDetails: AdminDetails;
  handleInputChangeAdmin: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setAdminDetails: React.Dispatch<React.SetStateAction<AdminDetails>>;
};

const CreateNewUserAdminStepOne = ({
  email,
  role,
  handleCurrentPage,
  adminDetails,
  handleInputChangeAdmin,
  setAdminDetails,
}: Props) => {
  const [touched, setTouched] = useState<Record<keyof AdminDetails, boolean>>({
    fullName: false,
    phoneNumber: false,
  });

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const getColor = (name: keyof typeof adminDetails) => {
    if (!touched[name] && !adminDetails[name]) {
      return COLORS.TEXT_SECONDARY;
    } else {
      return adminDetails[name] ? COLORS.PRIMARY : COLORS.ERROR;
    }
  };

  const areDetailsFilled = !!(
    adminDetails.fullName &&
    adminDetails.phoneNumber.countryCode &&
    adminDetails.phoneNumber.number
  );

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
              // marginTop: '32px',
            }}
            className="modal-page-section-title"
          >
            Basic Info/ Contact Info:
          </h3>

          <AgoraInput
            htmlFor="fullName"
            type="text"
            name="fullName"
            id="fullName"
            placeholder=""
            label="Full Name"
            color={getColor('fullName')}
            labelColor={getColor('fullName')}
            borderColor={getColor('fullName')}
            labelMargin="0 0 0 8px"
            value={adminDetails?.fullName}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeAdmin}
            onBlur={handleBlur}
            isRequired
          />
        </div>

        <div className="flex flex-row gap-2">
          <Select
            className="max-w-[15%]"
            value={adminDetails.phoneNumber.countryCode}
            onSelect={(value) =>
              setAdminDetails((prevState) => ({
                ...prevState,
                phoneNumber: {
                  countryCode: String(value),
                  number: prevState.phoneNumber.number,
                },
              }))
            }
            options={countries.map((country) => ({
              label: `(${country.dialCode}) ${country.name}`,
              value: country.dialCode,
            }))}
            label="Country Code"
            size="large"
            isRequired
          />

          <Input
            type="tel"
            id="phone"
            label="Phone Number"
            name="phoneNumber.number"
            prefix={adminDetails.phoneNumber.countryCode}
            value={adminDetails.phoneNumber.number}
            onChange={(e) =>
              setAdminDetails((prevState) => ({
                ...prevState,
                phoneNumber: {
                  countryCode: prevState.phoneNumber.countryCode,
                  number: String(e.target.value),
                },
              }))
            }
            isRequired
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SecondaryButton
            onClick={() => {
              if (areDetailsFilled) {
                handleCurrentPage('create-new-user-permissions-admin');
              }
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="0 0 0 0"
            width="100%"
            disabled={!areDetailsFilled}
          >
            <span className="create-new-user-modal-submit-button" style={{ color: COLORS.BLACK }}>
              Continue
            </span>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserAdminStepOne;
