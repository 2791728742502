import { k12SchoolApis } from '@shared/apis';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithIdParams,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useGetK12Schools = genericQueryWithParams<k12SchoolApis.GetK12Schools>(
  k12SchoolApis.getK12Schools
);

export const useGetK12SchoolsLight = genericQueryWithParams<k12SchoolApis.GetK12SchoolsLight>(
  k12SchoolApis.getK12SchoolsLight
);

export const useGetK12School = genericQueryWithId<k12SchoolApis.GetK12School>(
  k12SchoolApis.getK12School
);

export const useAddK12School = genericMutation<k12SchoolApis.AddK12School>(
  k12SchoolApis.addK12School,
  k12SchoolApis.getK12Schools.endpoint
);

export const useDeleteK12School = genericMutationWithIdParams<k12SchoolApis.DeleteK12School>(
  k12SchoolApis.deleteK12School,
  k12SchoolApis.getK12Schools.endpoint
);

export const useRestoreK12School = genericMutationWithIdParams<k12SchoolApis.RestoreK12School>(
  k12SchoolApis.restoreK12School,
  k12SchoolApis.getK12Schools.endpoint
);

export const useEditK12School = genericMutationWithId<k12SchoolApis.EditK12School>(
  k12SchoolApis.editK12School,
  k12SchoolApis.getK12Schools.endpoint,
  k12SchoolApis.getK12School.endpoint
);
