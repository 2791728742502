import {
  CreateEnrollmentDTO,
  GetEnrollmentsDTO,
  GetHasRedundantEnrollmentsDTO,
  GetMentorEnrollmentsDetailsDTO,
  GetMentorTeachingAndEnrolledInCoursesDTO,
  GetSessionDetailsDTO,
  GetSessionDTO,
  GetStudentEnrollmentsDetailsDTO,
  SearchMentorEnrollmentsDTO,
  SearchSessionHistoryDTO,
  SearchSessionsDTO,
} from '@shared/clientTypes/DTOs/EnrollmentsDTO';
import {
  Enrollment,
  SearchMentorEnrollmentsParams,
  ActiveStudents,
  MentorHistory,
} from '../common';
import { ApiDescription, ApiData, RequestsMethod } from '../clientTypes';

export const getEnrollment: ApiDescription = {
  endpoint: '/enrollments/:id',
  method: RequestsMethod.Get,
};
export type GetEnrollment = ApiData<never, Enrollment>;

export const getEnrollmentMentorHistory: ApiDescription = {
  endpoint: '/enrollments/:id/mentor-history',
  method: RequestsMethod.Get,
};

export type GetEnrollmentMentorHistory = ApiData<never, MentorHistory[]>;

export const createEnrollment: ApiDescription = {
  endpoint: '/enrollments',
  method: RequestsMethod.Post,
};
export type CreateEnrollment = ApiData<
  Partial<Omit<Enrollment, 'mentor'>> & {
    userId: string;
    courseId: string;
    numberOfSessions: number;
    mentorId?: string;
  },
  CreateEnrollmentDTO
>;

export const updateEnrollment: ApiDescription = {
  endpoint: '/enrollments/update',
  method: RequestsMethod.Patch,
};
export type UpdateEnrollment = ApiData<
  Partial<Omit<Enrollment, 'sessions'>> & {
    enrollmentId: string;
    mentorId?: string;
    commentText?: string;
  },
  Enrollment
>;

export const getEnrollments: ApiDescription = {
  endpoint: '/user-enrollments/get-enrollments/:id',
  method: RequestsMethod.Get,
};
export type GetEnrollments = ApiData<{ id: string }, GetEnrollmentsDTO>;

export const getUserEnrollments: ApiDescription = {
  endpoint: '/enrollments/user/:id',
  method: RequestsMethod.Get,
};
export type GetUserEnrollments = ApiData<never, Enrollment[]>;

export const getStudentEnrollments: ApiDescription = {
  endpoint: '/enrollments/student',
  method: RequestsMethod.Get,
};
export type GetStudentEnrollments = ApiData<never, Enrollment[]>;

export const searchMentorEnrollments: ApiDescription = {
  endpoint: '/enrollments/search-mentor-enrollments',
  method: RequestsMethod.Get,
};

export type SearchMentorEnrollments = ApiData<
  SearchMentorEnrollmentsParams,
  SearchMentorEnrollmentsDTO
>;

export const getActiveStudentsByMentor: ApiDescription = {
  endpoint: '/enrollments/search-active-students',
  method: RequestsMethod.Get,
};

export type GetActiveStudentsByMentor = ApiData<unknown, ActiveStudents[]>;

export const endEnrollment: ApiDescription = {
  endpoint: '/enrollments/end',
  method: RequestsMethod.Post,
};

export type EndEnrollment = ApiData<{ enrollmentId: string }, Enrollment>;

export const resumeEnrollment: ApiDescription = {
  endpoint: '/enrollments/resume',
  method: RequestsMethod.Post,
};

export type ResumeEnrollment = ApiData<{ enrollmentId: string }, Enrollment>;

export const addSession: ApiDescription = {
  endpoint: '/enrollments/add-session',
  method: RequestsMethod.Patch,
};
export type AddSession = ApiData<{ enrollmentId: string }, Enrollment>;

export const deleteSession: ApiDescription = {
  endpoint: '/enrollments/delete-session',
  method: RequestsMethod.Patch,
};
export type DeleteSession = ApiData<{ enrollmentId: string; sessionId: string }, Enrollment>;

export const getMentorEnrollmentsDetails: ApiDescription = {
  endpoint: '/enrollments/mentor/:id/details',
  method: RequestsMethod.Get,
};
export type GetMentorEnrollmentsDetails = ApiData<
  {
    id: string;
    teachingPage: number;
    beingTaughtPage: number;
    isEnded: boolean;
    limit?: number;
  },
  GetMentorEnrollmentsDetailsDTO
>;

export const getStudentEnrollmentsDetails: ApiDescription = {
  endpoint: '/enrollments/student/:id/details',
  method: RequestsMethod.Get,
};

export type GetStudentEnrollmentsDetails = ApiData<
  {
    id: string;
    page: number;
    isEnded: boolean;
    limit?: number;
  },
  GetStudentEnrollmentsDetailsDTO
>;

export const getMentorTeachingAndEnrolledInCourses: ApiDescription = {
  endpoint: '/enrollments/mentor/:id/teaching-and-enrolled-in',
  method: RequestsMethod.Get,
};

export type GetMentorTeachingAndEnrolledInCourses = ApiData<
  {
    id: string;
  },
  GetMentorTeachingAndEnrolledInCoursesDTO
>;

export const getMinimumMentorCapacity: ApiDescription = {
  endpoint: '/v2/enrollments/minimum-mentor-capacity',
  method: RequestsMethod.Get,
};

export type GetMinimumMentorCapacity = ApiData<
  never,
  { currentCapacity: number; activeEnrollments: number }
>;

export const hasMentorUpcomingStudents: ApiDescription = {
  endpoint: '/v2/enrollments/has-upcoming-students',
  method: RequestsMethod.Get,
};

export type HasMentorUpcomingStudents = ApiData<never, boolean>;

export const unenrollUser: ApiDescription = {
  endpoint: '/v2/enrollments/unenroll',
  method: RequestsMethod.Post,
};

export type UnenrollUser = ApiData<{ enrollmentId: string }, unknown>;

export const searchSessions: ApiDescription = {
  endpoint: '/v2/enrollments/search-sessions',
  method: RequestsMethod.Get,
};

export type SearchSessions = ApiData<
  {
    page?: number;
    limit?: number;
    sortOrder?: 'asc' | 'desc';
    sortBy?: string;
    status?: number[];
    course?: string;
    student?: string;
    mentor?: string;
    rangeStart?: string;
    rangeEnd?: string;
    hideNoMeetingSessions?: string;
  },
  SearchSessionsDTO
>;

export const searchSessionHistory: ApiDescription = {
  endpoint: '/v2/enrollments/search-session-history',
  method: RequestsMethod.Get,
};

export type SearchSessionHistory = ApiData<
  {
    page?: number;
    limit?: number;
    sortOrder?: 'asc' | 'desc';
    student: string;
    sortBy?: string;
    status?: number;
    course?: string;
  },
  SearchSessionHistoryDTO
>;

export const markSessionAsCompleted: ApiDescription = {
  endpoint: '/v2/enrollments/mark-session-completed',
  method: RequestsMethod.Post,
};

export type MarkSessionAsCompleted = ApiData<
  {
    sessionId: string;
  },
  unknown
>;

export const getSession: ApiDescription = {
  endpoint: '/v2/enrollments/get-session/:session',
  method: RequestsMethod.Get,
};

export type GetSession = ApiData<unknown, GetSessionDTO>;

export const updateSession: ApiDescription = {
  endpoint: '/v2/enrollments/update-session/:sessionId',
  method: RequestsMethod.Patch,
};

export type UpdateSession = ApiData<{ status: number }, unknown>;

export const getSessionDetails: ApiDescription = {
  endpoint: '/v2/enrollments/get-session-details/:session',
  method: RequestsMethod.Get,
};

export type GetSessionDetails = ApiData<unknown, GetSessionDetailsDTO>;

export const getHasRedundantEnrollments: ApiDescription = {
  endpoint: '/v2/enrollments/get-redundant-enrollments',
  method: RequestsMethod.Get,
};

export type GetHasRedundantEnrollments = ApiData<unknown, GetHasRedundantEnrollmentsDTO>;
