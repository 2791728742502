import { SortOrder } from '@components/V4/Table/Table';
import { useDebounce, useQueryParamsState } from '@hooks';
import { TriState } from '@shared/common';
import moment from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import UniversitiesHeader from './UniversitiesHeader';
import UniversitiesTable from './UniversitiesTable';

const useQuery = () => new URLSearchParams(useLocation().search);

const Universities = () => {
  const [search, setSearch] = useState('');

  //TODO - replace this with useQueryParams when upgrading to react-router-dom v6
  const query = useQuery();

  const limit = query.get('limit') || '10';
  const page = query.get('page') || '1';
  const sortBy = query.get('sortBy') || null;
  const sortOrder = (query.get('sortOrder') || null) as SortOrder;
  const year = query.get('year') || moment().year();
  const region = query.get('region') || null;
  const country = query.get('country') || null;
  const isActive = (query.get('isActive') || 'checked') as TriState;
  const isUniversityQSSource = (query.get('isUniversityQSSource') || 'indeterminate') as TriState;
  const isRankedForSelectedYear = (query.get('isRankedForSelectedYear') ||
    'indeterminate') as TriState;

  const debouncedSearch = useDebounce(search, 500);

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    page: page,
    limit: limit,
    year: year,
    sortBy: sortBy,
    sortOrder: sortOrder,
    region: region,
    country: country,
    isActive: isActive,
    isUniversityQSSource: isUniversityQSSource,
    isRankedForSelectedYear: isRankedForSelectedYear,
  });

  return (
    <div className="flex flex-col">
      <UniversitiesHeader
        search={search}
        searchParams={searchParams}
        isActive={isActive}
        isUniversityQSSource={isUniversityQSSource}
        isRankedForSelectedYear={isRankedForSelectedYear}
        onSearch={setSearch}
        setSearchParams={setSearchParams}
      />
      <UniversitiesTable
        searchParams={searchParams}
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
        limit={limit}
        debouncedSearch={debouncedSearch}
        setSearchParams={setSearchParams}
      />
    </div>
  );
};

export default Universities;
