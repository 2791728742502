import Modal from '@components/V4/Modal/Modal';
import MajorModalDetails from './MajorModalDetails';
import CreateMajorForm from './CreateMajorForm';
import { useState } from 'react';

interface MajorModalProps {
  majorId?: string;
  onClose: () => void;
}

const MajorModal = (props: MajorModalProps) => {
  const { majorId, onClose } = props;
  const [major, setMajor] = useState(majorId);
  const [isCreateNewMajor, setIsCreateNewMajor] = useState(!majorId);

  const closeIsCreateNewMajorHandler = (majorId?: string) => {
    setIsCreateNewMajor(false);

    if (majorId) setMajor(majorId);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <Modal.Header title={!isCreateNewMajor ? 'Major Details' : 'Create Major'} />

      {!isCreateNewMajor && major ? (
        <MajorModalDetails majorId={major} />
      ) : (
        <CreateMajorForm
          closeModalHandler={onClose}
          onCloseEditMode={closeIsCreateNewMajorHandler}
        />
      )}
    </Modal>
  );
};

export default MajorModal;
