import { TriState } from '@shared/clientTypes/misc';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import InputLabel from '../Inputs/InputLabel';

type TriStateToggleProps = {
  title: string;
  options: { label: string; value: TriState }[];
  value?: boolean | TriState;
  onChange: (value: TriState) => void;
  className?: string;
};

const TriStateToggle = (props: TriStateToggleProps) => {
  const { title, options, value, onChange, className } = props;

  let triStateValue: TriState;
  if (typeof value === 'boolean') {
    triStateValue = value ? 'checked' : 'unchecked';
  } else if (value) {
    triStateValue = value;
  } else {
    triStateValue = 'indeterminate';
  }

  return (
    <div className={mergeClassNames('flex flex-col', className)}>
      {title && <InputLabel className="text-white" label={title} />}
      <div className="flex">
        {options.map(({ label, value }, index) => (
          <button
            key={value}
            onClick={() => onChange(value)}
            className={mergeClassNames(
              'py-1.5 px-3 text-xs transition-colors duration-300 cursor-pointer text-nowrap border-y border-zinc-500 focus:outline-none',
              {
                'bg-zinc-300 hover:text-zinc-500 text-zinc-800': triStateValue === value,
                'bg-zinc-800 hover:bg-zinc-500 text-zinc-300': triStateValue !== value,
                'rounded-s-full border-x': index === 0,
                'rounded-e-full border-x': index === options.length - 1,
              }
            )}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TriStateToggle;
