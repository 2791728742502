import { useGetCitiesLight } from '@actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import Select, { SelectOption } from '@components/V4/Select/Select';
import TriStateToggle from '@components/V4/TriStateToggle/TriStateToggle';
import { SearchIcon } from '@heroicons/react/outline';
import { useDebounce } from '@hooks';
import K12SchoolModal from '@modules/Admin/components/K12SchoolModal/K12SchoolModal';
import { TriState } from '@shared/common';
import { countryList, EDUCATION_LEVELS, SCHOOL_TYPES } from '@shared/constants';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useState } from 'react';

interface K12HeaderProps extends BasicProps {
  search: string;
  searchParams: any;
  onSearch: (search: string) => void;
  setSearchParams: (data: any) => void;
}

const K12Header = (props: K12HeaderProps) => {
  const { search, searchParams, onSearch, setSearchParams } = props;
  const [citySearch, setCitySearch] = useState('');
  const debouncedCitySearch = useDebounce(citySearch, 500);

  const [showAddSchoolModal, setShowAddSchoolModal] = useState(false);

  const { data: cities, isLoading: isLoadingCities } = useGetCitiesLight({
    search: debouncedCitySearch,
    limit: 20,
  });

  const schoolCountryChangeHandler = (country: string) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      country: country,
    });
  };

  const schoolCityChangeHandler = (city: string) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      city: city,
    });
  };

  const schoolTypeChangeHandler = (type?: number) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      type: type,
    });
  };

  const educationLevelChangeHandler = (educationLevel?: string[]) => {
    setSearchParams({
      ...searchParams,
      educationLevel: educationLevel?.join(','),
      page: 1,
    });
  };

  const isActiveChangeHandler = (isActive: TriState) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      isActive: isActive,
    });
  };

  const getCityOptions = (): SelectOption[] => {
    return (
      cities?.map((city) => ({
        label: `${city.name}, ${city.county}`,
        value: city._id,
      })) || []
    );
  };

  const schoolTypeOptions = Object.keys(SCHOOL_TYPES).map((type) => ({
    label: SCHOOL_TYPES[type],
    value: Number(type),
  }));

  const educationLevelOptions = Object.keys(EDUCATION_LEVELS).map((level) => ({
    label: EDUCATION_LEVELS[level],
    value: Number(level),
  }));

  return (
    <div className="flex flex-col gap-6 bg-surfaceObject px-18 pt-8 pb-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold">K12 Schools</h3>
        <Button buttonText="Add School" onClick={() => setShowAddSchoolModal(true)} />
      </div>
      <div className="relative w-full flex items-center bg-surfaceObject rounded-3xl">
        <input
          type="text"
          className="bg-transparent border rounded-3xl w-full py-1.5 px-6 text-sm text-white border-customGrey focus:border-customGrey placeholder:text-customGrey placeholder:opacity-50"
          placeholder="Search by School Name, Country or City"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
        />

        <SearchIcon className="w-6 h-6 absolute right-6 text-customGrey" />
      </div>
      <div className="flex gap-6 flex-wrap justify-between">
        <div className="flex items-center gap-6 flex-wrap">
          <div className="flex gap-6">
            <Select
              className="min-w-56"
              label="Country"
              options={countryList?.map((country) => ({
                label: country,
                value: country,
              }))}
              placeholder="Select Country"
              value={searchParams.country}
              onSelect={(value) => schoolCountryChangeHandler(value as string)}
              variant="secondary"
            />
            <Select
              className="min-w-56"
              label="City"
              options={getCityOptions()}
              placeholder="Select City"
              value={searchParams.city}
              onSearch={setCitySearch}
              notFoundContent={
                isLoadingCities ? <LoadingSpinner className="mx-auto" size="normal" /> : null
              }
              onSelect={(value) => schoolCityChangeHandler(value as string)}
              variant="secondary"
            />
            <Select
              className="min-w-56"
              mode="multiple"
              label="Education Level"
              options={educationLevelOptions}
              onClear={() => educationLevelChangeHandler(undefined)}
              placeholder="Select Education Level"
              value={
                searchParams.educationLevel
                  ? searchParams.educationLevel?.split(',').map((level: string) => Number(level))
                  : undefined
              }
              onChange={(value) => educationLevelChangeHandler(value as string[])}
              variant="secondary"
            />
            <Select
              className="min-w-56"
              label="School Type"
              options={schoolTypeOptions}
              onClear={() => schoolTypeChangeHandler(undefined)}
              placeholder="Select School Type"
              value={searchParams.type ? Number(searchParams.type) : undefined}
              onSelect={(value) => schoolTypeChangeHandler(value as number)}
              variant="secondary"
            />
          </div>

          <TriStateToggle
            title={'Show'}
            options={[
              { label: 'Active', value: 'checked' },
              { label: 'Deleted', value: 'unchecked' },
              { label: 'All', value: 'indeterminate' },
            ]}
            value={searchParams.isActive}
            onChange={isActiveChangeHandler}
          />
        </div>
      </div>

      {showAddSchoolModal && <K12SchoolModal onClose={() => setShowAddSchoolModal(false)} />}
    </div>
  );
};

export default K12Header;
