import { DownOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { mergeClassNames } from '../../utils/helpers';
import { MultiTab } from './tabs';
import { useNavigationState } from './NavigationContext';

interface NavGroupProps {
  multiTab: MultiTab;
}

const NavGroup = ({ multiTab }: NavGroupProps) => {
  const { pathname } = useLocation();

  const isActive = (route: string) => !!matchPath(route, pathname);
  const isLocationMatchingTab = multiTab.routes.some((tab) => isActive(tab.route));

  const [isOpen, setIsOpen] = useState(isLocationMatchingTab);
  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const { isCollapsed } = useNavigationState();

  return (
    <div className="w-full">
      <button
        onClick={toggleDropdown}
        className={mergeClassNames(
          'relative flex justify-between w-full py-2 px-4 text-white transition-all group',
          {
            'mb-1': isOpen,
            'justify-center': isCollapsed,
          }
        )}
      >
        <div
          className={mergeClassNames(
            'absolute inset-0 bg-white opacity-0 transition-opacity group-hover:opacity-100 rounded-md',
            { 'opacity-70': isLocationMatchingTab }
          )}
        ></div>

        <span
          className={mergeClassNames(
            'z-10 flex items-center gap-4 text-white group-hover:text-black',
            {
              'text-black': isLocationMatchingTab,
            }
          )}
        >
          <span className="flex items-center shrink-0 text-2xl">{multiTab.icon}</span>
          <span
            className={mergeClassNames('text-xl transition', {
              hidden: isCollapsed,
            })}
          >
            {multiTab.title}
          </span>
        </span>

        <DownOutlined
          className={mergeClassNames(
            'z-10 transition-transform text-white group-hover:text-black',
            {
              'text-black': isLocationMatchingTab,
              'rotate-180 ': isOpen,
              'absolute right-1 top-1/2 -translate-y-1/2': isCollapsed,
            }
          )}
        />
      </button>

      <div
        className={mergeClassNames(
          'flex flex-col gap-2 overflow-hidden ps-4 transition-all max-h-0',
          {
            'max-h-screen': isOpen,
            'ps-0': isCollapsed,
          }
        )}
      >
        {multiTab.routes.map((tab) => (
          <Link
            key={tab.route}
            to={tab.route}
            className={mergeClassNames(
              'relative flex items-center px-4 py-2 text-white transition group hover:text-black text-lg',
              {
                'text-black': isActive(tab.route),
                'justify-center': isCollapsed,
              }
            )}
          >
            <div
              className={mergeClassNames(
                'absolute inset-0 bg-white opacity-0 transition-opacity group-hover:opacity-100 rounded-md',
                { 'opacity-100': isActive(tab.route) }
              )}
            ></div>

            <span className="z-10 flex items-center gap-2 transition ">
              <span className="size-4 flex items-center">{tab.icon}</span>
              <span className={mergeClassNames({ hidden: isCollapsed })}>{tab.title}</span>
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NavGroup;
