import SessionModalEmptyState from '../../../DetailsModalEmptyState';
import ChatEntry from './ChatEntry';

interface SessionChatTabProps {
  chat: any[];
  meetingScheduledDate?: string;
}

const SessionChatTab = (props: SessionChatTabProps) => {
  const { chat, meetingScheduledDate } = props;
  return (
    <div className="flex flex-col gap-4">
      {!chat?.length ? (
        <SessionModalEmptyState />
      ) : (
        chat.map((message) => (
          <ChatEntry
            name={message.user}
            timestamp={message.message.at}
            message={message.message.text}
            meetingScheduledDate={meetingScheduledDate}
          />
        ))
      )}
    </div>
  );
};

export default SessionChatTab;
