import { useGetK12SchoolsLight, useSaveUserProfile } from '@actions';
import { ReactComponent as SchoolIcon } from '@assets/icons/profile-icons/school-icon.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import Select, { SelectOption } from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounce } from '@hooks';
import { EducationalBackground } from '@shared/common';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ProfileContext } from '../../../AccountProfile';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';

interface ResumeTabSchoolFormProps extends ExamFormProps {
  schoolData?: EducationalBackground;
}

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid school name.' }),
  gpa: z.number().min(0.5, { message: 'Please enter a valid GPA.' }),
  startYear: z.number().min(1, { message: 'Please enter a valid start year.' }),
  endYear: z.number().min(1, { message: 'Please enter a valid graduation year.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const ResumeTabSchoolForm = (props: ResumeTabSchoolFormProps) => {
  const { isModeAddNew, userId, schoolData, setIsEditMode, setIsAddNewItem, onCancel } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 500);

  const { isExternalViewer } = useContext(ProfileContext);

  const toast = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: schoolData?.school?._id || schoolData?.educationalInstitution.name,
      gpa: schoolData?.gpa ?? 0,
      startYear: schoolData?.startYear,
      endYear: schoolData?.endYear,
    },
  });

  const { field: startYearField, fieldState: startYearFieldState } = useController({
    name: 'startYear',
    control,
  });

  const { field: nameField, fieldState: nameFieldState } = useController({
    name: 'name',
    control,
  });

  const { field: endYearField, fieldState: endYearFieldState } = useController({
    name: 'endYear',
    control,
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { data: schools, isLoading: isLoadingSchools } = useGetK12SchoolsLight({
    search: debouncedSearch,
    limit: 20,
  });

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();

    if (isValid) {
      const newSchoolData: EducationalBackground = {
        educationalInstitution: {
          name: data.name,
          _id: data.name,
          type: 'School',
        },
        gpa: data.gpa,
        startYear: data.startYear,
        endYear: data.endYear,
        _id: schoolData?._id,
      };

      updateUserProfile({
        educationalBackground: [newSchoolData],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const getSchoolOptions = (): SelectOption[] => {
    const mappedSchools =
      schools?.map((school) => ({
        label: `${school.name}${
          school.cityRef?.name || school.city ? `, ${school.cityRef?.name || school.city}` : ''
        }`,
        value: school._id,
      })) || [];

    if (!schoolData?.school?.cityRef) return mappedSchools;

    const isSchoolRefInMappedSchools = !!mappedSchools.find(
      (city) => city.value === schoolData?.school?.cityRef?._id || city.value === nameField.value
    );

    if (!isSchoolRefInMappedSchools) {
      mappedSchools.unshift({
        label: `${schoolData?.school?.name}${
          schoolData?.school?.cityRef.name || schoolData?.school.city
            ? `, ${schoolData?.school.cityRef?.name || schoolData?.school.city}`
            : ''
        }`,
        value: schoolData?.school?._id,
      });
    }

    return mappedSchools;
  };

  return (
    <StudentFormWrapper
      title="School"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      icon={<SchoolIcon className="min-w-5 text-customPrimary" />}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      {isModeAddNew || !!schoolData?.school?._id ? (
        <Select
          label="School Name"
          value={nameField.value}
          filterOption={() => true}
          searchValue={searchQuery}
          onSearch={setSearchQuery}
          onSelect={(value) => nameField.onChange(value)}
          onBlur={nameField.onBlur}
          options={getSchoolOptions()}
          size="large"
          notFoundContent={
            isLoadingSchools ? <LoadingSpinner className="mx-auto" size="normal" /> : null
          }
          placeholder="Search School"
          isRequired
          isValid={!nameFieldState.error}
          isTouched={nameFieldState.isTouched}
          errorText={nameFieldState.error?.message}
        />
      ) : (
        <Input
          type="text"
          id={'name'}
          placeholder={''}
          label={'School Name'}
          isRequired
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          autoFocus
          {...register('name')}
        />
      )}
      <Input
        type="number"
        id={'gpa'}
        placeholder={''}
        label={'GPA'}
        isRequired
        isTouched={touchedFields.gpa}
        isValid={!errors.gpa}
        errorText={errors.gpa?.message}
        {...register('gpa', { valueAsNumber: true })}
      />

      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={startYearField.value ? moment(startYearField.value, 'YYYY') : undefined}
          onChange={(value) => startYearField.onChange(moment(value).year())}
          disabledDate={(date) =>
            endYearField.value ? moment(date).year() > endYearField.value : false
          }
          onBlur={startYearField.onBlur}
          allowClear={false}
          isRequired
          label="Start Year"
          picker="year"
          isValid={!startYearFieldState.error}
          isTouched={startYearFieldState.isTouched}
          errorText={startYearFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endYearField.value ? moment(endYearField.value, 'YYYY') : undefined}
          onChange={(value) => endYearField.onChange(moment(value).year())}
          onBlur={endYearField.onBlur}
          disabledDate={(date) =>
            startYearField.value ? moment(date).year() < startYearField.value : false
          }
          allowClear={false}
          isRequired
          picker="year"
          label="Graduation Year"
          isValid={!endYearFieldState.error}
          isTouched={endYearFieldState.isTouched}
          errorText={endYearFieldState.error?.message}
        />
      </div>
    </StudentFormWrapper>
  );
};

export default ResumeTabSchoolForm;
