import { UploadOutlined } from '@ant-design/icons';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import ArrowRight from '@assets/icons/arrow-right.svg';
import UploadInvoiceModal from '@components/UploadInvoiceModal/UploadInvoiceModal';
import Button from '@components/V4/Button';
import { useModal } from '@hooks';
import { UPLOAD_INVOICE_STATES, KONAMI_CODE } from '@shared/constants';
import {
  getUploadInvoiceBannerTitle,
  getUploadInvoiceBannerMessage,
  showMentorUploadInvoiceBanner,
  getInvoiceActionableState,
} from 'apps/agora/src/utils/uploadInvoiceBannerHelper';
import AgoraSpinner from '@utils/AgoraSpinner';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './banner-stack.styles.scss';
import apiClient from '@contexts/AuthContext/apiClient';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useAnalytics } from '@contexts/AnalyticsContext';
import { useAuthState } from '@contexts/AuthContext';
import { useGetLatestMentorReport, useCheckMentorLastMonthMeetingAndExtraServices } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import SkeletonBox from '@components/V4/SkeletonBox';
import { ReportStatus, UserRole } from '@shared/enums';

type Props = {
  handleSubmitFeedback: () => void;
  banners: any[];
  showSkeleton?: boolean;
};

const BannerStack = ({ handleSubmitFeedback, banners, showSkeleton }: Props) => {
  const history = useHistory();

  // UPLOAD BANNER
  const { trackEvent } = useAnalytics();

  const toast = useToast();

  const { userId, roles } = useAuthState();
  const userRole = localStorage.getItem('userRole');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [keySequence, setKeySequence] = useState<string[]>([]);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(true);
  const [isActivityReportDownloading, setIsActivityReportDownloading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleKeyDown = (event: any) => {
    setKeySequence((prevSequence) => [...prevSequence, event.code].slice(-11));
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isMentor: boolean = userRole === 'mentor' || roles.includes(UserRole.Mentor);

  const { data: report } = useGetLatestMentorReport({
    enabled: isMentor,
  });

  const showUploadInvoiceBanner: boolean = showMentorUploadInvoiceBanner(isMentor, report?.status);

  const [Modal, show] = useModal(UploadInvoiceModal);

  const { data: hasMentorMeetingsOrExtraServices, isLoading: isCheckingLoading } =
    useCheckMentorLastMonthMeetingAndExtraServices(userId, {
      enabled: showUploadInvoiceBanner,
    });

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (keySequence.join(' ') === KONAMI_CODE.join(' ')) {
      show({});
    }
  }, [keySequence]);

  const saveByteArrayFile = (blob: any, isActivityReport: boolean) => {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    link.href = url;

    const fileName = isActivityReport ? 'Activity_Report.pdf' : 'Invoice.pdf';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadInvoice = async (reportId: string) => {
    setIsInvoiceDownloading(true);
    try {
      const response = await apiClient.get(`/report/${reportId}/download-invoice`, {
        responseType: 'blob',
      });
      toast.success('Invoice downloaded successfully');
      saveByteArrayFile(response, false);
    } catch (error: any) {
      toast.error(error?.response.message || error?.message);
    } finally {
      setIsInvoiceDownloading(false);
    }
  };

  const handleDownloadActivityReport = async (reportId: string) => {
    setIsActivityReportDownloading(true);
    try {
      const response = await apiClient.get(`/report/${reportId}/download-activity-report`, {
        responseType: 'blob',
      });
      toast.success('Activity Report downloaded successfully');
      saveByteArrayFile(response, true);
    } catch (error: any) {
      setIsError(true);
      console.log('error: ', error?.response?.status);
      if (error?.response?.status === 404) {
        toast.error('Report was not found');
      }
    } finally {
      setIsActivityReportDownloading(false);
    }
  };

  // END UPLOAD BANNER

  // BANNER STACK

  const [currentBanner, setCurrentBanner] = useState(banners.length > 0 ? banners[0] : undefined);
  const [bannerList, setBannerList] = useState(banners);
  const [animationClass, setAnimationClass] = useState('');
  const bannerRef = useRef<HTMLDivElement | null>(null);

  const findCurrentIndex = () => banners.findIndex((banner) => banner === currentBanner);

  const handleArrowUp = () => {
    trackEvent(ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK);
    setAnimationClass('banner-exit-active');
    setTimeout(() => {
      const currentIndex = findCurrentIndex();
      const newIndex = currentIndex === 0 ? banners.length - 1 : currentIndex - 1;
      setCurrentBanner(banners[newIndex]);
      setAnimationClass('banner-enter-active');
      setTimeout(() => {
        setAnimationClass('');
        const updatedBanners = [...bannerList];
        const [removedBanner] = updatedBanners.splice(0, 1);
        setBannerList([...updatedBanners, removedBanner]);
      }, 200);
    }, 200);
  };

  const handleArrowDown = () => {
    trackEvent(ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK);
    setAnimationClass('banner-exit-active');
    setTimeout(() => {
      const updatedBanners = [...bannerList];
      const [removedBanner] = updatedBanners.splice(0, 1);
      setBannerList([...updatedBanners, removedBanner]);
      setCurrentBanner(updatedBanners[0]);
      setAnimationClass('banner-enter-active');
      setTimeout(() => {
        setAnimationClass('');
      }, 200);
    }, 200);
  };

  const nonCurrentBanners = bannerList.filter((banner) => banner !== currentBanner);

  useEffect(() => {
    setBannerList(banners);
    setCurrentBanner(banners.length > 0 ? banners[0] : undefined);
  }, [banners]);

  if (bannerList.length === 0 || currentBanner === undefined) {
    return null;
  }

  const getBannerBackgroundColor = (banner: any) => {
    switch (banner.name) {
      case 'invoice-banner':
        return report?.status === -1 || // report not generated
          report?.status === ReportStatus.INVOICE_UPLOADED ||
          report?.status === ReportStatus.INVOICE_ACCEPTED ||
          report?.status === ReportStatus.GENERATING_PDF
          ? COLORS.SURFACE_BACKGROUND
          : COLORS.ALERT_RED;
      default:
        return banner.backgroundColor;
    }
  };

  const InvoiceRejectedBanner = (
    <div>
      <span
        style={{ color: COLORS.TEXT_PRIMARY }}
        className="banner-description"
        dangerouslySetInnerHTML={{
          __html: getUploadInvoiceBannerMessage(UPLOAD_INVOICE_STATES.REJECTED),
        }}
      ></span>
      <div className="upload-invoice-banner-buttons-container gap-2 tablet:gap-4">
        {Modal}
        <Button
          variant="primary"
          onClick={() => show({})}
          buttonText="Upload Invoice"
          icon={<UploadOutlined />}
        />
        <Button
          variant="secondary"
          onClick={() => report?._id && handleDownloadActivityReport(report._id)}
          buttonText={isActivityReportDownloading ? 'Loading...' : 'View Monthly Report'}
          icon={isActivityReportDownloading ? <AgoraSpinner /> : undefined}
        />
      </div>
    </div>
  );

  const InvoiceActionableBanner = (
    <div>
      <span
        style={{ color: COLORS.TEXT_PRIMARY }}
        className="banner-description"
        dangerouslySetInnerHTML={{
          __html: getUploadInvoiceBannerMessage(getInvoiceActionableState(report?.status)),
        }}
      />
      {![ReportStatus.ERROR, ReportStatus.GENERATING_PDF].includes(report?.status || -1) && (
        <div className="upload-invoice-banner-buttons-container gap-2 tablet:gap-4">
          {Modal}

          {report?.status !== ReportStatus.INVOICE_ACCEPTED &&
          report?.status !== ReportStatus.INVALID_E_INVOICE ? (
            <Button
              variant="primary"
              onClick={() => show({})}
              buttonText={
                report?.status === ReportStatus.INVOICE_UPLOADED
                  ? 'Replace Invoice'
                  : 'Upload Invoice'
              }
              icon={<UploadOutlined />}
            />
          ) : (
            <Button
              variant="secondary"
              onClick={() => handleDownloadInvoice(report._id)}
              buttonText="Download Invoice"
              isLoading={isInvoiceDownloading}
            />
          )}

          <Button
            variant="secondary"
            onClick={() => report?._id && handleDownloadActivityReport(report._id)}
            buttonText={isActivityReportDownloading ? 'Loading...' : 'View Monthly Report'}
            icon={
              isActivityReportDownloading ? <AgoraSpinner margin="0px 8px 0px 0px" /> : undefined
            }
          />
        </div>
      )}
    </div>
  );

  if (showSkeleton) {
    return (
      <SkeletonBox className="w-full h-[210px] rounded-lg mb-18 mt-6 tablet:-mt-14 tablet:mb-[88px] laptop:mb-8 laptop:mt-auto" />
    );
  }

  // END BANNER STACK
  return (
    <div style={{ width: '100%' }} className="harvester-banner-container">
      <div>
        <div
          ref={bannerRef}
          style={{
            backgroundColor: getBannerBackgroundColor(currentBanner),
          }}
          className={`harvester-banner-inner-container ${animationClass}`}
        >
          {/*IMAGE*/}
          <div className="harvester-banner-image">
            <img
              style={{
                height: '98px',
                minWidth: '98px',
                maxWidth: '98px',
                // backgroundColor: 'black',
              }}
              src={currentBanner?.image}
              alt="banner-image"
            />
          </div>

          {/*CONTENT*/}
          <div
            style={{
              minHeight: '128px',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div className="w-full">
              {/*BANNER TITLE*/}
              <div style={{ marginBottom: '8px' }}>
                <h2 style={{ color: COLORS.TEXT_PRIMARY }} className="banner-title">
                  {currentBanner?.name === 'invoice-banner'
                    ? getUploadInvoiceBannerTitle(
                        hasMentorMeetingsOrExtraServices,
                        isError,
                        report?.status
                      )
                    : currentBanner?.title}
                </h2>
              </div>

              {Modal}
              {currentBanner?.name === 'invoice-banner' ? (
                <>
                  {(isCheckingLoading || isActivityReportDownloading) && (
                    <AgoraSpinner margin="0px 8px 0px 0px" fontSize={32} />
                  )}

                  {!isCheckingLoading && !isActivityReportDownloading && (
                    <>
                      {!hasMentorMeetingsOrExtraServices && (
                        <span
                          style={{ color: COLORS.TEXT_PRIMARY }}
                          className="banner-description"
                          dangerouslySetInnerHTML={{
                            __html: getUploadInvoiceBannerMessage(
                              UPLOAD_INVOICE_STATES.NOTHING_TO_UPLOAD
                            ),
                          }}
                        />
                      )}

                      {hasMentorMeetingsOrExtraServices &&
                        !isError &&
                        (report?.status === ReportStatus.REJECTED
                          ? InvoiceRejectedBanner
                          : InvoiceActionableBanner)}

                      {isError && (
                        <span
                          style={{ color: COLORS.TEXT_PRIMARY }}
                          className="banner-description"
                          dangerouslySetInnerHTML={{
                            __html: getUploadInvoiceBannerMessage(
                              UPLOAD_INVOICE_STATES.NOT_GENERATED
                            ),
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/*BANNER DESCRIPTION*/}
                  <div className="banner-description-container">
                    <p style={{ color: COLORS.TEXT_PRIMARY }} className="banner-description">
                      {currentBanner?.description}
                    </p>
                  </div>

                  {/*FEEDBACK LINK/BUTTON*/}
                  {currentBanner?.name !== 'invoice-banner' && (
                    <div
                      className="banner-link-container"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (currentBanner?.name === 'feedback-banner') {
                            handleSubmitFeedback();
                            trackEvent(
                              ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_SUBMIT_FEEDBACK_CLICK
                            );
                          } else if (currentBanner?.name === 'new-students-assigned-banner') {
                            trackEvent(
                              ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_UPCOMING_STUDENTS_CLICK
                            );
                            history.push('/my-enrollments?status=upcoming');
                          }
                        }}
                        buttonText={currentBanner?.secondaryButtonText}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            {/*BANNER BUTTONS*/}
            <div
              style={{
                marginLeft: '8px',
                minHeight: '100%',
                width: '56px',
                minWidth: '56px',
                maxWidth: '56px',
              }}
              className="banner-buttons-container"
            >
              {/*DESKTOP ARROWS*/}
              {banners.length > 1 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <div
                    onClick={handleArrowUp}
                    className="arrow arrow-up"
                    style={{ width: '24px', height: '24px' }} // Ensure to set the height as well for proper display
                  />
                  <div
                    onClick={handleArrowDown}
                    className="arrow arrow-down"
                    style={{ width: '24px', height: '24px' }} // Ensure to set the height as well for proper display
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop:
            animationClass === 'banner-exit-active' || animationClass === 'banner-enter-active'
              ? `calc(-${bannerRef.current?.clientHeight}px + 8px)`
              : ``,
        }}
      >
        {nonCurrentBanners.map((banner, index) => (
          <div
            key={banner?.name}
            style={{
              width: `calc(100% - (${index + 1} * 18px))`,
              // height: '8px',
              height:
                (animationClass === 'banner-exit-active' ||
                  animationClass === 'banner-enter-active') &&
                index === 0
                  ? `${bannerRef.current?.clientHeight}px`
                  : '8px',
              backgroundColor: getBannerBackgroundColor(banner),
              borderTopLeftRadius:
                (animationClass === 'banner-exit-active' ||
                  animationClass === 'banner-enter-active') &&
                index === 0
                  ? '12px'
                  : '0px',
              borderTopRightRadius:
                (animationClass === 'banner-exit-active' ||
                  animationClass === 'banner-enter-active') &&
                index === 0
                  ? '12px'
                  : '0px',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
            }}

            // className="harvester-banner-inner-container"
          >
            {(animationClass === 'banner-exit-active' ||
              animationClass === 'banner-enter-active') &&
              index === 0 && (
                <div
                  // style={{ transform: 'scale(0.8)' }}
                  // ref={bannerRef}
                  // style={{
                  //   backgroundColor: banner?.backgroundColor,
                  //   boxShadow: 'none',
                  //   minHeight: '129px',
                  //   padding: '12px 16px 12px 16px',
                  // }}
                  className={
                    (animationClass === 'banner-exit-active' ||
                      animationClass === 'banner-enter-active') &&
                    index === 0
                      ? `harvester-banner-inner-container`
                      : ''
                  }
                >
                  {/*IMAGE*/}
                  <div className="harvester-banner-image">
                    <img
                      style={{
                        height: '98px',
                        minWidth: '98px',
                        maxWidth: '98px',
                      }}
                      src={banner?.image}
                      alt="banner-image"
                    />
                  </div>

                  {/*// CONTENT BACK BANNER*/}

                  <div
                    style={{
                      minHeight: '108px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>
                      {/*BANNER TITLE*/}
                      <div style={{ marginBottom: '8px' }}>
                        <h2 style={{ color: COLORS.TEXT_PRIMARY }} className="banner-title">
                          {banner?.name === 'invoice-banner'
                            ? getUploadInvoiceBannerTitle(
                                hasMentorMeetingsOrExtraServices,
                                isError,
                                report?.status
                              )
                            : banner?.title}
                        </h2>
                      </div>

                      {banner?.name === 'invoice-banner' ? (
                        <>
                          {(isCheckingLoading || isActivityReportDownloading) && (
                            <AgoraSpinner margin="0px 8px 0px 0px" fontSize={32} />
                          )}

                          {!isCheckingLoading && !isActivityReportDownloading && (
                            <>
                              {!hasMentorMeetingsOrExtraServices && (
                                <span
                                  style={{ color: COLORS.TEXT_PRIMARY }}
                                  className="banner-description"
                                  dangerouslySetInnerHTML={{
                                    __html: getUploadInvoiceBannerMessage(
                                      UPLOAD_INVOICE_STATES.NOTHING_TO_UPLOAD
                                    ),
                                  }}
                                />
                              )}

                              {hasMentorMeetingsOrExtraServices &&
                                !isError &&
                                (report?.status === ReportStatus.REJECTED
                                  ? InvoiceRejectedBanner
                                  : InvoiceActionableBanner)}

                              {isError && (
                                <span
                                  style={{ color: COLORS.TEXT_PRIMARY }}
                                  className="banner-description"
                                  dangerouslySetInnerHTML={{
                                    __html: getUploadInvoiceBannerMessage(
                                      UPLOAD_INVOICE_STATES.NOT_GENERATED
                                    ),
                                  }}
                                />
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/*BANNER DESCRIPTION*/}
                          <div className="banner-description-container">
                            <p
                              style={{ color: COLORS.TEXT_PRIMARY }}
                              className="banner-description"
                            >
                              {banner?.description}
                            </p>
                          </div>
                          {/*FEEDBACK LINK/BUTTON*/}
                          {banner?.name !== 'invoice-banner' && (
                            <div
                              className="banner-link-container"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Button
                                variant="secondary"
                                onClick={() => {}}
                                buttonText={banner?.secondaryButtonText}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {/*BANNER BUTTONS*/}
                    <div
                      style={{
                        marginLeft: '8px',
                        minHeight: '100%',
                        width: '56px',
                        minWidth: '56px',
                        maxWidth: '56px',
                      }}
                      className="banner-buttons-container"
                    >
                      {/*DESKTOP ARROWS*/}
                      {banners.length > 1 && (
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <div
                            // onClick={handleArrowUp}
                            className="arrow arrow-up-back-banner"
                            style={{ width: '24px', height: '24px' }}
                          />
                          <div
                            // onClick={handleArrowDown}
                            className="arrow arrow-down-back-banner"
                            style={{ width: '24px', height: '24px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        ))}

        {/*DOTS*/}
        {banners.length > 1 && (
          <div style={{ marginTop: '4px' }} className="banner-dots-container">
            {banners.map((banner) => (
              <div
                key={banner?.name}
                onClick={() => {
                  setCurrentBanner(banner);
                }}
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    height: banner?.name === currentBanner?.name ? '6px' : '4px',
                    width: banner?.name === currentBanner?.name ? '6px' : '4px',
                    borderRadius: '50%',
                    backgroundColor: banner?.name === currentBanner?.name ? '#FFFFFF' : '#A3A3A3',
                  }}
                ></div>
              </div>
            ))}
          </div>
        )}

        {/*MOBILE ARROWS*/}
        {banners.length > 1 && (
          <div
            style={{
              marginTop: '4px',
              width: '100%',
            }}
            className="banner-mobile-arrow-buttons"
          >
            <img
              onClick={handleArrowUp}
              src={ArrowLeft}
              style={{ width: '24px', height: 'auto', cursor: 'pointer' }}
            />
            <img
              onClick={handleArrowDown}
              src={ArrowRight}
              style={{ width: '24px', height: 'auto', cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerStack;
