import Modal from '@components/V4/Modal/Modal';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useState } from 'react';
import K12SchoolModalForm from './K12SchoolModalForm';
import K12SchoolModalDetails from './K12SchoolModalDetails';

interface K12SchoolModalProps extends BasicProps {
  schoolId?: string;
  onClose: () => void;
}

const K12SchoolModal = (props: K12SchoolModalProps) => {
  const { schoolId, onClose } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <Modal.Header title={schoolId ? 'School Details' : 'Create School'} />
      <Modal.Body>
        {schoolId ? (
          <K12SchoolModalDetails schoolId={schoolId} />
        ) : (
          <K12SchoolModalForm
            k12School={undefined}
            isEditMode={isEditMode}
            onCloseEditMode={() => setIsEditMode(false)}
            onCloseModal={onClose}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default K12SchoolModal;
