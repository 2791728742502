import Button from '@components/V4/Button';
import RangePicker from '@components/V4/RangePicker/RangePicker';
import TriStateToggle from '@components/V4/TriStateToggle/TriStateToggle';
import { SearchIcon } from '@heroicons/react/outline';
import { TriState } from '@shared/common';
import { getEndDateValue, getStartDateValue } from 'apps/agora/src/utils/helpers';
import { Moment } from 'moment';
import moment from 'moment';
import React from 'react';

interface ExamsHeaderProps {
  examSearch?: string;
  showDeleted: TriState;
  setExamSearch: React.Dispatch<React.SetStateAction<string>>;
  searchParams: any;
  setSearchParams: (data: Partial<any>) => void;
  openExamModalHandler: (examId?: string) => void;
}

const ExamsHeader = (props: ExamsHeaderProps) => {
  const {
    setExamSearch,
    showDeleted,
    examSearch,
    searchParams,
    setSearchParams,
    openExamModalHandler,
  } = props;

  const rangePickerChangeHandler = (dates: [Moment | null, Moment | null]) => {
    const start = dates?.[0] ? getStartDateValue(dates?.[0]) : null;
    const end = dates?.[1] ? getEndDateValue(dates?.[1]) : null;

    setSearchParams({
      rangeStart: start ? start.toISOString() : null,
      rangeEnd: end ? end.toISOString() : null,
      page: 1,
    });
  };

  const getRangePickerValue = (): [Moment, Moment] | null => {
    if (!searchParams.rangeStart || !searchParams.rangeEnd) return null;

    return [moment(searchParams.rangeStart), moment(searchParams.rangeEnd)];
  };

  const triStateChangeHandler = (value: TriState) => {
    setSearchParams({ showDeleted: value, page: 1 });
  };

  return (
    <section className="flex flex-col gap-6 bg-surfaceObject px-18 pt-8 pb-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold">Exams</h3>
        <div className="flex justify-end">
          <Button buttonText="Add Exam" onClick={() => openExamModalHandler()} />
        </div>
      </div>
      <div className="relative w-full flex items-center bg-surfaceObject rounded-3xl">
        <input
          type="text"
          className="bg-transparent border rounded-3xl w-full py-1.5 px-6 text-sm text-white border-customGrey focus:border-customGrey placeholder:text-customGrey placeholder:opacity-50"
          placeholder="Search by Exam Name"
          value={examSearch}
          onChange={(e) => setExamSearch(e.target.value)}
        />

        <SearchIcon className="w-6 h-6 absolute right-6 text-customGrey" />
      </div>

      <div className="flex gap-4">
        <RangePicker
          variant="secondary"
          label="Exam Date"
          className="w-64"
          value={getRangePickerValue()}
          onChange={rangePickerChangeHandler}
        />

        <TriStateToggle
          title="Filter Exams:"
          options={[
            { label: 'Deleted', value: 'checked' },
            { label: 'Active', value: 'unchecked' },
            { label: 'All', value: 'indeterminate' },
          ]}
          value={showDeleted}
          onChange={triStateChangeHandler}
        />
      </div>
    </section>
  );
};

export default ExamsHeader;
