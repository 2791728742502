import { ReactComponent as ContactIcon } from '@assets/icons/profile-icons/banner-icons/contact-icon.svg';
import { ReactComponent as ExamsIcon } from '@assets/icons/profile-icons/banner-icons/exams-icon.svg';
import { ReactComponent as SchoolIcon } from '@assets/icons/profile-icons/banner-icons/school-icon.svg';
import { ReactComponent as UniversityGoalsIcon } from '@assets/icons/profile-icons/banner-icons/university-goals-icon.svg';
import { ReactComponent as UniversityIcon } from '@assets/icons/profile-icons/banner-icons/university-icon.svg';
import { ReactComponent as WorkIcon } from '@assets/icons/profile-icons/banner-icons/work-icon.svg';
import Button from '@components/V4/Button';

interface StepBannerProps {
  stepOption: StepOption;

  onClick: () => void;
}

export type StepOption = keyof typeof stepData;

const stepData = {
  missingContact: {
    icon: <ContactIcon />,
    title: 'Add missing contact details',
    subTitle: 'Your profile is incomplete due to missing essential contact details.',
    buttonText: 'Add contact details',
  },
  missingUniversityGoal: {
    icon: <UniversityGoalsIcon />,
    title: 'Set University Goals',
    subTitle:
      'Set your university goals to help us & your mentors guide you through the application process.',
    buttonText: 'Set University Goals',
  },
  missingExams: {
    icon: <ExamsIcon />,
    title: 'Add exams',
    subTitle: 'Keep track of your exams and stay on top of deadlines by adding them here.',
    buttonText: 'Set Exams',
  },
  missingSchool: {
    icon: <SchoolIcon className="text-customPrimary" />,
    title: 'Add School',
    subTitle: 'Help us understand your background—add your school information',
    buttonText: 'Add School',
  },
  missingUniversity: {
    icon: <UniversityIcon />,
    title: 'Add University',
    subTitle: "Complete your profile by adding the university you've attended.",
    buttonText: 'Add University',
  },
  missingWork: {
    icon: <WorkIcon />,
    title: 'Add Experience',
    subTitle: 'Share your experience to assist us in tailoring your application strategy.',
    buttonText: 'Add Experience',
  },
};

const StepBanner = (props: StepBannerProps) => {
  const { stepOption, onClick } = props;

  const selectedStep = stepData[stepOption as StepOption];

  return (
    <div className="flex flex-col gap-6 px-6 py-4 bg-surfaceHover rounded-xl laptop:flex-row laptop:items-center laptop:justify-between">
      <div className="flex gap-6">
        {selectedStep.icon}
        <div className="flex flex-col gap-2">
          <p className="font-raleway font-bold text-xsm">{selectedStep.title}</p>
          <p className="text-customGrey text-xs">{selectedStep.subTitle}</p>
        </div>
      </div>

      <div className="">
        {/* {isSkippable && <Button variant="text" buttonText={'Skip for now'} />} */}
        <Button
          variant="primary"
          buttonText={selectedStep.buttonText}
          onClick={onClick}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default StepBanner;
