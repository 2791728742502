import Table from '@components/V4/Table/Table';
import { useMemo, useState } from 'react';
import AdmissionRoundsForm from './AdmissionRoundsForm';
import ModalSection, { ActionButton } from '@modules/Admin/components/ModalSection';
import IconButton from '@components/V4/IconButton';
import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useUniversityModalContext } from '@modules/Admin/contexts/UniversityModalContext';
import moment from 'moment';
import { UniversityAdmissionRound } from '@shared/serverTypes';
import useToast from 'apps/agora/src/hooks/useToast';
import { useDeleteAdmissionRoundFromUniversity } from '@actions';

const AdmissionRoundsTab = () => {
  const { university } = useUniversityModalContext();

  const [isAddingAdmissionRound, setIsAddingAdmissionRound] = useState(false);
  const [admissionRoundToEdit, setAdmissionRoundToEdit] = useState<any>();

  const admissionRoundsButtons: ActionButton[] = [
    {
      buttonText: 'Add Admission Round',
      onClick: () => setIsAddingAdmissionRound(true),
    },
  ];

  const toast = useToast();

  const { mutate: deleteAdmissionRound, isLoading: isDeletingAdmissionRound } =
    useDeleteAdmissionRoundFromUniversity({
      onSuccess: () => {
        toast.success('Successfully deleted admission round');
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error?.message ?? 'There was an error, please try again later.';

        toast.error(errorMessage);
      },
    });

  const deleteAdmissionRoundClickHandler = (roundId: string, year: number) => {
    deleteAdmissionRound([university?._id || '', roundId], {});
  };

  const admissionRounds: Record<number, UniversityAdmissionRound[]> = useMemo(() => {
    const admissionRoundsPerYear: Record<number, UniversityAdmissionRound[]> = {};
    university?.admissionData.forEach((universityData) => {
      if (universityData.admissionRounds.length) {
        admissionRoundsPerYear[universityData.year] = universityData.admissionRounds;
      }
    });

    return admissionRoundsPerYear;
  }, [university]);

  const admissionRoundsYears = Object.keys(admissionRounds).sort().reverse();

  const getResultTimeline = (period?: string, month?: string) => {
    if (!period && !month) return 'n/a';

    if (!period) return month;

    return `${period}-${month}`;
  };

  const getAdmissionsTableLabel = (year: string, index: number) => {
    const currentYear = moment().year();
    const nextYear = moment(year).add(1, 'year').year();

    if (index === 0) {
      if (Number(year) < currentYear) {
        return `${year} - Present`;
      }

      return year;
    }

    if (Number(admissionRoundsYears[index - 1]) !== nextYear) {
      return `${year} - ${Number(admissionRoundsYears[index - 1]) - 1} `;
    }

    return year;
  };

  return isAddingAdmissionRound ? (
    <AdmissionRoundsForm
      universityId={university?._id || ''}
      isModeAddNew
      onCloseEditForm={() => setIsAddingAdmissionRound(false)}
    />
  ) : (
    <ModalSection
      className="mt-6"
      sectionTitle="Admission Rounds"
      buttonsArray={admissionRoundsButtons}
    >
      <div className="flex flex-col gap-4">
        {admissionRoundsYears.length ? (
          admissionRoundsYears.map((year, index) => (
            <div key={year} className="flex flex-col gap-2">
              <h4 className="text-white font-bold">{getAdmissionsTableLabel(year, index)}</h4>
              <Table size="small">
                <Table.Header>
                  <Table.Row isHeader>
                    <Table.HeaderCell className="w-1/3">Name</Table.HeaderCell>
                    <Table.HeaderCell className="w-1/3">Deadline</Table.HeaderCell>
                    <Table.HeaderCell className="w-1/3">Results Timeline</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {admissionRounds[Number(year)].map((item) => (
                    <Table.Row key={item._id}>
                      {admissionRoundToEdit?._id === item._id ? (
                        <Table.Cell className="!p-0" colSpan={4}>
                          <AdmissionRoundsForm
                            universityId={university?._id || ''}
                            className="rounded-none"
                            year={Number(year)}
                            admissionRoundData={item}
                            onCloseEditForm={() => setAdmissionRoundToEdit(undefined)}
                          />
                        </Table.Cell>
                      ) : (
                        <>
                          <Table.Cell>{item.name}</Table.Cell>
                          <Table.Cell>
                            {item.deadline ? moment(item.deadline).format('DD MMM') : ' n/a'}
                          </Table.Cell>
                          <Table.Cell>
                            {getResultTimeline(
                              item.resultsTimeline.period,
                              item.resultsTimeline.month
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <div className="flex gap-4">
                              <IconButton
                                size="small"
                                variant="ghost"
                                icon={<EditIcon />}
                                onClick={() => setAdmissionRoundToEdit(item)}
                              />
                              <IconButton
                                isDisabled={isDeletingAdmissionRound}
                                size="small"
                                variant="ghost"
                                color="danger"
                                icon={<TrashIcon />}
                                onClick={() =>
                                  deleteAdmissionRoundClickHandler(item?._id ?? '', Number(year))
                                }
                              />
                            </div>
                          </Table.Cell>
                        </>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ))
        ) : (
          <EmptyStateCard
            text="No Existing Data"
            icon={<NoDataIcon className="text-customGrey" />}
          />
        )}
      </div>
    </ModalSection>
  );
};

export default AdmissionRoundsTab;
