import { useSaveUserProfile } from '@actions';
import { ReactComponent as ContactIcon } from '@assets/icons/profile-icons/contact-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/profile-icons/edit-icon.svg';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import Tooltip from '@components/V4/Tooltip';
import { zodResolver } from '@hookform/resolvers/zod';
import { PhoneNumber } from '@shared/serverTypes';
import { phoneNumberValidation } from '@validations/generics';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { countries } from 'country-codes-flags-phone-codes';
import { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ProfileContext } from '../AccountProfile';
import StudentAsideInfo from './ProfileAsideInfo';

interface ProfileAsideContactProps {
  fullName: string;
  email: string;
  phone?: PhoneNumber;
  userId: string;
  hasEditPermissions: boolean;
  isMentorProfile: boolean;
}

const contactSchema = z.object({
  fullName: z.string().nonempty('Name is required.'),
  email: z.string().email({ message: 'Invalid email address' }),
  countryCode: z.string().min(1, 'Country code is required.'),
  phone: phoneNumberValidation,
});

type FormData = z.infer<typeof contactSchema>;

const ProfileAsideContact = (props: ProfileAsideContactProps) => {
  const { fullName, email, phone, userId, hasEditPermissions, isMentorProfile } = props;

  const { isExternalViewer, isLoading: isProfileLoading } = useContext(ProfileContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();

  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(contactSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      fullName,
      email,
      phone: phone?.number,
      countryCode: phone?.countryCode,
    },
  });

  const { field: countryCodeField, fieldState: countryCodeFieldState } = useController({
    name: 'countryCode',
    control,
  });
  const { field: phoneNumberField, fieldState: phoneNumberFieldState } = useController({
    name: 'phone',
    control,
  });

  const editButtonHandler = () => {
    reset({ fullName, email, phone: phone?.number, countryCode: phone?.countryCode });
    setIsEditMode((prev) => !prev);
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      toast.success('Successfully updated your profile.');
      await queryClient.invalidateQueries('/users/:id/profile');

      editButtonHandler();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        ...(isMentorProfile && { fullName: data.fullName }),
        phoneNumber: {
          countryCode: data.countryCode?.split('#')?.[0],
          number: data.phone,
        },
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (isEditMode) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, [isEditMode]);

  return isEditMode ? (
    <div className="flex gap-6 py-4 bg-surfaceHover px-6 rounded-xl">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-4 w-full">
          <ContactIcon />
          <h3 className="font-raleway font-bold">Contact Information</h3>
        </div>
        <div className="flex flex-col gap-6">
          <h4 className="text-customPrimary font-raleway text-xsm font-bold">
            Edit Contact Information
          </h4>
          {isMentorProfile && (
            <div>
              <Input
                type="fullName"
                autoFocus
                id="fullName"
                placeholder="John Doe"
                label="Full Name"
                isTouched={touchedFields.fullName}
                isValid={!errors.fullName}
                errorText={errors.fullName?.message}
                {...register('fullName', { required: true })}
              />
            </div>
          )}
          <Tooltip
            text="Please contact an administrator in order to have your email changed."
            placement="top"
          >
            <div>
              <Input
                isDisabled
                type="email"
                id="email"
                placeholder="john.doe@gmail.com"
                label="Email"
                isTouched={touchedFields.email}
                isValid={!errors.email}
                errorText={errors.email?.message}
                {...register('email', { required: true })}
              />
            </div>
          </Tooltip>
          <div className="flex flex-col gap-2 laptop:flex-row">
            <Select
              className="laptop:max-w-[33.33%]"
              value={countryCodeField.value}
              onSelect={(value) => countryCodeField.onChange(value)}
              onClear={() => countryCodeField.onChange('')}
              onBlur={countryCodeField.onBlur}
              options={countries.map((country, index) => ({
                label: `(${country.dialCode}) ${country.name}`,
                value: `${country.dialCode}#${index}`,
              }))}
              label="Country"
              size="large"
              isRequired
              isValid={!countryCodeFieldState.error}
              isTouched={countryCodeFieldState.isTouched}
              errorText={countryCodeFieldState.error?.message}
            />

            <Input
              type="tel"
              id="phone"
              label="Phone"
              isRequired
              name="countryCode"
              prefix={countryCodeField.value?.split('#')?.[0]}
              onChange={(value) => phoneNumberField.onChange(value)}
              onBlur={phoneNumberField.onBlur}
              value={phoneNumberField.value}
              isDisabled={!countryCodeField.value}
              isValid={!phoneNumberFieldState.error}
              isTouched={phoneNumberFieldState.isTouched}
              errorText={countryCodeField.value ? phoneNumberFieldState.error?.message : undefined}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button buttonText="Cancel" variant="secondary" onMouseDown={editButtonHandler} />
          <Button
            buttonText="Save"
            isLoading={isLoading}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-6 py-4 px-6">
      <div className="flex gap-4 w-full">
        <ContactIcon />
        <div className="flex flex-col gap-6 w-full">
          <h3 className="font-raleway font-bold">Contact Information</h3>
          {isMentorProfile && (
            <StudentAsideInfo label="Name" value={fullName} isLoading={isProfileLoading} />
          )}
          <StudentAsideInfo label="Email" value={email} isLoading={isProfileLoading} />
          <StudentAsideInfo
            label="Phone"
            value={`${phone?.countryCode ?? ''}${phone?.number ?? ''}`}
            isLoading={isProfileLoading}
          />
        </div>
      </div>
      {hasEditPermissions && (
        <IconButton
          onClick={editButtonHandler}
          className="min-h-6 min-w-6"
          icon={<EditIcon className="min-w-3 min-h-3" />}
        />
      )}
    </div>
  );
};

export default ProfileAsideContact;
