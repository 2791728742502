import { ExamWithUser } from '@shared/clientTypes/DTOs/ExamsDTO';
import moment from 'moment';
import { formatTestDuration, formatValidityPeriod } from '../../utils/helpers';
import InfoBar from './InfoBar';

interface ExamInfoBarProps {
  exam: ExamWithUser;
}

const ExamInfoBar = (props: ExamInfoBarProps) => {
  const { exam } = props;

  const examDetailsArray = [
    { label: 'Exam Name:', value: exam?.name },

    {
      label: 'Test Duration:',
      value: exam?.testDuration ? formatTestDuration(exam?.testDuration) : undefined,
    },

    {
      label: 'Exam Date:',
      value: exam?.examDate ? moment(exam?.examDate).format('DD.MM.YYYY') : undefined,
    },
    {
      label: 'Validity Period:',
      value: exam?.validityPeriod ? formatValidityPeriod(exam?.validityPeriod) : undefined,
    },
    {
      label: 'Registration Deadline:',
      value: exam?.registrationDeadline
        ? moment(exam?.registrationDeadline).format('DD.MM.YYYY')
        : undefined,
    },
    { label: 'Retake Policy:', value: exam?.retakePolicy ?? undefined },
    {
      label: 'Scoring Scale:',
      value: exam?.scoringScale ? `${exam.scoringScale.min} - ${exam.scoringScale.max}` : undefined,
    },
    {
      label: 'Test Fee:',
      value: exam?.testFee ? `${exam.testFee.value} ${exam.testFee.currency}` : undefined,
    },
    { label: 'Target Score:', value: exam?.targetScore },

    { label: 'Mode of Registration:', value: exam?.modeOfRegistration ?? undefined },
  ];
  return (
    <InfoBar className="mt-2" messageTitle="Exam Details" variant="info" color="light">
      <div className="w-full flex flex-wrap gap-y-1  my-3">
        {examDetailsArray.map(
          (examDetail, index) =>
            examDetail.value !== undefined && (
              <span
                key={`row-${index}`}
                className="flex text-customGrey font-raleway text-xs basis-1/2"
              >
                <span className="min-w-max">{examDetail.label}</span>
                <span className="text-white ml-2 break-words">{examDetail.value}</span>
              </span>
            )
        )}
      </div>
    </InfoBar>
  );
};

export default ExamInfoBar;
