import { useContext, useMemo } from 'react';
import SchoolSection from '../../../SchoolSection/SchoolSection';
import UniversitySection from '../../../UniversitySection/UniversitySection';
import WorkExperienceSection from '../../../WorkExperienceSection/WorkExperienceSection';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';

const ResumeTab = () => {
  const { student } = useContext(StudentDetailsPageContext);

  const { schools, universities } = useMemo(() => {
    const schools: any[] = [];
    const universities: any[] = [];

    student?.educationalBackground?.forEach((educationItem) => {
      const institutionType = educationItem.educationalInstitution?.type;

      if (institutionType === 'University' || !!educationItem.university) {
        universities.push(educationItem);
      } else if (institutionType === 'School' || !!educationItem.school) {
        schools.push(educationItem);
      }
    });

    return { schools, universities };
  }, [student?.educationalBackground]);

  return (
    <>
      <WorkExperienceSection experiences={student?.experiences} userId={student?._id || ''} />
      <UniversitySection universities={universities} userId={student?._id || ''} />
      <SchoolSection schools={schools} userId={student?._id || ''} />
    </>
  );
};

export default ResumeTab;
