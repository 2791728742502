import {
  GetAllMajorNamesDTO,
  GetMajorDTO,
  SearchMajorsDTO,
} from '@shared/clientTypes/DTOs/MajorsDTO';
import { ApiDescription, RequestsMethod, ApiData, Major, TriState } from '../common';

export const createMajor: ApiDescription = {
  endpoint: '/majors',
  method: RequestsMethod.Post,
};

export type CreateMajor = ApiData<Partial<Major>, Major>;

export const getMajors: ApiDescription = {
  endpoint: '/majors',
  method: RequestsMethod.Get,
};

export type GetMajors = ApiData<unknown, Major[]>;

export const getMajor: ApiDescription = {
  endpoint: '/majors/:id',
  method: RequestsMethod.Get,
};

export type GetMajor = ApiData<unknown, GetMajorDTO>;

export const updateMajor: ApiDescription = {
  endpoint: '/majors/:id',
  method: RequestsMethod.Patch,
};

export type UpdateMajor = ApiData<Partial<Major>, Major>;

export const deleteMajor: ApiDescription = {
  endpoint: '/majors/:id',
  method: RequestsMethod.Delete,
};

export type DeleteMajor = ApiData<unknown, Major>;

export const restoreMajor: ApiDescription = {
  endpoint: '/majors/restore/:id',
  method: RequestsMethod.Patch,
};

export type RestoreMajor = ApiData<unknown, Major>;

export const getAllMajorNames: ApiDescription = {
  endpoint: '/majors/names',
  method: RequestsMethod.Get,
};

export type GetAllMajorNames = ApiData<unknown, GetAllMajorNamesDTO>;

export const searchMajors: ApiDescription = {
  endpoint: '/majors/search-majors',
  method: RequestsMethod.Get,
};

export type SearchMajors = ApiData<
  {
    limit: number;
    page: number;
    rangeStart?: string;
    rangeEnd?: string;
    user?: string;
    sortBy: string | null;
    sortOrder: 'asc' | 'desc' | null;
    search?: string;
    showDeleted?: TriState;
  },
  SearchMajorsDTO
>;
