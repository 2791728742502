import { MeetingDetails } from '@shared/clientTypes';
import { LiveTranscriptionLanguage, Stream, VideoClient } from '@zoom/videosdk';
import { createContext, Dispatch } from 'react';

interface ZoomContextProps {
  zoomClient?: typeof VideoClient;
  meetingDetails?: MeetingDetails;
  activeCamera?: string;
  activeMicrophone?: string;
  isMicrophoneActive: boolean;
  isCameraActive: boolean;
  isMeetingLoading: boolean;
  canJoinMeeting: boolean;
  stream?: typeof Stream;
  webSocket?: WebSocket;
  isSocketConnected: boolean;
  transcriptLanguage: LiveTranscriptionLanguage | undefined;
  videoSettings: { noiseSuppression?: boolean; virtualBackground?: string };
  setTranscriptLanguage: Dispatch<React.SetStateAction<LiveTranscriptionLanguage | undefined>>;
  setActiveCamera: Dispatch<React.SetStateAction<string | undefined>>;
  setActiveMicrophone: Dispatch<React.SetStateAction<string | undefined>>;
  setIsMicrophoneActive: Dispatch<React.SetStateAction<boolean>>;
  setIsCameraActive: Dispatch<React.SetStateAction<boolean>>;
  setIsMeetingLoading: Dispatch<React.SetStateAction<boolean>>;
  setCanJoinMeeting: Dispatch<React.SetStateAction<boolean>>;
  setStream: Dispatch<React.SetStateAction<typeof Stream | undefined>>;
  closeWebsocketConnection: () => void;
}

export const ZoomContext = createContext<ZoomContextProps>({
  isMicrophoneActive: false,
  isCameraActive: false,
  isMeetingLoading: false,
  canJoinMeeting: false,
  isSocketConnected: false,
  videoSettings: { noiseSuppression: false },
  transcriptLanguage: LiveTranscriptionLanguage.English,
  setTranscriptLanguage: () => console.warn('setTransriptLanguage implementation needed'),
  setActiveCamera: () => console.warn('setActiveCamera implementation needed'),
  setActiveMicrophone: () => console.warn('setActiveMicrophone implementation needed'),
  setIsMicrophoneActive: () => console.warn('setIsMicrophoneActive implementation needed'),
  setIsCameraActive: () => console.warn('setIsCameraActive implementation needed'),
  setIsMeetingLoading: () => console.warn('setIsMeetingLoading implementation needed'),
  setCanJoinMeeting: () => console.warn('setCanJoinMeeting implementation needed'),
  setStream: () => console.warn('setStream implementation needed'),
  closeWebsocketConnection: () => console.warn('closeWebsocketConnection implementation needed'),
});
