import DriveIcon from '@assets/icons/Icon-awesome-google-drive.png';
import { ReactComponent as SessionDetailsIcon } from '@assets/icons/V4/info-icon.svg';
import { ReactComponent as OptionsIcon } from '@assets/icons/V4/options-icon.svg';
import { ReactComponent as FeedbackIcon } from '@assets/icons/V4/session-details/feedback-icon.svg';
import { ReactComponent as JoinMeetingIcon } from '@assets/icons/video-icons/camera-icon.svg';
import IconButton from '@components/V4/IconButton';
import { Meeting, MeetingWithHomework, SessionStatus } from '@shared/common';
import { Tooltip as AntdTooltip } from 'antd';
import Tooltip from '@components/V4/Tooltip';

import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import HomeworkButton from 'apps/agora/src/views/UserView/Home/Common/Buttons/HomeworkButton';
import JoinButtonMentor from 'apps/agora/src/views/UserView/Home/Common/Buttons/JoinButtonMentor';
import JoinButtonStudent from 'apps/agora/src/views/UserView/Home/Common/Buttons/JoinButtonStudent';
import MeetingListItemButton from 'apps/agora/src/views/UserView/Home/Common/Buttons/MeetingListItemButton';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  SessionHistoryMeetingDTO,
  SessionHistorySessionDTO,
} from '@shared/clientTypes/DTOs/EnrollmentsDTO';

interface RelevantSessionActionButtonsProps {
  meeting: SessionHistoryMeetingDTO;
  session: SessionHistorySessionDTO;
  onOpenFeedbackModal: () => void;
  isMentor: boolean;
}

const RelevantSessionActionButtons = (props: RelevantSessionActionButtonsProps) => {
  const { meeting, session, onOpenFeedbackModal, isMentor } = props;

  const { trackEvent } = useAnalytics();

  let buttons: ReactNode[] = [];
  switch (session.status) {
    case SessionStatus.SCHEDULED:
    case SessionStatus.ONGOING:
      buttons = [
        <>
          <Link key="meeting" to={`/video-meeting/${meeting?._id}`}>
            <Tooltip text="Click to join Video Meeting!">
              <IconButton icon={<JoinMeetingIcon className="h-4 w-4" />} />
            </Tooltip>
          </Link>
          <AntdTooltip
            key="session-details"
            zIndex={9999}
            color="transparent"
            placement="bottom"
            trigger="click"
            title={
              <div className="tooltip-container next-meeting-card-tooltip-menu-container !min-w-44">
                <MeetingListItemButton>
                  <Link
                    className="flex items-center gap-3.5 w-full h-full text-white py-2 pl-4"
                    to={`/session-details/${session._id}`}
                    onClick={() =>
                      trackEvent(ANALYTICS_EVENT_NAMES.SESSION_DETAILS_GOTO_BUTTON_CLICK)
                    }
                  >
                    <SessionDetailsIcon className="w-3.5 h-3.5 text-white" />

                    <span className="text-white">Session Details</span>
                  </Link>
                </MeetingListItemButton>

                {isMentor ? (
                  <>
                    <HomeworkButton meeting={meeting as unknown as MeetingWithHomework} />
                    <JoinButtonMentor meeting={meeting as unknown as Meeting} />
                  </>
                ) : (
                  <>
                    <MeetingListItemButton
                      className={meeting?.homework ? '' : 'cursor-not-allowed'}
                    >
                      <div
                        className="flex items-center w-full h-full text-white py-2 px-4"
                        onClick={() => {
                          if (meeting?.homework) {
                            window.open(
                              `https://docs.google.com/document/d/${meeting?.homework?.fileId}`,
                              '_blank'
                            );
                          }
                        }}
                      >
                        <img src={DriveIcon} className="next-meeting-student-drive-icon" />

                        <span
                          className={mergeClassNames('gradient cursor-pointer text-white', {
                            'cursor-not-allowed': !meeting?.homework,
                          })}
                        >
                          Homework
                        </span>
                      </div>
                    </MeetingListItemButton>
                    <JoinButtonStudent meeting={meeting as unknown as Meeting} />
                  </>
                )}
              </div>
            }
          >
            <IconButton icon={<OptionsIcon />} />
          </AntdTooltip>
        </>,
      ];
      break;

    default:
      buttons = [
        <Link key="session-details" to={`/session-details/${session._id}`}>
          <Tooltip text="Click to view Session Details">
            <IconButton
              variant="ghost"
              icon={<SessionDetailsIcon className="w-6 h-6 laptop:w-4 laptop:h-4" />}
            />
          </Tooltip>
        </Link>,
      ];

      if (isMentor) {
        if (session.status === SessionStatus.NEEDS_FEEDBACK) {
          buttons.unshift(
            <IconButton
              key="feedback"
              onClick={onOpenFeedbackModal}
              icon={<FeedbackIcon className="w-4 h-4" />}
            />
          );
        }
      } else if (!session.studentFeedback?.length) {
        buttons.unshift(
          <IconButton
            key="feedback"
            onClick={onOpenFeedbackModal}
            icon={<FeedbackIcon className="w-4 h-4" />}
          />
        );
      }

      break;
  }

  return <>{buttons}</>;
};

export default RelevantSessionActionButtons;
