import DownIcon from '@assets/icons/down-icon.png';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import RangePicker from '@components/V4/RangePicker/RangePicker';
import Select, { SelectOption } from '@components/V4/Select/Select';
import TriStateToggle from '@components/V4/TriStateToggle/TriStateToggle';
import { useDebounce } from '@hooks';
import { FLAG_TYPE } from '@shared/constants';
import { TriState } from '@shared/clientTypes/misc';
import AgoraSearchBar from '@utils/AgoraSearchbar';
import { useGetAllMentors, useGetAllStudents } from 'apps/agora/src/actions';
import { useGetAllCourses } from 'apps/agora/src/actions/courses.actions';
import { COLORS } from 'apps/agora/src/v3/global';
import { Moment } from 'moment';
import { useState } from 'react';

type Props = {
  search?: string;
  handleFlagType: (flagType: string | undefined) => void;
  handleCourseId: (courseId: any) => void;
  handleStudentId: (studentId: any) => void;
  handleMentorId: (mentorId: any) => void;
  handleSearch: (searchValue: string) => void;
  handleActiveFlags: (active: string) => void;
  handleUserStatus: (userStatus: string) => void;
  selectedTab: string;
  handleSelectedTab: (tab: string) => void;
  flagType?: string;
  courseId?: string;
  studentId?: string;
  mentorId?: string;
  getRangePickerValue: () => [Moment, Moment] | null;
  rangePickerChangeHandler: (dates: [Moment | null, Moment | null]) => void;
};

const tabs = [
  { name: 'Flags', current: false, key: 'Flags' },
  { name: 'Overview', current: false, key: 'Overview' },
];

const FlagsTableHeader = ({
  search,
  handleFlagType,
  handleCourseId,
  handleStudentId,
  handleMentorId,
  handleSearch,
  handleActiveFlags,
  handleUserStatus,
  selectedTab,
  handleSelectedTab,
  flagType,
  courseId,
  studentId,
  mentorId,
  getRangePickerValue,
  rangePickerChangeHandler,
}: Props) => {
  const { data: allCourses } = useGetAllCourses();

  const [checkedState, setCheckedState] = useState<TriState>('unchecked');
  const [studentSearch, setStudentSearch] = useState('');
  const [mentorSearch, setMentorSearch] = useState('');
  const debouncedStudentSearch = useDebounce(studentSearch ?? '', 500);
  const debouncedMentorSearch = useDebounce(mentorSearch ?? '', 500);

  const allOption = { label: 'ALL', value: null };

  const { data: students, isLoading: isStudentsLoading } = useGetAllStudents({
    search: debouncedStudentSearch,
    limit: 20,
  });

  const { data: mentors, isLoading: isMentorsLoading } = useGetAllMentors({
    search: debouncedMentorSearch,
    limit: 20,
  });

  const getStudentOptions = () => {
    const studentOptions: SelectOption[] =
      students?.map((student) => ({
        label: student.fullName,
        value: student._id,
      })) || [];

    if (!debouncedStudentSearch) {
      studentOptions.unshift(allOption);
    }

    return studentOptions;
  };

  const getMentorOptions = () => {
    const mentorOptions: SelectOption[] =
      mentors?.map((mentor) => ({
        label: mentor.fullName,
        value: mentor._id,
      })) || [];

    if (!debouncedMentorSearch) {
      mentorOptions.unshift(allOption);
    }

    return mentorOptions;
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  const testUsersTriStateHandler = (value: TriState) => {
    setCheckedState(value);
    handleUserStatus(value);
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.DARK_GREY_ALT,
      }}
      className="pt-6 px-8"
    >
      <div className="flex flex-col w-full">
        {/*HEADER TITLE AND BUTTON*/}
        <div className="flex justify-between items-center mb-6">
          <h1 className="header-title text-white">Flags Dashboard</h1>

          <select
            onChange={(e) => {
              const selectedValue = e.target.value;
              handleActiveFlags(selectedValue);
            }}
            className="flags-table-header-custom-select"
            style={{
              borderColor: COLORS.TEXT_PRIMARY,
              color: COLORS.PRIMARY,
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
              paddingRight: '0px',
              paddingLeft: '0px',
              backgroundImage: `url(${DownIcon})`,
              backgroundSize: '11px auto',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right center',
              marginRight: '20px',
              cursor: 'pointer',
              boxShadow: 'none',
            }}
            defaultValue={'active'}
          >
            <option value={'0'}>Show active flags</option>
            <option value={'1'}>Show closed flags</option>
          </select>
        </div>

        <div>
          {/*SEARCHBAR*/}
          <AgoraSearchBar
            search={search}
            handleSearch={handleSearch}
            placeholder={'Search by student, mentor, enrolment or keywords...'}
          />

          <div className="flex flex-col">
            <div className="flex flex-wrap gap-4">
              {/*FLAG TYPE*/}
              <Select
                className="!w-48"
                label="Flag Type"
                value={flagType}
                options={FLAG_TYPE.map((flag, index) => ({
                  value: index,
                  label: flag,
                }))}
                onSelect={(value) => handleFlagType(value as string)}
                placeholder="Search Flag Type"
                variant="secondary"
                showMultiline
              />

              <Select
                className="!w-48"
                label="Course Flagged"
                value={courseId}
                options={allCourses?.map((course) => ({
                  value: course._id,
                  label: course.title,
                }))}
                onSelect={(value) => handleCourseId(value as string)}
                placeholder="Search Course Flagged"
                variant="secondary"
              />

              <Select
                className="!w-48"
                label="Students Flagged"
                value={studentId}
                filterOption={() => true}
                searchValue={studentSearch}
                onSearch={setStudentSearch}
                onBlur={() => setStudentSearch('')}
                onSelect={(value) => handleStudentId(value)}
                options={getStudentOptions()}
                notFoundContent={
                  isStudentsLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
                }
                placeholder="Search Students"
                variant="secondary"
              />

              <Select
                className="!w-48"
                label="Mentor Flagged"
                value={mentorId}
                filterOption={() => true}
                searchValue={mentorSearch}
                onSearch={setMentorSearch}
                onSelect={(value) => handleMentorId(value)}
                onBlur={() => setMentorSearch('')}
                options={getMentorOptions()}
                notFoundContent={
                  isMentorsLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
                }
                placeholder="Search Mentor"
                variant="secondary"
              />

              <RangePicker
                variant="secondary"
                label="Date Range"
                className="w-48"
                value={getRangePickerValue()}
                onChange={rangePickerChangeHandler}
              />
            </div>
            <TriStateToggle
              title="Show Users:"
              options={[
                { label: 'Regular', value: 'unchecked' },
                { label: 'Test', value: 'checked' },
                { label: 'All', value: 'indeterminate' },
              ]}
              value={checkedState}
              onChange={(value) => testUsersTriStateHandler(value)}
              className="mt-3"
            />

            <div className="mt-3">
              <div className="hidden sm:block">
                <div>
                  <nav className="-mb-px flex" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <span
                        style={{
                          cursor: 'pointer',
                          color: selectedTab === tab?.key ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
                          borderColor:
                            selectedTab === tab?.key ? COLORS.PRIMARY : COLORS.DARK_GREY_ALT,
                          maxWidth: '100px',
                        }}
                        key={tab?.key}
                        onClick={() => handleSelectedTab(tab?.key)}
                        className={classNames(
                          tab?.key === selectedTab
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                        )}
                        aria-current={tab?.current ? 'page' : undefined}
                      >
                        {tab?.name}
                      </span>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlagsTableHeader;
