import { useRef, useState } from 'react';
import CloseIcon from '@assets/icons/close-side-menu.svg';
import SearchbarIcon from '@assets/icons/searchbar-icon.svg';
import { KnowledgeBaseChat } from '@shared/clientTypes/misc';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';

interface ChatTitle {
  threadId: string;
  title: string;
  updatedAt: string;
  _id: string;
}

interface GroupedChatTitles {
  today: ChatTitle[];
  previous7Days: ChatTitle[];
  previous30Days: ChatTitle[];
  older: { [key: string]: ChatTitle[] };
}

interface SideMenuProps {
  items?: KnowledgeBaseChat[];
  selectedItemId?: string;
  onClose: () => void;
  onItemClick: (chatId: string) => void;
}

const SideMenu = (props: SideMenuProps) => {
  const { items, selectedItemId, onClose, onItemClick } = props;

  const topChatTitleRef = useRef<HTMLDivElement | null>(null);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredChatTitles = items?.filter((chatTitle) =>
    chatTitle?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const groupChatTitlesByDate = (chatTitles: ChatTitle[] | undefined): GroupedChatTitles => {
    const now = new Date();
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    const today: ChatTitle[] = [];
    const previous7Days: ChatTitle[] = [];
    const previous30Days: ChatTitle[] = [];
    const older: { [key: string]: ChatTitle[] } = {};

    chatTitles?.forEach((chatTitle) => {
      const titleDate = new Date(chatTitle.updatedAt);
      const timeDifference = now.getTime() - titleDate.getTime();

      if (timeDifference < oneDayInMillis) {
        today.push(chatTitle);
      } else if (timeDifference < 7 * oneDayInMillis) {
        previous7Days.push(chatTitle);
      } else if (timeDifference < 30 * oneDayInMillis) {
        previous30Days.push(chatTitle);
      } else {
        const monthYear = titleDate.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
        if (!older[monthYear]) {
          older[monthYear] = [];
        }
        older[monthYear].push(chatTitle);
      }
    });

    // Sort each group by date, newest first
    const sortByDateDesc = (a: ChatTitle, b: ChatTitle) =>
      new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();

    today.sort(sortByDateDesc);
    previous7Days.sort(sortByDateDesc);
    previous30Days.sort(sortByDateDesc);

    Object.keys(older).forEach((monthYear) => {
      older[monthYear].sort(sortByDateDesc);
    });

    return { today, previous7Days, previous30Days, older };
  };

  const sortedChatTitles = filteredChatTitles?.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );

  const groupedChatTitles = groupChatTitlesByDate(sortedChatTitles);

  const renderChatTitles = (chatTitles: ChatTitle[]) =>
    chatTitles.map((chatTitle, index) => (
      <div
        key={chatTitle._id}
        ref={index === 0 ? topChatTitleRef : null}
        onClick={() => onItemClick(chatTitle._id)}
        className={mergeClassNames(
          'cursor-pointer p-2 bg-transparent rounded-lg hover:bg-surfaceHover',
          { 'bg-surfaceHover': selectedItemId === chatTitle._id }
        )}
      >
        <h6 className="text-xsm text-white">{chatTitle.title ? chatTitle.title : 'No title'}</h6>
      </div>
    ));

  return (
    <div className="flex flex-col justify-start fixed p-8 pt-24 z-10 h-[calc(100vh-3.5rem)] min-w-76 max-w-76 bg-surfaceBackground laptop:h-screen laptop:static">
      <div className="flex justify-between mb-6">
        <div>
          <h4 className="text-base text-white">Chat History</h4>
        </div>
        <div onClick={onClose} className="cursor-pointer h-fit p-0.5">
          <img src={CloseIcon} className="w-3.5 h-auto" alt="close-icon" />
        </div>
      </div>
      <div className="relative rounded-md shadow-sm mb-6">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <img
            src={SearchbarIcon}
            alt="search"
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="name"
          name="search"
          id="search"
          autoComplete="off"
          className="bg-surfaceHover text-customGrey block w-full rounded-lg border-0 outline-0 py-1.5 pl-10 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          placeholder="Search chats"
        />
      </div>

      <div className="h-full overflow-y-auto">
        {groupedChatTitles.today.length > 0 && (
          <>
            <div className="m-2">
              <h6 className="text-customGrey text-xsm">Today</h6>
            </div>
            {renderChatTitles(groupedChatTitles.today)}
          </>
        )}

        {groupedChatTitles.previous7Days.length > 0 && (
          <>
            {' '}
            <div className="m-2">
              <h6 className="text-xsm text-customGrey">Previous 7 Days</h6>
            </div>
            {renderChatTitles(groupedChatTitles.previous7Days)}
          </>
        )}

        {groupedChatTitles.previous30Days.length > 0 && (
          <>
            {' '}
            <div className="m-2">
              <h6 className="text-xsm text-customGrey">Previous 30 Days</h6>
            </div>
            {renderChatTitles(groupedChatTitles.previous30Days)}
          </>
        )}

        {Object.keys(groupedChatTitles.older).length > 0 &&
          Object.keys(groupedChatTitles.older).map((monthYear) => (
            <div key={monthYear}>
              <div className="mb-2">
                <h6 className="text-xsm text-customGrey">{monthYear}</h6>
              </div>
              {renderChatTitles(groupedChatTitles.older[monthYear])}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SideMenu;
