// =========================
// User Roles and Permissions
// =========================
export enum UserRole {
  User,
  Moderator,
  Admin,
  Mentor,
  Student,
  FreeUser,
  Alumni,
  Influencer,
  SuperMentor,
  Financial,
  SuperUser,
  CourseCreator,
  CustomerSuccess,
  StudentsManager,
  MentorsManager,
  UsersManager,
}

// =========================
// Mentoring and Coaching
// =========================
export enum MentoringStyle {
  HighPerforming,
  HandHolding,
  Inspirational,
  Experienced,
  Demanding,
  Rookie,
  OnProbation,
  QuickResponse,
}

export enum MentorStatus {
  InTraining = 'In Training',
  Active = 'Active',
  UnderObservation = 'Under Observation',
  Leaving = 'Leaving',
  Inactive = 'Inactive',
}

// =========================
// Session and Meeting Status
// =========================
export enum SessionStatus {
  PENDING,
  ONGOING,
  NEEDS_FEEDBACK,
  COMPLETED,
  PENDING_REVIEW,
  PAID,
  UNPAYABLE,
  SCHEDULED,
}

export enum MeetingStatus {
  SCHEDULED,
  RESCHEDULED,
  CANCELLED,
  MISSED,
  STARTED,
  ENDED,
  NO_SHOW,
}

export enum OldMeetingStatus {
  SCHEDULED,
  RESCHEDULED,
  CANCELED,
  ATTENDED,
  STUDENT_ATTENDED,
  MENTOR_ATTENDED,
  NO_SHOW,
  COMPLETED,
  COMPLETED_ADMIN,
  PENDING_REVIEW,
  UNCOMPLETED,
  PAID,
  UNPAYABLE,
}

export enum AttendeeResponseStatus {
  ACCEPTED,
  DECLINED,
  PENDING,
}

// =========================
// Reports and Invoices
// =========================
export enum ActivityReportQueueStatus {
  COMPLETED,
  FAILED,
}

export enum ReportStatus {
  AWAIT_INVOICE,
  NEED_REGENERATION,
  INVOICE_UPLOADED,
  READY_FOR_PAYMENT,
  PAID,
  CANCELLED,
  REJECTED,
  INVOICE_ACCEPTED,
  INVALID_E_INVOICE,
  ERROR,
  GENERATING_PDF,
}

// =========================
// File Storage and Assets
// =========================
export enum SPACES_ROOT_KEY {
  ACTIVITY_REPORTS = 'activity-reports',
  INVOICES = 'invoices',
  VIDEO_BACKGROUNDS = 'video-backgrounds',
}

export enum AgoraAssetsFolders {
  CourseImages = 'course-images',
}

export enum UserGeneratedContentFolders {
  UsersAvatar = 'users-avatar',
  VideoBackgrounds = 'video-backgrounds',
  AdmissionDocuments = 'admission-documents',
}

// =========================
// Rewards and Extras
// =========================
export enum REFERRAL_REWARDS {
  '5 extra sessions of CoachMe',
  '50% off one course',
  '€200 eMAG voucher',
}

export enum ExtraServiceStatus {
  CREATED,
  PAID,
}

export enum ServiceType {
  WEBINAR_HOST = 'webinar host',
  SPEAKING_LIVE_EVENT = 'speaking live event',
  REFERRAL = 'referral',
  MARKETING_ACTION = 'marketing action',
  CREATIVE_WRITING_COURSE = 'creative writing course',
  SUBSCRIPTION = 'subscription',
  EXTRA_SESSIONS = 'extra sessions',
  RESOURCE_DEVELOPMENT = 'resource development',
  EXTRA_PAYMENT_PER_SESSION = 'extra payment per session',
  MENTOR_TRAINING = 'mentor training',
  OTHER = 'other',
}

// =========================
// Employment and Experience
// =========================
export enum ExperienceType {
  Work,
  ExtracurricularActivity,
}

export enum EmploymentType {
  Fulltime,
  PartTime,
  Internship,
}

// =========================
// Activity Types
// =========================
export enum ActivityType {
  AcademicAndIntellectualPursuits,
  ArtsAndCreativeExpression,
  AthleticsAndPhysicalActivities,
  CommunityServiceAndVolunteering,
  CareerOrientedAndPracticalExperience,
  LeadershipAndSocialImpact,
  STEMAndTechnology,
  CulturalAndReligiousEngagement,
}

// =========================
// Learning Management System (LMS)
// =========================
export enum LmsProductType {
  COURSE = 'course',
  BUNDLE = 'bundle',
  SUBSCRIPTION = 'subscription',
}

export enum LmsCourseAccess {
  FREE,
  DRAFT,
}

// =========================
// Flags and Statuses
// =========================
export enum FlagStatus {
  ACTIVE,
  CLOSED,
}

export enum FlagTypes {
  FIRST_MEETING_NOT_SCHEDULED_IN_3_DAYS,
  FIRST_MEETING_NOT_HELD_IN_7_DAYS,
  NEXT_MEETING_FURTHER_THAN_62_DAYS,
  NO_MEETING_SCHEDULED_AFTER_48_HOURS_SIENCE_LAST_MEETING_HELD,
  NO_FEEDBACK_AFTER_48_HOURS_SIENCE_MEETING_HELD,
  MAX_DURATION_COURSE_EXCEEDED,
  NO_ADMISSION_RESULTS_COMPLETED,
}

export enum ModelFlagTypes {
  ENROLLMENT,
  MEETING,
}

// =========================
// DRIVE
// =========================
export enum DRIVE_STATUS {
  NOT_CREATED,
  ACTIVE,
  MISSING_FOLDER,
}

export enum ErrorCodes {
  //GENERAL
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  USERID_NOT_PROVIDED = 'USERID_NOT_PROVIDED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  NOTHING_TO_UPDATE = 'NOTHING_TO_UPDATE',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_INPUT = 'INVALID_INPUT',

  //LMS
  LMS_LOGIN_AND_REDIRECT = 'LMS_LOGIN_AND_REDIRECT',
  LMS_ENROLLMENT_FAILED = 'LMS_ENROLLMENT_FAILED',
  LMS_ERROR = 'LMS_ERROR',

  //AUTH
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_USER_ID = 'INVALID_USER_ID',
  USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED',
  USER_NOT_AUTHENTICATED = 'USER_NOT_AUTHENTICATED',
  ROUTE_DISABLED = 'ROUTE_DISABLED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  ROLE_NOT_FOUND = 'ROLE_NOT_FOUND',
  USER_ALREADY_ACTIVATED = 'USER_ALREADY_ACTIVATED',
  USER_IS_DEACTIVATED = 'USER_IS_DEACTIVATED',

  //MEETING
  MEETING_NOT_FOUND = 'MEETING_NOT_FOUND',
  MEETING_DATE_IN_PAST = 'MEETING_DATE_IN_PAST',
  MEETING_DATE_NOT_PROVIDED = 'MEETING_DATE_NOT_PROVIDED',
  MEETING_DATE_DUPLICATE = 'MEETING_DATE_DUBLICATE',
  ANOTHER_MEETING_ALREADY_SCHEDULED = 'ANOTHER_MEETING_ALREADY_SCHEDULED',
  MEETING_ALREADY_ENDED = 'MEETING_ALREADY_ENDED',
  MEETING_UPDATE_INVALID_FIELDS = 'MEETING_UPDATE_INVALID_FIELDS',
  MEETING_STUDENT_NOT_FOUND = 'MEETING_STUDENT_NOT_FOUND',
  MEETING_CREATION_INVALID_FIELDS = 'MEETING_CREATION_INVALID_FIELDS',
  MEETING_CREATION_MISSING_FIELDS = 'MEETING_CREATION_MISSING_FIELDS',
  MEETING_INVALID_STATUS = 'MEETING_INVALID_STATUS',

  //VIDEO

  VIDEO_BACKGROUND_UPLOAD_FAILED = 'VIDEO_BACKGROUND_UPLOAD_FAILED',
  VIDEO_BACKGROUND_DELETE_FAILED = 'VIDEO_BACKGROUND_DELETE_FAILED',

  //ENROLLMENT
  STUDENT_NOT_ENROLLED_IN_COURSE = 'STUDENT_NOT_ENROLLED_IN_COURSE',
  INVALID_FEEDBACK = 'INVALID_FEEDBACK',
  ENROLLMENT_SESSION_NOT_FOUND = 'ENROLLMENT_SESSION_NOT_FOUND',
  INVERVAL_CANT_BE_GREATER_THAN_24_HOURS = 'INVERVAL_CANT_BE_GREATER_THAN_24_HOURS',
  INVERVAL_CANT_BE_LESSER_THAN_0_HOURS = 'INVERVAL_CANT_BE_LESSER_THAN_0_HOURS',
  INVERVAL_MUST_BE_ASCENDING = 'INVERVAL_MUST_BE_ASCENDING',
  MENTOR_SLOT_NOT_AVAILABLE = 'MENTOR_SLOT_NOT_AVAILABLE',
  ENROLLMENT_NOT_FOUND = 'ENROLLMENT_NOT_FOUND',
  ENROLLMENT_HAS_SCHEDULED_MEETINGS = 'ENROLLMENT_HAS_SCHEDULED_MEETINGS',
  STUDENT_ALREADY_ACCEPTED = 'STUDENT_ALREADY_ACCEPTED',
  STUDENT_OPPORTUNITY_EXPIRED = 'STUDENT_OPPORTUNITY_EXPIRED',
  ENROLLMENTID_OR_SESSION_INDEX_NOT_PROVIDED = 'ENROLLMENTID_OR_SESSION_INDEX_NOT_PROVIDED',
  MEETING_CREATION_DEACTIVATED_STUDENT = 'MEETING_CREATION_DEACTIVATED_STUDENT',
  NO_SESSIONS_AVAILABLE = 'NO_SESSIONS_AVAILABLE',

  //COURSE
  COURSE_NOT_FOUND = 'COURSE_NOT_FOUND',
  COURSE_ALREADY_DELETED = 'COURSE_ALREADY_DELETED',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  UPLOAD_ERROR = 'UPLOAD_ERROR',

  //ACTIVITY REPORT
  PDF_GENERATION_ERROR = 'PDF_GENERATION_ERROR',
  REPORT_NOT_FOUND = 'REPORT_NOT_FOUND',
  DOWNLOAD_BULK_ERROR = 'DOWNLOAD_BULK_ERROR',
  MONTH_REQUIRED = 'MONTH_REQUIRED',
  REPORT_IN_ADVANCED_STATE = 'REPORT_IN_ADVANCED_STATE',
  UPLOAD_ALLOWED_ONLY_BETWEEN_2ST_AND_7TH = 'UPLOAD_ALLOWED_ONLY_BETWEEN_2ST_AND_7TH',
  REPORT_IS_NOT_READY_TO_BE_MARKED_AS_READY = 'REPORT_IS_NOT_READY_TO_BE_MARKED_AS_READY',
  REPORT_IS_NOT_READY_TO_BE_MARKED_AS_PAID = 'REPORT_IS_NOT_READY_TO_BE_MARKED_AS_PAID',
  INVOICE_NOT_FOUND = 'INVOICE_NOT_FOUND',
  DATE_TYPE_INVALID = 'DATE_TYPE_INVALID',
  REPORT_IN_INITIAL_STATE = 'REPORT_IN_INITIAL_STATE',
  REPORT_REGENERATION_FAILED = 'REPORT_REGENERATION_FAILED',

  //ARTICLE
  ARTICLE_NOT_FOUND = 'ARTICLE_NOT_FOUND',

  //MRS
  MRS_NOT_FOUND = 'MRS_NOT_FOUND',
  DEFAULT_MRS_NOT_FOUND = 'DEFAULT_MRS_NOT_FOUND',
  MRS_IS_NOT_DEFAULT = 'MRS_IS_NOT_DEFAULT',

  //USER
  MENTOR_CAPACITY_NOT_FOUND = 'MENTOR_CAPACITY_NOT_FOUND',
  ADMISSION_DOCUMENT_DELETE_FAILED = 'ADMISSION_DOCUMENT_DELETE_FAILED',

  //USER ROLES
  USER_IS_NOT_MENTOR = 'USER_IS_NOT_MENTOR',
  USER_ALREADY_ALUMNI = 'USER_ALREADY_ALUMNI',
  USER_ALREADY_MENTOR = 'USER_ALREADY_MENTOR',
  USER_ALREADY_SUPER_MENTOR = 'USER_ALREADY_SUPER_MENTOR',
  USER_ALREADY_ADMIN = 'USER_ALREADY_ADMIN',
  USER_ALREADY_FINANCIAL = 'USER_ALREADY_FINANCIAL',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',

  //CHECKLIST
  CHECKLIST_NOT_TEMPLATE = 'CHECKLIST_NOT_TEMPLATE',

  //FLAGS
  MISSING_FIELDS = 'MISSING_FIELDS',

  //REDIS
  REDIS_ERROR = 'REDIS_ERROR',

  //MAJORS
  MAJOR_ALREADY_EXISTS = 'MAJOR_ALREADY_EXISTS',
  MAJOR_NOT_FOUND = 'MAJOR_NOT_FOUND',

  //EXAMS
  EXAM_ALREADY_EXISTS = 'EXAM_ALREADY_EXISTS',
  EXAM_NOT_FOUND = 'EXAM_NOT_FOUND',

  //UNIVERSITIES
  UNIVERSITY_NOT_FOUND = 'UNIVERSITY_NOT_FOUND',
  NAME_NOT_PROVIDED = 'NAME_NOT_PROVIDED',
  COUNTRY_NOT_PROVIDED = 'COUNTRY_NOT_PROVIDED',
  REGION_NOT_PROVIDED = 'REGION_NOT_PROVIDED',
  UNIVERSITY_NAME_TAKEN = 'UNIVERSITY_NAME_TAKEN',

  //SCHOOLS
  SCHOOL_NOT_FOUND = 'SCHOOL_NOT_FOUND',
  CITY_NOT_PROVIDED = 'CITY_NOT_PROVIDED',
  EDUCATION_LEVEL_NOT_PROVIDED = 'EDUCATION_LEVEL_NOT_PROVIDED',
  SCHOOL_TYPE_NOT_PROVIDED = 'SCHOOL_TYPE_NOT_PROVIDED',

  //OTHER
  USERNAME_ALREADY_TAKEN = 'USERNAME_ALREADY_TAKEN',
  MISSING_PARAMETERS = 'MISSING_PARAMETERS',
  WRONG_PARAMETER_FORMAT = 'WRONG_PARAMETER_FORMAT',
}

export enum ANALYTICS_EVENT_NAMES {
  // Session Scheduling (Mentors)
  SCHEDULE_MEETING_MODAL_OPEN = 'SCHEDULE_MEETING_MODAL_OPEN',
  SCHEDULE_MEETING_SUCCESS = 'SCHEDULE_MEETING_SUCCESS',
  SCHEDULE_MEETING_FAIL = 'SCHEDULE_MEETING_FAIL',
  SCHEDULE_MEETING_TRIGGER = 'SCHEDULE_MEETING_TRIGGER',
  SCHEDULE_MEETING_MODAL_CLOSE = 'SCHEDULE_MEETING_MODAL_CLOSE',
  RESCHEDULE_MEETING_MODAL_OPEN = 'RESCHEDULE_MEETING_MODAL_OPEN',
  RESCHEDULE_MEETING_SUCCESS = 'RESCHEDULE_MEETING_SUCCESS',
  RESCHEDULE_MEETING_FAIL = 'RESCHEDULE_MEETING_FAIL',
  RESCHEDULE_MEETING_TRIGGER = 'RESCHEDULE_MEETING_TRIGGER',
  RESCHEDULE_MEETING_MODAL_CLOSE = 'RESCHEDULE_MEETING_MODAL_CLOSE',

  // Video
  MEETING_JOIN = 'MEETING_JOIN',
  MEETING_JOIN_FAIL = 'MEETING_JOIN_FAIL',
  MEETING_LEAVE = 'MEETING_LEAVE',
  MEETING_LEAVE_FAIL = 'MEETING_LEAVE_FAIL',
  SHARE_SCREEN_START = 'SHARE_SCREEN_START',
  SHARE_SCREEN_END = 'SHARE_SCREEN_END',
  SHARE_SCREEN_END_FAIL = 'SHARE_SCREEN_END_FAIL',
  SHARE_SCREEN_FAIL = 'SHARE_SCREEN_FAIL',
  SHARE_SCREEN_VIEW_FAIL = 'SHARE_SCREEN_VIEW_FAIL',
  CAMERA_START_FAIL = 'CAMERA_START_FAIL',
  MICROPHONE_START_FAIL = 'MICROPHONE_START_FAIL',
  PIP_FAIL = 'PIP_FAIL',

  // Mentor Profile
  PROFILE_CHANGE = 'PROFILE_CHANGE',
  PROFILE_PAYMENT_CHANGE = 'PROFILE_PAYMENT_CHANGE',
  PROFILE_COURSES_CAN_TEACH_CHANGE = 'PROFILE_COURSES_CAN_TEACH_CHANGE',
  PROFILE_SUBJECTS_CAN_TEACH_CHANGE = 'PROFILE_SUBJECTS_CAN_TEACH_CHANGE',
  PROFILE_TAB_CHANGE = 'PROFILE_TAB_CHANGE',
  PROFILE_CAPACITY_CHANGE = 'PROFILE_CAPACITY_CHANGE',

  // Student Profile
  STUDENT_PROFILE_CHANGE_BY_MENTOR = 'STUDENT_PROFILE_CHANGE_BY_MENTOR',

  // Profile Completion Banner
  PROFILE_BANNER_STACK_BUTTON_CLICK = 'PROFILE_BANNER_STACK_BUTTON_CLICK',

  // Banner Stack
  MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK = 'MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK',
  MENTOR_BANNER_STACK_UPCOMING_STUDENTS_CLICK = 'MENTOR_BANNER_STACK_UPCOMING_STUDENTS_CLICK',
  MENTOR_BANNER_STACK_SUBMIT_FEEDBACK_CLICK = 'MENTOR_BANNER_STACK_SUBMIT_FEEDBACK_CLICK',

  // Meeting
  MEETING_ADD_HOMEWORK_BUTTON_CLICK = 'MEETING_ADD_HOMEWORK_BUTTON_CLICK',
  MEETING_RESCHEDULE_BUTTON_CLICK = 'MEETING_RESCHEDULE_BUTTON_CLICK',
  MEETING_RESCHEDULE = 'MEETING_RESCHEDULE',
  MEETING_CANCEL_BUTTON_CLICK = 'MEETING_CANCEL_BUTTON_CLICK',
  MEETING_CANCEL = 'MEETING_CANCEL',
  MEETING_GIVE_FEEDBACK_BUTTON_CLICK = 'MEETING_GIVE_FEEDBACK_BUTTON_CLICK',
  MEETING_GIVE_FEEDBACK = 'MEETING_GIVE_FEEDBACK',
  CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL',
  CLOSE_FEEDBACK_MODAL_ON_MEETING_END = 'CLOSE_FEEDBACK_MODAL_ON_MEETING_END',
  FEEDBACK_MODAL_ON_END_MEETING_SHOW = 'FEEDBACK_MODAL_ON_END_MEETING_SHOW',
  FEEDBACK_ON_END_MEETING = 'FEEDBACK_ON_END_MEETING',

  // NPS Mentor
  NPS_MODAL_CLOSE = 'NPS_MODAL_CLOSE',
  NPS_MODAL_SUBMIT = 'NPS_MODAL_SUBMIT',
  GLASSDOOR_REVIEW_MODAL_BUTTON_CLICK = 'GLASSDOOR_REVIEW_MODAL_BUTTON_CLICK',
  GLASSDOOR_REVIEW_MODAL_CLOSE = 'GLASSDOOR_REVIEW_MODAL_CLOSE',

  // NPS Student
  GOOGLE_REVIEW_MODAL_BUTTON_CLICK = 'GOOGLE_REVIEW_MODAL_BUTTON_CLICK',
  GOOGLE_REVIEW_MODAL_CLOSE = 'GOOGLE_REVIEW_MODAL_CLOSE',

  // Session Details
  SESSION_DETAILS_GOTO_BUTTON_CLICK = 'SESSION_DETAILS_GOTO_BUTTON_CLICK',
  SESSION_NAVIGATION_ARROW_CLICK = 'SESSION_NAVIGATION_ARROW_CLICK',
  SESSION_SUBMIT_FEEDBACK_BUTTON_CLICK = 'SESSION_SUBMIT_FEEDBACK_BUTTON_CLICK',
  SESSION_ADD_NOTES_BUTTON_CLICK = 'SESSION_ADD_NOTES_BUTTON_CLICK',
  SESSION_ADD_NOTES = 'SESSION_ADD_NOTES',
  SESSION_SHOW_ENTIRE_TRANSCRIPT_BUTTON_CLICK = 'SESSION_SHOW_ENTIRE_TRANSCRIPT_BUTTON_CLICK',
}

export enum GoalStatusTypes {
  ApplicationNotStarted,
  ApplicationInProgress,
  ApplicationSent,
  ApplicationRejected,
  OfferReceived,
  OfferAccepted,
  OfferDeclined,
}

export enum TestFeeCurrenciesEnum {
  USD = 'USD',
  EUR = 'EUR',
  RON = 'RON',
}

export enum EducationLevel {
  ELEMENTARY_SCHOOL,
  MIDDLE_SCHOOL,
  HIGH_SCHOOL,
}

export enum SchoolType {
  PRIVATE,
  PUBLIC,
}
