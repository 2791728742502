import Button from '@components/V4/Button';
import { Comment, Enrollment } from '@shared/common';
import moment from 'moment';
import { useState } from 'react';

interface EnrollmentDetailsProps {
  enrollmentDetails: EnrollmentDetailsType;
}

export interface EnrollmentDetailsType {
  startDate: string;
  endDate: string;
  ended?: boolean;
  noOfSessions?: number;
  comment?: Comment;
}

const EnrollmentDetails = (props: EnrollmentDetailsProps) => {
  const { enrollmentDetails } = props;

  const [showFullComment, setShowFullComment] = useState(false);

  const charThreshold = 100;
  const commentText = enrollmentDetails.comment?.message ?? 'n/a';

  const isLongComment = commentText.split('\n').length > 2 || commentText.length > charThreshold;

  const getTruncatedComment = () => {
    const firstNewlineIndex = commentText.indexOf('\n');
    const secondNewlineIndex =
      firstNewlineIndex !== -1 ? commentText.indexOf('\n', firstNewlineIndex + 1) : -1;

    return secondNewlineIndex !== -1
      ? commentText.slice(0, secondNewlineIndex)
      : commentText.slice(0, charThreshold);
  };

  const displayedComment = showFullComment ? commentText : getTruncatedComment();

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="w-full flex gap-x-8">
        <div className="flex flex-col gap-2 w-1/2">
          <div className="flex text-customGrey">
            <span className="min-w-max">Start Date:</span>
            <span className="text-white ml-2 break-words">
              {enrollmentDetails.startDate
                ? moment(enrollmentDetails.startDate).format('DD.MM.YYYY')
                : ' n/a'}
            </span>
          </div>

          <div className="flex text-customGrey">
            <span className="min-w-max">End Date:</span>
            <span className="text-white ml-2 break-words">
              {enrollmentDetails.endDate
                ? moment(enrollmentDetails.endDate).format('DD.MM.YYYY')
                : ' n/a'}
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-1/2">
          <div className="flex text-customGrey">
            <span className="min-w-max">Status:</span>
            <span className="text-white ml-2 break-words">
              {enrollmentDetails.ended ? 'Ended' : 'Active'}
            </span>
          </div>

          <div className="flex text-customGrey">
            <span className="min-w-max">Total Sessions:</span>
            <span className="text-white ml-2 break-words">{enrollmentDetails.noOfSessions}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex text-customGrey">
          <span className="min-w-max">Comment:</span>
          <span className="text-white ml-2 break-words whitespace-pre-wrap">
            {displayedComment}
            {isLongComment && !showFullComment && (
              <>
                <br />
                ...
              </>
            )}
          </span>
        </div>
      </div>
      {isLongComment && (
        <Button
          variant="text"
          className="text-customPrimary ml-auto"
          buttonText={showFullComment ? 'Show Less' : 'Show More'}
          onClick={() => setShowFullComment(!showFullComment)}
        />
      )}
    </div>
  );
};

export default EnrollmentDetails;
