import { BookOutlined, TeamOutlined, ClockCircleOutlined } from '@ant-design/icons';
import MyAccountIcon from '@assets/icons/Exclusion_2.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/V4/close-icon.svg';
import { ReactComponent as DiscordIcon } from '@assets/icons/V4/discord-icon.svg';
import { ReactComponent as MailIcon } from '@assets/icons/V4/mail-icon.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/V4/phone-icon.svg';
import MyFilesIcon from '@assets/icons/folder.svg';
import KnowledgeBasePrimary from '@assets/icons/knowledge-base-icon-primary.svg';
import KnowledgeBaseWhite from '@assets/icons/knowledge-base-icon-white.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import UELogoV3 from '@assets/icons/ue-logo-v3.svg';
import UELogo from '@assets/icons/ue-logo.svg';
import {
  ACCOUNT,
  COURSES,
  MY_ENROLLMENTS,
  KNOWLEDGE_BASE_ASSISTANT,
  SESSIONS,
  MENTOR_ACTIVITY_REPORT,
} from '@routes';
import {
  DISCORD_MENTOR_CHANNEL,
  DISCORD_STUDENT_CHANNEL,
  SUPPORT_UPGRADE_EDUCATION_EMAIL,
} from '@shared/constants';
import { Alert, message, Tooltip } from 'antd';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './header-style.scss';
import IconButton from '@components/V4/IconButton';
import { useAuthActions, useAuthState } from '@contexts/AuthContext';
import { UserRole } from '@shared/enums';

type Props = {
  children: any;
};

interface ContactPopupProps {
  variant: 'mobile' | 'desktop';
  isContactMenuOpen?: boolean;
  isMentor?: boolean;
  getInTouchClickHandler: () => void;
}

const ContactButton = (props: ContactPopupProps) => {
  const { variant, getInTouchClickHandler, isContactMenuOpen } = props;

  return (
    <div
      onClick={getInTouchClickHandler}
      className={mergeClassNames(
        'group flex items-center justify-center  w-8 h-8 cursor-pointer hover:rounded-xsm hover:bg-customLila transition-all duration-100 ease-in-out',
        {
          'rounded-xsm bg-customLila': isContactMenuOpen,
          'rounded-1/2 bg-white': !isContactMenuOpen,
          'mb-0': variant === 'mobile',
          'mb-6': variant === 'desktop',
        }
      )}
    >
      <p
        className={mergeClassNames(
          'font-raleway font-bold leading-5 text-customLila text-xl group-hover:text-white',
          {
            'text-white': isContactMenuOpen,
          }
        )}
      >
        ?
      </p>
    </div>
  );
};

const ContactPopup = (props: ContactPopupProps) => {
  const { variant, getInTouchClickHandler, isMentor } = props;

  const contactPoints = [
    {
      icon: <PhoneIcon className="text-inherit" />,
      text: '+40316305013',
      href: 'tel:+40316305013',
    },
    {
      icon: <MailIcon className="text-inherit" />,
      text: SUPPORT_UPGRADE_EDUCATION_EMAIL,
      href: `mailto:${SUPPORT_UPGRADE_EDUCATION_EMAIL}`,
    },
    {
      icon: <DiscordIcon className="text-inherit" />,
      text: 'Chat with us on Discord',
      href: isMentor ? DISCORD_MENTOR_CHANNEL : DISCORD_STUDENT_CHANNEL,
    },
  ];
  return (
    <div
      className={mergeClassNames(
        'absolute flex-col gap-6 pt-8 pb-12 px-12 rounded-lg bg-contactPopup z-9999 shadow-contactPopup',
        {
          'flex laptop:hidden top-[calc(100%+4px)] left-5 w-auto': variant === 'mobile',
          'hidden laptop:flex top-[calc(100%-24px)] left-[calc(100%+4px)] -translate-y-full w-max max-w-[421px]':
            variant === 'desktop',
        }
      )}
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <h2 className="font font-raleway font-semibold leading-8 text-2xl">Get in Touch</h2>
          <IconButton onClick={getInTouchClickHandler} icon={<CloseIcon />} />
        </div>
        <p className="text-customGrey text-sm">How can we help you? We'd love to hear from you</p>
      </div>

      <div className="flex flex-col gap-4">
        {contactPoints.map((contact) => (
          <a
            key={contact.href}
            className="flex items-center gap-4 text-white hover:text-customLila"
            href={contact.href}
            target="_blank"
            rel="noreferrer"
          >
            {contact.icon}
            <span className="font-raleway font-bold ">{contact.text}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

const HeaderV3 = ({ children }: Props) => {
  const { userId, roles } = useAuthState();
  const { logout } = useAuthActions();
  const { googleDrive } = useAuthState();
  const userRole = localStorage.getItem('userRole');
  const location = useLocation();

  const isDeactivated = !userId;

  const [knowledgebaseHover, setKnowledgeBaseHover] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isContactMenuOpen, setIsContactMenuOpen] = useState(false);

  let isMentor: any = undefined;

  if (userRole === 'student') {
    isMentor = false;
  } else if (userRole === 'mentor') {
    isMentor = true;
  } else {
    isMentor = roles.includes(UserRole.Mentor);
  }

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const getInTouchClickHandler = () => {
    setIsContactMenuOpen((prev) => !prev);
  };

  const isImpersonating = () => !!localStorage.getItem('impersonate-user-id');

  const stopImpersonating = () => {
    localStorage.removeItem('impersonate-user-id');
    localStorage.removeItem('userRole');
    localStorage.setItem('menuType', '2');

    window.location.href = '/';
  };

  const closeMenuHandler = () => setMenuOpen(false);

  const params = new URLSearchParams(location.search);
  const inMeeting = params.get('inMeeting');

  const linkTarget = inMeeting === 'true' ? '_blank' : '_self';

  return (
    <div className="header-banner-container h-full">
      <div className="header-container">
        <div className="relative burger-left-container">
          <ContactButton
            variant="mobile"
            getInTouchClickHandler={getInTouchClickHandler}
            isContactMenuOpen={isContactMenuOpen}
          />
        </div>

        {isContactMenuOpen && (
          <ContactPopup
            variant="mobile"
            getInTouchClickHandler={getInTouchClickHandler}
            isMentor={isMentor}
          />
        )}

        <Link to={'/home'} target={linkTarget}>
          <div
            onClick={() => {
              isMenuOpen && closeMenuHandler();
            }}
            className="image-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              className="default-image"
              style={{
                width: '32px',
                marginTop: '32px',
                marginBottom: '56px',
                marginLeft: '4px',
              }}
              src={UELogo}
              alt="logo-upgrade"
            />
            <img
              className="hover-image"
              style={{
                width: '32px',
                marginTop: '32px',
                marginBottom: '56px',
                marginLeft: '4px',
              }}
              src={UELogoV3}
              alt="logo-upgrade"
            />
          </div>
        </Link>

        <div className="desktop-links-container">
          {/*HEADER DESKTOP LINKS HERE*/}

          <div className="flex flex-col items-center">
            {isMentor && (
              <Tooltip
                zIndex={1}
                color="transparent"
                placement="right"
                title={
                  <div style={{ padding: '24px' }} className="tooltip-container">
                    <div className="tooltip-title">My Enrollments</div>
                    <div className="tooltip-description">Manage your enrollments</div>
                  </div>
                }
              >
                <Link to={MY_ENROLLMENTS} target={linkTarget}>
                  <div className="circle-icon">
                    <TeamOutlined className="menu-icon" />
                  </div>
                </Link>
              </Tooltip>
            )}

            {!isDeactivated && (
              <>
                <Tooltip
                  zIndex={1}
                  color="transparent"
                  placement="right"
                  title={
                    <div style={{ padding: '24px' }} className="tooltip-container">
                      <div className="tooltip-title">Courses</div>
                      <div className="tooltip-description">Explore your courses collection</div>
                    </div>
                  }
                >
                  <Link to={COURSES}>
                    <div className="circle-icon">
                      <BookOutlined className="menu-icon" />
                    </div>
                  </Link>
                </Tooltip>

                <Tooltip
                  zIndex={1}
                  color="transparent"
                  placement="right"
                  title={
                    <div style={{ padding: '24px' }} className="tooltip-container">
                      <div className="tooltip-title">My Files</div>
                      <div className="tooltip-description">Access and manage your files</div>
                    </div>
                  }
                >
                  <Link
                    to={{
                      pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
                    }}
                    target="_blank"
                  >
                    <div className="circle-icon">
                      <img style={{ width: '16px' }} src={MyFilesIcon} />
                    </div>
                  </Link>
                </Tooltip>
              </>
            )}

            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">Sessions</div>
                  <div className="tooltip-description">
                    Track your Sessions and manage their related Meetings
                  </div>
                </div>
              }
            >
              <Link onClick={closeMenuHandler} to={SESSIONS} target={linkTarget}>
                <div className="circle-icon">
                  <ClockCircleOutlined className="menu-icon" />
                </div>{' '}
              </Link>
            </Tooltip>

            {isMentor && (
              <>
                <Tooltip
                  zIndex={1}
                  color="transparent"
                  placement="right"
                  title={
                    <div style={{ padding: '24px' }} className="tooltip-container">
                      <div className="tooltip-title">Activity Report</div>
                      <div className="tooltip-description">See your activity report.</div>
                    </div>
                  }
                >
                  <Link to={MENTOR_ACTIVITY_REPORT} target={linkTarget}>
                    <div className="circle-icon">
                      <p className="menu-icon text-center font-bold text-xl">$</p>
                    </div>
                  </Link>
                </Tooltip>

                <Tooltip
                  zIndex={1}
                  color="transparent"
                  placement="right"
                  title={
                    <div style={{ padding: '24px' }} className="tooltip-container">
                      <div className="tooltip-title">Knowledge Base Assistant</div>
                      <div className="tooltip-description">
                        Use AI to explore our existing knowledge base
                      </div>
                    </div>
                  }
                >
                  <Link
                    onMouseEnter={() => setKnowledgeBaseHover(true)}
                    onMouseLeave={() => setKnowledgeBaseHover(false)}
                    to={KNOWLEDGE_BASE_ASSISTANT}
                    target={linkTarget}
                  >
                    <div className="circle-icon">
                      {/*<TeamOutlined className="menu-icon" />*/}
                      {knowledgebaseHover ? (
                        <img src={KnowledgeBasePrimary} alt="knowledge-base" />
                      ) : (
                        <img src={KnowledgeBaseWhite} alt="knowledge-base" />
                      )}
                    </div>
                  </Link>
                </Tooltip>
              </>
            )}

            {!isDeactivated && (
              <>
                <div className="icon-divider"></div>

                <Tooltip
                  zIndex={1}
                  color="transparent"
                  placement="right"
                  title={
                    <div style={{ padding: '24px' }} className="tooltip-container">
                      <div className="tooltip-title">My Account</div>
                      <div className="tooltip-description">Manage your profile</div>
                    </div>
                  }
                >
                  <Link to={ACCOUNT}>
                    <div className="circle-icon">
                      <img style={{ width: '16px' }} src={MyAccountIcon} />
                    </div>
                  </Link>
                </Tooltip>
              </>
            )}

            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">Logout</div>
                  <div className="tooltip-description">Logout securely from your account</div>
                </div>
              }
            >
              <div onClick={logout} className="circle-icon">
                <img style={{ width: '16px' }} src={LogoutIcon} />
              </div>
            </Tooltip>
          </div>

          <ContactButton
            variant="desktop"
            getInTouchClickHandler={getInTouchClickHandler}
            isContactMenuOpen={isContactMenuOpen}
          />

          {isContactMenuOpen && (
            <ContactPopup
              variant="desktop"
              isMentor={isMentor}
              getInTouchClickHandler={getInTouchClickHandler}
            />
          )}
        </div>

        <div className="burger-right-container" onClick={handleMenuClick}>
          <div className={`burger-menu ${isMenuOpen ? 'open' : ''}`}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full">
        {isImpersonating() && (
          <Alert
            message="You are currently impersonating another user's account. Click to stop."
            type="info"
            onClick={stopImpersonating}
            showIcon
            style={{ cursor: 'pointer', textAlign: 'center' }}
          />
        )}
        <div
          style={{
            backgroundColor: '#121212',
            height: '100vh',
            paddingLeft: '48px',
            paddingTop: '72px',
          }}
          className={mergeClassNames('fixed inset-0 z-9999 hidden laptop:hidden', {
            'flex flex-col': isMenuOpen,
          })}
        >
          {/*HEADER MOBILE LINKS HERE*/}
          {isMentor && (
            <Link
              onClick={closeMenuHandler}
              className="mobile-link-item"
              to={MY_ENROLLMENTS}
              target={linkTarget}
            >
              My Enrollments
            </Link>
          )}

          <Link
            onClick={closeMenuHandler}
            className="mobile-link-item"
            to={COURSES}
            target={linkTarget}
          >
            Courses
          </Link>

          <Link
            onClick={closeMenuHandler}
            to={{
              pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
            }}
            target="_blank"
            className="mobile-link-item"
          >
            My Files
          </Link>

          <Link
            onClick={closeMenuHandler}
            className="mobile-link-item"
            to={SESSIONS}
            target={linkTarget}
          >
            Session History
          </Link>

          {isMentor && (
            <>
              <Link
                onClick={closeMenuHandler}
                className="mobile-link-item"
                to={MENTOR_ACTIVITY_REPORT}
                target={linkTarget}
              >
                Activity Report
              </Link>

              <Link
                onClick={closeMenuHandler}
                className="mobile-link-item"
                to={KNOWLEDGE_BASE_ASSISTANT}
                target={linkTarget}
              >
                Knowledge Base Assistant
              </Link>
            </>
          )}

          <div className="h-0.75 w-[200px] bg-white my-4" />
          <Link onClick={closeMenuHandler} to={ACCOUNT} className="mobile-link-item">
            My Account
          </Link>

          <Link
            to=""
            onClick={() => {
              logout();
              closeMenuHandler();
            }}
            className="mobile-link-item"
          >
            Logout
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};

export default HeaderV3;
