import { knowledgeBaseApis } from '@shared/apis';

import {
  genericMutation,
  genericMutationWithId,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useKnowledgeBaseArticleData =
  genericQueryWithId<knowledgeBaseApis.GetKnowledgeBaseArticle>(
    knowledgeBaseApis.getKnowledgeBaseArticle
  );

export const useUpdateKnowledgeBaseArticleData =
  genericMutationWithId<knowledgeBaseApis.UpdateKnowledgeBaseArticle>(
    knowledgeBaseApis.updateKnowledgeBaseArticle,
    knowledgeBaseApis.getKnowledgeBaseArticle.endpoint
  );

export const useSearchKnowledgeBaseArticles =
  genericQueryWithParams<knowledgeBaseApis.SearchKnowledgeBaseArticles>(
    knowledgeBaseApis.searchKnowledgeBaseArticles
  );

export const useCreateKnowledgeBaseArticle =
  genericMutation<knowledgeBaseApis.CreateKnowledgeBaseArticle>(
    knowledgeBaseApis.createKnowledgeBaseArticle
  );
