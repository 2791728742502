import apiClient from '@contexts/AuthContext/apiClient';
import { RequestsMethod } from '@shared/clientTypes';

function useAuthAxios() {
  const actionCreator = <Payload, Response>(
    endpoint: string,
    method: RequestsMethod = RequestsMethod.Post
  ) => {
    return async (data: Payload) => {
      const response = await apiClient({
        url: endpoint,
        method,
        data,
      });

      return response.data as Response;
    };
  };

  return [actionCreator];
}

export default useAuthAxios;
