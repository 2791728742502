import Modal from '@components/V4/Modal/Modal';
import { University } from '@shared/clientTypes/DTOs/UniversityDTO';
import { BasicProps } from 'apps/agora/src/utils/types';
import UniversityDetailsSection from './UniversityDetailsSection/UniversityDetailsSection';
import UniversityTabsSection from './UniversityTabsSection/UniversityTabsSection';
import { UniversityModalContext } from '@modules/Admin/contexts/UniversityModalContext';
import { useGetUniversity } from '@actions';

interface UniversityModalProps extends BasicProps {
  universityId: string;
  onClose: () => void;
}

const UniversityModal = (props: UniversityModalProps) => {
  const { universityId, onClose } = props;

  const { data: university } = useGetUniversity(universityId);

  return (
    <Modal isOpen size="large" onClose={onClose}>
      <Modal.Header className="relative font-raleway text-xl font-bold text-center mb-6">
        <h3>University</h3>
        <h3>{university?.name}</h3>
      </Modal.Header>
      <Modal.Body>
        <UniversityModalContext.Provider value={{ university }}>
          <UniversityDetailsSection />
          <UniversityTabsSection />
        </UniversityModalContext.Provider>
      </Modal.Body>
    </Modal>
  );
};

export default UniversityModal;
