import { useSaveUserProfile } from '@actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { degreeTypes } from '@modules/Account/Profile/components/AccountProfile/constants';
import SessionModalFormWrapper from '@modules/Admin/components/DetailsModal/DetailsModalFormWrapper';
import { EducationalBackground, SelectOption } from '@shared/common';
import { useGetAllMajorNames } from 'apps/agora/src/actions/major.actions';
import useToast from 'apps/agora/src/hooks/useToast';
import useUniversitySearch from 'apps/agora/src/hooks/useUniversitySearch';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';

interface UniversitySectionFormProps extends BasicProps {
  userId: string;
  isModeAddNew?: boolean;
  universityData?: EducationalBackground;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid school name.' }),
  degreeName: z.string().min(1, { message: 'Please enter a valid degree type.' }),
  major: z.string().min(1, { message: 'Please select a valid major.' }),
  startYear: z.number().min(1, { message: 'Please enter a valid start year.' }),
  endYear: z.number().min(1, { message: 'Please enter a valid graduation year.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const UniversitySectionForm = (props: UniversitySectionFormProps) => {
  const { userId, className, isModeAddNew = false, universityData, onCloseEditForm } = props;

  const {
    searchQuery,
    setSearchQuery,
    universities,
    isLoading: isUniversitiesLoading,
  } = useUniversitySearch(
    !isModeAddNew && universityData?.university?._id ? universityData?.university?.name : '',
    isModeAddNew || !!universityData?.university?._id
  );

  const toast = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');
      onCloseEditForm();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: universityData?.university?._id
        ? undefined
        : universityData?.educationalInstitution.name,
      degreeName: universityData?.degreeType,
      major: universityData?.major?._id,
      startYear: universityData?.startYear,
      endYear: universityData?.endYear,
    },
  });

  const { field: nameField, fieldState: nameFieldState } = useController({
    name: 'name',
    control,
  });

  const { field: startYearField, fieldState: startYearFieldState } = useController({
    name: 'startYear',
    control,
  });

  const { field: endYearField, fieldState: endYearFieldState } = useController({
    name: 'endYear',
    control,
  });

  const { field: degreeField, fieldState: degreeFieldState } = useController({
    name: 'degreeName',
    control,
  });

  const { field: majorField, fieldState: majorFieldState } = useController({
    name: 'major',
    control,
  });

  const shouldAutoSelectUniversityRef = useRef(true);

  useEffect(() => {
    if (
      !!universityData?.university?._id &&
      !nameField.value &&
      shouldAutoSelectUniversityRef.current
    ) {
      nameField.onChange(universityData?.university?._id);
      setSearchQuery('');
      shouldAutoSelectUniversityRef.current = false;
    }
  }, [universityData?.university?._id, universities, nameField.value]);

  const { data: majorsList } = useGetAllMajorNames();

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const newUniversityData: any = {
        educationalInstitution: {
          name: data.name,
          _id: data.name,
          type: 'University',
        },
        major: data.major,
        degreeType: data.degreeName,
        startYear: data.startYear,
        endYear: data.endYear,
        _id: universityData?._id,
      };

      updateUserProfile({
        educationalBackground: [newUniversityData],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const getUniversityOptions = (): SelectOption[] => {
    return (
      universities?.map((university) => ({
        label: university.name,
        value: university._id,
      })) || []
    );
  };

  const universityBlurHandler = () => {
    setSearchQuery('');
    nameField.onBlur();
  };

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add University' : 'Edit University'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText={isModeAddNew ? 'Create University' : 'Save University'}
    >
      {isModeAddNew || !!universityData?.university?._id ? (
        <Select
          size="large"
          label="University"
          value={nameField.value}
          filterOption={() => true}
          searchValue={searchQuery}
          onSearch={setSearchQuery}
          onSelect={(value) => nameField.onChange(value)}
          onBlur={universityBlurHandler}
          options={getUniversityOptions()}
          notFoundContent={
            isUniversitiesLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
          }
          placeholder="Search University"
          isRequired
          isValid={!nameFieldState.error}
          isTouched={nameFieldState.isTouched}
          errorText={nameFieldState.error?.message}
        />
      ) : (
        <Input
          isRequired
          type="text"
          id="university"
          label="University"
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          autoFocus
          {...register('name')}
        />
      )}

      <Select
        isRequired
        value={degreeField.value}
        onSelect={(value) => degreeField.onChange(value)}
        options={degreeTypes?.map((degree) => ({
          label: degree,
          value: degree,
        }))}
        onBlur={degreeField.onBlur}
        size="large"
        label="Degree"
        isValid={!degreeFieldState.error}
        isTouched={degreeFieldState.isTouched}
        errorText={degreeFieldState.error?.message}
      />
      <Select
        value={majorField.value}
        onSelect={(value) => majorField.onChange(value)}
        onBlur={majorField.onBlur}
        allowClear={false}
        options={majorsList?.map((major) => ({
          label: major.name,
          value: major._id ?? '',
        }))}
        label="Majors"
        size="large"
        isRequired
        isValid={!majorFieldState.error}
        isTouched={majorFieldState.isTouched}
        errorText={majorFieldState.error?.message}
      />
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={startYearField.value ? moment(startYearField.value, 'YYYY') : undefined}
          onChange={(value) => startYearField.onChange(moment(value).year())}
          disabledDate={(date) =>
            endYearField.value ? moment(date).year() > endYearField.value : false
          }
          onBlur={startYearField.onBlur}
          allowClear={false}
          isRequired
          label="Start Year"
          picker="year"
          isValid={!startYearFieldState.error}
          isTouched={startYearFieldState.isTouched}
          errorText={startYearFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endYearField.value ? moment(endYearField.value, 'YYYY') : undefined}
          onChange={(value) => endYearField.onChange(moment(value).year())}
          onBlur={endYearField.onBlur}
          disabledDate={(date) =>
            startYearField.value ? moment(date).year() < startYearField.value : false
          }
          allowClear={false}
          isRequired
          picker="year"
          label="Graduation Year"
          isValid={!endYearFieldState.error}
          isTouched={endYearFieldState.isTouched}
          errorText={endYearFieldState.error?.message}
        />
      </div>
    </SessionModalFormWrapper>
  );
};

export default UniversitySectionForm;
