import { UniversityAdmissionRound } from '@shared/serverTypes';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import InfoBar from './InfoBar';
import Table from './Table/Table';

interface AdmissionRoundsInfoBarProps extends BasicProps {
  admissionRounds?: UniversityAdmissionRound[];
  admissionRoundsYear?: number;
}

const AdmissionRoundsInfoBar = (props: AdmissionRoundsInfoBarProps) => {
  const { admissionRounds, admissionRoundsYear } = props;

  const admissionRoundsLabel = (() => {
    if (!admissionRoundsYear) {
      return undefined;
    }

    if (admissionRoundsYear < moment().year()) {
      return `Admission Rounds Since ${admissionRoundsYear}`;
    }

    return `Admission Rounds for ${admissionRoundsYear}`;
  })();

  return !!admissionRounds?.length ? (
    <InfoBar className="my-2" color="light" variant="info" messageTitle={admissionRoundsLabel}>
      <Table className="w-full" size="small">
        <Table.Header>
          <Table.Row isHeader>
            <Table.HeaderCell>Admission Round</Table.HeaderCell>
            <Table.HeaderCell>Deadline</Table.HeaderCell>
            <Table.HeaderCell>Results Timeline</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {admissionRounds.map((admissionRound) => (
            <Table.Row key={admissionRound._id} isHoverable={false}>
              <Table.Cell>{admissionRound.name}</Table.Cell>
              <Table.Cell>
                {admissionRound.deadline
                  ? moment(admissionRound.deadline).format('DD MM YYYY')
                  : 'n/a'}
              </Table.Cell>
              <Table.Cell>{`${admissionRound.resultsTimeline.period}-${admissionRound.resultsTimeline.month}`}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </InfoBar>
  ) : (
    <InfoBar
      className="my-2"
      color="light"
      variant="info"
      messageTitle={'No admission rounds to show.'}
    />
  );
};

export default AdmissionRoundsInfoBar;
