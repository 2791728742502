import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import Tooltip from '@components/V4/Tooltip';
import ModalSection, { ActionButton } from '@modules/Admin/components/ModalSection';
import { useUniversityModalContext } from '@modules/Admin/contexts/UniversityModalContext';
import { UniversityRanking } from '@shared/serverTypes';
import useToast from 'apps/agora/src/hooks/useToast';
import { getValueOrNA } from 'apps/agora/src/utils/helpers';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useMemo, useState } from 'react';

const RankingsTab = () => {
  const { university } = useUniversityModalContext();

  const [isAddingRanking, setIsAddingRanking] = useState(false);
  const [yearToEdit, setYearToEdit] = useState<any>();

  const rankingsButtons: ActionButton[] = [];

  if (university?.isAddedByUser) {
    // rankingsButtons.push({
    //   buttonText: 'Add Ranking',
    //   onClick: () => setIsAddingRanking(true),
    // });
  }

  const toast = useToast();

  const deleteRankingClickHandler = (year: number) => {};

  const rankings: Record<number, Record<string, UniversityRanking>> = useMemo(() => {
    const rankingsPerYear: Record<number, Record<string, UniversityRanking>> = {};
    university?.rankings.forEach((rankingData) => {
      const rankings = {
        overallRanking: rankingData.overallRanking,
        academicReputation: rankingData.academicReputation,
        employerReputation: rankingData.employerReputation,
        facultyStudent: rankingData.facultyStudent,
        citationsPerFaculty: rankingData.citationsPerFaculty,
        internationalFaculty: rankingData.internationalFaculty,
        internationalStudents: rankingData.internationalStudents,
        internationalResearchNetwork: rankingData.internationalResearchNetwork,
        employmentOutcomes: rankingData.employmentOutcomes,
        sustainability: rankingData.sustainability,
      };

      const doesYearHaveRankings = Object.values(rankings).some((value) => !!value?.rank);

      if (doesYearHaveRankings) {
        rankingsPerYear[rankingData.year] = rankings;
      }
    });

    return rankingsPerYear;
  }, [university]);

  const rankingsYears = Object.keys(rankings).sort().reverse();

  const getRankingsTableLabel = (year: string, index: number) => {
    const currentYear = moment().year();
    const nextYear = moment(year).add(1, 'year').year();

    if (index === 0) {
      if (Number(year) < currentYear) {
        return `${year} - Present`;
      }

      return year;
    }

    if (Number(rankingsYears[index - 1]) !== nextYear) {
      return `${year} - ${Number(rankingsYears[index - 1]) - 1} `;
    }

    return year;
  };

  return (
    <ModalSection className="mt-6" sectionTitle="Rankings" buttonsArray={rankingsButtons}>
      <div className="flex flex-col gap-4">
        {rankingsYears.length ? (
          rankingsYears.map((year, index) => (
            <div className="flex flex-col gap-2" key={year}>
              <h4 className="text-white font-bold">{getRankingsTableLabel(year, index)}</h4>
              <Table textAlignment="center" size="small">
                <Table.Header>
                  <Table.Row isHeader>
                    <Table.HeaderCell>Overall</Table.HeaderCell>
                    <Tooltip text="Academic Reputation">
                      <Table.HeaderCell>AR</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="Employer Reputation">
                      <Table.HeaderCell>ER</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="Faculty Student">
                      <Table.HeaderCell>FS</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="Citations per Faculty">
                      <Table.HeaderCell>CPF</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="International Faculty">
                      <Table.HeaderCell>IF</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="International Students">
                      <Table.HeaderCell>IS</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="International Research Network">
                      <Table.HeaderCell>IRN</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="Employment Outcomes">
                      <Table.HeaderCell>EO</Table.HeaderCell>
                    </Tooltip>
                    <Tooltip text="Sustainability">
                      <Table.HeaderCell>SUS</Table.HeaderCell>
                    </Tooltip>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row isHoverable={false}>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].overallRanking?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].academicReputation?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].employerReputation?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].facultyStudent?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].citationsPerFaculty?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].internationalFaculty?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].internationalStudents?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].internationalResearchNetwork?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].employmentOutcomes?.rank)}
                    </Table.Cell>
                    <Table.Cell>
                      {getValueOrNA(rankings[Number(year)].sustainability?.rank)}
                    </Table.Cell>
                    {!!university?.isAddedByUser && (
                      <Table.Cell>
                        <div className="flex gap-4">
                          <IconButton
                            size="small"
                            variant="ghost"
                            icon={<EditIcon />}
                            onClick={() => setYearToEdit(year)}
                          />
                          <IconButton
                            isDisabled={false}
                            size="small"
                            variant="ghost"
                            color="danger"
                            icon={<TrashIcon />}
                            onClick={() => deleteRankingClickHandler(Number(year))}
                          />
                        </div>
                      </Table.Cell>
                    )}
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          ))
        ) : (
          <EmptyStateCard
            text="No Existing Data"
            icon={<NoDataIcon className="text-customGrey" />}
          />
        )}
      </div>
    </ModalSection>
  );
};

export default RankingsTab;
