import { examApis } from '@shared/apis';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithIdParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useSearchExams = genericQueryWithParams<examApis.SearchExams>(examApis.searchExams);

export const useCreateExam = genericMutation<examApis.CreateExam>(
  examApis.createExam,
  examApis.searchExams.endpoint,
  examApis.getExam.endpoint
);

export const useDeleteExam = genericMutationWithIdParams<examApis.DeleteExam>(
  examApis.deleteExam,
  examApis.searchExams.endpoint,
  examApis.getExam.endpoint
);

export const useRestoreExam = genericMutationWithIdParams<examApis.RestoreExam>(
  examApis.restoreExam,
  examApis.searchExams.endpoint,
  examApis.getExam.endpoint
);

export const useGetExam = genericQueryWithId<examApis.GetExam>(examApis.getExam);

export const useGetExams = genericQuery<examApis.GetExams>(examApis.getExams);

export const useGetAllExamNames = genericQuery<examApis.GetAllExamNames>(examApis.getAllExamNames);

export const useUpdateExam = genericMutationWithId<examApis.UpdateExam>(
  examApis.updateExam,
  examApis.searchExams.endpoint,
  examApis.getExam.endpoint
);
