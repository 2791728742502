import { ApiDescription, ApiData, RequestsMethod, User } from '@shared/clientTypes';

export const mentorPerformance: ApiDescription = {
  endpoint: '/cs/mentor-performance',
  method: RequestsMethod.Get,
};

export type MentorPerformance = ApiData<
  { dateStart: string; dateEnd: string; courseId: string },
  {
    feedbackAverage: string;
    feedbackLastPeriodAverage: string;
    trend: string;
    ratings: Array<number>;
  }
>;

export const mentorPerformanceTrends: ApiDescription = {
  endpoint: '/cs/mentor-performance-trends',
  method: RequestsMethod.Get,
};

export type MentorPerformanceTrends = ApiData<
  { dateStart: string; dateEnd: string; courseId: string },
  {
    lastSixWeeks: string[];
    lastSixMonths: string[];
  }
>;

export const studentsOverview: ApiDescription = {
  endpoint: '/cs/students-overview',
  method: RequestsMethod.Get,
};

export type StudentsOverview = ApiData<
  unknown,
  {
    activeClients: string[];
    enrolledWithoutMentor: string[];
    users: { [id: string]: User };
  }
>;
