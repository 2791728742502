import { UserProfileDTO } from '@shared/clientTypes/DTOs/UserDTO';

export const getUserPills = (user?: Partial<UserProfileDTO>) => {
  const pills = [];

  if (!!user?.isDezactivated) {
    pills.push({
      text: 'Inactive',
      className: 'bg-customRed text-white',
    });
  }

  if (!!user?.isTestUser) {
    pills.push({
      text: 'Test User',
      className: 'bg-customGold text-white',
    });
  }

  return pills;
};
