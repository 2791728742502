import moment from 'moment';
import PastExamsSection from './PastExamsSection/PastExamsSection';
import UpcomingExamsSection from './UpcomingExamsSection/UpcomingExamsSection';
import { UserExamWithName } from '@shared/clientTypes/DTOs/UserDTO';

interface ExamsTabProps {
  exams: UserExamWithName[];
  userId: string;
}

const ExamsTab = (props: ExamsTabProps) => {
  const { exams, userId } = props;

  const today = moment().startOf('day');

  const pastExams = exams.filter((exam) => moment(exam.date).isBefore(today, 'day'));

  const upcomingExams = exams.filter((exam) => moment(exam.date).isSameOrAfter(today, 'day'));

  return (
    <section className="flex flex-col gap-4">
      <UpcomingExamsSection userId={userId} exams={upcomingExams} />

      <PastExamsSection userId={userId} exams={pastExams} />
    </section>
  );
};

export default ExamsTab;
