import { ModalProps } from '@components/V4/Modal/Modal';
import { useState } from 'react';
import MultiPageModal, {
  NavigationProps,
} from '../../../../components/V4/Modal/MultiPageModal/MultiPageModal';
import EnrollmentDetailsPage from './EnrollmentDetailsPage/EnrollmentDetailsPage';
import MentorDetailsPage from './MentorDetailsPage/MentorDetailsPage';
import SessionDetailsPage from './SessionDetailsPage/SessionDetailsPage';
import { StudentTab } from './SessionDetailsPage/SessionTabsSection/SessionTabsSection';
import StudentDetailsPage from './StudentDetailsPage/StudentDetailsPage';

interface DetailsModalProps extends ModalProps {
  mentorId?: string;
  studentId?: string;
  enrollmentId?: string;
  sessionId?: string;
  page?: DetailsModalPage;
  tab?: StudentTab;
}

export type DetailsModalPage = 'mentor' | 'student' | 'enrollment' | 'session' | undefined;

const DetailsModal = (props: DetailsModalProps) => {
  const {
    mentorId: receivedMentorId,
    studentId: receivedStudentId,
    page,
    enrollmentId: receivedEnrollmentId,
    sessionId: receivedSessionId,
    isOpen,
    onClose,
    tab,
  } = props;

  const [enrollmentId, setEnrollmentId] = useState<string | undefined>(receivedEnrollmentId);
  const [sessionId, setSessionId] = useState<string | undefined>(receivedSessionId);
  const [studentId, setStudentId] = useState<string | undefined>(receivedStudentId);
  const [mentorId, setMentorId] = useState<string | undefined>(receivedMentorId);

  const enrollmentModalOpenHandler = (id: string, goToPage: (page: string) => void) => {
    setEnrollmentId(id);
    goToPage('enrollment');
  };

  const sessionModalOpenHandler = (sessionId: string, goToPage: (page: string) => void) => {
    setSessionId(sessionId);
    goToPage('session');
  };

  const studentModalOpenHandler = (studentId: string, goToPage: (page: string) => void) => {
    setStudentId(studentId);
    goToPage('student');
  };

  const mentorModalOpenHandler = (mentorId: string, goToPage: (page: string) => void) => {
    setMentorId(mentorId);
    goToPage('mentor');
  };

  const pages = {
    mentor: ({ goToPage, goBack, isBackButtonActive }: NavigationProps) => (
      <MentorDetailsPage
        mentorId={mentorId ?? ''}
        isBackButtonActive={isBackButtonActive}
        onGoBack={goBack}
        onEnrollmentModalOpen={(id: string) => enrollmentModalOpenHandler(id, goToPage)}
        onSessionModalOpen={(id: string) => sessionModalOpenHandler(id, goToPage)}
        onStudentModalOpen={(studentId: string) => studentModalOpenHandler(studentId, goToPage)}
      />
    ),
    student: ({ goToPage, goBack, isBackButtonActive }: NavigationProps) => (
      <StudentDetailsPage
        studentId={studentId ?? ''}
        isBackButtonActive={isBackButtonActive}
        onGoBack={goBack}
        onEnrollmentModalOpen={(id: string) => enrollmentModalOpenHandler(id, goToPage)}
        onSessionModalOpen={(id: string) => sessionModalOpenHandler(id, goToPage)}
        onMentorModalOpen={(id: string) => mentorModalOpenHandler?.(id, goToPage)}
      />
    ),
    enrollment: ({ goToPage, goBack, isBackButtonActive }: NavigationProps) => (
      <EnrollmentDetailsPage
        isBackButtonActive={isBackButtonActive}
        enrollmentId={enrollmentId ?? ''}
        onGoBack={goBack}
        onSessionModalOpen={(sessionId: string) => sessionModalOpenHandler(sessionId, goToPage)}
      />
    ),
    session: ({ goBack, isBackButtonActive }: NavigationProps) => (
      <SessionDetailsPage
        isBackButtonActive={isBackButtonActive}
        sessionId={sessionId ?? ''}
        onGoBack={goBack}
        tab={tab}
      />
    ),
  };

  return (
    <MultiPageModal
      size="large"
      className="min-h-[616px]"
      isOpen={isOpen}
      onClose={onClose}
      initialPage={page || 'student'}
      pages={pages}
    />
  );
};

export default DetailsModal;
