import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { useSaveUserProfile } from '@actions';
import { UserProfileDTO } from '@shared/clientTypes/DTOs/UserDTO';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ProfileContext } from '../../../AccountProfile';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import ProfileMainSection from '../../ResumeSection';
import UniversitiesSectionCard from './UniversitiesSectionCard/UniversitiesSectionCard';
import UniversitiesSectionForm from './UniversitiesSectionForm';
import SkeletonBox from '@components/V4/SkeletonBox';

interface UniversitiesSectionProps {
  userId: string;
  userProfile?: Partial<UserProfileDTO>;
}

const UniversitiesSection = (props: UniversitiesSectionProps) => {
  const { userId, userProfile } = props;

  const { isMentorViewer, isLoading } = useContext(ProfileContext);

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'appliedUniversities',
  });

  const toast = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const { mutate: updateUserProfile, isLoading: isUpdatingUserProfile } = useSaveUserProfile(
    userId,
    {
      onSuccess: () => {
        trackEvent(
          isMentorViewer
            ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
            : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
        );

        query.invalidateQueries('/users/:id/profile');
      },
      onError: () => {
        toast.error('Goals order could not be updated.');
      },
    }
  );

  const onModifyGoalOrder = (newOrder?: number, _id?: string) => {
    if (!newOrder || !_id) return;

    updateUserProfile({
      appliedUniversities: [{ newOrder, _id }],
    });
  };

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="University List"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isLoading ? (
        <SkeletonBox className="w-full h-[78px]" />
      ) : (
        <>
          {isAddNewItem && (
            <UniversitiesSectionForm
              isModeAddNew
              userId={userId}
              onCancel={addNewItemCancelHandler}
              setIsAddNewItem={setIsAddNewItem}
            />
          )}

          {userProfile?.appliedUniversities?.map((goal) => (
            <UniversitiesSectionCard
              key={goal._id}
              arrLength={userProfile.appliedUniversities?.length}
              universityGoal={goal}
              userId={userId}
              onDeleteEntry={deleteEntryHandler}
              onModifyGoalOrder={onModifyGoalOrder}
              isUpdatingUserProfile={isUpdatingUserProfile}
            />
          ))}

          {!isAddNewItem && !userProfile?.appliedUniversities?.length && !isMentorViewer && (
            <StepBanner
              stepOption={'missingUniversityGoal'}
              onClick={() => setIsAddNewItem((prev) => !prev)}
            />
          )}
        </>
      )}
    </ProfileMainSection>
  );
};

export default UniversitiesSection;
