import { useDeleteAdmissionRequirementFromUniversity } from '@actions';
import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import ModalSection, { ActionButton } from '@modules/Admin/components/ModalSection';
import { useUniversityModalContext } from '@modules/Admin/contexts/UniversityModalContext';
import { UniversityRequirement } from '@shared/clientTypes/misc';
import useToast from 'apps/agora/src/hooks/useToast';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useMemo, useState } from 'react';
import AdmissionRequirementsForm from './AdmissionRequirementsForm';

const AdmissionRequirementsTab = () => {
  const { university } = useUniversityModalContext();

  const [isAddingRequirement, setIsAddingRequirement] = useState(false);
  const [requirementToEdit, setRequirementToEdit] = useState<any>();

  const requirementButtons: ActionButton[] = [
    {
      buttonText: 'Add Requirement',
      onClick: () => setIsAddingRequirement(true),
    },
  ];

  const toast = useToast();

  const { mutate: deleteAdmissionRequirement, isLoading: isDeletingAdmissionRequirement } =
    useDeleteAdmissionRequirementFromUniversity({
      onSuccess: () => {
        toast.success('Successfully deleted admission requirement');
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error?.message ?? 'There was an error, please try again later.';

        toast.error(errorMessage);
      },
    });

  const deleteRequirementClickHandler = (requirementId: string) => {
    deleteAdmissionRequirement([university?._id || '', requirementId || ''], {});
  };

  const requirements: Record<number, UniversityRequirement[]> = useMemo(() => {
    const requirementsPerYear: Record<number, UniversityRequirement[]> = {};

    university?.admissionData.forEach((universityData) => {
      if (universityData.admissionRequirements.length) {
        requirementsPerYear[universityData.year] = universityData.admissionRequirements;
      }
    });

    return requirementsPerYear;
  }, [university]);

  const requirementsYears = Object.keys(requirements).sort().reverse();

  const getRequirementsTableLabel = (year: string, index: number) => {
    const currentYear = moment().year();
    const nextYear = moment(year).add(1, 'year').year();

    if (index === 0) {
      if (Number(year) < currentYear) {
        return `${year} - Present`;
      }

      return year;
    }

    if (Number(requirementsYears[index - 1]) !== nextYear) {
      return `${year} - ${Number(requirementsYears[index - 1]) - 1} `;
    }

    return year;
  };

  return isAddingRequirement ? (
    <AdmissionRequirementsForm
      universityId={university?._id || ''}
      isModeAddNew
      onCloseEditForm={() => setIsAddingRequirement(false)}
    />
  ) : (
    <ModalSection
      className="mt-6"
      sectionTitle="Admission Requirements"
      buttonsArray={requirementButtons}
    >
      <div className="flex flex-col gap-4">
        {requirementsYears.length ? (
          requirementsYears.map((year, index) => (
            <div key={year} className="flex flex-col gap-2">
              <h4 className="text-white font-bold">{getRequirementsTableLabel(year, index)}</h4>
              <Table size="small">
                <Table.Header>
                  <Table.Row isHeader>
                    <Table.HeaderCell className="w-1/3">Notation System Name</Table.HeaderCell>
                    <Table.HeaderCell className="w-1/3">Min. GPA</Table.HeaderCell>
                    <Table.HeaderCell className="w-1/3">Exam - Min. Score</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {requirements[Number(year)].map((item) => (
                    <Table.Row key={item._id}>
                      {requirementToEdit?._id === item._id ? (
                        <Table.Cell className="!p-0" colSpan={4}>
                          <AdmissionRequirementsForm
                            universityId={university?._id || ''}
                            className="rounded-none"
                            year={Number(year)}
                            requirementData={item}
                            onCloseEditForm={() => setRequirementToEdit(undefined)}
                          />
                        </Table.Cell>
                      ) : (
                        <>
                          <Table.Cell>{item.notationSystemName}</Table.Cell>
                          <Table.Cell>{item.minGPA}</Table.Cell>
                          <Table.Cell>{`${item.exam.name} - ${item.minExamScore}`} </Table.Cell>
                          <Table.Cell>
                            <div className="flex gap-4">
                              <IconButton
                                size="small"
                                variant="ghost"
                                icon={<EditIcon />}
                                onClick={() => setRequirementToEdit(item)}
                              />
                              <IconButton
                                isDisabled={isDeletingAdmissionRequirement}
                                size="small"
                                variant="ghost"
                                color="danger"
                                icon={<TrashIcon />}
                                onClick={() => deleteRequirementClickHandler(item?._id ?? '')}
                              />
                            </div>
                          </Table.Cell>
                        </>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ))
        ) : (
          <EmptyStateCard
            text="No Existing Data"
            icon={<NoDataIcon className="text-customGrey" />}
          />
        )}
      </div>
    </ModalSection>
  );
};

export default AdmissionRequirementsTab;
