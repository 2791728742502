import {
  useAddUniversityAdmissionRequirement,
  useGetAllExamNames,
  useGetExam,
  useUpdateAdmissionRequirementInUniversity,
} from '@actions';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import ExamInfoBar from '@components/V4/ExamInfoBar';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import ModalFormWrapper from '@modules/Admin/components/ModalFormWrapper';
import { UniversityRequirement } from '@shared/clientTypes/misc';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';

interface AdmissionRequirementsFormProps extends BasicProps {
  universityId: string;
  isModeAddNew?: boolean;
  year?: number;
  requirementData?: UniversityRequirement;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  year: z.number().min(1, { message: 'Please select a year.' }),
  notationSystemName: z.string().min(1, { message: 'Please enter a notation system.' }),
  minGPA: z.string().min(1, { message: 'Please enter a valid GPA.' }),
  exam: z.string({ message: 'Please select an exam' }),
  minExamScore: z
    .string()
    .min(1, { message: 'Please enter a minimum score for the selected exam.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const AdmissionRequirementsForm = (props: AdmissionRequirementsFormProps) => {
  const { universityId, year, requirementData, isModeAddNew, onCloseEditForm } = props;

  const toast = useToast();

  const { data: exams } = useGetAllExamNames();

  const { mutate: addAdmissionRequirement, isLoading: isAddingAdmissionRequirement } =
    useAddUniversityAdmissionRequirement(universityId, {
      onSuccess: () => {
        toast.success('Successfully added admission requirement');
        onCloseEditForm();
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error?.message ?? 'There was an error, please try again later.';

        toast.error(errorMessage);
      },
    });

  const { mutate: updateAdmissionRequirement, isLoading: isUpdatingAdmissionRequirement } =
    useUpdateAdmissionRequirementInUniversity({
      onSuccess: () => {
        toast.success('Successfully added admission requirement');
        onCloseEditForm();
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error?.message ?? 'There was an error, please try again later.';

        toast.error(errorMessage);
      },
    });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      year: year,
      notationSystemName: requirementData?.notationSystemName,
      minGPA: requirementData?.minGPA,
      exam: requirementData?.exam?._id,
      minExamScore: requirementData?.minExamScore,
    },
  });

  const { field: yearField, fieldState: yearFieldState } = useController({
    name: 'year',
    control,
  });

  const { field: examField, fieldState: examFieldState } = useController({
    name: 'exam',
    control,
  });

  const { data: selectedExam } = useGetExam(examField.value, {
    enabled: !!examField.value,
  });

  const touchAllFields = (
    fields: Partial<Record<keyof FormData, string | number | { _id: string; fullName: string }>>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();

    if (isValid && universityId) {
      if (isModeAddNew) {
        addAdmissionRequirement({
          year: data.year,
          notationSystemName: data.notationSystemName,
          minGPA: data.minGPA,
          exam: data.exam,
          minExamScore: data.minExamScore,
        });

        return;
      }

      if (requirementData?._id && year) {
        updateAdmissionRequirement([universityId, requirementData?._id || ''], {
          year: year,
          notationSystemName: data.notationSystemName,
          minGPA: data.minGPA,
          exam: data.exam,
          minExamScore: data.minExamScore,
        });
      }
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <ModalFormWrapper
      title={`${isModeAddNew ? 'Add' : 'Edit'} Admission Requirement`}
      submitButtonText={`${isModeAddNew ? 'Create' : 'Edit'} Admission Requirement`}
      isLoading={isAddingAdmissionRequirement || isUpdatingAdmissionRequirement}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
    >
      {isModeAddNew && (
        <AgoraDatePicker
          value={yearField.value ? moment(yearField.value.toString()) : undefined}
          onChange={(value) => yearField.onChange(moment(value).year())}
          onBlur={yearField.onBlur}
          allowClear={false}
          picker="year"
          isRequired
          label="Year"
          isValid={!yearFieldState.error}
          isTouched={yearFieldState.isTouched}
          errorText={yearFieldState.error?.message}
        />
      )}

      <div className="flex justify-between gap-4">
        <Input
          isRequired
          type="text"
          id="name"
          label="Notation System"
          isTouched={touchedFields.notationSystemName}
          isValid={!errors.notationSystemName}
          errorText={errors.notationSystemName?.message}
          {...register('notationSystemName', { required: true })}
        />
        <Input
          isRequired
          type="text"
          id="name"
          label="Minimum GPA"
          isTouched={touchedFields.minGPA}
          isValid={!errors.minGPA}
          errorText={errors.minGPA?.message}
          {...register('minGPA', { required: true })}
        />
      </div>
      <div className="flex justify-between gap-4">
        <Select
          size="large"
          label="Exam Name"
          options={exams?.map((exam) => ({ label: exam.name, value: exam._id || '' }))}
          isRequired
          value={examField.value}
          onSelect={(value) => examField.onChange(value)}
          onClear={() => examField.onChange('')}
          onBlur={examField.onBlur}
          isValid={!examFieldState.error}
          isTouched={examFieldState.isTouched}
          errorText={examFieldState.error?.message}
        />
        <Input
          isRequired
          type="text"
          id="name"
          label="Exam Minimum Score"
          isTouched={touchedFields.minExamScore}
          isValid={!errors.minExamScore}
          errorText={errors.minExamScore?.message}
          {...register('minExamScore', { required: true })}
        />
      </div>
      {!!selectedExam && <ExamInfoBar exam={selectedExam} />}
    </ModalFormWrapper>
  );
};

export default AdmissionRequirementsForm;
