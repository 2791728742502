import { UserProfileDTO } from '@shared/clientTypes/DTOs/UserDTO';
import { createContext, useContext } from 'react';

export const UserProfileContext = createContext<{ userProfile?: Partial<UserProfileDTO> } | null>(
  null
);

export const useUserProfileContext = () => {
  const context = useContext(UserProfileContext);
  if (!context) {
    throw new Error('useUserProfileContext must be used within a UserProfileProvider');
  }
  return context;
};
