import { useSaveUserProfile } from '@actions';
import { ReactComponent as UniversityIcon } from '@assets/icons/profile-icons/university-icon.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { EducationalBackground, SelectOption } from '@shared/common';
import { useGetAllMajorNames } from 'apps/agora/src/actions/major.actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import useUniversitySearch from 'apps/agora/src/hooks/useUniversitySearch';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ProfileContext } from '../../../AccountProfile';
import { degreeTypes } from '../../../constants';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';

interface ResumeTabEducationFormProps extends ExamFormProps {
  schoolData?: EducationalBackground;
}

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid school name.' }),
  degreeName: z.string().min(1, { message: 'Please enter a valid degree type.' }),
  major: z.string().min(1, { message: 'Please select a valid major.' }),
  startYear: z.number().min(1, { message: 'Please enter a valid start year.' }),
  endYear: z.number().min(1, { message: 'Please enter a valid graduation year.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const ResumeTabEducationForm = (props: ResumeTabEducationFormProps) => {
  const { isModeAddNew, userId, schoolData, setIsEditMode, setIsAddNewItem, onCancel } = props;

  const { isExternalViewer } = useContext(ProfileContext);

  const toast = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const {
    searchQuery,
    setSearchQuery,
    universities,
    isLoading: isUniversitiesLoading,
  } = useUniversitySearch(
    !isModeAddNew && schoolData?.university?._id ? schoolData?.university.name : '',
    isModeAddNew || !!schoolData?.university?._id
  );

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: schoolData?.university?._id ? undefined : schoolData?.educationalInstitution.name,
      degreeName: schoolData?.degreeType,
      major: schoolData?.major?._id,
      startYear: schoolData?.startYear,
      endYear: schoolData?.endYear,
    },
  });

  const { field: nameField, fieldState: nameFieldState } = useController({
    name: 'name',
    control,
  });

  const { field: startYearField, fieldState: startYearFieldState } = useController({
    name: 'startYear',
    control,
  });

  const { field: endYearField, fieldState: endYearFieldState } = useController({
    name: 'endYear',
    control,
  });

  const { field: degreeField, fieldState: degreeFieldState } = useController({
    name: 'degreeName',
    control,
  });

  const { field: majorField, fieldState: majorFieldState } = useController({
    name: 'major',
    control,
  });

  const shouldAutoSelectUniversityRef = useRef(true);

  useEffect(() => {
    if (
      !!schoolData?.university?._id &&
      !nameField.value &&
      shouldAutoSelectUniversityRef.current
    ) {
      nameField.onChange(schoolData?.university?._id);
      setSearchQuery('');
      shouldAutoSelectUniversityRef.current = false;
    }
  }, [schoolData?.university?._id, universities, nameField.value]);

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { data: majorsList } = useGetAllMajorNames();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const universityData: Omit<EducationalBackground, 'major' | 'university'> & {
        major: string;
      } = {
        educationalInstitution: {
          name: data.name,
          _id: data.name,
          type: 'University',
        },
        major: data.major,
        degreeType: data.degreeName,
        startYear: data.startYear,
        endYear: data.endYear,
        _id: schoolData?._id,
      };

      updateUserProfile({
        educationalBackground: [universityData],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const getUniversityOptions = (): SelectOption[] => {
    return (
      universities?.map((university) => ({
        label: university.name,
        value: university._id,
      })) || []
    );
  };

  const universityBlurHandler = () => {
    setSearchQuery('');
    nameField.onBlur();
  };

  return (
    <StudentFormWrapper
      title="University"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      icon={<UniversityIcon className="min-w-5" />}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      {isModeAddNew || !!schoolData?.university?._id ? (
        <Select
          size="large"
          label="Universities"
          value={nameField.value}
          filterOption={() => true}
          searchValue={searchQuery}
          onSearch={setSearchQuery}
          onSelect={(value) => nameField.onChange(value)}
          onBlur={universityBlurHandler}
          options={getUniversityOptions()}
          notFoundContent={
            isUniversitiesLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
          }
          placeholder="Search University"
          isRequired
          isValid={!nameFieldState.error}
          isTouched={nameFieldState.isTouched}
          errorText={nameFieldState.error?.message}
        />
      ) : (
        <Input
          isRequired
          type="text"
          id="university"
          label="University"
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          autoFocus
          {...register('name')}
        />
      )}

      <AgoraBigSelector
        isRequired
        value={degreeField.value}
        onSelect={(value) => degreeField.onChange(value)}
        options={degreeTypes?.map((degree) => ({
          label: degree,
          value: degree,
        }))}
        onBlur={degreeField.onBlur}
        labelKey="label"
        valueKey="value"
        label="Degree"
        isValid={!degreeFieldState.error}
        isTouched={degreeFieldState.isTouched}
        errorText={degreeFieldState.error?.message}
      />
      <Select
        value={majorField.value}
        onSelect={(value) => majorField.onChange(value)}
        onBlur={majorField.onBlur}
        allowClear={false}
        options={majorsList?.map((major) => ({
          label: major.name,
          value: major._id ?? '',
        }))}
        label="Majors"
        size="large"
        isRequired
        isValid={!majorFieldState.error}
        isTouched={majorFieldState.isTouched}
        errorText={majorFieldState.error?.message}
      />
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={startYearField.value ? moment(startYearField.value, 'YYYY') : undefined}
          onChange={(value) => startYearField.onChange(moment(value).year())}
          disabledDate={(date) =>
            endYearField.value ? moment(date).year() > endYearField.value : false
          }
          onBlur={startYearField.onBlur}
          allowClear={false}
          isRequired
          label="Start Year"
          picker="year"
          isValid={!startYearFieldState.error}
          isTouched={startYearFieldState.isTouched}
          errorText={startYearFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endYearField.value ? moment(endYearField.value, 'YYYY') : undefined}
          onChange={(value) => endYearField.onChange(moment(value).year())}
          onBlur={endYearField.onBlur}
          disabledDate={(date) =>
            startYearField.value ? moment(date).year() < startYearField.value : false
          }
          allowClear={false}
          isRequired
          picker="year"
          label="Graduation Year"
          isValid={!endYearFieldState.error}
          isTouched={endYearFieldState.isTouched}
          errorText={endYearFieldState.error?.message}
        />
      </div>
    </StudentFormWrapper>
  );
};

export default ResumeTabEducationForm;
