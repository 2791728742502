import { useGetUniversityCountriesAndRegions } from '@actions';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Button from '@components/V4/Button';
import Select from '@components/V4/Select/Select';
import TriStateToggle from '@components/V4/TriStateToggle/TriStateToggle';
import { SearchIcon } from '@heroicons/react/outline';
import AddUniversityModal from '@modules/Admin/components/AddUniversityModal';
import { TriState } from '@shared/common';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useState } from 'react';

interface UniversitiesHeaderProps extends BasicProps {
  search: string;
  searchParams: any;
  isActive: TriState;
  isUniversityQSSource: TriState;
  isRankedForSelectedYear: TriState;
  onSearch: (search: string) => void;
  setSearchParams: (data: any) => void;
}

const UniversitiesHeader = (props: UniversitiesHeaderProps) => {
  const {
    search,
    searchParams,
    isActive,
    isUniversityQSSource,
    isRankedForSelectedYear,
    onSearch,
    setSearchParams,
  } = props;

  const { data: countriesAndRegions } = useGetUniversityCountriesAndRegions();

  const [showAddUniversityModal, setShowAddUniversityModal] = useState(false);

  const { countries, regions } = countriesAndRegions || {};

  const yearChangeHandler = (year: string | number | null) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      year: year ? year : null,
    });
  };

  const universitySourceChangeHandler = (source: TriState) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      isUniversityQSSource: source,
    });
  };

  const isRankedChangeHandler = (isRanked: TriState) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      isRankedForSelectedYear: isRanked,
    });
  };

  const isActiveChangeHandler = (isActive: TriState) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      isActive: isActive,
    });
  };

  const universityCountryChangeHandler = (country: string) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      country: country,
    });
  };

  const universityRegionChangeHandler = (region: string) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      region: region,
    });
  };

  return (
    <div className="flex flex-col gap-6 bg-surfaceObject px-18 pt-8 pb-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold">Universities</h3>
        <Button buttonText="Add University" onClick={() => setShowAddUniversityModal(true)} />
      </div>
      <div className="relative w-full flex items-center bg-surfaceObject rounded-3xl">
        <input
          type="text"
          className="bg-transparent border rounded-3xl w-full py-1.5 px-6 text-sm text-white border-customGrey focus:border-customGrey placeholder:text-customGrey placeholder:opacity-50"
          placeholder="Search by University Name, Country or Region"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
        />

        <SearchIcon className="w-6 h-6 absolute right-6 text-customGrey" />
      </div>
      <div className="flex gap-6 flex-wrap">
        <div className="flex gap-6">
          <Select
            className="min-w-56"
            label="Region"
            options={regions?.map((region) => ({ label: region, value: region }))}
            placeholder="Select Region"
            value={searchParams.region}
            onSelect={(value) => universityRegionChangeHandler(value as string)}
            variant="secondary"
          />

          <Select
            className="min-w-56"
            label="Country"
            options={countries?.map((country) => ({
              label: country,
              value: country,
            }))}
            placeholder="Select Country"
            value={searchParams.country}
            onSelect={(value) => universityCountryChangeHandler(value as string)}
            variant="secondary"
          />

          <AgoraDatePicker
            className="min-w-56"
            label="Ranking Year"
            placeholder="Select Year"
            picker={'year'}
            value={searchParams.year ? moment(searchParams.year) : undefined}
            variant="secondary"
            onChange={(value) => yearChangeHandler(moment(value).year())}
            size="default"
          />
        </div>
        <div className="flex items-center gap-6">
          <TriStateToggle
            title={'Show for Selected Year'}
            options={[
              { label: 'Ranked', value: 'checked' },
              { label: 'Unranked', value: 'unchecked' },
              { label: 'All', value: 'indeterminate' },
            ]}
            value={isRankedForSelectedYear}
            onChange={isRankedChangeHandler}
          />
          <TriStateToggle
            title={'Show'}
            options={[
              { label: 'QS', value: 'checked' },
              { label: 'User Added', value: 'unchecked' },
              { label: 'All', value: 'indeterminate' },
            ]}
            value={isUniversityQSSource}
            onChange={universitySourceChangeHandler}
          />
          <TriStateToggle
            title={'Show'}
            options={[
              { label: 'Active', value: 'checked' },
              { label: 'Deleted', value: 'unchecked' },
              { label: 'All', value: 'indeterminate' },
            ]}
            value={isActive}
            onChange={isActiveChangeHandler}
          />
        </div>
      </div>

      {showAddUniversityModal && (
        <AddUniversityModal onClose={() => setShowAddUniversityModal(false)} />
      )}
    </div>
  );
};

export default UniversitiesHeader;
