import { ReactComponent as PdfIcon } from '@assets/icons/pdf-icon.svg';
import { ReactComponent as RemoveIcon } from '@assets/icons/remove-icon.svg';
import { mergeClassNames } from '../../utils/helpers';

interface FileProps {
  href?: string;
  name: string;
  onRemoveFile?: () => void;
  className?: string;
}

const File = (props: FileProps) => {
  const { href, name, onRemoveFile, className } = props;
  return (
    <div
      className={mergeClassNames(
        'flex items-center justify-between min-h-14 bg-customGrey-700 rounded-lg',
        className
      )}
    >
      <a href={href} target="blank" className="flex items-center gap-4">
        <div className="">
          <PdfIcon />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-xs break-words text-customPrimary">{name}</p>
        </div>
      </a>
      {!!onRemoveFile && (
        <div
          onClick={onRemoveFile}
          className="rounded-full fill-customRed cursor-pointer hover:bg-customRed hover:fill-black"
        >
          <RemoveIcon className="fill-inherit" />
        </div>
      )}
    </div>
  );
};

export default File;
