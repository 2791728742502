import DetractorsAndPassivesPage from '@components/V3/NPSDetractorsAndPassivesModal/DetractorsAndPassivesPage';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import { NPSCategoriesType } from '@shared/clientTypes';
import React, { useState } from 'react';
import './nps-detractors-passives-modal.styles.scss';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  title: string;
  date: any;
  role: 'student' | 'mentor' | 'parent';
  npsType: 'platform' | 'education';
  reason?: string | undefined;
  category?: NPSCategoriesType[] | undefined;
  showCategoryTabs?: boolean | undefined;
};

const NPSDetractorsAndPassivesModal = ({
  showModal,
  handleShowModal,
  title,
  date,
  role,
  npsType,
  reason,
  category,
  showCategoryTabs,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState(showCategoryTabs ? 'Detractors' : undefined);

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <AgoraModal
        title={title}
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        <DetractorsAndPassivesPage
          selectedTab={selectedTab}
          handleSelectedTab={handleSelectedTab}
          date={date}
          role={role}
          npsType={npsType}
          reason={reason}
          category={category}
          showCategoryTabs={showCategoryTabs}
        />
      </AgoraModal>
    </div>
  );
};

export default NPSDetractorsAndPassivesModal;
