import { UserProfileDTO } from '@shared/clientTypes/DTOs/UserDTO';
import ResumeEducationSection from './ResumeEducationSection/ResumeEducationSection';
import ResumeSchoolSection from './ResumeSchoolSection/ResumeSchoolSection';
import ResumeWorkSection from './ResumeWorkSection/ResumeWorkSection';

interface ResumeTabProps {
  userId: string;
  userProfile?: Partial<UserProfileDTO>;
  isMentorProfile: boolean;
}

const ResumeTab = (props: ResumeTabProps) => {
  const { userId, userProfile, isMentorProfile } = props;

  const universitiesList = userProfile?.educationalBackground?.filter(
    (edu) => edu.educationalInstitution?.type === 'University' || !!edu?.university
  );

  const schoolsList = userProfile?.educationalBackground?.filter(
    (edu) => edu.educationalInstitution?.type === 'School' || !!edu?.school
  );

  return (
    <section className="flex flex-col gap-4">
      <ResumeWorkSection
        userId={userId}
        experiences={userProfile?.experiences ?? []}
        isMentorProfile={isMentorProfile}
      />

      <ResumeEducationSection
        userId={userId}
        eduList={universitiesList ?? []}
        isMentorProfile={isMentorProfile}
      />

      <ResumeSchoolSection
        userId={userId}
        eduList={schoolsList ?? []}
        isMentorProfile={isMentorProfile}
      />
    </section>
  );
};

export default ResumeTab;
