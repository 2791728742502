import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { degreeTypes } from '@modules/Account/Profile/components/AccountProfile/constants';
import {
  useDeleteAdmissionDocument,
  useGetUniversityAdmissionRounds,
  useSaveUserProfile,
} from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../../DetailsModalFormWrapper';
import {
  AppliedUniversity,
  GOAL_STATUS_OPTIONS,
  GoalStatusTypes,
  Major,
  SelectOption,
} from '@shared/common';
import DropZone from '@components/V4/DropZone/DropZone';
import { useGetAllMajorNames } from 'apps/agora/src/actions/major.actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';
import AdmissionRoundsInfoBar from '@components/V4/AdmissionRoundsInfoBar';
import useUniversitySearch from 'apps/agora/src/hooks/useUniversitySearch';

interface UniversityGoalFormProps extends BasicProps {
  isModeAddNew?: boolean;
  studentId: string;
  goal?: any;
  onCloseEditForm: () => void;
}

const UniversityGoalForm = (props: UniversityGoalFormProps) => {
  const { isModeAddNew = false, studentId, goal, className, onCloseEditForm } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const formDataSchema = z
    .object({
      name: z.string().min(1, { message: 'Please enter a valid university name.' }),
      majors: z.array(z.string()).min(1, { message: 'Please select at least one major.' }),
      degree: z.string().min(1, { message: 'Please enter a valid degree type.' }),
      applicationDeadline: z.string().min(1, { message: 'Please enter a valid date.' }),
      status: z.nativeEnum(GoalStatusTypes, { message: 'Please select a valid status type.' }),
      pros: z.string().optional().default(''),
      cons: z.string().optional().default(''),
      academicRequirements: z.string().optional().default(''),
      admissionDocument: z.instanceof(File).optional().nullable().default(null),
    })
    .superRefine((data, ctx) => {
      if (data.admissionDocument && !['application/pdf'].includes(data.admissionDocument.type)) {
        ctx.addIssue({
          path: ['admissionDocument'],
          message: 'Invalid document file type. Only PDFs are allowed.',
          code: z.ZodIssueCode.custom,
        });
      }
    });

  type FormData = z.infer<typeof formDataSchema>;

  const toast = useToast();

  const { data: majorsList } = useGetAllMajorNames();

  const {
    searchQuery,
    setSearchQuery,
    universities,
    isLoading: isUniversitiesLoading,
  } = useUniversitySearch(
    !isModeAddNew && goal?.newUniversity?._id ? goal?.newUniversity.name : '',
    isModeAddNew || !!goal?.newUniversity?._id
  );

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(studentId, {
    onSuccess: async () => {
      toast.success('Goals updated successfully.');
      onCloseEditForm();
    },
    onError: () => {
      toast.error('Goals failed to update.');
    },
  });

  const { mutate: deleteAdmissionDocument, isLoading: isDeletingAdmissionDocument } =
    useDeleteAdmissionDocument(studentId, {
      onSuccess: async () => {
        toast.success('Admission document deleted successfully.');
      },
      onError: () => {
        toast.error('Admission document failed to delete.');
      },
    });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: goal?.newUniversity?._id ? undefined : goal?.university.name,
      majors: goal?.majors?.map((major: Major) => major._id) ?? [],
      degree: goal?.degree,
      applicationDeadline: goal?.applicationDeadline ?? '',
      status: goal?.status,
      pros: goal?.pros ?? '',
      cons: goal?.cons ?? '',
      academicRequirements: goal?.academicRequirements,
      admissionDocument: null,
    },
  });

  const { field: nameField, fieldState: nameFieldState } = useController({
    name: 'name',
    control,
  });

  const { field: degreeField, fieldState: degreeFieldState } = useController({
    name: 'degree',
    control,
  });

  const { field: statusField, fieldState: statusFieldState } = useController({
    name: 'status',
    control,
  });

  const { field: majorsField, fieldState: majorsFieldState } = useController({
    name: 'majors',
    control,
  });

  const { field: applicationDeadlineField, fieldState: applicationDeadlineFieldState } =
    useController({
      name: 'applicationDeadline',
      control,
    });

  const { field: admissionDocumentField, fieldState: admissionDocumentFieldState } = useController({
    name: 'admissionDocument',
    control,
  });

  const shouldAutoSelectUniversityRef = useRef(true);

  useEffect(() => {
    if (!!goal?.newUniversity?._id && !nameField.value && shouldAutoSelectUniversityRef.current) {
      nameField.onChange(goal?.newUniversity?._id);
      setSearchQuery('');
      shouldAutoSelectUniversityRef.current = false;
    }
  }, [goal?.newUniversity?._id, universities, nameField.value]);

  const { data: admissionRoundsData } = useGetUniversityAdmissionRounds(
    {
      id: nameField.value,
      graduationYear: student?.graduationYear || moment().year(),
    },
    {
      enabled: !!nameField.value && (isModeAddNew || !!goal?.newUniversity?._id),
    }
  );

  const { year: admissionRoundsYear, admissionRounds } = admissionRoundsData || {};

  const fileChangeHandler = (files: FileList | null) => {
    const file = files?.[0] ?? null;
    setValue('admissionDocument', file, { shouldValidate: true });
  };

  const showDropzone = [
    GoalStatusTypes.OfferReceived,
    GoalStatusTypes.OfferAccepted,
    GoalStatusTypes.OfferDeclined,
  ].includes(statusField.value as GoalStatusTypes);

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const universityData: Omit<AppliedUniversity, 'majors' | 'newUniversity'> & {
        majors: string[];
      } = {
        _id: goal?._id,
        university: {
          name: data.name,
          _id: data.name,
          type: 'University',
        },
        majors: data.majors,

        applicationDeadline: data.applicationDeadline,
        academicRequirements: data.academicRequirements ?? '',
        degree: data.degree,
        pros: data.pros,
        cons: data.cons,
        status: data.status,
      };

      const formData = new FormData();
      if (data.admissionDocument && showDropzone) {
        formData.append('admissionDocument', data.admissionDocument);
      }
      formData.append('universityData', JSON.stringify({ appliedUniversities: [universityData] }));

      updateUserProfile(formData);
    }
  };

  const touchAllFields = (
    fields: Record<keyof FormData, string | string[] | GoalStatusTypes | File | null>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const removeExistingFileHandler = (admissionDocument: string) => {
    deleteAdmissionDocument({
      appliedUniversity: goal._id,
      admissionDocument,
    });
  };

  const getUniversityOptions = (): SelectOption[] => {
    return (
      universities?.map((university) => ({
        label: university.name,
        value: university._id,
      })) || []
    );
  };

  const universityBlurHandler = () => {
    setSearchQuery('');
    nameField.onBlur();
  };

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Goal' : 'Edit Goal'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText="Create Goal"
    >
      {isModeAddNew || !!goal?.newUniversity?._id ? (
        <Select
          size="large"
          label="Universities"
          value={nameField.value}
          filterOption={() => true}
          searchValue={searchQuery}
          onSearch={setSearchQuery}
          onSelect={(value) => nameField.onChange(value)}
          onBlur={universityBlurHandler}
          options={getUniversityOptions()}
          notFoundContent={
            isUniversitiesLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
          }
          placeholder="Search University"
          isRequired
          isValid={!nameFieldState.error}
          isTouched={nameFieldState.isTouched}
          errorText={nameFieldState.error?.message}
        />
      ) : (
        <Input
          isRequired
          type="text"
          id="university"
          label="University"
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          autoFocus
          {...register('name')}
        />
      )}

      {(isModeAddNew || goal?.newUniversity?._id) && !!nameField.value && (
        <AdmissionRoundsInfoBar
          admissionRounds={admissionRounds}
          admissionRoundsYear={admissionRoundsYear}
        />
      )}

      <div className="flex gap-6">
        <Select
          value={majorsField.value}
          onChange={(value) => majorsField.onChange(Array.isArray(value) ? value : [value])}
          onClear={() => majorsField.onChange([])}
          onBlur={majorsField.onBlur}
          options={majorsList?.map((major) => ({
            label: major.name,
            value: major._id ?? '',
          }))}
          mode="multiple"
          label="Majors"
          size="large"
          isRequired
          isValid={!majorsFieldState.error}
          isTouched={majorsFieldState.isTouched}
          errorText={majorsFieldState.error?.message}
        />

        <Select
          size="large"
          isRequired
          value={degreeField.value}
          onSelect={(value) => degreeField.onChange(value)}
          options={degreeTypes?.map((degree) => ({
            label: degree,
            value: degree,
          }))}
          onBlur={degreeField.onBlur}
          label="Degree"
          isValid={!degreeFieldState.error}
          isTouched={degreeFieldState.isTouched}
          errorText={degreeFieldState.error?.message}
        />
      </div>
      <div className="flex gap-6">
        <AgoraDatePicker
          isRequired
          value={
            applicationDeadlineField.value ? moment(applicationDeadlineField.value) : undefined
          }
          onChange={(value) =>
            applicationDeadlineField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={applicationDeadlineField.onBlur}
          allowClear={false}
          label="Application Deadline"
          isValid={!applicationDeadlineFieldState.error}
          isTouched={applicationDeadlineFieldState.isTouched}
          errorText={applicationDeadlineFieldState.error?.message}
        />
        <Select
          size="large"
          isRequired
          value={statusField.value}
          onSelect={(value) => statusField.onChange(value)}
          options={GOAL_STATUS_OPTIONS}
          onBlur={statusField.onBlur}
          label="Status"
          isValid={!statusFieldState.error}
          isTouched={statusFieldState.isTouched}
          errorText={statusFieldState.error?.message}
        />
      </div>
      <div className="flex gap-6">
        <TextArea id="pros" label="Pros" {...register('pros')} />
        <TextArea id="cons" label="Cons" {...register('cons')} />
      </div>
      <TextArea
        id="academicRequirements"
        label="Academic Requirements"
        {...register('academicRequirements')}
      />

      {showDropzone && (
        <DropZone
          className="mb-2"
          onChange={fileChangeHandler}
          label={'Admission Document:'}
          existingFiles={
            goal?.admissionDocument
              ? [{ label: goal.admissionDocument?.name, value: goal.admissionDocument?.key }]
              : []
          }
          onRemoveExistingFile={removeExistingFileHandler}
          maxFileSize={15 * 1024 * 1024}
        />
      )}
    </SessionModalFormWrapper>
  );
};

export default UniversityGoalForm;
