import {
  ExamWithUser,
  GetAllExamNamesDTO,
  SearchExamsDTO,
} from '@shared/clientTypes/DTOs/ExamsDTO';
import { ApiDescription, RequestsMethod, ApiData, TriState, Exam } from '../common';

export const createExam: ApiDescription = {
  endpoint: '/exams',
  method: RequestsMethod.Post,
};

export type CreateExam = ApiData<Partial<Exam>, Exam>;

export const getAllExamNames: ApiDescription = {
  endpoint: '/exams/names',
  method: RequestsMethod.Get,
};

export type GetAllExamNames = ApiData<unknown, GetAllExamNamesDTO[]>;

export const getExam: ApiDescription = {
  endpoint: '/exams/:id',
  method: RequestsMethod.Get,
};

export type GetExam = ApiData<unknown, ExamWithUser>;

export type GetExams = ApiData<unknown, Exam[]>;

export const getExams: ApiDescription = {
  endpoint: '/exams',
  method: RequestsMethod.Get,
};

export const updateExam: ApiDescription = {
  endpoint: '/exams/:id',
  method: RequestsMethod.Patch,
};

export type UpdateExam = ApiData<Partial<Exam>, Exam>;

export const deleteExam: ApiDescription = {
  endpoint: '/exams/:id',
  method: RequestsMethod.Delete,
};

export type DeleteExam = ApiData<unknown, Exam>;

export const restoreExam: ApiDescription = {
  endpoint: '/exams/restore/:id',
  method: RequestsMethod.Patch,
};

export type RestoreExam = ApiData<unknown, Exam>;

export const searchExams: ApiDescription = {
  endpoint: '/exams/search-exams',
  method: RequestsMethod.Get,
};

export type SearchExams = ApiData<
  {
    limit: number;
    page: number;
    rangeStart?: string;
    rangeEnd?: string;
    sortBy: string | null;
    sortOrder: 'asc' | 'desc' | null;
    search?: string;
    showDeleted?: TriState;
  },
  SearchExamsDTO
>;
