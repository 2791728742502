import { useCreateExam } from '@actions';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Button from '@components/V4/Button';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { Exam, supportedCurrencies } from '@shared/common';
import { TestFeeCurrencies } from '@shared/serverTypes/exam.types';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';

interface CreateExamFormProps extends BasicProps {
  onCloseEditMode: (examId?: string) => void;
  closeModalHandler: () => void;
}

const formDataSchema = z.object({
  name: z.string().nonempty('Exam name is required.'),
  minScoreScale: z.string().nonempty('Min Score Scale is required.'),
  maxScoreScale: z.string().nonempty('Max Score Scale is required.'),
  targetScore: z.string().nonempty('Target Score is required.'),
  validityPeriod: z.preprocess(
    (val) => (val === '' || val === null ? undefined : Number(val)),
    z.number().min(0, { message: 'Validity Period cannot be negative.' }).optional()
  ),
  testFee: z.preprocess(
    (val) => (val === '' || val === null ? undefined : Number(val)),
    z.number().min(0, { message: 'Test Fee cannot be negative.' }).optional()
  ),
  examDate: z.string().optional(),
  registrationDeadline: z.string().optional(),
  currency: z
    .string()
    .refine((value) => supportedCurrencies.includes(value), {
      message: 'Please select a valid currency.',
    })
    .optional(),
  retakePolicy: z.preprocess(
    (val) => (val === '' || val === null ? undefined : val),
    z.string().optional()
  ),
  modeOfRegistration: z.preprocess(
    (val) => (val === '' || val === null ? undefined : val),
    z.string().optional()
  ),
  duration: z.preprocess(
    (val) => (val === '' || val === null ? undefined : Number(val)),
    z.number().min(0, { message: 'Duration cannot be negative.' }).optional()
  ),
});

type FormData = z.infer<typeof formDataSchema>;

const CreateExamForm = (props: CreateExamFormProps) => {
  const { onCloseEditMode, closeModalHandler } = props;

  const toast = useToast();

  const { mutate: updateExam, isLoading } = useCreateExam({
    onSuccess: async (data) => {
      toast.success('Exam created successfully.');
      onCloseEditMode(data._id);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message || 'Exam failed to create.');
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    control,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: currencyField } = useController({
    name: 'currency',
    control,
  });

  const { field: examDateField } = useController({
    name: 'examDate',
    control,
  });

  const { field: registrationDeadlineField } = useController({
    name: 'registrationDeadline',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const examData: Partial<Exam> = {
        name: data.name,
        scoringScale: { min: data.minScoreScale, max: data.maxScoreScale },
        targetScore: data.targetScore,
        examDate: data.examDate,
        registrationDeadline: data.registrationDeadline,
        testDuration: data.duration,
        validityPeriod: data.validityPeriod,
        retakePolicy: data.retakePolicy,
        ...(data.testFee &&
          data.currency && {
            testFee: { value: data.testFee, currency: data.currency as TestFeeCurrencies },
          }),
        modeOfRegistration: data.modeOfRegistration,
      };

      updateExam(examData);
    }
  };
  const touchAllFields = (fields: Partial<Record<keyof FormData, string | number | null>>) => {
    Object.keys(fields).forEach((key) => {
      const fieldValue = fields[key as keyof FormData];
      if (fieldValue !== undefined && fieldValue !== null) {
        setValue(key as keyof FormData, getValues()[key as keyof FormData], {
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <div>
      <div className="flex flex-col gap-4 bg-surfaceHover rounded-lg w-full px-4 py-6 tablet:px-8 ">
        <div className="flex gap-4 items-center h-6">
          <h4 className="font-raleway text-xsm font-bold text-customGrey">Create Exam</h4>
        </div>

        <div className="flex flex-col gap-2">
          <Input
            isRequired
            type="text"
            id="examName"
            label="Exam Name"
            isTouched={touchedFields.name}
            isValid={!errors.name}
            errorText={errors.name?.message}
            autoFocus
            {...register('name')}
          />

          <div className="flex gap-4">
            <Input
              isRequired
              type="text"
              id="minScoreScale"
              label="Min (Scoring Scale)"
              isTouched={touchedFields.minScoreScale}
              isValid={!errors.minScoreScale}
              errorText={errors.minScoreScale?.message}
              {...register('minScoreScale')}
            />
            <Input
              isRequired
              type="text"
              id="maxScoreScale"
              label="Max (Scoring Scale)"
              isTouched={touchedFields.maxScoreScale}
              isValid={!errors.maxScoreScale}
              errorText={errors.maxScoreScale?.message}
              {...register('maxScoreScale')}
            />
            <Input
              isRequired
              type="text"
              id="targetScore"
              label="Target Score"
              isTouched={touchedFields.targetScore}
              isValid={!errors.targetScore}
              errorText={errors.targetScore?.message}
              {...register('targetScore')}
            />
          </div>

          <div className="flex gap-4">
            <AgoraDatePicker
              value={examDateField.value ? moment(examDateField.value) : undefined}
              onChange={(value) => examDateField.onChange(moment(value).format('YYYY-MM-DD'))}
              onBlur={examDateField.onBlur}
              allowClear={false}
              label="Exam Date"
            />
            <AgoraDatePicker
              value={
                registrationDeadlineField.value
                  ? moment(registrationDeadlineField.value)
                  : undefined
              }
              onChange={(value) =>
                registrationDeadlineField.onChange(moment(value).format('YYYY-MM-DD'))
              }
              onBlur={registrationDeadlineField.onBlur}
              allowClear={false}
              label="Registration Deadline"
            />
          </div>
          <div className="flex gap-4">
            <Input
              type="number"
              id="duration"
              label="Test Duration (minutes)"
              {...register('duration', {
                required: false,
              })}
            />
            <Input
              type="number"
              id="validityPeriod"
              label="Validity Period (months)"
              {...register('validityPeriod', {
                required: false,
              })}
            />
          </div>
          <div className="flex gap-4">
            <Input type="number" id="testFee" label="Test Fee" {...register('testFee')} />
            <Select
              options={supportedCurrencies.map((currency) => ({
                label: currency,
                value: currency,
              }))}
              onSelect={currencyField.onChange}
              onBlur={currencyField.onBlur}
              value={currencyField.value}
              size="large"
              id={'currency'}
              label={'Currency'}
              allowClear={false}
            />
          </div>
          <Input
            type="text"
            id="retakePolicy"
            label="Retake Policy"
            {...register('retakePolicy')}
          />
          <Input
            type="text"
            id="modeOfRegistration"
            label="Mode of Registration"
            {...register('modeOfRegistration')}
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button buttonText="Cancel" variant="secondary" onMouseDown={closeModalHandler} />
          <Button
            buttonText="Create Exam"
            isLoading={isLoading}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateExamForm;
