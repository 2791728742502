import { useSearchExams } from '@actions';
import { PaginationProps } from '@components/V4/Pagination';
import { SortOrder } from '@components/V4/Table/Table';
import { useDebounce, useQueryParamsState } from '@hooks';
import { TriState } from '@shared/common';
import { getValidMoment } from 'apps/agora/src/utils/helpers';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ExamModal from './components/ExamModal/ExamModal';
import ExamsHeader from './components/ExamsHeader';
import ExamsTable from './components/ExamsTable';

const useQuery = () => new URLSearchParams(useLocation().search);

const Exams = () => {
  const [examSearch, setExamSearch] = useState('');
  const debouncedMajorSearch = useDebounce(examSearch, 500);
  const [showExamModal, setShowExamModal] = useState(false);
  const [examId, setExamId] = useState<string>();

  const query = useQuery();
  const limit = query.get('limit') || '10';
  const page = query.get('page') || '1';
  const rangeStart = getValidMoment(query.get('rangeStart'));
  const rangeEnd = getValidMoment(query.get('rangeEnd'));
  const sortBy = query.get('sortBy') || null;
  const sortOrder = (query.get('sortOrder') || null) as SortOrder;
  const showDeleted = (query.get('showDeleted') || 'unchecked') as TriState;

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    page: page,
    limit: limit,
    rangeStart: rangeStart,
    rangeEnd: rangeEnd,
    sortBy: sortBy,
    sortOrder: sortOrder,
    showDeleted: showDeleted,
  });

  const { data: exams, isLoading: areExamsLoading } = useSearchExams({
    limit: searchParams.limit,
    page: searchParams.page,
    rangeStart: searchParams.rangeStart,
    rangeEnd: searchParams.rangeEnd,
    sortBy: searchParams.sortBy,
    sortOrder: searchParams.sortOrder,
    search: debouncedMajorSearch,
    showDeleted: searchParams.showDeleted,
  });

  const currentPageChangeHandler = (page: number) => {
    setSearchParams({
      page,
    });
  };

  const pageSizeChangeHandler = (size: number) => {
    setSearchParams({
      limit: size,
    });
  };

  const pagination: PaginationProps = {
    pageSize: limit !== null ? parseInt(limit) : 10,
    total: exams?.total,
    currentPage: page !== null ? parseInt(page) : 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  const closeExamModalHandler = () => {
    setShowExamModal(false);
  };

  const openExamModalHandler = (examId?: string) => {
    setExamId(examId);
    setShowExamModal(true);
  };

  return (
    <section className="flex flex-col gap-8">
      <ExamsHeader
        showDeleted={showDeleted}
        examSearch={examSearch}
        setExamSearch={setExamSearch}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        openExamModalHandler={openExamModalHandler}
      />

      <ExamsTable
        exams={exams?.exams}
        areExamsLoading={areExamsLoading}
        pagination={pagination}
        sortBy={searchParams.sortBy}
        sortOrder={searchParams.sortOrder}
        setSearchParams={setSearchParams}
        openExamModalHandler={openExamModalHandler}
      />
      {showExamModal && <ExamModal examId={examId} onClose={closeExamModalHandler} />}
    </section>
  );
};

export default Exams;
