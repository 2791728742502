import { useSaveUserProfile } from '@actions';
import SecondaryButton from '@utils/SecondaryButton';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-users.styles.scss';

import { CheckCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import Select from '@components/V4/Select/Select';
import { countries } from 'country-codes-flags-phone-codes';
import Input from '@components/V4/Inputs/Input';

type Props = {
  userId: string;
  userData: any;
  handleCurrentPage: (page: string) => void;
  refetchUserData: () => void;
};

const ProfilePageUsers = ({ userId, userData, handleCurrentPage, refetchUserData }: Props) => {
  const [isEditStudentDetails, setIsEditStudentDetails] = useState(false);

  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [parentName, setParentName] = useState<string | null>(null);
  const [parentEmail, setParentEmail] = useState<string | null>(null);
  const [parentPhone, setParentPhone] = useState<string | null>(null);

  // TODO Delete old edit profile details

  return (
    <div style={{ height: '60vh' }}>
      {/*ENROLMENT INFO*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
                className="edit-enrolment-section-subtitle"
              >
                Contact Info
              </span>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Email:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={email === null ? userData?.email : email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {userData?.email ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Phone:{' '}
                </span>
                {isEditStudentDetails ? (
                  <div className="flex flex-row gap-2">
                    <Select
                      className="max-w-[15%]"
                      value={
                        countryCode === null ? userData?.phoneNumber?.countryCode : countryCode
                      }
                      onSelect={(value) => setCountryCode(value as string)}
                      options={countries.map((country) => ({
                        label: `(${country.dialCode}) ${country.name}`,
                        value: country.dialCode,
                      }))}
                      label="Country Code"
                      size="large"
                      isRequired
                    />

                    <Input
                      type="tel"
                      id="phone"
                      label="Phone Number"
                      name="phoneNumber.number"
                      prefix={countryCode ?? ''}
                      value={phone === null ? userData?.phoneNumber?.number : phone}
                      onChange={(e) => setPhone(e.target.value)}
                      isRequired
                    />
                  </div>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {userData?.phoneNumber?.number
                      ? `${userData?.phoneNumber?.countryCode ?? ''}${
                          userData?.phoneNumber?.number ?? ''
                        }`
                      : '-'}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '36px',
            }}
          >
            <SecondaryButton
              onClick={() => {
                handleCurrentPage('edit-profile-details');
              }}
              backgroundColor={COLORS.WHITE}
              height="27px"
              padding="6px 24px 6px 24px"
              margin="0px"
            >
              <span className="button-text" style={{ color: COLORS.BLACK }}>
                Edit
              </span>
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '8px' }}>
        {/*USER ATTRIBUTES*/}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '14px',
          }}
        >
          <span
            style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
            className="edit-enrolment-section-subtitle"
          >
            User Attributes
          </span>

          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                height: 'fit-content',
                marginRight: '4px',
              }}
              className="edit-enrolment-details-label"
            >
              User created:{' '}
            </span>

            <span style={{ color: COLORS.TEXT_PRIMARY }} className="edit-enrolment-details-value">
              {moment(userData?.createdAt).format('DD.MM.YYYY') ?? '-'}
            </span>
          </div>
          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                height: 'fit-content',
                marginRight: '4px',
              }}
              className="edit-enrolment-details-label"
            >
              Account Activated:{' '}
            </span>

            <span style={{ color: COLORS.TEXT_PRIMARY }} className="edit-enrolment-details-value">
              {userData?.isOnboarded ? 'Yes' : 'No'}
            </span>
          </div>

          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                height: 'fit-content',
                marginRight: '4px',
              }}
              className="edit-enrolment-details-label"
            >
              User Account Status:{' '}
            </span>

            <span style={{ color: COLORS.TEXT_PRIMARY }} className="edit-enrolment-details-value">
              {userData?.isDezactivated ? 'Deactivated Account' : 'Active Account'}
            </span>
          </div>

          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                height: 'fit-content',
                marginRight: '4px',
              }}
              className="edit-enrolment-details-label"
            >
              Test User:{' '}
            </span>

            <span style={{ color: COLORS.TEXT_PRIMARY }} className="edit-enrolment-details-value">
              {userData?.isTestUser ? 'Yes' : 'No'}
            </span>
          </div>

          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                height: 'fit-content',
                marginRight: '4px',
              }}
              className="edit-enrolment-details-label"
            >
              Auth0 Account:{' '}
            </span>

            <span
              style={{
                color: COLORS.TEXT_PRIMARY,
                display: 'flex',
                alignItems: 'center',
              }}
              className="edit-enrolment-details-value"
            >
              {userData?.authId ? (
                <CheckCircleOutlined style={{ width: '20px', color: COLORS.PRIMARY }} />
              ) : (
                <ExclamationCircleFilled style={{ width: '20px', color: COLORS.ERROR }} />
              )}
            </span>
          </div>

          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                height: 'fit-content',
                marginRight: '4px',
              }}
              className="edit-enrolment-details-label"
            >
              LMS Account:{' '}
            </span>

            <span
              style={{
                color: COLORS.TEXT_PRIMARY,
                display: 'flex',
                alignItems: 'center',
              }}
              className="edit-enrolment-details-value"
            >
              {userData?.lmsId ? (
                <CheckCircleOutlined style={{ width: '20px', color: COLORS.PRIMARY }} />
              ) : (
                <ExclamationCircleFilled style={{ width: '20px', color: COLORS.ERROR }} />
              )}
            </span>
          </div>

          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                height: 'fit-content',
                marginRight: '4px',
              }}
              className="edit-enrolment-details-label"
            >
              Drive Folder:{' '}
            </span>

            <span
              style={{
                color: COLORS.TEXT_PRIMARY,
                display: 'flex',
                alignItems: 'center',
              }}
              className="edit-enrolment-details-value"
            >
              {userData?.googleDrive ? (
                <CheckCircleOutlined style={{ width: '20px', color: COLORS.PRIMARY }} />
              ) : (
                <ExclamationCircleFilled style={{ width: '20px', color: COLORS.ERROR }} />
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePageUsers;
