import { GetK12SchoolsDTO, K12School, K12SchoolLight } from '@shared/clientTypes/DTOs/K12SchoolDTO';
import {
  ApiData,
  ApiDescription,
  EducationLevel,
  RequestsMethod,
  SchoolType,
  TriState,
} from '@shared/common';

export const importK12: ApiDescription = {
  endpoint: '/import-k12',
  method: RequestsMethod.Post,
};

export type ImportK12 = ApiData<unknown, unknown>;

export const getK12Schools: ApiDescription = {
  endpoint: '/k12-schools',
  method: RequestsMethod.Get,
};

export type GetK12Schools = ApiData<
  {
    page?: number;
    limit?: number;
    sortOrder?: 'asc' | 'desc';
    sortBy?: string;
    city?: string;
    country?: string;
    search?: string;
    educationLevel?: EducationLevel[];
    type?: number;
    isActive?: TriState;
  },
  GetK12SchoolsDTO
>;

export const getK12School: ApiDescription = {
  endpoint: '/k12-schools/:id',
  method: RequestsMethod.Get,
};

export type GetK12SchoolsLight = ApiData<unknown, K12SchoolLight[]>;

export const getK12SchoolsLight: ApiDescription = {
  endpoint: '/k12-schools-light',
  method: RequestsMethod.Get,
};

export type GetK12School = ApiData<unknown, K12School>;

export const addK12School: ApiDescription = {
  endpoint: '/k12-schools',
  method: RequestsMethod.Post,
};

export type AddK12School = ApiData<
  {
    name: string;
    country: string;
    city: string | null;
    cityRef: string | null;
    type: SchoolType;
    educationLevel: EducationLevel[];
  },
  GetK12SchoolsDTO
>;

export const editK12School: ApiDescription = {
  endpoint: '/k12-schools/:id',
  method: RequestsMethod.Patch,
};

export type EditK12School = ApiData<
  {
    name?: string;
    country?: string;
    city?: string | null;
    cityRef?: string | null;
    type?: SchoolType;
    educationLevel?: EducationLevel[];
  },
  GetK12SchoolsDTO
>;

export const deleteK12School: ApiDescription = {
  endpoint: '/k12-schools/:id',
  method: RequestsMethod.Delete,
};

export type DeleteK12School = ApiData<unknown, unknown>;

export const restoreK12School: ApiDescription = {
  endpoint: '/k12-schools/restore/:id',
  method: RequestsMethod.Patch,
};

export type RestoreK12School = ApiData<unknown, unknown>;
