import { ApiDescription, ApiData, RequestsMethod, MentorData, User } from '@shared/clientTypes';
import {
  DeleteAdmissionDocumentDTO,
  GetUserDetailsDTO,
  GetVideoSettingsDTO,
  SearchStudentsDTO,
  SearchUsersDTO,
  UserProfileDTO,
} from '@shared/clientTypes/DTOs/UserDTO';
import { RoleType } from '@shared/serverTypes';
import { MyAccountInfo, PermissionsType, TriState } from '../common';

export const isUserOrParentEmailRegistered: ApiDescription = {
  endpoint: '/users/is-user-or-parent-email-registered',
  method: RequestsMethod.Get,
};

export type IsUserOrParentEmailRegistered = ApiData<{ email: string }, boolean>;

export const createAdminUser: ApiDescription = {
  endpoint: '/users/create-admin-user',
  method: RequestsMethod.Post,
};
export type CreateAdminUser = ApiData<
  { email: string; fullName: string },
  { password: string; errors?: string[] }
>;

export const me: ApiDescription = {
  endpoint: '/users/me',
  method: RequestsMethod.Get,
};
export type Me = ApiData<never, MyAccountInfo>;

export const updateMyMentorData: ApiDescription = {
  endpoint: '/users/me/mentor-data',
  method: RequestsMethod.Patch,
};

type StudySubjectMentor = MentorData & {
  studySubjects: string[];
  userId?: string;
};
export type UpdateMyMentorData = ApiData<Partial<StudySubjectMentor>, User>;

export const searchStudents: ApiDescription = {
  endpoint: '/users/search-students',
  method: RequestsMethod.Get,
};

export type SearchStudents = ApiData<
  {
    search?: string;
    mentoredBy?: string;
    enrolledIn?: string[];
    graduationYear?: number;
    status?: string;
    page?: number;
    limit?: number;
    sortDirection?: 'asc' | 'desc';
    sortBy?: 'fullName' | 'graduationYear';
    extraFilter?: any;
    isTestUser?: boolean;
    isDeactivated?: boolean;
  },
  SearchStudentsDTO
>;

export const updateMentorData: ApiDescription = {
  endpoint: '/users/:id/mentor-data',
  method: RequestsMethod.Patch,
};
export type UpdateMentorData = ApiData<Partial<MentorData>, MentorData>;

export const getStudentDetails: ApiDescription = {
  endpoint: '/users/student/:id',
  method: RequestsMethod.Get,
};
export type GetStudentDetails = ApiData<{ studentId: string }, User>;

export const searchUsers: ApiDescription = {
  endpoint: '/users',
  method: RequestsMethod.Get,
};
export type SearchUsers = ApiData<
  {
    page?: number;
    limit?: number;
    roles?: RoleType[];
    search?: string;
    countries?: string[];
    majors?: string[];
    teachesCourse?: string;
    courseId?: string;
    mentorId?: string;
    graduationYear?: number;
    sortBy?: 'createdAt' | 'avgSatisfaction' | 'courseStartDate';
    sortDirection?: 'asc' | 'desc';
    isTakingCourse?: boolean;
    isDezactivated?: string;
    isTestUser?: string;
  },
  SearchUsersDTO
>;

export const searchUsersV2: ApiDescription = {
  endpoint: '/users-v2',
  method: RequestsMethod.Get,
};
export type SearchUsersV2 = ApiData<
  {
    page?: number;
    limit?: number;
    roles?: RoleType[];
    search?: string;
    showTestUsers?: TriState;
    status?: string;
    sortBy?: 'createdAt' | 'fullName';
    sortDirection?: 'asc' | 'desc';
  },
  SearchUsersDTO
>;

export const searchUsersByFullName: ApiDescription = {
  endpoint: '/users/search-by-fullname',
  method: RequestsMethod.Get,
};

export type SearchUsersByFullName = ApiData<
  {
    fullName?: string;
    role: RoleType;
  },
  User[]
>;

export const updateUser: ApiDescription = {
  endpoint: '/users/:id',
  method: RequestsMethod.Patch,
};
export type UpdateUser = ApiData<Partial<User>, string>;

export const updateUserNote: ApiDescription = {
  endpoint: '/users/:id/update-notes',
  method: RequestsMethod.Post,
};
export type UpdateUserNote = ApiData<{ notes: string[] }, string>;

export const getUserDetails: ApiDescription = {
  endpoint: '/users/:id/details',
  method: RequestsMethod.Get,
};
export type GetUserDetails = ApiData<unknown, GetUserDetailsDTO>;

export const getUserNotes: ApiDescription = {
  endpoint: '/users/:id/notes',
  method: RequestsMethod.Get,
};
export type GetUserNotes = ApiData<unknown, Array<string>>;

export const getAllMentors: ApiDescription = {
  endpoint: '/mentor/get-all',
  method: RequestsMethod.Get,
};
export type GetAllMentors = ApiData<
  { search?: string; limit?: number; active?: boolean; haveStatusInactiveOrLeaving?: boolean },
  User[]
>;

export const getAllStudents: ApiDescription = {
  endpoint: '/student/get-all',
  method: RequestsMethod.Get,
};

export type GetAllStudents = ApiData<{ search?: string; limit?: number; active?: boolean }, User[]>;

export const getUserProfile: ApiDescription = {
  endpoint: '/users/:id/profile',
  method: RequestsMethod.Get,
};

export type GetUserProfile = ApiData<unknown, Partial<UserProfileDTO>>;

export const saveUserProfile: ApiDescription = {
  endpoint: '/users/:id/save-profile',
  method: RequestsMethod.Put,
};

export type SaveUserProfile = ApiData<FormData | any, Partial<User>>;

export const getStudentStatistics: ApiDescription = {
  endpoint: '/users/students/statistics',
  method: RequestsMethod.Get,
};

export type GetStudentStatistics = ApiData<unknown, any>;

export const editUserPermissions: ApiDescription = {
  endpoint: '/users/:id/permissions',
  method: RequestsMethod.Patch,
};

export type EditUserPermissions = ApiData<{ permissions: PermissionsType }, unknown>;

export const getUserPermissions: ApiDescription = {
  endpoint: '/users/:id/permissions',
  method: RequestsMethod.Get,
};

export type GetUserPermissions = ApiData<unknown, PermissionsType>;

export const migratePayrateFromHubspot: ApiDescription = {
  endpoint: '/users/migrate-payrate-from-hubspot',
  method: RequestsMethod.Post,
};

export type MigratePayrateFromHubspot = ApiData<unknown, unknown>;

export type DeleteVirtualBackground = ApiData<{ background: string }, string>;

export const deleteVirtualBackground: ApiDescription = {
  endpoint: '/users/me/virtual-backgrounds',
  method: RequestsMethod.Put,
};

export const addVirtualBackground: ApiDescription = {
  endpoint: '/users/me/virtual-backgrounds',
  method: RequestsMethod.Patch,
};

export type AddVirtualBackground = ApiData<FormData, string>;

export const getVirtualBackgrounds: ApiDescription = {
  endpoint: '/users/me/virtual-backgrounds',
  method: RequestsMethod.Get,
};

export type GetVirtualBackgrounds = ApiData<unknown, string[]>;

export const getVideoSettings: ApiDescription = {
  endpoint: '/users/me/video-settings',
  method: RequestsMethod.Get,
};

export type GetVideoSettings = ApiData<unknown, GetVideoSettingsDTO>;

export const updateVideoSettings: ApiDescription = {
  endpoint: '/users/me/video-settings',
  method: RequestsMethod.Patch,
};

export type UpdateVideoSettings = ApiData<
  {
    noiseSuppression?: boolean;
    virtualBackground?: string | null;
  },
  unknown
>;

export const getTimezonesList: ApiDescription = {
  endpoint: '/users/me/timezones-list',
  method: RequestsMethod.Get,
};

export type GetTimezonesList = ApiData<unknown, string[]>;

export const deleteAdmissionDocument: ApiDescription = {
  endpoint: '/users/:id/delete-admission-document',
  method: RequestsMethod.Put,
};

export type DeleteAdmissionDocument = ApiData<
  { appliedUniversity: string; admissionDocument: string },
  DeleteAdmissionDocumentDTO
>;
