import React, { useState, useRef } from 'react';
import Button from '../Button';
import InputLabel from '../Inputs/InputLabel';
import { mergeClassNames } from '../../../utils/helpers';
import { ReactComponent as PdfIcon } from '@assets/icons/pdf-icon.svg';
import { ReactComponent as RemoveIcon } from '@assets/icons/remove-icon.svg';
import { ReactComponent as UploadFileIcon } from '@assets/icons/upload-file-icon.svg';
import File from '../File';
import { SPACES_USER_GENERATED_CONTENT_BUCKET, spacesEndpoint } from '@shared/constants';
import useToast from 'apps/agora/src/hooks/useToast';

export type FileAcceptType = 'image/*' | '.pdf';

export interface ExistingFileType {
  label: string;
  value: string;
}

interface DropZoneProps {
  onChange: (files: FileList | null) => void;
  label: string;
  className?: string;
  isRequired?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
  fileType?: FileAcceptType;
  mode?: 'single' | 'multiple';
  existingFiles?: ExistingFileType[];
  maxFileSize?: number;
  onRemoveExistingFile?: (admissionDocument: string) => void;
}

const DropZone = (props: DropZoneProps) => {
  const {
    onChange,
    label,
    className,
    isRequired,
    isValid,
    isTouched,
    fileType = '.pdf',
    mode = 'single',
    existingFiles,
    onRemoveExistingFile,
    maxFileSize,
  } = props;

  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null); // Ref for file input
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const hasNoExistingFiles = !existingFiles || existingFiles.length < 1;
  const hasNoUploadedFiles = !uploadedFiles || uploadedFiles.length < 1;

  const isAllowedToUpload =
    mode === 'multiple' || (mode === 'single' && hasNoExistingFiles && hasNoUploadedFiles);

  const maxFileSizeMB = !!maxFileSize && (maxFileSize / 1024 / 1024).toFixed(2);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isAllowedToUpload) {
      onChange(null);
      return;
    }
    const files: File[] = e.target.files ? Array.from(e.target.files) : [];

    const validFiles = files.filter((file) => {
      if (maxFileSize && file.size > maxFileSize) {
        toast.error(
          `File ${file.name} exceeds the max file size limit of ${maxFileSizeMB} MB.`,
          'Error'
        );
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) return;

    const updatedFiles = mode === 'multiple' ? [...uploadedFiles, ...validFiles] : validFiles;

    setUploadedFiles(updatedFiles);

    const dataTransfer = new DataTransfer();
    updatedFiles.forEach((file) => dataTransfer.items.add(file));
    onChange(dataTransfer.files);
  };

  const removeFile = (index: number) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);

    setUploadedFiles(updatedFiles);

    if (updatedFiles.length === 0) {
      onChange(null);

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    } else {
      const dataTransfer = new DataTransfer();
      updatedFiles.forEach((file) => dataTransfer.items.add(file));
      onChange(dataTransfer.files);
    }
  };

  const getFileTypeFormatAsString = () => {
    switch (fileType) {
      case '.pdf':
        return 'PDF';
      case 'image/*':
        return 'image format (jpg, png, jpeg)';
      default:
        return '';
    }
  };

  return (
    <div className={mergeClassNames('w-full', className)}>
      <InputLabel label={label} isRequired={isRequired} isTouched={isTouched} isValid={isValid} />
      <div className="relative cursor-pointer">
        <input
          ref={inputRef}
          type="file"
          accept={fileType}
          multiple={mode === 'multiple'}
          disabled={!isAllowedToUpload}
          className={mergeClassNames(
            'absolute top-0 left-0 opacity-0 z-20 w-full h-full rounded-full cursor-pointer',
            {
              'cursor-not-allowed': !isAllowedToUpload,
            }
          )}
          onChange={handleFileChange}
        />
        <div
          className={mergeClassNames(
            'w-full flex flex-col items-center gap-6 py-6 px-12 border border-dashed border-customGrey rounded-lg',
            {
              ' border-customPrimary focus:!border-customPrimary': isValid && isTouched,
              ' border-customRed focus:!border-customRed': !isValid && isTouched,
            }
          )}
        >
          <UploadFileIcon />
          <div className="flex flex-col gap-2 text-customGrey">
            <p className="font-bold text-xsm font-raleway text-inherit">
              {!isAllowedToUpload ? 'File(s) uploaded.' : 'Choose a file or drag and drop it here'}
            </p>
            <p className="text-xs text-inherit">
              Must be in {getFileTypeFormatAsString()} format.{' '}
              {mode === 'single' ? 'Single' : 'Multiple files'} upload supported.
            </p>
            {!!maxFileSize && (
              <p className="text-xs text-inherit">Max. file size: {maxFileSizeMB} MB.</p>
            )}
          </div>
          {isAllowedToUpload && <Button variant="secondary" buttonText="Browse File" />}
        </div>
      </div>
      {uploadedFiles.length > 0 && (
        <div className="mt-2 space-y-2">
          <InputLabel label={`Uploaded ${uploadedFiles.length > 1 ? 'Files' : 'File'}:`} />
          {uploadedFiles.map((file, index) => (
            <div
              key={`${file.name}-${index}`}
              className="flex items-center justify-between py-2.5 px-4 min-h-14 bg-customGrey-700 rounded-lg"
            >
              <div className="flex items-center gap-4">
                <div className="">
                  <PdfIcon />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-xs break-words">{file.name}</p>
                  {!!file.size && (
                    <p className="text-xxs font-light">{(file.size / 1024).toFixed(2)} KB</p>
                  )}
                </div>
              </div>
              <div
                onClick={() => removeFile(index)}
                className="rounded-full fill-customRed cursor-pointer hover:bg-customRed hover:fill-black"
              >
                <RemoveIcon className="fill-inherit" />
              </div>
            </div>
          ))}
        </div>
      )}

      {!!existingFiles?.length && (
        <div className="mt-2 space-y-2">
          <InputLabel label={`Uploaded ${existingFiles.length > 1 ? 'Files' : 'File'}:`} />
          {existingFiles.map((file) => (
            <File
              key={file.value}
              name={file.label}
              href={`https://${SPACES_USER_GENERATED_CONTENT_BUCKET}.${spacesEndpoint}/${file.value}`}
              onRemoveFile={() => onRemoveExistingFile?.(file.value)}
              className="py-2.5 px-4"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DropZone;
