import USBigPrimary from '@assets/images/us_big_1.jpg';
import USBigPurple from '@assets/images/us_big_2.jpg';
import USBigGold from '@assets/images/us_big_3.jpg';
import './course-card.styles.scss';
import { useEffect, useRef } from 'react';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import Tooltip from '@components/V4/Tooltip';

type Props = {
  title?: string;
  subtitle?: string;
  color?: string;
  image?: any;
  imageWidth?: any;
  imageHeight?: any;
  hasLMSCourseID?: boolean;
};

const CourseCard = ({
  title,
  subtitle,
  color,
  image,
  imageWidth,
  imageHeight,
  hasLMSCourseID = true,
}: Props) => {
  const subtitleRef = useRef<HTMLDivElement>(null);

  const getCourseImage = () => {
    if (image) {
      return image;
    }

    if (title?.toLowerCase().includes('coach') || title?.toLowerCase().includes('training')) {
      return USBigPrimary;
    }

    if (title?.toLowerCase().includes('academic')) {
      return USBigPurple;
    }
    return USBigGold;
  };

  const truncateText = () => {
    if (subtitleRef.current) {
      const element = subtitleRef.current;
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxHeight = lineHeight * 2;
      element.style.maxHeight = `${maxHeight}px`;
      element.style.overflow = 'hidden';
      element.style.textOverflow = 'ellipsis';
    }
  };

  useEffect(() => {
    truncateText();
  }, [subtitle]);

  return (
    <Tooltip text={!hasLMSCourseID ? 'No course materials available.' : null}>
      <div
        className={mergeClassNames('course-card-container p-0 bg-surfaceObject ', {
          'hover:bg-[#232323]': hasLMSCourseID,
        })}
      >
        <div className="course-card-content w-full">
          <img
            className="course-image"
            style={{ width: imageWidth ?? '', height: imageHeight ?? '' }}
            src={getCourseImage()}
            alt="course"
            crossOrigin={'anonymous'}
          />

          <div className="course-card-text-container pt-2 gap-1 w-3/5 h-full laptop:pt-4">
            <div className="course-card-title">
              <div className="text-white overflow-hidden whitespace-nowrap text-ellipsis">
                <span>{title}</span>
              </div>
            </div>

            <div ref={subtitleRef} className="course-card-subtitle text-customGrey">
              <span>{subtitle}</span>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default CourseCard;
