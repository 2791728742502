import styles from '@styles/components/sider.module.scss';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Tab } from './tabs';

interface NavItemProps {
  tab: Tab;
}

const NavItem = (props: NavItemProps) => {
  const { tab } = props;

  const { pathname } = useLocation();

  const isActive = matchPath(pathname, {
    path: tab.route,
    strict: false,
    exact: false,
  });

  return (
    <Link to={tab.route} className={`${styles.navItem} ${isActive ? styles.navItemActive : ''}`}>
      <div className={styles.icon}>{tab.icon}</div>
      <div className={styles.title}>{tab.title}</div>
    </Link>
  );
};

export default NavItem;
