import { useAddK12School, useEditK12School, useGetCitiesLight } from '@actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import Input from '@components/V4/Inputs/Input';
import Select, { SelectOption } from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounce } from '@hooks';
import { countryList, EDUCATION_LEVELS, SCHOOL_TYPES } from '@shared/constants';
import { EducationLevel } from '@shared/enums';
import { K12School } from '@shared/serverTypes';

import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';

interface K12SchoolModalFormProps extends BasicProps {
  isEditMode?: boolean;
  k12School?: K12School;
  onCloseEditMode?: () => void;
  onCloseModal?: () => void;
}

const formDataSchema = z
  .object({
    name: z.string().nonempty('School name is required'),
    country: z.string().nonempty('Country is required'),
    city: z.string().optional().nullable(),
    cityRef: z.string().optional().nullable(),
    type: z.number().min(0, 'Type is required'),
    educationLevel: z.number().array().nonempty('Education Level is required'),
  })
  .superRefine((data, ctx) => {
    if (data.country === 'Romania') {
      if (!data.cityRef) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['cityRef'],
          message: 'Please select a valid city.',
        });
      }
    } else {
      if (!data.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['city'],
          message: 'Please enter a valid city.',
        });
      }
    }
  });

type FormData = z.infer<typeof formDataSchema>;

const K12SchoolModalForm = (props: K12SchoolModalFormProps) => {
  const { k12School, isEditMode = false, onCloseModal, onCloseEditMode } = props;
  const [citySearch, setCitySearch] = useState('');
  const debouncedCitySearch = useDebounce(citySearch, 500);

  const toast = useToast();

  const { data: cities, isLoading: isLoadingCities } = useGetCitiesLight({
    search: debouncedCitySearch,
    limit: 20,
  });

  const { mutate: addK12School, isLoading: isAddingK12School } = useAddK12School({
    onSuccess: () => {
      toast.success('School created successfully.');
      onCloseModal?.();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message || 'School failed to create.');
    },
  });

  const { mutate: editK12School, isLoading: isEditingK12School } = useEditK12School(
    k12School?._id || '',
    {
      onSuccess: () => {
        toast.success('School edited successfully.');
        onCloseEditMode?.();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.error?.message || 'School failed to edit.');
      },
    }
  );

  const {
    register,
    handleSubmit,
    trigger,
    control,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: k12School?.name,
      country: k12School?.country,
      city: k12School?.city || null,
      cityRef: k12School?.cityRef?._id || null,
      type: k12School?.type,
      educationLevel: (k12School?.educationLevel as EducationLevel[]) || [],
    },
  });

  const { field: countryField, fieldState: countryFieldState } = useController({
    name: 'country',
    control,
  });

  const { field: cityRefField, fieldState: cityRefFieldState } = useController({
    name: 'cityRef',
    control,
  });

  const { field: educationLevelField, fieldState: educationLevelFieldState } = useController({
    name: 'educationLevel',
    control,
  });

  const { field: typeField, fieldState: typeFieldState } = useController({
    name: 'type',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();

    if (isValid) {
      const k12SchoolData: Omit<K12School, 'cityRef' | '_id'> & {
        cityRef: string | null;
      } = {
        name: data.name,
        country: data.country,
        city: data.city || null,
        cityRef: data.cityRef || null,
        type: data.type,
        educationLevel: data.educationLevel as EducationLevel[],
      };

      if (isEditMode) {
        if (!k12School) return;

        const oldCityRefId = k12School.cityRef?._id;
        const newCityRefId = k12SchoolData.cityRef;
        const sameEducationLevels =
          k12School.educationLevel.length === k12SchoolData.educationLevel.length &&
          k12School.educationLevel.every((level) => k12SchoolData.educationLevel.includes(level)) &&
          k12SchoolData.educationLevel.every((level) => k12School.educationLevel.includes(level));

        const alteredFields: Partial<Omit<K12School, 'cityRef'>> & { cityRef?: string | null } = {
          ...(k12School.name !== k12SchoolData.name && { name: k12SchoolData.name }),
          ...(k12School.country !== k12SchoolData.country && { country: k12SchoolData.country }),
          ...((oldCityRefId || newCityRefId) &&
            oldCityRefId !== newCityRefId && { cityRef: newCityRefId }),
          ...((k12School.city || k12SchoolData.city) &&
            k12School.city !== k12SchoolData.city && { city: k12SchoolData.city }),
          ...(!sameEducationLevels && { educationLevel: k12SchoolData.educationLevel }),
          ...(k12School.type !== typeField.value && { type: typeField.value }),
        };

        if (Object.keys(alteredFields).length === 0) {
          toast.info('No changes to update.');
          return;
        }

        editK12School(alteredFields);
      } else {
        addK12School(k12SchoolData);
      }
    }
  };

  const touchAllFields = (
    fields: Record<keyof Omit<FormData, 'city' | 'cityRef'>, string | number | number[]>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isAddingK12School || isEditingK12School) return;
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  const countryChangeHandler = (value: string | number | null) => {
    countryField.onChange(value);

    if (value === 'Romania') {
      setValue('city', null);
    } else {
      setValue('cityRef', null);
    }
  };

  const cityBlurHandler = () => {
    setCitySearch('');
    cityRefField.onBlur();
  };

  const getCityOptions = (): SelectOption[] => {
    const mappedCities =
      cities?.map((city) => ({
        label: `${city.name}, ${city.county}`,
        value: city._id,
      })) || [];

    if (!k12School || !k12School.cityRef) return mappedCities;

    const isCityRefInMappedCities = !!mappedCities.find(
      (city) => city.value === k12School?.cityRef?._id
    );

    if (!isCityRefInMappedCities) {
      mappedCities.push({
        label: `${k12School?.cityRef.name}, ${k12School?.cityRef.county}`,
        value: k12School?.cityRef._id,
      });
    }

    return mappedCities;
  };

  const schoolTypeOptions = Object.keys(SCHOOL_TYPES).map((type) => ({
    label: SCHOOL_TYPES[type],
    value: Number(type),
  }));

  const educationLevelOptions = Object.keys(EDUCATION_LEVELS).map((level) => ({
    label: EDUCATION_LEVELS[level],
    value: Number(level),
  }));

  return (
    <div>
      <div className="flex flex-col gap-4 bg-surfaceHover rounded-lg w-full px-4 py-6 tablet:px-8 ">
        <div className="flex gap-4 items-center h-6">
          <h4 className="font-raleway text-xsm font-bold text-customGrey">Create School</h4>
        </div>
        <Input
          isRequired
          type="text"
          id="schoolName"
          label="School Name"
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          autoFocus
          {...register('name')}
        />
        <div className="flex gap-6">
          <Select
            value={educationLevelField.value}
            onChange={educationLevelField.onChange}
            onClear={() => educationLevelField.onChange(undefined)}
            onBlur={educationLevelField.onBlur}
            options={educationLevelOptions}
            isRequired
            mode="multiple"
            allowClear={false}
            size="large"
            label="Education Level"
            isValid={!educationLevelFieldState.error}
            isTouched={educationLevelFieldState.isTouched}
            errorText={educationLevelFieldState.error?.message}
          />
          <Select
            value={typeField.value}
            onSelect={typeField.onChange}
            onClear={() => typeField.onChange(undefined)}
            onBlur={typeField.onBlur}
            options={schoolTypeOptions}
            isRequired
            allowClear={false}
            size="large"
            label="Type"
            isValid={!typeFieldState.error}
            isTouched={typeFieldState.isTouched}
            errorText={typeFieldState.error?.message}
          />
        </div>
        <div className="flex justify-between gap-6">
          <Select
            value={countryField.value}
            onSelect={countryChangeHandler}
            onClear={() => countryField.onChange(undefined)}
            onBlur={countryField.onBlur}
            options={countryList.map((country) => ({
              label: country,
              value: country,
            }))}
            isRequired
            allowClear={false}
            size="large"
            label="Country"
            isValid={!countryFieldState.error}
            isTouched={countryFieldState.isTouched}
            errorText={countryFieldState.error?.message}
          />
          {countryField.value === 'Romania' ? (
            <Select
              size="large"
              label="City"
              value={cityRefField.value}
              filterOption={() => true}
              searchValue={citySearch}
              onSearch={setCitySearch}
              onSelect={(value) => cityRefField.onChange(value)}
              onBlur={cityBlurHandler}
              options={getCityOptions()}
              notFoundContent={
                isLoadingCities ? <LoadingSpinner className="mx-auto" size="normal" /> : null
              }
              placeholder="Search City"
              isRequired
              isValid={!cityRefFieldState.error}
              isTouched={cityRefFieldState.isTouched}
              errorText={cityRefFieldState.error?.message}
            />
          ) : (
            <Input
              type="text"
              id="city"
              label="City"
              isRequired
              isTouched={touchedFields.city}
              isValid={!errors.city}
              errorText={errors.city?.message}
              {...register('city', { required: true })}
            />
          )}
        </div>
        <div className="flex justify-end gap-4">
          <Button
            buttonText="Cancel"
            variant="secondary"
            onMouseDown={isEditMode ? onCloseEditMode : onCloseModal}
          />
          <Button
            buttonText={isEditMode ? 'Save School' : 'Create School'}
            isLoading={isAddingK12School || isEditingK12School}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default K12SchoolModalForm;
