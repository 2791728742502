import Button from '@components/V4/Button';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { enrollmentsApis } from '@shared/apis';
import { useUpdateMeetingNotes } from '@actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';

type DefaultData = {
  id: string;
  notes?: string;
};

interface SessionDetailsNotesFormProps {
  defaultData: DefaultData;
  onCancel: () => void;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const formDataSchema = z.object({
  notes: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const SessionDetailsNotesForm = (props: SessionDetailsNotesFormProps) => {
  const { defaultData, onCancel, setIsEditMode } = props;

  const toast = useToast();
  const queryClient = useQueryClient();

  const { trackEvent } = useAnalytics();

  const { register, handleSubmit, trigger, watch, reset } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      notes: defaultData?.notes ?? '',
    },
  });

  const notes = watch('notes');

  const { mutate: updateMeetingNotes, isLoading: isUpdateNotesLoading } = useUpdateMeetingNotes(
    defaultData.id,
    {
      onSuccess: () => {
        trackEvent(ANALYTICS_EVENT_NAMES.SESSION_ADD_NOTES);

        toast.success('Session notes updated successfully');
        queryClient.invalidateQueries(enrollmentsApis.getSessionDetails.endpoint);
        setIsEditMode(false);
        reset();
      },
      onError: (error: any) => {
        toast.error(error.response.data.error.message);
      },
    }
  );

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateMeetingNotes({
        notes: data.notes,
      });
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleSubmit(onSubmit)(e);
  };

  const isSubmitDisabled = !notes || notes.trim().length === 0;

  return (
    <section className="flex flex-col gap-8">
      <TextArea id="notes" label="Notes" autoFocus {...register('notes')} />

      <div className="flex justify-end gap-4">
        <Button variant="secondary" buttonText="Cancel" onClick={onCancel} />
        <Button
          variant="primary"
          buttonText="Update Notes"
          isLoading={isUpdateNotesLoading}
          disabled={isSubmitDisabled}
          onClick={submitHandler}
        />
      </div>
    </section>
  );
};

export default SessionDetailsNotesForm;
