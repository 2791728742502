import { PageLayout } from '@components';
import { useCreateKnowledgeBaseArticle, useUpdateKnowledgeBaseArticleData } from '@actions';
import { EdjData } from '@shared/clientTypes';
import AgoraSpinner from '@utils/AgoraSpinner';
import { Input, Select, Popconfirm, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainEditor from '../LessonEditor/MainEditor';
import ArticleCRUD from './article-crud';
import useToast from 'apps/agora/src/hooks/useToast';

const { Option } = Select;

const KnowledgeBaseEditor = () => {
  const { id }: any = useParams();

  const toast = useToast();

  const { mutate: createNewArticle, data: newArticle } = useCreateKnowledgeBaseArticle({
    onSuccess: (res) => {
      toast.success('Article created!');
    },
    onError: () => {
      toast.error('Something went wrong, please try again.');
    },
  });

  const [currentId, setCurrentId] = useState<string>(id || '');
  const { mutate: updateArticle } = useUpdateKnowledgeBaseArticleData(currentId, {
    onSuccess: () => {
      toast.success('Article created!');
    },
    onError: () => {
      toast.error('Something went wrong, please try again.');
    },
  });

  const { data: currentArticleData } = useUpdateKnowledgeBaseArticleData(currentId);

  const [action, setAction] = useState('new');
  const [showDeletePopConfirm, setShowDeletePopConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  // article data
  const [title, setTitle] = useState<string>('');
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [body, setBody] = useState<EdjData>(DEFAULT_BODY);

  useEffect(() => {
    if (currentId !== '') {
      setAction('edit');
      populateArticle();
    } else {
      setLoading(false);
    }
  }, []);

  const populateArticle = async () => {
    const article = await ArticleCRUD.read(currentId);
    setTitle(article.data.title);
    setKeywords(article.data.keywords);
    setBody(article.data.body);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const deleteArticle = async () => {
    await ArticleCRUD.remove(currentId);
    setTimeout(() => {
      window.location.href = '/admin/manage-knowledge-base';
    }, 1000);
  };

  const handleKeywordsChange = (value: Array<string>) => {
    setKeywords(value);
  };

  const onUpdate = (api: any, newContent: any) => setBody(newContent);

  const inputStyle = {
    maxWidth: '675px',
    margin: '0 auto 10px',
    display: 'block',
  };

  const saveArticle = async () => {
    const articleData = {
      title,
      keywords,
      body,
    };

    if (action === 'edit') {
      updateArticle({
        title: title,
        keywords: keywords,
        body: body,
      });

      return;
    }

    if (title) {
      const newArticle = await ArticleCRUD.create(articleData);
      setCurrentId(newArticle.data._id);
      setAction('edit');
    } else {
      toast.error('Title is required');
    }
  };

  const headerExtra = [
    <>
      {currentId && (
        <Popconfirm
          title={`This action is irreversible. Are you sure you want to delete ${title}?`}
          visible={showDeletePopConfirm}
          onConfirm={deleteArticle}
          onCancel={() => setShowDeletePopConfirm(false)}
        >
          <Button key="delete-page" onClick={() => setShowDeletePopConfirm(true)}>
            Delete
          </Button>
        </Popconfirm>
      )}
    </>,
    <Button type="primary" onClick={saveArticle}>
      {action === 'new' ? 'Publish' : 'Save'}
    </Button>,
  ];

  if (loading)
    return (
      <div
        style={{
          minHeight: 'calc(100vh - 75px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AgoraSpinner fontSize={40} color="#000000" />
      </div>
    );

  return (
    <PageLayout backButton headerTitle="Knowledge Base Editor" headerExtra={headerExtra}>
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Article Title"
        style={inputStyle}
      />
      <Select
        mode="tags"
        style={inputStyle}
        placeholder="Keywords"
        onChange={handleKeywordsChange}
        value={keywords}
      >
        <Option key="lesson-plan" value="lesson-plan">
          lesson-plan
        </Option>
        <Option key="case-study" value="case-study">
          case-study
        </Option>
      </Select>

      <MainEditor onUpdate={onUpdate} data={body} />
    </PageLayout>
  );
};

const DEFAULT_BODY: EdjData = {
  blocks: [],
  time: 0,
  version: '0.0.1',
};
export default KnowledgeBaseEditor;
