import {
  useDeleteAdmissionDocument,
  useGetUniversityAdmissionRounds,
  useSaveUserProfile,
} from '@actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import AdmissionRoundsInfoBar from '@components/V4/AdmissionRoundsInfoBar';
import DropZone from '@components/V4/DropZone/DropZone';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserProfileContext } from '@modules/Account/contexts/UserProfileContext';
import {
  AppliedUniversity,
  GOAL_STATUS_OPTIONS,
  GoalStatusTypes,
  SelectOption,
} from '@shared/common';
import { useGetAllMajorNames } from 'apps/agora/src/actions/major.actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import useUniversitySearch from 'apps/agora/src/hooks/useUniversitySearch';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ProfileContext } from '../../../AccountProfile';
import { degreeTypes } from '../../../constants';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';

interface UniversitiesSectionFormProps extends ExamFormProps {
  goalData?: AppliedUniversity;
}

const UniversitiesSectionForm = (props: UniversitiesSectionFormProps) => {
  const {
    isModeAddNew,
    userId,
    contentClassName,
    className,
    goalData,
    setIsEditMode,
    setIsAddNewItem,
    onCancel,
  } = props;

  const { userProfile } = useUserProfileContext();

  const { isExternalViewer, isMentorViewer } = useContext(ProfileContext);

  const formDataSchema = z
    .object({
      name: z.string().min(1, { message: 'Please enter a valid exam name.' }),
      majors: z.array(z.string()).min(1, { message: 'Please select at least one major.' }),
      degree: z.string().min(1, { message: 'Please enter a valid degree type.' }),
      applicationDeadline: z.string().min(1, { message: 'Please enter a valid date.' }),
      status: z.nativeEnum(GoalStatusTypes, { message: 'Please select a valid status type.' }),
      pros: z.string().optional().default(''),
      cons: z.string().optional().default(''),
      academicRequirements: z.string().optional().default(''),

      admissionDocument: isMentorViewer
        ? z.instanceof(File).optional().nullable().default(null)
        : z.instanceof(File).nullable().default(null),
    })
    .superRefine((data, ctx) => {
      const hasExistingDocument = !!goalData?.admissionDocument?.key;

      if (
        [
          GoalStatusTypes.OfferReceived,
          GoalStatusTypes.OfferAccepted,
          GoalStatusTypes.OfferDeclined,
        ].includes(data.status as GoalStatusTypes) &&
        !data.admissionDocument &&
        !hasExistingDocument &&
        !isMentorViewer
      ) {
        ctx.addIssue({
          path: ['admissionDocument'],
          message: 'Admission document is required when status is "received".',
          code: z.ZodIssueCode.custom,
        });
      } else if (
        data.admissionDocument &&
        !['application/pdf'].includes(data.admissionDocument.type)
      ) {
        ctx.addIssue({
          path: ['admissionDocument'],
          message: 'Invalid document file type. Only PDFs are allowed.',
          code: z.ZodIssueCode.custom,
        });
      }
    });

  type FormData = z.infer<typeof formDataSchema>;

  const toast = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const {
    searchQuery,
    setSearchQuery,
    universities,
    isLoading: isUniversitiesLoading,
  } = useUniversitySearch(
    !isModeAddNew && goalData?.newUniversity?._id ? goalData?.newUniversity.name : '',
    isModeAddNew || !!goalData?.newUniversity?._id
  );

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: goalData?.newUniversity?._id ? undefined : goalData?.university.name,
      majors: goalData?.majors?.map((major) => major._id) ?? [],
      degree: goalData?.degree,
      applicationDeadline: goalData?.applicationDeadline ?? '',
      status: goalData?.status,
      pros: goalData?.pros ?? '',
      cons: goalData?.cons ?? '',
      academicRequirements: goalData?.academicRequirements,
      admissionDocument: null,
    },
  });

  const { field: nameField, fieldState: nameFieldState } = useController({
    name: 'name',
    control,
  });

  const { field: degreeField, fieldState: degreeFieldState } = useController({
    name: 'degree',
    control,
  });

  const { field: statusField, fieldState: statusFieldState } = useController({
    name: 'status',
    control,
  });

  const { field: applicationDeadlineField, fieldState: applicationDeadlineFieldState } =
    useController({
      name: 'applicationDeadline',
      control,
    });

  const { field: admissionDocumentField, fieldState: admissionDocumentFieldState } = useController({
    name: 'admissionDocument',
    control,
  });

  const { field: majorsField, fieldState: majorsFieldState } = useController({
    name: 'majors',
    control,
  });

  const shouldAutoSelectUniversityRef = useRef(true);

  useEffect(() => {
    if (
      !!goalData?.newUniversity?._id &&
      !nameField.value &&
      shouldAutoSelectUniversityRef.current
    ) {
      nameField.onChange(goalData?.newUniversity?._id);
      setSearchQuery('');
      shouldAutoSelectUniversityRef.current = false;
    }
  }, [goalData?.newUniversity?._id, universities, nameField.value]);

  const { data: admissionRoundsData } = useGetUniversityAdmissionRounds(
    {
      id: nameField.value,
      graduationYear: userProfile?.graduationYear || moment().year(),
    },
    {
      enabled: !!nameField.value && (isModeAddNew || !!goalData?.newUniversity?._id),
    }
  );

  const { year: admissionRoundsYear, admissionRounds } = admissionRoundsData || {};

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const fileChangeHandler = (files: FileList | null) => {
    const file = files?.[0] ?? null;

    setValue('admissionDocument', file, { shouldValidate: true, shouldTouch: true });
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const { data: majorsList } = useGetAllMajorNames();

  const { mutate: deleteAdmissionDocument, isLoading: isDeletingAdmissionDocument } =
    useDeleteAdmissionDocument(userId, {
      onSuccess: async () => {
        toast.success('Admission document deleted successfully.');
      },
      onError: () => {
        toast.error('Admission document failed to delete.');
      },
    });

  const showDropzone = [
    GoalStatusTypes.OfferReceived,
    GoalStatusTypes.OfferAccepted,
    GoalStatusTypes.OfferDeclined,
  ].includes(statusField.value as GoalStatusTypes);

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const universityData: Omit<AppliedUniversity, 'majors' | 'newUniversity'> & {
        majors: string[];
      } = {
        _id: goalData?._id,
        university: {
          name: data.name,
          _id: data.name,
          type: 'University',
        },
        majors: Array.isArray(data.majors) ? data.majors.map(String) : [],
        applicationDeadline: data.applicationDeadline,
        academicRequirements: data.academicRequirements ?? '',
        degree: data.degree,
        pros: data.pros,
        cons: data.cons,
        status: data.status,
      };

      const formData = new FormData();
      if (data.admissionDocument && showDropzone) {
        formData.append('admissionDocument', data.admissionDocument);
      }
      formData.append('universityData', JSON.stringify({ appliedUniversities: [universityData] }));

      updateUserProfile(formData);
    }
  };

  const touchAllFields = (
    fields: Record<keyof FormData, string | string[] | GoalStatusTypes | File | null>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const removeExistingFileHandler = (admissionDocument: string) => {
    deleteAdmissionDocument({
      appliedUniversity: goalData?._id ?? '',
      admissionDocument,
    });
  };

  const getUniversityOptions = (): SelectOption[] => {
    return (
      universities?.map((university) => ({
        label: university.name,
        value: university._id,
      })) || []
    );
  };

  const universityBlurHandler = () => {
    setSearchQuery('');
    nameField.onBlur();
  };

  return (
    <StudentFormWrapper
      title="University"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      className={className}
      contentClassName={contentClassName}
      icon={isModeAddNew && <p className="text-xsm text-customPrimary font-raleway font-bold">#</p>}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      {isModeAddNew || !!goalData?.newUniversity?._id ? (
        <Select
          size="large"
          label="Universities"
          value={nameField.value}
          filterOption={() => true}
          searchValue={searchQuery}
          onSearch={setSearchQuery}
          onSelect={(value) => nameField.onChange(value)}
          onBlur={universityBlurHandler}
          options={getUniversityOptions()}
          notFoundContent={
            isUniversitiesLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
          }
          placeholder="Search University"
          isRequired
          isValid={!nameFieldState.error}
          isTouched={nameFieldState.isTouched}
          errorText={nameFieldState.error?.message}
        />
      ) : (
        <Input
          isRequired
          type="text"
          id="university"
          label="University"
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          autoFocus
          {...register('name')}
        />
      )}

      {(isModeAddNew || goalData?.newUniversity?._id) && !!nameField.value && (
        <AdmissionRoundsInfoBar
          admissionRounds={admissionRounds}
          admissionRoundsYear={admissionRoundsYear}
        />
      )}

      <div className="gap-6 laptop:flex">
        <Select
          value={majorsField.value}
          onChange={(value) => majorsField.onChange(Array.isArray(value) ? value : [value])}
          onClear={() => majorsField.onChange([])}
          onBlur={majorsField.onBlur}
          options={majorsList?.map((major) => ({
            label: major.name,
            value: major._id ?? '',
          }))}
          mode="multiple"
          label="Majors"
          size="large"
          isRequired
          isValid={!majorsFieldState.error}
          isTouched={majorsFieldState.isTouched}
          errorText={majorsFieldState.error?.message}
        />

        <AgoraBigSelector
          value={degreeField.value}
          onSelect={(value) => degreeField.onChange(value)}
          options={degreeTypes?.map((degree) => ({
            label: degree,
            value: degree,
          }))}
          onBlur={degreeField.onBlur}
          labelKey="label"
          valueKey="value"
          label="Degree"
          isValid={!degreeFieldState.error}
          isTouched={degreeFieldState.isTouched}
          errorText={degreeFieldState.error?.message}
          isRequired
        />
      </div>
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={
            applicationDeadlineField.value ? moment(applicationDeadlineField.value) : undefined
          }
          onChange={(value) =>
            applicationDeadlineField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={applicationDeadlineField.onBlur}
          allowClear={false}
          isRequired
          label="Application Deadline"
          isValid={!applicationDeadlineFieldState.error}
          isTouched={applicationDeadlineFieldState.isTouched}
          errorText={applicationDeadlineFieldState.error?.message}
        />
        <AgoraBigSelector
          value={statusField.value}
          onSelect={(value) => statusField.onChange(value)}
          options={GOAL_STATUS_OPTIONS}
          onBlur={statusField.onBlur}
          labelKey="label"
          valueKey="value"
          label="Status"
          isValid={!statusFieldState.error}
          isTouched={statusFieldState.isTouched}
          errorText={statusFieldState.error?.message}
          isRequired
        />
      </div>
      <div className="gap-6 laptop:flex">
        <TextArea id={'pros'} label={'Pros'} {...register('pros')} />
        <TextArea id={'cons'} label={'Cons'} {...register('cons')} />
      </div>
      <TextArea
        id={'academicRequirements'}
        placeholder={''}
        label={'Academic Requirements'}
        {...register('academicRequirements')}
      />

      {showDropzone && (
        <DropZone
          className="mb-2"
          onChange={fileChangeHandler}
          isRequired={!isMentorViewer}
          isValid={!isMentorViewer && !admissionDocumentFieldState.error}
          isTouched={!isMentorViewer && admissionDocumentFieldState.isTouched}
          label={'Admission Document:'}
          existingFiles={
            goalData?.admissionDocument
              ? [{ label: goalData.admissionDocument.name, value: goalData.admissionDocument.key }]
              : []
          }
          onRemoveExistingFile={removeExistingFileHandler}
          maxFileSize={15 * 1024 * 1024}
        />
      )}
    </StudentFormWrapper>
  );
};

export default UniversitiesSectionForm;
