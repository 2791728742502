import { useGetCitiesLight, useGetTimezonesList, useSaveUserProfile } from '@actions';
import { useAnalytics } from '@contexts/AnalyticsContext';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounce } from '@hooks';
import { City } from '@shared/serverTypes';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ProfileContext } from '../../AccountProfile';
import { ANALYTICS_EVENT_NAMES } from '@shared/enums';
import { countryList, SelectOption, supportedLanguages } from '@shared/common';
import { ReactComponent as LocationIcon } from '@assets/icons/profile-icons/location-icon.svg';
import Select from '@components/V4/Select/Select';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Input from '@components/V4/Inputs/Input';
import Button from '@components/V4/Button';

interface ProfileAsideLocationFormProps extends BasicProps {
  city: string;
  cityRef?: City;
  country: string;
  timezone: string;
  languages: string[];
  userId: string;
  onEditClose: () => void;
}

const additionalInfoSchema = z
  .object({
    country: z.string().min(1, { message: 'Please enter a valid country.' }),
    city: z.string().optional().nullable(),
    cityRef: z.string().optional().nullable(),
    timezone: z.string().min(1, { message: 'Please enter a valid timezone.' }),
    spokenLanguages: z.array(z.string()).min(1, { message: 'Please enter a valid language.' }),
  })
  .superRefine((data, ctx) => {
    if (data.country === 'Romania') {
      if (!data.cityRef) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['cityRef'],
          message: 'Please select a valid city.',
        });
      }
    } else {
      if (!data.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['city'],
          message: 'Please enter a valid city.',
        });
      }
    }
  });

type FormData = z.infer<typeof additionalInfoSchema>;

const ProfileAsideLocationForm = (props: ProfileAsideLocationFormProps) => {
  const { city, cityRef, country, timezone, languages, userId, onEditClose } = props;
  const [citySearch, setCitySearch] = useState('');

  const { isExternalViewer } = useContext(ProfileContext);

  const debouncedCitySearch = useDebounce(citySearch, 500);

  const toast = useToast();
  const query = useQueryClient();

  const { data: timezones, isLoading: isTimezonesLoading } = useGetTimezonesList();
  const { data: cities, isLoading: isLoadingCities } = useGetCitiesLight({
    search: debouncedCitySearch,
    limit: 20,
  });

  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(additionalInfoSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      country,
      city: country === 'Romania' ? undefined : city,
      cityRef: undefined,
      timezone,
      spokenLanguages: languages,
    },
  });

  const { field: countryField, fieldState: countryFieldState } = useController({
    name: 'country',
    control,
  });

  const { field: cityRefField, fieldState: cityRefFieldState } = useController({
    name: 'cityRef',
    control,
  });

  const { field: timezoneField, fieldState: timezoneFieldState } = useController({
    name: 'timezone',
    control,
  });

  const { field: languageField, fieldState: languageFieldState } = useController({
    name: 'spokenLanguages',
    control,
  });

  const closeEditHandler = () => {
    reset({ country, city, timezone, spokenLanguages: languages });

    onEditClose();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');

      closeEditHandler();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        timezone: data.timezone,
        spokenLanguages: data.spokenLanguages,
        city: data.city,
        cityRef: data.cityRef,
        country: data.country,
      });
    }
  };

  const touchAllFields = (
    fields: Record<keyof Omit<FormData, 'city' | 'cityRef'>, string | string[]>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);

    if (!formData.timezone) {
      setValue('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone, {
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, []);

  const getCityOptions = (): SelectOption[] => {
    const mappedCities =
      cities?.map((city) => ({
        label: `${city.name}, ${city.county}`,
        value: city._id,
      })) || [];

    if (!cityRef) return mappedCities;

    const isCityRefInMappedCities = !!mappedCities.find((city) => city.value === cityRef?._id);

    if (!isCityRefInMappedCities) {
      mappedCities.push({
        label: `${cityRef.name}, ${cityRef.county}`,
        value: cityRef._id,
      });
    }

    return mappedCities;
  };

  const cityBlurHandler = () => {
    setCitySearch('');
    cityRefField.onBlur();
  };

  const countryChangeHandler = (value: string | number | null) => {
    countryField.onChange(value);

    if (value === 'Romania') {
      setValue('city', null);
    } else {
      setValue('cityRef', null);
    }
  };

  const shouldAutoSelectCityRef = useRef(true);

  useEffect(() => {
    if (!cityRefField.value && cityRef?._id && shouldAutoSelectCityRef.current) {
      setValue('cityRef', cityRef?._id, { shouldTouch: true, shouldValidate: true });
      shouldAutoSelectCityRef.current = false;
    }
  }, [cityRefField.value, cityRef?._id]);

  return (
    <div className="flex gap-6 py-4 bg-surfaceHover px-6 rounded-xl">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-4 w-full">
          <LocationIcon />

          <h3 className="font-raleway font-bold">Location & Language</h3>
        </div>
        <div className="flex flex-col gap-6">
          <h4 className=" text-customPrimary font-raleway text-xsm font-bold">
            Edit Location & Language
          </h4>
          <Select
            value={countryField.value}
            onSelect={(value) => countryChangeHandler(value)}
            onClear={() => countryField.onChange(undefined)}
            onBlur={countryField.onBlur}
            options={countryList.map((country) => ({
              label: country,
              value: country,
            }))}
            isRequired
            allowClear={false}
            size="large"
            label="Country"
            isValid={!countryFieldState.error}
            isTouched={countryFieldState.isTouched}
            errorText={countryFieldState.error?.message}
          />

          {countryField.value === 'Romania' ? (
            <Select
              label="City"
              value={cityRefField.value}
              filterOption={() => true}
              searchValue={citySearch}
              onSearch={setCitySearch}
              onSelect={(value) => cityRefField.onChange(value)}
              onBlur={cityBlurHandler}
              options={getCityOptions()}
              notFoundContent={
                isLoadingCities ? <LoadingSpinner className="mx-auto" size="normal" /> : null
              }
              placeholder="Search City"
              isRequired
              isValid={!cityRefFieldState.error}
              isTouched={cityRefFieldState.isTouched}
              errorText={cityRefFieldState.error?.message}
            />
          ) : (
            <Input
              isDisabled={!countryField.value}
              type="text"
              autoFocus
              id="city"
              placeholder="E.g. Amsterdam"
              label="City"
              isRequired
              isValid={!errors.city?.message}
              isTouched={touchedFields.city}
              errorText={errors.city?.message}
              {...register('city')}
            />
          )}

          <Select
            value={timezoneField.value}
            onSelect={(value) => timezoneField.onChange(value)}
            onClear={() => timezoneField.onChange('')}
            onBlur={timezoneField.onBlur}
            options={timezones?.map((timezone) => ({
              label: timezone,
              value: timezone,
            }))}
            isLoading={isTimezonesLoading}
            label="Timezone"
            size="large"
            isRequired
            isValid={!timezoneFieldState.error}
            isTouched={timezoneFieldState.isTouched}
            errorText={timezoneFieldState.error?.message}
          />

          <Select
            value={languageField.value}
            onChange={(value) => languageField.onChange(Array.isArray(value) ? value : [value])}
            onClear={() => languageField.onChange([])}
            onBlur={languageField.onBlur}
            options={supportedLanguages.map((language) => ({
              label: language,
              value: language,
            }))}
            mode="multiple"
            label="Spoken Languages"
            size="large"
            isRequired
            isValid={!languageFieldState.error}
            isTouched={languageFieldState.isTouched}
            errorText={languageFieldState.error?.message}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button buttonText="Cancel" variant="secondary" onMouseDown={closeEditHandler} />
          <Button
            buttonText="Save"
            variant="primary"
            isLoading={isLoading}
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileAsideLocationForm;
