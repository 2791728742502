import Button from '@components/V4/Button';
import { BasicProps } from 'apps/agora/src/utils/types';

export interface ActionButton {
  buttonText: string;
  onClick: () => void;
}

interface ModalSectionProps extends BasicProps {
  sectionTitle: string;
  buttonsArray?: ActionButton[];
}

const ModalSection = (props: ModalSectionProps) => {
  const { sectionTitle, buttonsArray, children, className } = props;

  return (
    <section className={className}>
      <div className="flex justify-between mb-4">
        <p className="text-xs font-bold h-6">{sectionTitle}</p>

        <div className="flex gap-2">
          {buttonsArray?.map((button, index) => (
            <Button
              key={`${button.buttonText} - ${index}`}
              buttonText={button.buttonText}
              onClick={button.onClick}
            />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};

export default ModalSection;
