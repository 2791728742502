import Button from '@components/V4/Button';
import RangePicker from '@components/V4/RangePicker/RangePicker';
import Select from '@components/V4/Select/Select';
import TriStateToggle from '@components/V4/TriStateToggle/TriStateToggle';
import { SearchIcon } from '@heroicons/react/outline';
import { TriState } from '@shared/common';
import { getEndDateValue, getStartDateValue } from 'apps/agora/src/utils/helpers';
import { Moment } from 'moment';
import moment from 'moment';
import React from 'react';

interface UsersFilterProps {
  _id: string;
  fullName: string;
}

interface MajorsHeaderProps {
  majorSearch?: string;
  showDeleted: TriState;
  setMajorSearch: React.Dispatch<React.SetStateAction<string>>;
  createdByOptions?: UsersFilterProps[];
  searchParams: any;
  setSearchParams: (data: Partial<any>) => void;
  openMajorModalHandler: (majorId?: string) => void;
}

const MajorsHeader = (props: MajorsHeaderProps) => {
  const {
    setMajorSearch,
    showDeleted,
    majorSearch,
    searchParams,
    setSearchParams,
    createdByOptions,
    openMajorModalHandler,
  } = props;

  const rangePickerChangeHandler = (dates: [Moment | null, Moment | null]) => {
    const start = dates?.[0] ? getStartDateValue(dates?.[0]) : null;
    const end = dates?.[1] ? getEndDateValue(dates?.[1]) : null;

    setSearchParams({
      rangeStart: start ? start.toISOString() : null,
      rangeEnd: end ? end.toISOString() : null,
      page: 1,
    });
  };
  const userFilterChangeHandler = (userId: string) => {
    setSearchParams({
      user: userId,
    });
  };

  const getRangePickerValue = (): [Moment, Moment] | null => {
    if (!searchParams.rangeStart || !searchParams.rangeEnd) return null;

    return [moment(searchParams.rangeStart), moment(searchParams.rangeEnd)];
  };

  const triStateChangeHandler = (value: TriState) => {
    setSearchParams({ showDeleted: value, page: 1 });
  };

  return (
    <section className="flex flex-col gap-6 bg-surfaceObject px-18 pt-8 pb-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold">Majors</h3>
        <div className="flex justify-end">
          <Button buttonText="Add Major" onClick={() => openMajorModalHandler()} />
        </div>
      </div>
      <div className="relative w-full flex items-center bg-surfaceObject rounded-3xl">
        <input
          type="text"
          className="bg-transparent border rounded-3xl w-full py-1.5 px-6 text-sm text-white border-customGrey focus:border-customGrey placeholder:text-customGrey placeholder:opacity-50"
          placeholder="Search by Major Name"
          value={majorSearch}
          onChange={(e) => setMajorSearch(e.target.value)}
        />

        <SearchIcon className="w-6 h-6 absolute right-6 text-customGrey" />
      </div>

      <div className="flex gap-4">
        <Select
          className="w-1/6 max-w-64"
          label="Created By"
          value={searchParams?.user}
          options={createdByOptions?.map((user) => ({
            label: user.fullName,
            value: user._id,
          }))}
          onSelect={(value) => userFilterChangeHandler(value as string)}
          placeholder="Search User"
          variant="secondary"
        />
        <RangePicker
          variant="secondary"
          label="Created At Date Range"
          className="w-64"
          value={getRangePickerValue()}
          onChange={rangePickerChangeHandler}
        />

        <TriStateToggle
          title="Filter Majors:"
          options={[
            { label: 'Deleted', value: 'checked' },
            { label: 'Active', value: 'unchecked' },
            { label: 'All', value: 'indeterminate' },
          ]}
          value={showDeleted}
          onChange={triStateChangeHandler}
        />
      </div>
    </section>
  );
};

export default MajorsHeader;
