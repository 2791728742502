import { MutateOptions, UseMutationOptions, UseQueryOptions, useQueryClient } from 'react-query';
import { ApiData, ApiDescription } from '@shared/clientTypes';
import useAuthQuery from '../hooks/action-hooks/useAuthQuery';
import useAuthQueryWithParams from '../hooks/action-hooks/useAuthQueryWithParams';
import useAuthAxios from '../hooks/action-hooks/useAuthAxios';
import useAuthMutation from '../hooks/action-hooks/useAuthMutation';

const maxRetries = 1;

export function genericQuery<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  options?: UseQueryOptions<AD['response']>
) {
  return (optionsQuery?: UseQueryOptions<AD['response']>) =>
    useAuthQuery(desc.endpoint, {
      ...options,
      ...optionsQuery,
      retry: (failureCount, error: any) => {
        if (failureCount >= maxRetries) {
          return false;
        }

        return !(
          error?.response?.status === 400 ||
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404
        );
      },
    });
}

export function genericQueryWithId<AD extends ApiData<unknown, unknown>>(desc: ApiDescription) {
  return (id: string | undefined, options?: UseQueryOptions<AD['response']>) =>
    useAuthQuery([desc.endpoint, id], {
      enabled: !!id,
      ...options,
      retry: (failureCount, error: any) => {
        if (failureCount >= maxRetries) {
          return false;
        }

        return !(
          error?.response?.status === 400 ||
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404
        );
      },
    });
}

export function genericQueryWithParams<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  options?: UseQueryOptions<AD['response']>,
  noCache?: boolean
) {
  return (params?: AD['payload'], optionsQuery?: UseQueryOptions<AD['response']>) =>
    useAuthQueryWithParams(
      desc.endpoint,
      params,
      {
        ...options,
        ...optionsQuery,
        retry: (failureCount, error: any) => {
          if (failureCount >= maxRetries) {
            return false;
          }

          return !(
            error?.response?.status === 400 ||
            error?.response?.status === 401 ||
            error?.response?.status === 403 ||
            error?.response?.status === 404
          );
        },
      },
      noCache
    );
}

/**
 *
 * @param desc
 * @param invalidations A list of strings on which queryClient.invalidateQueries to be called
 * @returns
 */
export function genericMutation<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  ...invalidations: string[]
) {
  return (options?: UseMutationOptions<AD['response'], unknown, AD['payload']>) => {
    const [actionCreator] = useAuthAxios();
    const action = actionCreator(desc.endpoint, desc.method);

    const queryClient = useQueryClient();

    return useAuthMutation<AD['payload'], AD['response']>(action, {
      ...options,
      onSettled: (data, error, variable, context) => {
        options?.onSettled && options.onSettled(data, error, variable, context);

        invalidations.forEach((invalidation) => {
          queryClient.invalidateQueries(invalidation);
        });
      },
    });
  };
}

/**
 *
 * @param desc
 * @param invalidations A list of strings on which queryClient.invalidateQueries to be called
 * @returns
 */
export function genericMutationWithId<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  ...invalidations: string[]
) {
  return (
    ids: string | string[],
    options?: UseMutationOptions<AD['response'], unknown, AD['payload']>
  ) => {
    const [actionCreator] = useAuthAxios();
    const queryClient = useQueryClient();

    const allIds = Array.isArray(ids) ? ids : [ids];

    let i = 0;
    const url = desc.endpoint.replace(/:\w+/g, () => {
      if (i >= allIds.length) {
        throw new Error(
          `Not enough IDs provided for endpoint placeholders. 
          Endpoint: ${desc.endpoint}, Provided: [${allIds.join(', ')}]`
        );
      }
      return allIds[i++];
    });

    if (i < allIds.length) {
      console.warn(
        `Unused IDs in genericMutationWithId: the endpoint has fewer placeholders than the IDs provided. Endpoint: ${desc.endpoint}`
      );
    }

    const action = actionCreator(url, desc.method);

    return useAuthMutation<AD['payload'], AD['response']>(action, {
      ...options,
      onSettled: (data, error, variable, context) => {
        options?.onSettled?.(data, error, variable, context);

        invalidations.forEach((invalidation) => {
          const query = invalidation.includes(':') ? [invalidation, ...allIds] : [invalidation];

          queryClient.invalidateQueries(query);
        });
      },
    });
  };
}

export function genericMutationWithParams<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  ...invalidations: string[]
) {
  return (options?: UseMutationOptions<AD['response'], unknown, AD['payload']>) => {
    const [actionCreator] = useAuthAxios();
    const queryClient = useQueryClient();

    return useAuthMutation<AD['payload'], AD['response']>(
      (payload: AD['payload']) => {
        const queryParams = new URLSearchParams(payload as Record<string, string>).toString();
        const url = `${desc.endpoint}?${queryParams}`;
        const action = actionCreator(url, desc.method);

        return action(payload);
      },
      {
        ...options,
        onSettled: (data, error, variables, context) => {
          options?.onSettled && options.onSettled(data, error, variables, context);

          invalidations.forEach((invalidation) => {
            queryClient.invalidateQueries(invalidation);
          });
        },
      }
    );
  };
}

export function genericMutationWithIdParams<AD extends ApiData<unknown, unknown>>(
  desc: ApiDescription,
  ...invalidations: string[]
) {
  return function useHookWithIds(
    options?: UseMutationOptions<AD['response'], unknown, { ids: string[]; payload: AD['payload'] }>
  ) {
    const queryClient = useQueryClient();
    const [actionCreator] = useAuthAxios();

    const mutation = useAuthMutation<{ ids: string[]; payload: AD['payload'] }, AD['response']>(
      async ({ ids, payload }) => {
        let i = 0;
        const url = desc.endpoint.replace(/:\w+/g, () => {
          if (i >= ids.length) {
            throw new Error(
              `Not enough IDs provided for endpoint placeholders. 
               Endpoint: ${desc.endpoint}, Provided: [${ids.join(', ')}]`
            );
          }
          return ids[i++];
        });

        if (i < ids.length) {
          console.warn(
            `Unused IDs in genericMutationWithId: 
             the endpoint has fewer placeholders than the IDs provided. 
             Endpoint: ${desc.endpoint}, Provided: [${ids.join(', ')}]`
          );
        }

        const axiosRequest = actionCreator(url, desc.method);
        return axiosRequest(payload);
      },
      {
        ...options,
        onSettled: (data, error, variables, context) => {
          options?.onSettled?.(data, error, variables, context);

          if (variables?.ids) {
            invalidations.forEach((invalidation) => {
              queryClient.invalidateQueries([invalidation]);
            });
          }
        },
      }
    );

    const mutateWithIds = (
      ids: string[],
      payload: AD['payload'],
      config?: MutateOptions<AD['response'], unknown, { ids: string[]; payload: AD['payload'] }>
    ) => {
      mutation.mutate({ ids, payload }, config);
    };

    return {
      ...mutation,
      mutate: mutateWithIds,
    };
  };
}
