import Modal from '@components/V4/Modal/Modal';
import { useState } from 'react';
import CreateExamForm from './CreateExamForm';
import ExamModalDetails from './ExamModalDetails';

interface ExamModalProps {
  examId?: string;
  onClose: () => void;
}

const ExamModal = (props: ExamModalProps) => {
  const { examId, onClose } = props;
  const [exam, setExam] = useState(examId);
  const [isCreateNewExam, setIsCreateNewExam] = useState(!examId);

  const closeIsCreateNewExamHandler = (examId?: string) => {
    setIsCreateNewExam(false);

    if (examId) setExam(examId);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <Modal.Header title={!isCreateNewExam ? 'Exam Details' : 'Create Exam'} />

      {!isCreateNewExam && exam ? (
        <ExamModalDetails examId={exam} />
      ) : (
        <CreateExamForm closeModalHandler={onClose} onCloseEditMode={closeIsCreateNewExamHandler} />
      )}
    </Modal>
  );
};

export default ExamModal;
