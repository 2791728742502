// TODO: Delete this component after <NextMeeting/> refactor

import NextMeeting, { NextMeetingDateToShow } from '@components/V3/NextMeeting';
import { MeetingWithHomework } from '@shared/common';
import React from 'react';

interface Props {
  meeting: MeetingWithHomework;
  refetch?: () => void;
  isUserProfileView?: boolean;
  isMentor?: boolean;
  dateToShow?: NextMeetingDateToShow;
}

const MeetingNeedingFeedbackMentor: React.FC<Props> = (props: Props) => {
  const { meeting, isMentor, dateToShow, isUserProfileView } = props;
  const { students } = meeting;

  const name = students[0]?.fullName || '';

  return (
    <NextMeeting
      isUserProfileView={isUserProfileView}
      meetingNeedingFeedback={true}
      meeting={meeting}
      otherName={name}
      isMentor={isMentor}
      hideJoinBtn
      dateToShow={dateToShow}
    />
  );
};

export default MeetingNeedingFeedbackMentor;
