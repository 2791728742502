import { UseSearchFlagsDTO } from '@shared/clientTypes/DTOs/FlagsDTO';
import { ApiDescription, ApiData, RequestsMethod, Flag, CreateNewFlag } from '../clientTypes';

const BASE_URL = '/flags';

export const searchFlags: ApiDescription = {
  endpoint: `${BASE_URL}/search`,
  method: RequestsMethod.Get,
};

export type SearchFlags = ApiData<
  {
    page: number;
    limit: number;
    sort: 'asc' | 'desc';
    search?: string;
    isActive?: string;
    flagType?: string;
    startRange?: string;
    endRange?: string;
    studentId?: string;
    mentorId?: string;
    courseId?: string;
    userStatus?: string;
  },
  UseSearchFlagsDTO
>;

export const createFlag: ApiDescription = {
  endpoint: `${BASE_URL}`,
  method: RequestsMethod.Post,
};

export type CreateFlag = ApiData<CreateNewFlag, Flag>;

export const dismissFlag: ApiDescription = {
  endpoint: `${BASE_URL}/dismiss/:id`,
  method: RequestsMethod.Put,
};

export type DismissFlag = ApiData<{ closedBy: string }, Flag>;

export const deleteFlag: ApiDescription = {
  endpoint: `${BASE_URL}/:id`,
  method: RequestsMethod.Delete,
};

export type DeleteFlag = ApiData<never, unknown>;

export const getNoOfFlagsByFlagTypes: ApiDescription = {
  endpoint: `${BASE_URL}/get-no-of-flags-by-flagTypes`,
  method: RequestsMethod.Get,
};

export type GetNoOfFlagsByFlagTypes = ApiData<
  {
    search?: string;
    isActive?: string;
    flagType?: string;
    startRange?: string;
    endRange?: string;
    studentId?: string;
    mentorId?: string;
    courseId?: string;
  },
  any
>;

export const searchFlagsForLinearChart: ApiDescription = {
  endpoint: `${BASE_URL}/search-for-linear-chart`,
  method: RequestsMethod.Get,
};

export type SearchFlagsForLinearChart = ApiData<
  {
    search?: string;
    isActive?: string;
    flagType?: string;
    startRange?: string;
    endRange?: string;
    studentId?: string;
    mentorId?: string;
    courseId?: string;
  },
  any
>;

export const closeMultipleFlags: ApiDescription = {
  endpoint: `${BASE_URL}/close-multiple`,
  method: RequestsMethod.Put,
};

export type CloseMultipleFlags = ApiData<{ flagIds: string[]; closedBy: string }, unknown>;
