import { useDeleteUniversity, useGetUniversities, useRestoreUniversity } from '@actions';
import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import Table, { SortOrder } from '@components/V4/Table/Table';
import UniversityModal from '@modules/Admin/components/UniversityModal/UniversityModal';
import { UniversitySizeTypes } from '@shared/constants';
import { Popconfirm } from 'antd';
import useToast from 'apps/agora/src/hooks/useToast';
import { getValueOrNA } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useState } from 'react';

interface UniversitiesTableProps extends BasicProps {
  searchParams: any;
  page: string;
  sortBy: string | null;
  sortOrder: SortOrder | null;
  limit: string | null;
  debouncedSearch: string;
  setSearchParams: (data: any) => void;
}

const UniversitiesTable = (props: UniversitiesTableProps) => {
  const [showModal, setShowModal] = useState(false);
  const [universityId, setUniversityId] = useState<string>();

  const { page, sortBy, sortOrder, limit, searchParams, debouncedSearch, setSearchParams } = props;

  const toast = useToast();

  const { data: universityData, isLoading: isLoadingUniversityData } = useGetUniversities({
    year: searchParams.year,
    limit: searchParams.limit,
    page: searchParams.page,
    sortBy: searchParams.sortBy,
    sortOrder: searchParams.sortOrder,
    region: searchParams.region,
    country: searchParams.country,
    search: debouncedSearch,
    isActive: searchParams.isActive,
    isUniversityQSSource: searchParams.isUniversityQSSource,
    isRankedForSelectedYear: searchParams.isRankedForSelectedYear,
  });

  const { mutate: deleteUniversity } = useDeleteUniversity({
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.error?.message ??
        'Failed to delete university. Please try again later.';

      toast.error(errorMessage);
    },
    onSuccess: () => toast.success('Successfully deleted university'),
  });

  const { mutate: restoreUniversity } = useRestoreUniversity({
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.error?.message ??
        'Failed to restore university. Please try again later.';

      toast.error(errorMessage);
    },
    onSuccess: () => toast.success('Successfully restore university'),
  });

  const currentPageChangeHandler = (page: number) => {
    setSearchParams({
      page,
    });
  };

  const pageSizeChangeHandler = (size: number) => {
    setSearchParams({
      limit: size,
      page: 1,
    });
  };

  const sortHandler = (field: string, order: SortOrder) => {
    setSearchParams({
      sortBy: order ? field : null,
      sortOrder: order || null,
      page: 1,
    });
  };

  const openModalHandler = (universityId: string) => {
    setShowModal(true);
    setUniversityId(universityId);
  };

  return (
    <div className="mb-20 mt-8">
      {isLoadingUniversityData ? (
        <div className="h-24 flex items-center justify-center text-customGrey">Loading...</div>
      ) : (
        <Table
          className="px-18"
          pagination={{
            pageSize: limit !== null ? parseInt(limit) : 10,
            total: universityData?.total,
            currentPage: page !== null ? parseInt(page) : 1,
            onCurrentPageChange: currentPageChangeHandler,
            onPageSizeChange: pageSizeChangeHandler,
          }}
        >
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell
                onSort={(order) => sortHandler('name', order)}
                sortOrder={sortBy === 'name' ? sortOrder : undefined}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                onSort={(order) => sortHandler('overallRank', order)}
                sortOrder={sortBy === 'overallRank' ? sortOrder : undefined}
              >
                Overall Rank
              </Table.HeaderCell>
              <Table.HeaderCell>Country</Table.HeaderCell>
              <Table.HeaderCell>Region</Table.HeaderCell>
              <Table.HeaderCell
                onSort={(order) => sortHandler('size', order)}
                sortOrder={sortBy === 'size' ? sortOrder : undefined}
              >
                Size
              </Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!universityData?.universities?.length && (
              <tr>
                <td colSpan={100} className="h-64">
                  <EmptyStateCard
                    icon={<BlockIcon className="text-customGrey" />}
                    text={'No results.'}
                  />
                </td>
              </tr>
            )}
            {!!universityData?.universities?.length &&
              universityData?.universities?.map((university) => (
                <Table.Row key={university._id}>
                  <Table.Cell>{getValueOrNA(university.name)}</Table.Cell>
                  <Table.Cell>{getValueOrNA(university.rank)}</Table.Cell>
                  <Table.Cell>{getValueOrNA(university.country) ?? 'n/a'}</Table.Cell>
                  <Table.Cell>{getValueOrNA(university.region) ?? 'n/a'}</Table.Cell>
                  <Table.Cell>
                    {getValueOrNA(UniversitySizeTypes?.[university.classification?.size]?.name)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex gap-4">
                      {university.isDeleted ? (
                        <button
                          className="text-xs text-customGrey underline"
                          onClick={() => restoreUniversity([university?._id], {})}
                        >
                          Restore
                        </button>
                      ) : (
                        <>
                          <button
                            className="text-xs text-customGrey underline"
                            onClick={() => openModalHandler(university._id)}
                          >
                            View
                          </button>
                          {!!university.isAddedByUser && (
                            <Popconfirm
                              onConfirm={() => deleteUniversity([university?._id], {})}
                              title={
                                <section className="max-w-110 flex flex-col gap-6">
                                  <p>Are you sure you want to delete this University?</p>
                                </section>
                              }
                            >
                              <button className="text-xs text-customRed underline">Delete</button>
                            </Popconfirm>
                          )}
                        </>
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}

      {showModal && !!universityId && (
        <UniversityModal universityId={universityId} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default UniversitiesTable;
