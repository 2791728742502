import { UserProfileDTO } from '@shared/clientTypes/DTOs/UserDTO';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ProfileBanner from '../../ProfileBanner/ProfileBanner';
import { ProfileContext } from '../AccountProfile';
import CapacityTab from './CapacityTab/CapacityTab';
import CoursesTab from './CoursesTab/CoursesTab';
import ExamsTab from './ExamsTab/ExamsTab';
import GoalsTab from './GoalsTab/GoalsTab';
import PaymentsTab from './PaymentsTab/PaymentsTab';
import ResumeTab from './ResumeTab/ResumeTab';
import SessionsTab from './SessionsTab/SessionsTab';
import TabItem from './TabItem';
import { UserProfileContext } from '@modules/Account/contexts/UserProfileContext';

interface ProfileMainProps {
  userProfile?: Partial<UserProfileDTO>;
  isMentorProfile: boolean;
}

const baseTabs = ['Goals', 'Exams', 'Resume'];

const ProfileMain = (props: ProfileMainProps) => {
  const { userProfile, isMentorProfile } = props;

  const { isMentorViewer, isLoading } = useContext(ProfileContext);

  const { trackEvent } = useAnalytics();

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const queryTab = queryParams.get('tab');

  const [tabs, setTabs] = useState(baseTabs);
  const [currentTab, setCurrentTab] = useState(queryTab ?? (isMentorProfile ? 'Resume' : tabs[0]));

  useEffect(() => {
    if (isMentorProfile) {
      setTabs(['Resume', 'Courses', 'Capacity', 'Payments']);
    } else if (isMentorViewer) {
      setTabs([...baseTabs, 'Courses', 'Sessions']);
    } else {
      setTabs(baseTabs);
    }
  }, [isMentorViewer]);

  const displayTabPageByCurrentTab = () => {
    if (!userProfile && !isLoading) return;

    switch (currentTab) {
      case 'Resume':
        return (
          <ResumeTab
            userId={userProfile?._id ?? ''}
            userProfile={userProfile}
            isMentorProfile={isMentorProfile}
          />
        );
      case 'Exams':
        return (
          <ExamsTab
            userId={userProfile?._id ?? ''}
            exams={userProfile?.exams ?? []}
          />
        );
      case 'Goals':
        return <GoalsTab userId={userProfile?._id ?? ''} userProfile={userProfile} />;
      case 'Courses':
        return <CoursesTab userProfile={userProfile} isMentorProfile={isMentorProfile} />;
      case 'Sessions':
        return <SessionsTab userProfile={userProfile} />;
      case 'Payments':
        return <PaymentsTab mentorData={userProfile?.mentorData} userId={userProfile?._id ?? ''} />;
      case 'Capacity':
        return <CapacityTab userId={userProfile?._id ?? ''} userProfile={userProfile} />;
      default:
        return null;
    }
  };

  const tabItemHandler = (tabName: string) => {
    if (queryParams.get('tab')) {
      queryParams.delete('tab');

      history.push({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }
    trackEvent(ANALYTICS_EVENT_NAMES.PROFILE_TAB_CHANGE);
    setCurrentTab(tabName);
  };

  return (
    <section className="flex flex-col gap-4 tablet:w-2/3">
      <UserProfileContext.Provider value={{ userProfile }}>
        {!isMentorViewer && <ProfileBanner hasSubBanner={false} />}
        <section className="flex flex-col bg-surfaceObject rounded-lg overflow-hidden ">
          <nav>
            <ul className="flex flex-wrap justify-between bg-surfaceBackground border-b border-b-customPrimary tablet:justify-start">
              {tabs.map((tab) => (
                <TabItem
                  onClick={() => tabItemHandler(tab)}
                  key={tab}
                  tabName={tab}
                  isActive={tab === currentTab}
                />
              ))}
            </ul>
          </nav>

          <section className="p-4 tablet:p-6 laptop:p-8">{displayTabPageByCurrentTab()}</section>
        </section>
      </UserProfileContext.Provider>
    </section>
  );
};

export default ProfileMain;
