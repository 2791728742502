import { ReactComponent as EmptyProfilePic } from '@assets/icons/V4/empty-profile-pic.svg';
import MentorPlaceholderAvatar from '@assets/icons/mentor-placeholder-avatar.png';
import USBigPrimary from '@assets/images/us_big_1.jpg';
import USBigPurple from '@assets/images/us_big_2.jpg';
import USBigGold from '@assets/images/us_big_3.jpg';
import { Enrollment } from '@shared/clientTypes';
import moment from 'moment';
import { getCourseProgressPercentage, mergeClassNames } from '../../utils/helpers';
import Tooltip from './Tooltip';
import { useLoginAndRedirectToCourse } from '@actions';
import useToast from '../../hooks/useToast';

interface CourseProgressCardProps {
  enrollment: Partial<Enrollment>;
  className?: string;
  onClick?: () => void;
}

const CourseProgressCard = (props: CourseProgressCardProps) => {
  const { enrollment, className, onClick } = props;

  const { course } = enrollment;

  const toast = useToast();

  const hasLMSCourseID = !!course?.lmsCourseId;

  const { mutate: redirectToLMS } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
    onError: () => {
      toast.error('Failed to redirect to LMS!');
    },
  });

  const progressPercentage = getCourseProgressPercentage(
    enrollment.noOfCompletedSessions,
    enrollment.noOfSessions,
    undefined,
    0
  );

  const getCourseBackground = () => {
    if (course?.headerImage?.['470x320']) {
      return course?.headerImage['470x320'];
    } else if (
      course?.title?.toLowerCase()?.includes('coach') ||
      course?.title?.toLowerCase()?.includes('training')
    ) {
      return USBigPrimary;
    } else if (course?.title?.toLowerCase()?.includes('academic')) {
      return USBigPurple;
    } else {
      return USBigGold;
    }
  };

  const courseClickHandler = () => {
    if (!course?.lmsCourseId) return;
    redirectToLMS({ courseId: course.lmsCourseId });
    onClick?.();
  };

  const isCourseCompleted = enrollment.noOfCompletedSessions === enrollment.noOfSessions;

  return (
    <Tooltip text={!hasLMSCourseID ? 'No course materials available.' : null}>
      <div
        className={mergeClassNames(
          'group flex flex-col gap-3 rounded-xl cursor-pointer bg-surfaceObject min-h-72',
          { 'cursor-default': !hasLMSCourseID },
          className
        )}
        onClick={courseClickHandler}
      >
        <div className="overflow-hidden h-36 rounded-xl relative">
          <img
            className="object-cover object-center w-full h-full"
            src={getCourseBackground()}
            alt="Background"
            crossOrigin="anonymous"
          />

          <div className="absolute flex items-center justify-center bottom-2 left-2 py-1 px-2 rounded-full bg-customGrey bg-opacity-35">
            <span className="text-xxs text-white">{`${
              enrollment?.startDate ? moment(enrollment.startDate).format("MMM 'YY") : 'N/A'
            } - ${
              enrollment?.estimatedEndDate
                ? moment(enrollment.estimatedEndDate).format("MMM 'YY")
                : 'N/A'
            }`}</span>
          </div>
        </div>

        <div className="flex flex-col gap-3 px-3 pb-3">
          <p
            className={mergeClassNames(
              'flex items-end font-raleway font-bold leading-5 min-h-9',
              hasLMSCourseID && {
                'group-hover:text-customGold': true,
                'group-hover:text-customPrimary':
                  course?.title?.toLowerCase()?.includes('coach') ||
                  course?.title?.toLowerCase()?.includes('training'),
                'group-hover:text-customLila': course?.title?.toLowerCase()?.includes('academic'),
              }
            )}
          >
            {course?.title}
          </p>

          {!(enrollment?.course?.isMentorless || !enrollment.noOfSessions) && (
            <>
              <div className="flex gap-3 items-center">
                {enrollment?.mentor?._id ? (
                  <img
                    src={MentorPlaceholderAvatar}
                    alt="mentor"
                    className="h-7 w-7 rounded-full"
                  />
                ) : (
                  <EmptyProfilePic className="text-customGrey" />
                )}
                <p className="text-xxs text-customGrey font-semibold">
                  {enrollment?.mentor?._id ? enrollment?.mentor?.fullName : 'Waiting for Mentor'}
                </p>
              </div>
              <div className="relative w-full h-2 rounded-full bg-customGrey bg-opacity-50 min-w-24">
                <div
                  style={{
                    width: getCourseProgressPercentage(
                      enrollment?.noOfCompletedSessions,
                      enrollment?.noOfSessions,
                      enrollment?.noOfScheduledOrInProgressSessions
                    ),
                  }}
                  className={mergeClassNames(`rounded-full h-full bg-opacity-35 bg-customGold`, {
                    'bg-customPrimary':
                      course?.title?.toLowerCase()?.includes('coach') ||
                      course?.title?.toLowerCase()?.includes('training'),
                    'bg-customPurple': course?.title?.toLowerCase()?.includes('academic'),
                  })}
                />
                <div
                  style={{
                    width: getCourseProgressPercentage(
                      enrollment?.noOfCompletedSessions,
                      enrollment?.noOfSessions
                    ),
                  }}
                  className={mergeClassNames(
                    `absolute top-0 left-0 rounded-full h-full bg-customGold`,
                    {
                      'bg-customPrimary':
                        course?.title?.toLowerCase()?.includes('coach') ||
                        course?.title?.toLowerCase()?.includes('training'),
                      'bg-customPurple': course?.title?.toLowerCase()?.includes('academic'),
                    }
                  )}
                />
              </div>

              <div className="flex gap-1.5 items-center">
                <p className="text-xxs text-customGrey">
                  <span className="font-bold">{progressPercentage}</span> Completed
                </p>
                <p className="text-customGrey"> • </p>
                {isCourseCompleted ? (
                  <p className="text-xxs text-customGrey">
                    <span className="font-bold">{enrollment.noOfCompletedSessions}</span>{' '}
                    {`Session${enrollment.noOfSessions > 1 ? 's' : ''}`}
                  </p>
                ) : (
                  <Tooltip text="Completed Sessions / Scheduled or Ongoing Sessions / Total No. of Sessions">
                    <p className="text-xxs text-customGrey">
                      <span className="font-bold">
                        {enrollment.noOfCompletedSessions} /{' '}
                        {enrollment.noOfScheduledOrInProgressSessions} / {enrollment.noOfSessions}
                      </span>{' '}
                      Sessions
                    </p>
                  </Tooltip>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default CourseProgressCard;
