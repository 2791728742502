import Button from '@components/V4/Button';
import { useContext, useState } from 'react';
import { MentorDetailsPageContext } from '../MentorDetailsPage';
import MentorDetailsForm from './MentorDetailsForm';
import { getUserLocation } from 'apps/agora/src/utils/helpers';

const LENGTH_OF_BIOGRAPHY_DISPLAY = 200;

const MentorDetailsSection = () => {
  const [isEditMode, setIsEditMode] = useState(false);

  const { mentor } = useContext(MentorDetailsPageContext);

  const getBiographyValue = () => {
    if (!mentor?.mentorData?.biography?.length) return 'n/a';

    const prefix = mentor?.mentorData?.biography?.length > LENGTH_OF_BIOGRAPHY_DISPLAY ? '...' : '';

    return mentor?.mentorData?.biography.slice(0, LENGTH_OF_BIOGRAPHY_DISPLAY) + prefix;
  };

  const mentorDetailsArray = [
    [
      { label: 'Mail:', value: mentor?.email },
      {
        label: 'Phone:',
        value: `${mentor?.phoneNumber?.countryCode ?? ''}${mentor?.phoneNumber?.number ?? ''}`,
      },
    ],
    [
      {
        label: 'Bio:',
        value: getBiographyValue(),
      },
    ],
    [
      {
        label: 'Location:',
        value: getUserLocation(mentor),
      },
      { label: 'Timezone:', value: mentor?.timezone },
      { label: 'Language:', value: mentor?.spokenLanguages?.join(', ') },
    ],
  ];

  const editModeChangeHandler = () => {
    setIsEditMode((editMode) => !editMode);
  };

  return isEditMode ? (
    <MentorDetailsForm onCloseEditMode={editModeChangeHandler} />
  ) : (
    <>
      <div className="flex justify-between mb-2">
        <p className="text-xs font-bold">Contact Info</p>
        <Button buttonText="Edit" onClick={editModeChangeHandler} />
      </div>
      <div className="w-full flex gap-x-8 justify-between">
        {mentorDetailsArray.map((mentorDetails, index) => (
          <div key={`column-${index}`} className="flex flex-col gap-2 ">
            {mentorDetails.map((mentorDetail, index) => (
              <span key={`row-${index}`} className="flex text-customGrey font-raleway text-xs">
                <span className="min-w-max">{mentorDetail.label}</span>
                <span className="text-white ml-2 break-words">{mentorDetail.value ?? 'n/a'}</span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default MentorDetailsSection;
