import { DeleteFilled, SearchOutlined } from '@ant-design/icons';
import { CreateUserV2 } from '@shared/apis/auth.apis';
import { useGetAllCourses } from '@actions';
import { CreateNewUserResponse } from '@shared/clientTypes';
import SecondaryButton from '@utils/SecondaryButton';
import { Button, Checkbox, List, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SelectValue } from 'antd/lib/select';
import React, { useState, useEffect } from 'react';
import { UseMutateFunction } from 'react-query';
import { COLORS } from '../../../v3/global';
import './user-permissions-modal.styles.scss';

const { Option } = Select;

type Props = {
  userData: any;
  createUserV2?: UseMutateFunction<CreateNewUserResponse, Error, CreateUserV2['payload']>;
  currentPage?: string;
  refetchUserData: () => void;
  editError?: string | undefined;
  userPermissions?: any;
  editUserPermissions?: any;
};

const UserPermissionsPage = ({
  userData,
  createUserV2,
  currentPage,
  refetchUserData,
  editError,
  userPermissions,
  editUserPermissions,
}: Props) => {
  const { data: courses } = useGetAllCourses();

  const [isStudentChecked, setIsStudentChecked] = useState<boolean | undefined>(
    (userPermissions?.student || userData.userType === 'STUDENT') ?? false
  );
  const [isMentorChecked, setIsMentorChecked] = useState<boolean | undefined>(
    (userPermissions?.mentor || userData.userType === 'MENTOR') ?? false
  );
  const [isCourseCreatorChecked, setIsCourseCreatorChecked] = useState<boolean | undefined>(
    userPermissions?.course_creator?.isCourseCreator ?? false
  );
  const [isPaymentsDashboardChecked, setIsPaymentsDashboardChecked] = useState<boolean | undefined>(
    userPermissions?.admin_views?.payments ?? false
  );
  const [isCustomerSuccessDashboardChecked, setIsCustomerSuccessDashboardChecked] = useState<
    boolean | undefined
  >(userPermissions?.admin_views?.customer_success ?? false);
  const [isStudentsDashboardChecked, setIsStudentsDashboardChecked] = useState<boolean | undefined>(
    userPermissions?.admin_views?.students ?? false
  );
  const [isMentorsDashboardChecked, setIsMentorsDashboardChecked] = useState<boolean | undefined>(
    userPermissions?.admin_views?.mentors ?? false
  );
  const [isUsersDashboardChecked, setIsUsersDashboardChecked] = useState<boolean | undefined>(
    userPermissions?.admin_views?.users ?? false
  );

  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [selectedCourses, setSelectedCourses] = useState<string[]>(
    userPermissions?.course_creator?.courses ?? []
  );

  const [isSelectFocused, setIsSelectFocused] = useState(false);

  const handleChange = (value: SelectValue) => {
    setSelectedCourse(value as string);
  };

  const handleAdd = () => {
    if (selectedCourse && !selectedCourses.includes(selectedCourse)) {
      setSelectedCourses((prevItems) => [...prevItems, selectedCourse]);
      setSelectedCourse(null);
    }
  };

  const handleDelete = (itemToDelete: any) => {
    setSelectedCourses(selectedCourses.filter((item) => item !== itemToDelete));
  };

  useEffect(() => {
    if (userPermissions) {
      setIsStudentChecked(userPermissions.student ?? false);
      setIsMentorChecked(userPermissions.mentor ?? false);
      setIsCourseCreatorChecked(userPermissions.course_creator?.isCourseCreator ?? false);
      setIsPaymentsDashboardChecked(userPermissions.admin_views?.payments ?? false);
      setIsCustomerSuccessDashboardChecked(userPermissions.admin_views?.customer_success ?? false);
      setIsStudentsDashboardChecked(userPermissions.admin_views?.students ?? false);
      setIsMentorsDashboardChecked(userPermissions.admin_views?.mentors ?? false);
      setIsUsersDashboardChecked(userPermissions.admin_views?.users ?? false);
      setSelectedCourses(userPermissions.course_creator?.courses ?? []);
    }
  }, [userPermissions]);

  const isAdminChecked =
    isPaymentsDashboardChecked ||
    isCustomerSuccessDashboardChecked ||
    isStudentsDashboardChecked ||
    isMentorsDashboardChecked ||
    isUsersDashboardChecked;

  const areCourseOptionsValid =
    isCourseCreatorChecked || selectedCourses.length > 0
      ? isCourseCreatorChecked && selectedCourses.length > 0
      : true;

  const areDetailsFilled =
    areCourseOptionsValid &&
    (currentPage === 'create-new-user-permissions-admin' ? isAdminChecked : true);

  return (
    <div
      style={{
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/*FIRST COLUMN*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(50% - 16px)',
            marginRight: '16px',
          }}
        >
          <h3
            style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '16px' }}
            className="permissions-modal-title"
          >
            Agora Permissions
          </h3>
          <span style={{ color: COLORS.TEXT_SECONDARY }} className="permissions-modal-subtitle">
            Select more permissions for the respective user
          </span>

          <div style={{ marginTop: '24px', marginBottom: '16px' }}>
            <Checkbox style={{ marginRight: '12px' }} checked={true} disabled={true} />
            <span style={{ color: COLORS.TEXT_SECONDARY }} className="custom-checkbox-label">
              User
            </span>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <Checkbox
              style={{ marginRight: '12px' }}
              checked={isStudentChecked}
              onChange={(e: CheckboxChangeEvent) => {
                setIsStudentChecked(e.target.checked);
              }}
            />
            <span
              style={{
                color: isStudentChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
              }}
              className="custom-checkbox-label"
            >
              Student
            </span>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <Checkbox
              style={{ marginRight: '12px' }}
              checked={isMentorChecked}
              onChange={(e: CheckboxChangeEvent) => {
                setIsMentorChecked(e.target.checked);
              }}
            />
            <span
              style={{
                color: isMentorChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
              }}
              className="custom-checkbox-label"
            >
              Mentor
            </span>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <Checkbox
              style={{ marginRight: '12px' }}
              checked={isCourseCreatorChecked}
              onChange={(e: CheckboxChangeEvent) => {
                setIsCourseCreatorChecked(e.target.checked);
              }}
            />
            <span
              style={{
                color: isCourseCreatorChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
              }}
              className="custom-checkbox-label"
            >
              Course Creator
            </span>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <Checkbox
              style={{ cursor: 'default' }}
              indeterminate={
                (isPaymentsDashboardChecked ||
                  isCustomerSuccessDashboardChecked ||
                  isStudentsDashboardChecked ||
                  isMentorsDashboardChecked ||
                  isUsersDashboardChecked) &&
                !(
                  isPaymentsDashboardChecked &&
                  isCustomerSuccessDashboardChecked &&
                  isStudentsDashboardChecked &&
                  isMentorsDashboardChecked &&
                  isUsersDashboardChecked
                )
              }
              checked={
                isPaymentsDashboardChecked &&
                isCustomerSuccessDashboardChecked &&
                isStudentsDashboardChecked &&
                isMentorsDashboardChecked &&
                isUsersDashboardChecked
              }
              onChange={(e: CheckboxChangeEvent) => {
                setIsPaymentsDashboardChecked(e.target.checked);
                setIsCustomerSuccessDashboardChecked(e.target.checked);
                setIsStudentsDashboardChecked(e.target.checked);
                setIsMentorsDashboardChecked(e.target.checked);
                setIsUsersDashboardChecked(e.target.checked);
              }}
            >
              <style>
                {`
                  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
                    background-color: ${COLORS.PRIMARY};
                    height: 2px;
                  }
                `}
              </style>
            </Checkbox>
            <span
              style={{
                color:
                  (isPaymentsDashboardChecked ||
                    isCustomerSuccessDashboardChecked ||
                    isStudentsDashboardChecked ||
                    isMentorsDashboardChecked ||
                    isUsersDashboardChecked) &&
                  !(
                    isPaymentsDashboardChecked &&
                    isCustomerSuccessDashboardChecked &&
                    isStudentsDashboardChecked &&
                    isMentorsDashboardChecked &&
                    isUsersDashboardChecked
                  )
                    ? COLORS.PRIMARY
                    : COLORS.TEXT_SECONDARY,
              }}
              className="custom-checkbox-label"
            >
              Admin
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '32px',
            }}
          >
            <div style={{ marginBottom: '16px' }}>
              <Checkbox
                style={{ marginRight: '12px' }}
                checked={isPaymentsDashboardChecked}
                onChange={(e: CheckboxChangeEvent) => {
                  setIsPaymentsDashboardChecked(e.target.checked);
                }}
              />
              <span
                style={{
                  color: isPaymentsDashboardChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
                }}
                className="custom-checkbox-label"
              >
                Payments Dashboard
              </span>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <Checkbox
                style={{ marginRight: '12px' }}
                checked={isCustomerSuccessDashboardChecked}
                onChange={(e: CheckboxChangeEvent) => {
                  setIsCustomerSuccessDashboardChecked(e.target.checked);
                }}
              />
              <span
                style={{
                  color: isCustomerSuccessDashboardChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
                }}
                className="custom-checkbox-label"
              >
                Customer Success Dashboard
              </span>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <Checkbox
                style={{ marginRight: '12px' }}
                checked={isStudentsDashboardChecked}
                onChange={(e: CheckboxChangeEvent) => {
                  setIsStudentsDashboardChecked(e.target.checked);
                }}
              />
              <span
                style={{
                  color: isStudentsDashboardChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
                }}
                className="custom-checkbox-label"
              >
                Students Dashboard
              </span>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <Checkbox
                style={{ marginRight: '12px' }}
                checked={isMentorsDashboardChecked}
                onChange={(e: CheckboxChangeEvent) => {
                  setIsMentorsDashboardChecked(e.target.checked);
                }}
              />
              <span
                style={{
                  color: isMentorsDashboardChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
                }}
                className="custom-checkbox-label"
              >
                Mentors Dashboard
              </span>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <Checkbox
                style={{ marginRight: '12px' }}
                checked={isUsersDashboardChecked}
                onChange={(e: CheckboxChangeEvent) => {
                  setIsUsersDashboardChecked(e.target.checked);
                }}
              />
              <span
                style={{
                  color: isUsersDashboardChecked ? COLORS.PRIMARY : COLORS.TEXT_SECONDARY,
                }}
                className="custom-checkbox-label"
              >
                Users Dashboard
              </span>
            </div>
          </div>
        </div>

        {/*SECOND COLUMN*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
          }}
        >
          <h3
            style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '16px' }}
            className="permissions-modal-title"
          >
            Course Creator Permissions
          </h3>

          {/*COURSE SELECTOR*/}
          <div>
            <p className="user-permissions-search-label">Search Course</p>
            <div
              style={{ display: 'flex', flexDirection: 'row', height: '32px' }}
              className={`agora-select-add-component-selector ${
                isSelectFocused ? 'is-focused' : ''
              }`}
            >
              <Select
                showSearch
                style={{
                  height: '100%',
                  width: 'calc(100% - 80px)',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                placeholder="Search courses..."
                optionFilterProp="children"
                onChange={handleChange}
                value={selectedCourse}
                suffixIcon={<SearchOutlined style={{ color: COLORS.TEXT_PRIMARY }} />}
                onFocus={() => setIsSelectFocused(true)}
                onBlur={() => setIsSelectFocused(false)}
                notFoundContent={null}
              >
                {courses?.map((course) => (
                  <Option key={course._id} value={course._id}>
                    {course.title}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={handleAdd}
                style={{
                  width: '80px',
                  height: '100%',
                  backgroundColor: COLORS.WHITE,
                  borderRadius: '0 6px 6px 0',
                }}
                disabled={!selectedCourse}
              >
                <span style={{ color: COLORS.BLACK }} className="agora-select-add-component">
                  Add
                </span>
              </Button>
            </div>
            <List
              style={{
                marginTop: 20,
                borderWidth: '0px',
              }}
              bordered
              dataSource={selectedCourses}
              renderItem={(item) => (
                <List.Item
                  className="user-permissions-course"
                  style={{
                    padding: '4px',
                  }}
                  actions={[
                    <Button
                      icon={<DeleteFilled style={{ color: COLORS.ERROR, width: '14px' }} />}
                      onClick={() => handleDelete(item)}
                      type="text"
                    />,
                  ]}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '9px',
                        minWidth: '9px',
                        height: '9px',
                        backgroundColor: COLORS.PRIMARY,
                        borderRadius: '50%',
                        marginRight: '8px',
                      }}
                    ></div>
                    <span
                      style={{ color: COLORS.TEXT_SECONDARY }}
                      className="agora-select-add-component-item-text"
                    >
                      {courses?.find((course) => course._id === item)?.title}
                    </span>
                  </div>
                </List.Item>
              )}
            />
          </div>
          {/*END COURSE SELECTOR*/}
        </div>
      </div>

      {selectedCourses.length > 0 && !isCourseCreatorChecked ? (
        <span style={{ color: COLORS.ERROR, fontSize: '14px' }}>
          Warning: Found selected courses but "Course Creator" permission is not selected.
        </span>
      ) : null}

      {editError && <span style={{ color: COLORS.ERROR, fontSize: '14px' }}>{editError}</span>}
      {currentPage === 'create-new-user-permissions-mentor' ||
      currentPage === 'create-new-user-permissions-admin' ? (
        <SecondaryButton
          onClick={() => {
            const permissions = {
              student: isStudentChecked,
              mentor: isMentorChecked,
              course_creator: {
                isCourseCreator: isCourseCreatorChecked,
                courses: selectedCourses,
              },
              admin: isAdminChecked,
              admin_views: {
                payments: isPaymentsDashboardChecked,
                customer_success: isCustomerSuccessDashboardChecked,
                students: isStudentsDashboardChecked,
                mentors: isMentorsDashboardChecked,
                users: isUsersDashboardChecked,
              },
            };

            if (areDetailsFilled) {
              createUserV2?.({
                ...userData,
                permissions,
              });
            }
          }}
          backgroundColor={COLORS.WHITE}
          height="40px"
          padding="12px"
          margin="0 0 0 0"
          width="100%"
          disabled={!areDetailsFilled}
        >
          <span className="create-new-user-modal-submit-button" style={{ color: COLORS.BLACK }}>
            Continue
          </span>
        </SecondaryButton>
      ) : (
        <SecondaryButton
          // onClick={() => {
          //   saveUserProfile({
          //     email: email === null ? userData?.email : email,
          //     phoneNumber: phone === null ? userData?.phoneNumber : phone,
          //   });
          // }}
          onClick={() => {
            editUserPermissions({
              permissions: {
                student: isStudentChecked,
                mentor: isMentorChecked,
                course_creator: {
                  isCourseCreator: isCourseCreatorChecked,
                  courses: selectedCourses,
                },
                admin: isAdminChecked,
                admin_views: {
                  payments: isPaymentsDashboardChecked,
                  customer_success: isCustomerSuccessDashboardChecked,
                  students: isStudentsDashboardChecked,
                  mentors: isMentorsDashboardChecked,
                  users: isUsersDashboardChecked,
                },
              },
            });
          }}
          backgroundColor={COLORS.WHITE}
          height="32px"
          padding="4px 10px 4px 10px"
          margin="12px 0 12px 0"
          width="100%"
        >
          <span className="button-text" style={{ color: COLORS.BLACK }}>
            Save
          </span>
        </SecondaryButton>
      )}
      {/*  */}
    </div>
  );
};

export default UserPermissionsPage;
