import { useAuth0 } from '@auth0/auth0-react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useAuthState } from '@contexts/AuthContext';
import { UserRole } from '@shared/enums';

type State = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  menuType: MenuType;
};
export const NavigationStateContext = createContext<State | undefined>(undefined);

type Props = {
  children: ReactNode;
};

const VALID_MENUS = [UserRole.Student, UserRole.Mentor, UserRole.Admin];
type MenuType = typeof UserRole.Student | typeof UserRole.Mentor | typeof UserRole.Admin;
const NavigationProvider = ({ children }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { isAuthenticated } = useAuth0();

  const [menuType, _setMenuType] = useState<MenuType>(UserRole.Student);
  const setMenuType = (menuType: MenuType) => {
    _setMenuType(menuType);
    localStorage.setItem('menuType', `${menuType}`);
  };

  const { isAdmin, roles } = useAuthState();

  useEffect(() => {
    const _localMenuType = localStorage.getItem('menuType');
    const localMenuType = Number(_localMenuType) as MenuType;
    const impersonateId = localStorage.getItem('impersonate-user-id');
    if (impersonateId) {
      return;
    }
    if (roles?.includes(localMenuType) && VALID_MENUS.includes(localMenuType)) {
      setMenuType(localMenuType);
      return;
    }

    if (roles?.includes(UserRole.Mentor)) {
      setMenuType(UserRole.Mentor);
    }
    if (isAdmin) {
      setMenuType(2);
    }
  }, [roles, isAdmin, isAuthenticated]);

  const state: State = {
    isCollapsed,
    setIsCollapsed,
    menuType,
  };

  return (
    <NavigationStateContext.Provider value={state}>{children}</NavigationStateContext.Provider>
  );
};

const useNavigationState = () => {
  const context = useContext(NavigationStateContext);
  if (context === undefined) {
    throw new Error('useNavigationState must be used within a NavigationProvider');
  }

  return context;
};

export { NavigationProvider, useNavigationState };
