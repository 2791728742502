import { useGetCitiesLight, useGetTimezonesList, useSaveUserProfile } from '@actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import Select, { SelectOption } from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounce } from '@hooks';
import { countryList, supportedLanguages } from '@shared/constants';
import { phoneNumberValidation } from '@shared/validations/generics';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import { countries } from 'country-codes-flags-phone-codes';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../DetailsModalFormWrapper';
import { StudentDetailsPageContext } from '../StudentDetailsPage';

interface StudentDetailsFormProps extends BasicProps {
  onCloseEditMode: () => void;
}

const contactSchema = z
  .object({
    email: z.string().email({ message: 'Invalid email address' }),
    studentPhoneNumber: phoneNumberValidation,
    studentPhoneCountryCode: z.string().min(1, 'Country code is required.'),
    parentMail: z.string().email().min(1, { message: 'Invalid email address' }),
    parentPhoneNumber: phoneNumberValidation,
    parentPhoneCountryCode: z.string().min(1, 'Country code is required.'),
    parentName: z.string().min(1, { message: 'Please enter a valid name' }),
    country: z.string().min(1, { message: 'Please select a country' }),
    city: z.string().optional().nullable(),
    cityRef: z.string().optional().nullable(),
    timezone: z.string().min(1, { message: 'Please select a timezone' }),
    languages: z.array(z.string()).min(1, { message: 'Please select at least one language' }),
    graduationYear: z.number().min(1, { message: 'Please select a graduation year' }),
  })
  .superRefine((data, ctx) => {
    if (data.country === 'Romania') {
      if (!data.cityRef) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['cityRef'],
          message: 'Please select a valid city.',
        });
      }
    } else {
      if (!data.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['city'],
          message: 'Please enter a valid city.',
        });
      }
    }
  });

type FormData = z.infer<typeof contactSchema>;

const StudentDetailsForm = (props: StudentDetailsFormProps) => {
  const { onCloseEditMode } = props;
  const [citySearch, setCitySearch] = useState('');
  const debouncedCitySearch = useDebounce(citySearch, 500);

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const toast = useToast();

  const { data: timezones, isLoading: isTimezonesLoading } = useGetTimezonesList();
  const { data: cities, isLoading: isLoadingCities } = useGetCitiesLight({
    search: debouncedCitySearch,
    limit: 20,
  });

  const { mutate: updateUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');
      onCloseEditMode();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(contactSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      email: student?.email,
      studentPhoneNumber: student?.phoneNumber?.number,
      studentPhoneCountryCode: student?.phoneNumber?.countryCode,
      parentMail: student?.parentData?.email,
      parentPhoneNumber: student?.parentData?.phoneNumber?.number,
      parentPhoneCountryCode: student?.parentData?.phoneNumber?.countryCode,
      parentName: student?.parentData?.fullName,
      country: student?.country,
      city: student?.country === 'Romania' ? undefined : student?.city,
      cityRef: undefined,
      timezone: student?.timezone,
      languages: student?.spokenLanguages,
      graduationYear: student?.graduationYear,
    },
  });

  const { field: countryField, fieldState: countryFieldState } = useController({
    name: 'country',
    control,
  });

  const { field: cityRefField, fieldState: cityRefFieldState } = useController({
    name: 'cityRef',
    control,
  });

  const { field: timezoneField, fieldState: timezoneFieldState } = useController({
    name: 'timezone',
    control,
  });

  const { field: languageField, fieldState: languageFieldState } = useController({
    name: 'languages',
    control,
  });

  const { field: graduationYearField, fieldState: graduationYearFieldState } = useController({
    name: 'graduationYear',
    control,
  });

  const { field: studentPhoneCountryCodeField, fieldState: studentPhoneCountryCodeFieldState } =
    useController({
      name: 'studentPhoneCountryCode',
      control,
    });
  const { field: studentPhoneNumberField, fieldState: studentPhoneNumberFieldState } =
    useController({
      name: 'studentPhoneNumber',
      control,
    });

  const { field: parentPhoneCountryCodeField, fieldState: parentPhoneCountryCodeFieldState } =
    useController({
      name: 'parentPhoneCountryCode',
      control,
    });
  const { field: parentPhoneNumberField, fieldState: parentPhoneNumberFieldState } = useController({
    name: 'parentPhoneNumber',
    control,
  });

  const touchAllFields = (
    fields: Record<keyof Omit<FormData, 'city' | 'cityRef'>, string | string[] | number>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        email: data.email,
        phoneNumber: {
          countryCode: data.studentPhoneCountryCode?.split('#')?.[0],
          number: data.studentPhoneNumber,
        },
        parentData: {
          fullName: data.parentName,
          phoneNumber: {
            countryCode: data.parentPhoneCountryCode?.split('#')?.[0],
            number: data.parentPhoneNumber,
          },
          email: data.parentMail,
        },
        spokenLanguages: data.languages,
        timezone: data.timezone,
        city: data.city,
        cityRef: data.cityRef,
        country: data.country,
        graduationYear: data.graduationYear,
      });
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  const getCityOptions = (): SelectOption[] => {
    const mappedCities =
      cities?.map((city) => ({
        label: `${city.name}, ${city.county}`,
        value: city._id,
      })) || [];

    if (!student?.cityRef) return mappedCities;

    const isCityRefInMappedCities = !!mappedCities.find(
      (city) => city.value === student?.cityRef?._id
    );

    if (!isCityRefInMappedCities) {
      mappedCities.push({
        label: `${student?.cityRef.name}, ${student?.cityRef.county}`,
        value: student?.cityRef._id,
      });
    }

    return mappedCities;
  };

  const cityBlurHandler = () => {
    setCitySearch('');
    cityRefField.onBlur();
  };

  const countryChangeHandler = (value: string | number | null) => {
    countryField.onChange(value);

    if (value === 'Romania') {
      setValue('city', null);
    } else {
      setValue('cityRef', null);
    }
  };

  const shouldAutoSelectCityRef = useRef(true);

  useEffect(() => {
    if (!cityRefField.value && student?.cityRef?._id && shouldAutoSelectCityRef.current) {
      setValue('cityRef', student?.cityRef?._id, { shouldTouch: true, shouldValidate: true });
      shouldAutoSelectCityRef.current = false;
    }
  }, [cityRefField.value, student?.cityRef?._id]);

  return (
    <SessionModalFormWrapper
      title="Edit Contact Info"
      isLoading={false}
      onCancel={onCloseEditMode}
      submitHandler={submitHandler}
    >
      <div className="flex justify-between gap-6">
        <Input
          isRequired
          type="text"
          id="mail"
          label="Mail"
          isTouched={touchedFields.email}
          isValid={!errors.email}
          errorText={errors.email?.message}
          {...register('email', { required: true })}
        />

        <AgoraDatePicker
          className="max-w-[33.33%]"
          value={moment().year(graduationYearField.value).startOf('year')}
          onChange={(value) => graduationYearField.onChange(value?.year())}
          onBlur={graduationYearField.onBlur}
          picker="year"
          size="large"
          label="Graduation Year"
          isRequired
          allowClear={false}
          isValid={!graduationYearFieldState.error}
          isTouched={graduationYearFieldState.isTouched}
          errorText={graduationYearFieldState.error?.message}
        />
      </div>

      <div className="flex flex-row gap-2">
        <Select
          className="max-w-[33.33%]"
          value={studentPhoneCountryCodeField.value}
          onSelect={(value) => studentPhoneCountryCodeField.onChange(value)}
          onClear={() => studentPhoneCountryCodeField.onChange('')}
          onBlur={studentPhoneCountryCodeField.onBlur}
          options={countries.map((country, index) => ({
            label: `(${country.dialCode}) ${country.name}`,
            value: `${country.dialCode}#${index}`,
          }))}
          label="Country Code"
          size="large"
          isRequired
          isValid={!studentPhoneCountryCodeFieldState.error}
          isTouched={studentPhoneCountryCodeFieldState.isTouched}
          errorText={studentPhoneCountryCodeFieldState.error?.message}
        />

        <Input
          type="tel"
          id="phone"
          label="Student Phone"
          isRequired
          name="countryCode"
          prefix={studentPhoneCountryCodeField.value?.split('#')?.[0]}
          onChange={(value) => studentPhoneNumberField.onChange(value)}
          onBlur={studentPhoneNumberField.onBlur}
          value={studentPhoneNumberField.value}
          isDisabled={!studentPhoneCountryCodeField.value}
          isValid={!studentPhoneNumberFieldState.error}
          isTouched={studentPhoneNumberFieldState.isTouched}
          errorText={
            studentPhoneCountryCodeField.value
              ? studentPhoneNumberFieldState.error?.message
              : undefined
          }
        />
      </div>

      <div className="flex justify-between gap-6"></div>
      <div className="flex justify-between gap-6">
        <Select
          value={countryField.value}
          onSelect={countryChangeHandler}
          onClear={() => countryField.onChange(undefined)}
          onBlur={countryField.onBlur}
          options={countryList.map((country) => ({
            label: country,
            value: country,
          }))}
          isRequired
          allowClear={false}
          size="large"
          label="Country"
          isValid={!countryFieldState.error}
          isTouched={countryFieldState.isTouched}
          errorText={countryFieldState.error?.message}
        />
        {countryField.value === 'Romania' ? (
          <Select
            size="large"
            label="City"
            value={cityRefField.value}
            filterOption={() => true}
            searchValue={citySearch}
            onSearch={setCitySearch}
            onSelect={(value) => cityRefField.onChange(value)}
            onBlur={cityBlurHandler}
            options={getCityOptions()}
            notFoundContent={
              isLoadingCities ? <LoadingSpinner className="mx-auto" size="normal" /> : null
            }
            placeholder="Search City"
            isRequired
            isValid={!cityRefFieldState.error}
            isTouched={cityRefFieldState.isTouched}
            errorText={cityRefFieldState.error?.message}
          />
        ) : (
          <Input
            type="text"
            id="city"
            label="City"
            isRequired
            isTouched={touchedFields.city}
            isValid={!errors.city}
            errorText={errors.city?.message}
            {...register('city', { required: true })}
          />
        )}
      </div>
      <div className="flex justify-between gap-6">
        <Select
          value={timezoneField.value}
          onSelect={(value) => timezoneField.onChange(value)}
          onClear={() => timezoneField.onChange(undefined)}
          onBlur={timezoneField.onBlur}
          options={timezones?.map((timezone) => ({
            label: timezone,
            value: timezone,
          }))}
          isLoading={isTimezonesLoading}
          size="large"
          label="Timezone"
          isRequired
          allowClear={false}
          isValid={!timezoneFieldState.error}
          isTouched={timezoneFieldState.isTouched}
          errorText={timezoneFieldState.error?.message}
        />
        <Select
          value={languageField.value}
          onChange={(value) => languageField.onChange(Array.isArray(value) ? value : [value])}
          onClear={() => languageField.onChange([])}
          onBlur={languageField.onBlur}
          options={supportedLanguages.map((language) => ({
            label: language,
            value: language,
          }))}
          mode="multiple"
          size="large"
          label="Language"
          allowClear={false}
          isRequired
          isValid={!languageFieldState.error}
          isTouched={languageFieldState.isTouched}
          errorText={languageFieldState.error?.message}
        />
      </div>

      <div className="flex justify-between gap-6">
        <Input
          type="text"
          id="parentName"
          label="Parent Name"
          isRequired
          isTouched={touchedFields.parentName}
          isValid={!errors.parentName}
          errorText={errors.parentName?.message}
          {...register('parentName', { required: true })}
        />

        <Input
          type="text"
          id="parentMail"
          label="Parent Mail"
          isRequired
          isTouched={touchedFields.parentMail}
          isValid={!errors.parentMail}
          errorText={errors.parentMail?.message}
          {...register('parentMail', { required: true })}
        />
      </div>
      <div className="flex flex-col gap-2 laptop:flex-row">
        <Select
          className="laptop:max-w-[33.33%]"
          value={parentPhoneCountryCodeField.value}
          onSelect={(value) => parentPhoneCountryCodeField.onChange(value)}
          onClear={() => parentPhoneCountryCodeField.onChange('')}
          onBlur={parentPhoneCountryCodeField.onBlur}
          options={countries.map((country, index) => ({
            label: `(${country.dialCode}) ${country.name}`,
            value: `${country.dialCode}#${index}`,
          }))}
          label="Country code"
          size="large"
          isRequired
          isValid={!parentPhoneCountryCodeFieldState.error}
          isTouched={parentPhoneCountryCodeFieldState.isTouched}
          errorText={parentPhoneCountryCodeFieldState.error?.message}
        />

        <Input
          type="tel"
          id="phone"
          label="Parent Phone"
          isRequired
          name="countryCode"
          prefix={parentPhoneCountryCodeField.value?.split('#')?.[0]}
          onChange={(value) => parentPhoneNumberField.onChange(value)}
          onBlur={parentPhoneNumberField.onBlur}
          value={parentPhoneNumberField.value}
          isDisabled={!parentPhoneCountryCodeField.value}
          isValid={!parentPhoneNumberFieldState.error}
          isTouched={parentPhoneNumberFieldState.isTouched}
          errorText={
            parentPhoneCountryCodeField.value
              ? parentPhoneNumberFieldState.error?.message
              : undefined
          }
        />
      </div>
    </SessionModalFormWrapper>
  );
};

export default StudentDetailsForm;
