import { useGetCitiesLight, useGetTimezonesList, useSaveUserProfile } from '@actions';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import Select, { SelectOption } from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounce } from '@hooks';
import { countryList, supportedLanguages } from '@shared/constants';
import { phoneNumberValidation } from '@shared/validations/generics';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import { countries } from 'country-codes-flags-phone-codes';
import { useContext, useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../DetailsModalFormWrapper';
import { MentorDetailsPageContext } from '../MentorDetailsPage';

interface MentorDetailsFormProps extends BasicProps {
  onCloseEditMode: () => void;
}

const contactSchema = z
  .object({
    email: z.string().email({ message: 'Invalid email address' }),
    mentorPhoneNumber: phoneNumberValidation,
    mentorPhoneCountryCode: z.string().min(1, 'Country code is required.'),
    biography: z.string(),
    country: z.string().min(1, { message: 'Please select a country' }),
    city: z.string().optional().nullable(),
    cityRef: z.string().optional().nullable(),
    timezone: z.string().min(1, { message: 'Please select a timezone' }),
    languages: z.array(z.string()).min(1, { message: 'Please select at least one language' }),
  })
  .superRefine((data, ctx) => {
    if (data.country === 'Romania') {
      if (!data.cityRef) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['cityRef'],
          message: 'Please select a valid city.',
        });
      }
    } else {
      if (!data.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['city'],
          message: 'Please enter a valid city.',
        });
      }
    }
  });

type FormData = z.infer<typeof contactSchema>;

const MentorDetailsForm = (props: MentorDetailsFormProps) => {
  const { onCloseEditMode } = props;

  const [citySearch, setCitySearch] = useState('');

  const { mentor } = useContext(MentorDetailsPageContext);

  const debouncedCitySearch = useDebounce(citySearch, 500);

  const mentorId = mentor?._id ?? '';

  const toast = useToast();

  const { data: timezones, isLoading: isTimezonesLoading } = useGetTimezonesList();
  const { data: cities, isLoading: isLoadingCities } = useGetCitiesLight({
    search: debouncedCitySearch,
    limit: 20,
  });

  const { mutate: updateUserProfile } = useSaveUserProfile(mentorId, {
    onSuccess: async () => {
      toast.success("Successfully updated mentor's profile.");
      onCloseEditMode();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(contactSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      email: mentor?.email,
      mentorPhoneNumber: mentor?.phoneNumber?.number,
      mentorPhoneCountryCode: mentor?.phoneNumber?.countryCode,
      biography: mentor?.mentorData?.biography,
      country: mentor?.country,
      city: mentor?.country === 'Romania' ? undefined : mentor?.city,
      cityRef: undefined,
      timezone: mentor?.timezone,
      languages: mentor?.spokenLanguages,
    },
  });

  const { field: countryField, fieldState: countryFieldState } = useController({
    name: 'country',
    control,
  });

  const { field: cityRefField, fieldState: cityRefFieldState } = useController({
    name: 'cityRef',
    control,
  });

  const { field: timezoneField, fieldState: timezoneFieldState } = useController({
    name: 'timezone',
    control,
  });

  const { field: languagesField, fieldState: languagesFieldState } = useController({
    name: 'languages',
    control,
  });

  const { field: mentorPhoneCountryCodeField, fieldState: mentorPhoneCountryCodeFieldState } =
    useController({
      name: 'mentorPhoneCountryCode',
      control,
    });

  const { field: mentorPhoneNumberField, fieldState: mentorPhoneNumberFieldState } = useController({
    name: 'mentorPhoneNumber',
    control,
  });

  const touchAllFields = (
    fields: Record<keyof Omit<FormData, 'city' | 'cityRef'>, string | string[]>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        email: data.email,
        phoneNumber: {
          countryCode: data.mentorPhoneCountryCode?.split('#')?.[0],
          number: data.mentorPhoneNumber,
        },
        spokenLanguages: data.languages,
        biography: data.biography,
        timezone: data.timezone,
        city: data.city,
        cityRef: data.cityRef,
        country: data.country,
      });
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  const getCityOptions = (): SelectOption[] => {
    const mappedCities =
      cities?.map((city) => ({
        label: `${city.name}, ${city.county}`,
        value: city._id,
      })) || [];

    if (!mentor?.cityRef) return mappedCities;

    const isCityRefInMappedCities = !!mappedCities.find(
      (city) => city.value === mentor?.cityRef?._id
    );

    if (!isCityRefInMappedCities) {
      mappedCities.push({
        label: `${mentor.cityRef.name}, ${mentor.cityRef.county}`,
        value: mentor.cityRef._id,
      });
    }

    return mappedCities;
  };

  const cityBlurHandler = () => {
    setCitySearch('');
    cityRefField.onBlur();
  };

  const countryChangeHandler = (value: string | number | null) => {
    countryField.onChange(value);

    if (value === 'Romania') {
      setValue('city', null);
    } else {
      setValue('cityRef', null);
    }
  };

  const shouldAutoSelectCityRef = useRef(true);

  useEffect(() => {
    if (!cityRefField.value && mentor?.cityRef?._id && shouldAutoSelectCityRef.current) {
      setValue('cityRef', mentor?.cityRef?._id, { shouldTouch: true, shouldValidate: true });
      shouldAutoSelectCityRef.current = false;
    }
  }, [cityRefField.value, mentor?.cityRef?._id]);

  return (
    <SessionModalFormWrapper
      title="Edit Contact Info"
      isLoading={false}
      onCancel={onCloseEditMode}
      submitHandler={submitHandler}
    >
      <div className="flex justify-between gap-6">
        <Input
          isRequired
          type="text"
          id="mail"
          label="Mail"
          isTouched={touchedFields.email}
          isValid={!errors.email}
          errorText={errors.email?.message}
          {...register('email', { required: true })}
        />
      </div>

      <div className="flex flex-row gap-2">
        <Select
          className="max-w-[33.33%]"
          value={mentorPhoneCountryCodeField.value}
          onSelect={(value) => mentorPhoneCountryCodeField.onChange(value)}
          onClear={() => mentorPhoneCountryCodeField.onChange('')}
          onBlur={mentorPhoneCountryCodeField.onBlur}
          options={countries.map((country, index) => ({
            label: `(${country.dialCode}) ${country.name}`,
            value: `${country.dialCode}#${index}`,
          }))}
          label="Country Code"
          size="large"
          isRequired
          isValid={!mentorPhoneCountryCodeFieldState.error}
          isTouched={mentorPhoneCountryCodeFieldState.isTouched}
          errorText={mentorPhoneCountryCodeFieldState.error?.message}
        />

        <Input
          type="tel"
          id="phone"
          label="Mentor Phone"
          isRequired
          name="countryCode"
          prefix={mentorPhoneCountryCodeField.value?.split('#')?.[0]}
          onChange={(value) => mentorPhoneNumberField.onChange(value)}
          onBlur={mentorPhoneNumberField.onBlur}
          value={mentorPhoneNumberField.value}
          isDisabled={!mentorPhoneCountryCodeField.value}
          isValid={!mentorPhoneNumberFieldState.error}
          isTouched={mentorPhoneNumberFieldState.isTouched}
          errorText={
            mentorPhoneCountryCodeField.value
              ? mentorPhoneNumberFieldState.error?.message
              : undefined
          }
        />
      </div>

      <div className="flex justify-between gap-6">
        <Select
          value={countryField.value}
          onSelect={countryChangeHandler}
          onClear={() => countryField.onChange(undefined)}
          onBlur={countryField.onBlur}
          options={countryList.map((country) => ({
            label: country,
            value: country,
          }))}
          isRequired
          allowClear={false}
          size="large"
          label="Country"
          isValid={!countryFieldState.error}
          isTouched={countryFieldState.isTouched}
          errorText={countryFieldState.error?.message}
        />
        {countryField.value === 'Romania' ? (
          <Select
            size="large"
            label="City"
            value={cityRefField.value}
            filterOption={() => true}
            searchValue={citySearch}
            onSearch={setCitySearch}
            onSelect={(value) => cityRefField.onChange(value)}
            onBlur={cityBlurHandler}
            options={getCityOptions()}
            notFoundContent={
              isLoadingCities ? <LoadingSpinner className="mx-auto" size="normal" /> : null
            }
            placeholder="Search City"
            isRequired
            isValid={!cityRefFieldState.error}
            isTouched={cityRefFieldState.isTouched}
            errorText={cityRefFieldState.error?.message}
          />
        ) : (
          <Input
            type="text"
            id="city"
            label="City"
            isRequired
            isTouched={touchedFields.city}
            isValid={!errors.city}
            errorText={errors.city?.message}
            {...register('city', { required: true })}
          />
        )}
      </div>

      <div className="flex justify-between gap-6">
        <Select
          value={timezoneField.value}
          onSelect={(value) => timezoneField.onChange(value)}
          onClear={() => timezoneField.onChange(undefined)}
          onBlur={timezoneField.onBlur}
          options={timezones?.map((timezone) => ({
            label: timezone,
            value: timezone,
          }))}
          size="large"
          label="Timezone"
          isLoading={isTimezonesLoading}
          isRequired
          allowClear={false}
          isValid={!timezoneFieldState.error}
          isTouched={timezoneFieldState.isTouched}
          errorText={timezoneFieldState.error?.message}
        />
        <Select
          value={languagesField.value}
          onChange={(value) => languagesField.onChange(Array.isArray(value) ? value : [value])}
          onClear={() => languagesField.onChange([])}
          onBlur={languagesField.onBlur}
          options={supportedLanguages.map((language) => ({
            label: language,
            value: language,
          }))}
          mode="multiple"
          size="large"
          label="Spoken Languages"
          isRequired
          isValid={!languagesFieldState.error}
          isTouched={languagesFieldState.isTouched}
          errorText={languagesFieldState.error?.message}
        />
      </div>

      <div className="flex justify-between gap-6">
        <TextArea
          id="bio"
          label="Bio"
          isRequired
          isTouched={touchedFields.biography}
          isValid={!errors.biography}
          errorText={errors.biography?.message}
          {...register('biography', { required: true })}
        />
      </div>
    </SessionModalFormWrapper>
  );
};

export default MentorDetailsForm;
