import { ReportStatus } from '@shared/enums';
import apiClient from '@contexts/AuthContext/apiClient';
import styles from '@styles/components/table.module.scss';
import { Button } from 'antd';
import { formatEnumValue } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../../v3/global';
import useToast from 'apps/agora/src/hooks/useToast';

interface Props {
  report: any;
  isEven: boolean;
}

const PastReportsRow: React.FC<Props> = (props) => {
  const { report, isEven } = props;
  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const toast = useToast();

  const handleDownloadActivityReport = async () => {
    const response = await apiClient.get(`/report/${report._id}/download-activity-report`, {
      responseType: 'blob',
    });
    saveByteArray(response, true);
  };

  const saveByteArray = (blob: any, isActivityReport: boolean) => {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    const fileName = isActivityReport ? 'Activity_Report.pdf' : 'Invoice.pdf';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadInvoie = async () => {
    try {
      const response = await apiClient.get(`/report/${report._id}/download-invoice`, {
        responseType: 'blob',
      });
      toast.success('Invoice downloaded successfully');

      saveByteArray(response, false);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(report?.createdAt).format('MMMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(report?.updatedAt).format('DD MMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {report?.paymentAmountInHC} {report?.hubspotCurrency}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {formatEnumValue(ReportStatus[report.status])}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {report?.invoiceId && (
          <Button
            type="primary"
            style={{
              border: '2px solid #000000',
              margin: '0px 10px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div onClick={() => handleDownloadInvoie()}>Download Invoice</div>
            </div>
          </Button>
        )}

        <Button
          onClick={() => handleDownloadActivityReport()}
          type="primary"
          style={{
            border: '2px solid #000000',
            margin: '0px 10px',
          }}
        >
          <span style={{ width: '170px' }}>View Monthly Report</span>
        </Button>
      </div>
    </>
  );
};

export default PastReportsRow;
