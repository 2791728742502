import { UserRole } from '@shared/enums';
import { AccountProfile } from './Profile';
import { useAuthState } from '@contexts/AuthContext';

const AccountPage = () => {
  const { roles } = useAuthState();
  const isMentor = roles.includes(UserRole.Mentor);

  return <AccountProfile isMentorProfile={isMentor} />;
};

export default AccountPage;
