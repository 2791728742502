import { useState } from 'react';
import useDebounce from './useDebounce';
import { useGetUniversitiesLight } from '@actions';

const useUniversitySearch = (initialValue: string, isEnabled: boolean) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const debouncedSearch = useDebounce(searchQuery, 500);

  const { data: universities, isLoading } = useGetUniversitiesLight(
    {
      search: debouncedSearch,
      limit: 20,
    },
    {
      enabled: isEnabled,
    }
  );

  return {
    searchQuery,
    setSearchQuery,
    universities,
    isLoading,
  };
};

export default useUniversitySearch;
