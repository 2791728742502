import { MentoringStyle, MentorStatus, UserRole } from "../enums";

export const ROLES: Record<UserRole, string> = {
  [UserRole.User]: "User",
  [UserRole.Moderator]: "Moderator",
  [UserRole.Admin]: "Admin",
  [UserRole.Mentor]: "Mentor",
  [UserRole.Student]: "Student",
  [UserRole.FreeUser]: "Free User",
  [UserRole.Alumni]: "Alumni",
  [UserRole.Influencer]: "Influencer",
  [UserRole.SuperMentor]: "Super Mentor",
  [UserRole.Financial]: "Financial",
  [UserRole.SuperUser]: "Super User",
  [UserRole.CourseCreator]: "Course creator",
  [UserRole.CustomerSuccess]: "Customer success",
  [UserRole.StudentsManager]: "Students manager",
  [UserRole.MentorsManager]: "Mentors manager",
  [UserRole.UsersManager]: "Users manager",
};

export const ROLESv2: Partial<Record<UserRole, string>> = {
  [UserRole.User]: ROLES[UserRole.User],
  [UserRole.Admin]: ROLES[UserRole.Admin],
  [UserRole.Mentor]: ROLES[UserRole.Mentor],
  [UserRole.Student]: ROLES[UserRole.Student],
  [UserRole.Influencer]: ROLES[UserRole.Influencer],
  [UserRole.SuperMentor]: ROLES[UserRole.SuperMentor],
};

export const ADMIN_ROLES: UserRole[] = [
  UserRole.Financial,
  UserRole.CustomerSuccess,
  UserRole.UsersManager,
  UserRole.StudentsManager,
  UserRole.MentorsManager,
];

export const ADMIN_MENTOR_ROLES: UserRole[] = [
  ...ADMIN_ROLES,
  UserRole.SuperMentor,
  UserRole.CourseCreator,
  UserRole.Mentor,
];

export const MENTORING_STYLES: Record<MentoringStyle, string> = {
  [MentoringStyle.HighPerforming]: "High Performing",
  [MentoringStyle.HandHolding]: "Hand Holding",
  [MentoringStyle.Inspirational]: "Inspirational",
  [MentoringStyle.Experienced]: "Experienced",
  [MentoringStyle.Demanding]: "Demanding",
  [MentoringStyle.Rookie]: "Rookie",
  [MentoringStyle.OnProbation]: "On Probation",
  [MentoringStyle.QuickResponse]: "Quick Response",
};

export const MENTOR_STATUS_V3: MentorStatus[] = [
  MentorStatus.InTraining,
  MentorStatus.Active,
  MentorStatus.UnderObservation,
  MentorStatus.Leaving,
  MentorStatus.Inactive,
];
