import { University } from '@shared/clientTypes/DTOs/UniversityDTO';
import { createContext, useContext } from 'react';

export const UniversityModalContext = createContext<{ university?: University } | null>(null);

export const useUniversityModalContext = () => {
  const context = useContext(UniversityModalContext);
  if (!context) {
    throw new Error('useUniversityModal must be used within a UniversityModalProvider');
  }
  return context;
};
