import { useAuth0 } from '@auth0/auth0-react';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import { MobileProvider } from '@components/Navigation/MobileContext';
import Auth from '@modules/Auth';
import LoginErrorView from '@modules/Auth/LoginErrorScreen';
import ParentView from '@modules/Auth/ParentView';
import ReferralThankYouView from '@modules/Auth/ReferralThankYouView/index';
import ReferralView from '@modules/Auth/ReferralView/index';
import TermsAndConditionsView from '@modules/Auth/TermsAndConditionsView';
import useHotjar from '@services/useHotjar';
import { USE_AUTH0 } from '@shared/frontendEnv';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import useTrackPageView from './hooks/useTrackPageView';
import { getIsPublicPath } from './utils/helpers';
import { AdminView, UserView } from './views';
import { useAuthState } from '@contexts/AuthContext';

const Router: React.FC = () => {
  const history = useHistory();

  const { isAdmin, isLoading, isLogoutInProgress, setIsLogoutInProgress } = useAuthState();

  const { error, isAuthenticated, loginWithRedirect } = useAuth0();
  const [authFlag] = useState<boolean>(USE_AUTH0 === '1');

  const impersonateId = localStorage.getItem('impersonate-user-id');

  useTrackPageView();
  useHotjar();

  useEffect(() => {
    if (!isAuthenticated && isLogoutInProgress) {
      setIsLogoutInProgress(false);
    }
  }, [isAuthenticated, isLogoutInProgress]);

  useEffect(() => {
    if (isLogoutInProgress) return;

    if (!isAuthenticated && !isLoading && !getIsPublicPath()) {
      localStorage.setItem('desiredPath', window.location.pathname + window.location.search);

      loginWithRedirect();
      return;
    }

    if (isAuthenticated && localStorage.getItem('desiredPath')) {
      const path = localStorage.getItem('desiredPath');

      localStorage.removeItem('desiredPath');
      history.push(path || '/');
    }
  }, [isAuthenticated, isLoading, isLogoutInProgress]);

  if (isLogoutInProgress || isLoading || (!isAuthenticated && !getIsPublicPath())) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner />
      </div>
    );
  } else {
    if (isAuthenticated && window.location.pathname.startsWith('/referral')) {
      return (
        <Switch>
          <Route path="/referral/:id" component={ReferralView} />
          <Route path="/referral" component={ReferralView} />
        </Switch>
      );
    }

    if (
      (isAuthenticated && window.location.pathname === '/thank-you') ||
      window.location.pathname === '/thank-you/'
    ) {
      return (
        <Switch>
          <Route path="/thank-you" component={ReferralThankYouView} />
        </Switch>
      );
    }

    if (isAuthenticated && window.location.pathname === '/terms-and-conditions') {
      return (
        <Switch>
          <Route path="/terms-and-conditions" component={TermsAndConditionsView} />
        </Switch>
      );
    }

    if (isAuthenticated && window.location.pathname.includes('/parent-view')) {
      return (
        <Switch>
          <Route path="/parent-view/:studentId" component={ParentView} />
        </Switch>
      );
    }

    if (!isAuthenticated || error) {
      if (
        (window.location.pathname === '/' ||
          window.location.pathname === '/auth' ||
          window.location.pathname === '/auth/') &&
        authFlag
      ) {
        if (error?.toString().includes('block')) {
          history.push('/login-error');
        } else {
          loginWithRedirect();
        }
      }

      return (
        <Switch>
          <Route path="/parent-view/:studentId" component={ParentView} />
          <Route path="/auth" component={Auth} />
          <Route path="/referral/:id" component={ReferralView} />
          <Route path="/referral" component={ReferralView} />
          <Route path="/thank-you" component={ReferralThankYouView} />
          <Route path="/login-error" component={LoginErrorView} />
          <Route path="/terms-and-conditions" component={TermsAndConditionsView} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <MobileProvider>
        <ConfigProvider locale={enGB}>
          <div className="main_wrapper h-full">
            {!isLoading && isAdmin && !impersonateId && isAuthenticated && <AdminView />}
            {!isLoading && (!isAdmin || impersonateId) && isAuthenticated && <UserView />}
          </div>
        </ConfigProvider>
      </MobileProvider>
    );
  }
};

export default Router;
