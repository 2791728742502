import Pill from '@components/V4/Pill';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { GOAL_STATUS_TYPES, GoalStatusTypes } from '@shared/common';

interface ApplicationStatusPillProps {
  status: GoalStatusTypes;
  className?: string;
}

const getStatusPreset = (status: GoalStatusTypes) => {
  const statusText = GOAL_STATUS_TYPES[status];
  const statusColors: Record<GoalStatusTypes, string> = {
    [GoalStatusTypes.ApplicationNotStarted]: 'bg-customRed',
    [GoalStatusTypes.ApplicationInProgress]: 'bg-customGold',
    [GoalStatusTypes.ApplicationSent]: 'bg-customLila',
    [GoalStatusTypes.ApplicationRejected]: 'bg-customGrey',
    [GoalStatusTypes.OfferReceived]: 'bg-customLime',
    [GoalStatusTypes.OfferAccepted]: 'bg-customPrimary',
    [GoalStatusTypes.OfferDeclined]: 'bg-customGrey',
  };

  return {
    text: statusText || 'Unknown Status',
    color: statusColors[status] || 'bg-customGrey',
  };
};

const ApplicationStatusPill = ({ status, className }: ApplicationStatusPillProps) => {
  const { text, color } = getStatusPreset(status);

  return <Pill isReadOnly size="small" text={text} className={mergeClassNames(color, className)} />;
};

export default ApplicationStatusPill;
