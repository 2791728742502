import { ReactComponent as ExamIcon } from '@assets/icons/profile-icons/exam-icon.svg';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserExam } from '@shared/common';
import { useGetAllExamNames, useGetExam, useSaveUserProfile } from '@actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ProfileContext } from '../../../AccountProfile';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';
import { UserExamWithName } from '@shared/clientTypes/DTOs/UserDTO';
import Select from '@components/V4/Select/Select';
import ExamInfoBar from '@components/V4/ExamInfoBar';

interface UpcomingExamsFormProps extends ExamFormProps {
  examData?: UserExamWithName;
}

const formDataSchema = z.object({
  exam: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  prevGrade: z.string().optional().default(''),
  date: z
    .string()
    .min(1, { message: 'Please enter a valid date.' })
    .refine((val) => moment(val).isSameOrBefore(moment(), 'day'), {
      message: 'Date cannot be in the future.',
    }),
});

type FormData = z.infer<typeof formDataSchema>;

const PastExamsForm = (props: UpcomingExamsFormProps) => {
  const {
    isModeAddNew,
    userId,
    contentClassName,
    className,
    examData,
    setIsEditMode,
    setIsAddNewItem,
    onCancel,
  } = props;

  const { isExternalViewer } = useContext(ProfileContext);

  const toast = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const hasNewExamType = !!examData?.newExam?._id;

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      exam: hasNewExamType ? examData?.newExam._id : examData?.exam,
      prevGrade: examData?.prevGrade ?? '',
      date: examData?.date,
    },
  });

  const { field: dateField, fieldState: dateFieldState } = useController({
    name: 'date',
    control,
  });

  const { field: examField, fieldState: examFieldState } = useController({
    name: 'exam',
    control,
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { data: examOptions } = useGetAllExamNames();
  const { data: selectedExam } = useGetExam(examField.value, {
    enabled: (!!examField.value && isModeAddNew) || hasNewExamType,
  });

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );
      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const exam: Partial<UserExam> = {
        ...data,
        ...((isModeAddNew || examData?.newExam?._id) && { exam: undefined, newExam: data.exam }),
        _id: examData?._id,
      };

      updateUserProfile({
        exams: [exam],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <StudentFormWrapper
      title="Past Exam"
      isModeAddNew={isModeAddNew}
      className={className}
      contentClassName={contentClassName}
      icon={isModeAddNew && <ExamIcon className="min-w-5 w-5" />}
      isLoading={isLoading}
      onCancel={onCancel}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      {isModeAddNew || !!examData?.newExam ? (
        <Select
          value={examField.value}
          onSelect={(value) => examField.onChange(value)}
          onBlur={examField.onBlur}
          allowClear={false}
          options={examOptions?.map((exam) => ({
            label: exam.name,
            value: exam._id ?? '',
          }))}
          label="Exam"
          size="large"
          isRequired
          isValid={!examFieldState.error}
          isTouched={examFieldState.isTouched}
          errorText={examFieldState.error?.message}
        />
      ) : (
        <Input
          isRequired
          type="text"
          id="university"
          label="University"
          isTouched={touchedFields.exam}
          isValid={!errors.exam}
          errorText={errors.exam?.message}
          autoFocus
          {...register('exam')}
        />
      )}

      {!!selectedExam && <ExamInfoBar exam={selectedExam} />}

      <AgoraDatePicker
        value={dateField.value ? moment(dateField.value) : undefined}
        onChange={(value) => dateField.onChange(moment(value).format('YYYY-MM-DD'))}
        disabledDate={(date) => date > moment(moment().format('YYYY-MM-DD'))}
        onBlur={dateField.onBlur}
        allowClear={false}
        isRequired
        label="Date"
        isValid={!dateFieldState.error}
        isTouched={dateFieldState.isTouched}
        errorText={dateFieldState.error?.message}
      />
      <Input type="text" id={'score'} placeholder={''} label={'Score'} {...register('prevGrade')} />
    </StudentFormWrapper>
  );
};

export default PastExamsForm;
