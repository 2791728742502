import Button from '@components/V4/Button';
import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { Major } from '@shared/common';
import { MajorWithUser } from '@shared/clientTypes/DTOs/MajorsDTO';
import { useUpdateMajor } from 'apps/agora/src/actions/major.actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface MajorDetailsFormProps extends BasicProps {
  major?: MajorWithUser;
  onCloseEditMode: () => void;
}

const formDataSchema = z.object({
  name: z.string().nonempty('Major name is required'),
});

type FormData = z.infer<typeof formDataSchema>;

const MajorDetailsForm = (props: MajorDetailsFormProps) => {
  const { major, onCloseEditMode } = props;

  const toast = useToast();

  const { mutate: updateMajor, isLoading } = useUpdateMajor(major?._id ?? '', {
    onSuccess: async () => {
      toast.success('Major updated successfully.');
      onCloseEditMode();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message || 'Major failed to update.');
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: major?.name,
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const majorData: Partial<Major> = {
        name: data.name,
      };

      updateMajor(majorData);
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  return (
    <div>
      <div className="flex flex-col gap-4 bg-surfaceHover rounded-lg w-full px-4 py-6 tablet:px-8 ">
        <div className="flex gap-4 items-center h-6">
          <h4 className="font-raleway text-xsm font-bold text-customGrey">Edit Major</h4>
        </div>

        <div className="flex flex-col gap-2">
          <Input
            isRequired
            type="text"
            id="majorName"
            label="Major Name"
            isTouched={touchedFields.name}
            isValid={!errors.name}
            errorText={errors.name?.message}
            autoFocus
            {...register('name')}
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button buttonText="Cancel" variant="secondary" onMouseDown={onCloseEditMode} />
          <Button
            buttonText="Save Major"
            isLoading={isLoading}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default MajorDetailsForm;
