import moment from 'moment';
import { useContext, useMemo } from 'react';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';
import PastExamsSection from './PastExamsSection/PastExamsSection';
import UpcomingExamsSection from './UpcomingExamsSection/UpcomingExamsSection';

const ExamsTab = () => {
  const { student } = useContext(StudentDetailsPageContext);

  const { upcomingExams, pastExams } = useMemo(() => {
    const today = moment().startOf('day');

    const pastExams = student?.exams?.filter((exam) => moment(exam.date).isBefore(today, 'day'));

    const upcomingExams = student?.exams?.filter((exam) =>
      moment(exam.date).isSameOrAfter(today, 'day')
    );

    return { upcomingExams, pastExams };
  }, [student?.exams]);

  return (
    <>
      <UpcomingExamsSection exams={upcomingExams} />
      <PastExamsSection exams={pastExams} />
    </>
  );
};

export default ExamsTab;
