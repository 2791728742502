import { ReactComponent as UniversityIcon } from '@assets/icons/profile-icons/university-icon.svg';
import moment from 'moment';
import { useState } from 'react';
import { EducationalItemProps } from '../../../types';
import ItemContent from '../ItemContent';
import ResumeTabEducationForm from './ResumeTabEducationForm';

const ResumeTabEducationItem = (props: EducationalItemProps) => {
  const { userId, schoolDetails, onDeleteEntry } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const cancelEditHandler = () => {
    setIsEditMode(false);
  };

  return isEditMode ? (
    <ResumeTabEducationForm
      schoolData={schoolDetails}
      isModeAddNew={false}
      onCancel={cancelEditHandler}
      userId={userId}
      setIsEditMode={setIsEditMode}
    />
  ) : (
    <ItemContent
      icon={<UniversityIcon className="text-customPrimary min-w-5" />}
      onEdit={() => setIsEditMode(true)}
      onDelete={() => onDeleteEntry(schoolDetails._id)}
    >
      <ItemContent.Title>
        {schoolDetails.university?.name || schoolDetails.educationalInstitution.name}
      </ItemContent.Title>

      <ItemContent.SubTitle>
        {schoolDetails.degreeType} <span>•</span> {schoolDetails.startYear} -{' '}
        {schoolDetails.endYear}
      </ItemContent.SubTitle>

      <ItemContent.Description className="text-customPrimary">
        {schoolDetails.major?.name}
      </ItemContent.Description>
    </ItemContent>
  );
};

export default ResumeTabEducationItem;
