import {
  ANALYTICS_EVENT_NAMES,
  EducationLevel,
  FlagTypes,
  ModelFlagTypes,
  SchoolType,
  ServiceType,
} from '../enums';

/* MAILS */
export const UPGRADE_EDUCATION_EMAIL = 'contact@upgrade.education';
export const SUPPORT_UPGRADE_EDUCATION_EMAIL = 'support@upgrade.education';
export const TECH_UPGRADE_EDUCATION_EMAIL = 'agora.test@upgrade.education';
export const MENTOR_NOTIFICATION_ADDR = ['mentor.management@upgrade.education'];
export const OPS_NOTIFICATION_ADDR = ['mentor.management@upgrade.education'];
export const FROM_EMAIL = 'contact@upgrade.education';
export const FAILURE_NOTIFICATION_EMAIL = 'tech@upgrade.education';
export const AGORA_TEST_EMAIL = 'agora.test@upgrade.education';

/* CONFIGS */
export const DEFAULT_LIMIT = 10;
export const DEFAULT_PAGE = 1;
export const NO_OF_HOURS_FOR_FEEDBACK = 48; // 2 days in hours
export const NO_OF_HOURS_FOR_FEEDBACK_NOT_ACCEPTABLE = 168; // 7 days in hours
export const MEETING_DURATION = 60 * 60; // 1 hour in seconds
export const MEETINGS_PAGE_LIMIT = 5;
export const MEETING_DATE_THRESHOLD = '2024-01-01T00:00:00.000Z';
export const MINUTES_45 = 45 * 60 * 1000; // 45 minutes in milliseconds
export const supportedCurrencies = ['RON', 'USD', 'GBP', 'EUR'];
export const hubspotPipeline = '75805637';
export const mentorHubspotPipeline = '45616597';
export const DISCORD_BASE_URL = 'https://discord.com/channels/1041701427321503818';
export const DISCORD_MENTOR_CHANNEL = `${DISCORD_BASE_URL}/1077929040842850375`;
export const DISCORD_STUDENT_CHANNEL = `${DISCORD_BASE_URL}/1255434275600470027`;
export const CAREER_COUNSELING_COURSE_ID = '614d8365ad030a001197ba26';
export const STRATEGY_EDU_COORD_COURSE_ID = '617a7d19547f840011272d9b';
export const daysInitials = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];

/* REPORTS */
export const VAT_MULTIPLIER = 0.19;

/* LMS */
export const baseLMSURL = 'https://lms.upgrade.education/admin/api';
export const cacheKey = 'lms-token';
export const baseRoleCacheKey = 'lms-role-id';
export const PLACEHOLDER_MENTOR_TRAINING_COURSE_ID = '6605944e12c35fb979010e67';

/* OTHER */
export const DELAY_DURATIONS = {
  EXTRA_SHORT: 100, // 100 milliseconds
  SHORT: 500, // 500 milliseconds
  SHORT_MEDIUM: 1000, // 1 second
  MEDIUM: 2000, // 2 seconds
  LONG: 5000, // 5 seconds
  EXTRA_LONG: 10000, // 10 seconds
};

export const BATCH_SIZES = {
  EXTRA_SMALL: 5,
  SMALL: 10,
  SMALL_MEDIUM: 25,
  MEDIUM: 50,
  LARGE: 100,
  XLARGE: 500,
};

export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const VIRTUAL_BACKGROUND_NOT_SUPPORTED_REASON = 'Cannot load virtual background model.';

export const WISE_SETTINGS = {
  headers: [
    'name',
    'recipientEmail',
    'paymentReference',
    'receiverType',
    'amountCurrency',
    'amount',
    'sourceCurrency',
    'targetCurrency',
    'IBAN',
  ],
  currencyTypes: ['EUR', 'GBP', 'USD', 'RON'],
  institution: 'INSTITUTION',
  amountCurrency: 'target',
};

export const KONAMI_CODE = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyD',
  'KeyE',
  'KeyV',
];

/* DRIVE */
export const DRIVE_CONTENT_MANAGER_PERMISSION = {
  value: 'default',
  type: 'anyone',
  role: 'fileOrganizer',
};

export const PUBLIC_R_PERMISSION = {
  value: 'default',
  type: 'anyone',
  role: 'reader',
};

export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

export const hoursOptions = [
  { label: '12am', value: 0 },
  { label: '1am', value: 1 },
  { label: '2am', value: 2 },
  { label: '3am', value: 3 },
  { label: '4am', value: 4 },
  { label: '5am', value: 5 },
  { label: '6am', value: 6 },
  { label: '7am', value: 7 },
  { label: '8am', value: 8 },
  { label: '9am', value: 9 },
  { label: '10am', value: 10 },
  { label: '11am', value: 11 },
  { label: '12pm', value: 12 },
  { label: '1pm', value: 13 },
  { label: '2pm', value: 14 },
  { label: '3pm', value: 15 },
  { label: '4pm', value: 16 },
  { label: '5pm', value: 17 },
  { label: '6pm', value: 18 },
  { label: '7pm', value: 19 },
  { label: '8pm', value: 20 },
  { label: '9pm', value: 21 },
  { label: '10pm', value: 22 },
  { label: '11pm', value: 23 },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const diacriticsApplyMap: any = {
  default: [
    {
      base: 'A',
      letters:
        /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g,
    },
    { base: 'AA', letters: /[\uA732]/g },
    { base: 'AE', letters: /[\u00C6\u01FC\u01E2]/g },
    { base: 'AO', letters: /[\uA734]/g },
    { base: 'AU', letters: /[\uA736]/g },
    { base: 'AV', letters: /[\uA738\uA73A]/g },
    { base: 'AY', letters: /[\uA73C]/g },
    {
      base: 'B',
      letters: /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g,
    },
    {
      base: 'C',
      letters: /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g,
    },
    {
      base: 'D',
      letters:
        /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g,
    },
    { base: 'DZ', letters: /[\u01F1\u01C4]/g },
    { base: 'Dz', letters: /[\u01F2\u01C5]/g },
    {
      base: 'E',
      letters:
        /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g,
    },
    { base: 'F', letters: /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
    {
      base: 'G',
      letters:
        /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g,
    },
    {
      base: 'H',
      letters:
        /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g,
    },
    {
      base: 'I',
      letters:
        /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g,
    },
    { base: 'J', letters: /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
    {
      base: 'K',
      letters:
        /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g,
    },
    {
      base: 'L',
      letters:
        /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g,
    },
    { base: 'LJ', letters: /[\u01C7]/g },
    { base: 'Lj', letters: /[\u01C8]/g },
    {
      base: 'M',
      letters: /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g,
    },
    {
      base: 'N',
      letters:
        /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g,
    },
    { base: 'NJ', letters: /[\u01CA]/g },
    { base: 'Nj', letters: /[\u01CB]/g },
    {
      base: 'O',
      letters:
        /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g,
    },
    { base: 'OI', letters: /[\u01A2]/g },
    { base: 'OO', letters: /[\uA74E]/g },
    { base: 'OU', letters: /[\u0222]/g },
    {
      base: 'P',
      letters: /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g,
    },
    { base: 'Q', letters: /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
    {
      base: 'R',
      letters:
        /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g,
    },
    {
      base: 'S',
      letters:
        /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g,
    },
    {
      base: 'T',
      letters:
        /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g,
    },
    { base: 'TZ', letters: /[\uA728]/g },
    {
      base: 'U',
      letters:
        /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g,
    },
    {
      base: 'V',
      letters: /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g,
    },
    { base: 'VY', letters: /[\uA760]/g },
    {
      base: 'W',
      letters: /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g,
    },
    { base: 'X', letters: /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
    {
      base: 'Y',
      letters:
        /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g,
    },
    {
      base: 'Z',
      letters:
        /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g,
    },
    {
      base: 'a',
      letters:
        /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g,
    },
    { base: 'aa', letters: /[\uA733]/g },
    { base: 'ae', letters: /[\u00E6\u01FD\u01E3]/g },
    { base: 'ao', letters: /[\uA735]/g },
    { base: 'au', letters: /[\uA737]/g },
    { base: 'av', letters: /[\uA739\uA73B]/g },
    { base: 'ay', letters: /[\uA73D]/g },
    {
      base: 'b',
      letters: /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g,
    },
    {
      base: 'c',
      letters: /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g,
    },
    {
      base: 'd',
      letters:
        /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g,
    },
    { base: 'dz', letters: /[\u01F3\u01C6]/g },
    {
      base: 'e',
      letters:
        /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g,
    },
    { base: 'f', letters: /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
    {
      base: 'g',
      letters:
        /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g,
    },
    {
      base: 'h',
      letters:
        /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g,
    },
    { base: 'hv', letters: /[\u0195]/g },
    {
      base: 'i',
      letters:
        /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g,
    },
    { base: 'j', letters: /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
    {
      base: 'k',
      letters:
        /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g,
    },
    {
      base: 'l',
      letters:
        /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g,
    },
    { base: 'lj', letters: /[\u01C9]/g },
    {
      base: 'm',
      letters: /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g,
    },
    {
      base: 'n',
      letters:
        /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g,
    },
    { base: 'nj', letters: /[\u01CC]/g },
    {
      base: 'o',
      letters:
        /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g,
    },
    { base: 'oi', letters: /[\u01A3]/g },
    { base: 'ou', letters: /[\u0223]/g },
    { base: 'oo', letters: /[\uA74F]/g },
    {
      base: 'p',
      letters: /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g,
    },
    { base: 'q', letters: /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
    {
      base: 'r',
      letters:
        /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g,
    },
    {
      base: 's',
      letters:
        /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g,
    },
    {
      base: 't',
      letters:
        /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g,
    },
    { base: 'tz', letters: /[\uA729]/g },
    {
      base: 'u',
      letters:
        /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g,
    },
    {
      base: 'v',
      letters: /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g,
    },
    { base: 'vy', letters: /[\uA761]/g },
    {
      base: 'w',
      letters: /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g,
    },
    { base: 'x', letters: /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
    {
      base: 'y',
      letters:
        /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g,
    },
    {
      base: 'z',
      letters:
        /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g,
    },
  ],
};

export const genderOptions = [
  { name: 'Male' },
  { name: 'Female' },
  {
    name: 'More',
    items: [
      { name: 'Agender' },
      { name: 'Androgyne' },
      { name: 'Bigender' },
      { name: 'Genderfluid' },
      { name: 'Genderqueer' },
      { name: 'Hijra' },
      { name: 'Neutrois' },
      { name: 'Non-binary' },
      { name: 'Pangender' },
      { name: 'Two-spirit' },
      { name: 'Other' },
    ],
  },
];

export const ANALYTICS_EVENTS: Record<
  ANALYTICS_EVENT_NAMES,
  { category: string; action: string; label?: string }
> = {
  // Session Scheduling (Mentors)
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_MODAL_OPEN]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_modal_open',
    label: 'User opened the schedule meeting modal.',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_SUCCESS]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_success',
    label: 'User successfully scheduled a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_FAIL]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_fail',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_TRIGGER]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_trigger',
    label: 'User attempted to schedule a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_MODAL_CLOSE]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_modal_close',
    label: 'User closed the schedule meeting modal.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_MODAL_OPEN]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_modal_open',
    label: 'User opened the reschedule meeting modal.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_SUCCESS]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_success',
    label: 'User successfully rescheduled a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_FAIL]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_fail',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_TRIGGER]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_trigger',
    label: 'User attempted to reschedule a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_MODAL_CLOSE]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_modal_close',
    label: 'User closed the reschedule meeting modal.',
  },

  // Video Events
  [ANALYTICS_EVENT_NAMES.MEETING_JOIN]: {
    category: 'Video Meeting',
    action: 'meeting_join',
    label: 'User joined the meeting.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_JOIN_FAIL]: {
    category: 'Video Meeting',
    action: 'meeting_join_fail',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_LEAVE]: {
    category: 'Video Meeting',
    action: 'meeting_leave',
    label: 'User left the meeting by pressing the leave button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_LEAVE_FAIL]: {
    category: 'Video Meeting',
    action: 'meeting_leave_fail',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_START]: {
    category: 'Video Meeting',
    action: 'share_screen_start',
    label: 'User started sharing the screen.',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_END]: {
    category: 'Video Meeting',
    action: 'share_screen_end',
    label: 'User stopped sharing the screen.',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_END_FAIL]: {
    category: 'Video Meeting',
    action: 'share_screen_end_fail',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_FAIL]: {
    category: 'Video Meeting',
    action: 'share_screen_fail',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_VIEW_FAIL]: {
    category: 'Video Meeting',
    action: 'share_screen_view_fail',
  },
  [ANALYTICS_EVENT_NAMES.CAMERA_START_FAIL]: {
    category: 'Video Meeting',
    action: 'camera_start_fail',
  },
  [ANALYTICS_EVENT_NAMES.MICROPHONE_START_FAIL]: {
    category: 'Video Meeting',
    action: 'microphone_start_fail',
  },
  [ANALYTICS_EVENT_NAMES.PIP_FAIL]: {
    category: 'Video Meeting',
    action: 'pip_fail',
  },

  // Mentor Profile Events
  [ANALYTICS_EVENT_NAMES.PROFILE_CHANGE]: {
    category: 'Profile',
    action: 'profile_change',
    label: 'User made changes to their profile.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_PAYMENT_CHANGE]: {
    category: 'Profile',
    action: 'profile_payment_change',
    label: 'User made changes to their payments details.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_COURSES_CAN_TEACH_CHANGE]: {
    category: 'Profile',
    action: 'profile_courses_can_teach_change',
    label: 'User made changes to their list of courses they can teach.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_SUBJECTS_CAN_TEACH_CHANGE]: {
    category: 'Profile',
    action: 'profile_subjects_can_teach_change',
    label: 'User made changes to their list of subjects they can teach.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_TAB_CHANGE]: {
    category: 'Profile',
    action: 'profile_tab_change',
    label: 'User changed the current viewing tab of their profile.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_CAPACITY_CHANGE]: {
    category: 'Profile',
    action: 'profile_capacity_change',
    label: 'User changed their capacity.',
  },

  // Student Profile Events
  [ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR]: {
    category: 'Student Profile',
    action: 'student_profile_change_by_mentor',
    label: 'Mentor made changes to the student’s profile.',
  },

  // Profile Completion Banner Events
  [ANALYTICS_EVENT_NAMES.PROFILE_BANNER_STACK_BUTTON_CLICK]: {
    category: 'Profile Completion Banner',
    action: 'profile_banner_stack_button_click',
    label: 'User clicked on the profile completion banner button: {buttonLabel}.',
  },

  // Banner Stack Events
  [ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK]: {
    category: 'Mentor Profile Completion Banner',
    action: 'mentor_banner_stack_navigation_arrow_click',
    label: 'Mentor clicked the navigation arrow button.',
  },
  [ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_UPCOMING_STUDENTS_CLICK]: {
    category: 'Mentor Profile Completion Banner',
    action: 'mentor_banner_stack_upcoming_students_click',
    label: 'Mentor clicked the "Upcoming Students" button.',
  },
  [ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_SUBMIT_FEEDBACK_CLICK]: {
    category: 'Mentor Profile Completion Banner',
    action: 'mentor_banner_stack_submit_feedback_click',
    label: 'Mentor clicked the "Submit Feedback" button.',
  },

  // Meeting Events
  [ANALYTICS_EVENT_NAMES.MEETING_ADD_HOMEWORK_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_add_homework_button_click',
    label: 'User clicked the "Add Homework" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_RESCHEDULE_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_reschedule_button_click',
    label: 'User clicked the "Reschedule" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_RESCHEDULE]: {
    category: 'Meeting',
    action: 'meeting_reschedule',
    label: 'User rescheduled the meeting.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_CANCEL_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_cancel_button_click',
    label: 'User clicked the "Cancel" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_CANCEL]: {
    category: 'Meeting',
    action: 'meeting_cancel',
    label: 'User cancelled the meeting.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_GIVE_FEEDBACK_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_mentor_give_feedback_button_click',
    label: 'User clicked the "Give Feedback" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_GIVE_FEEDBACK]: {
    category: 'Meeting',
    action: 'meeting_give_feedback',
    label: 'User gave feedback.',
  },
  [ANALYTICS_EVENT_NAMES.CLOSE_FEEDBACK_MODAL]: {
    category: 'Meeting',
    action: 'close_feedback_modal',
    label: 'User closed the feedback modal.',
  },
  [ANALYTICS_EVENT_NAMES.CLOSE_FEEDBACK_MODAL_ON_MEETING_END]: {
    category: 'Meeting',
    action: 'close_feedback_modal_on_meeting_end',
    label: 'User closed the feedback modal after it appeared on meeting end.',
  },
  [ANALYTICS_EVENT_NAMES.FEEDBACK_MODAL_ON_END_MEETING_SHOW]: {
    category: 'Meeting',
    action: 'feedback_modal_on_end_meeting_show',
    label: 'Modal appeared after meeting ended.',
  },
  [ANALYTICS_EVENT_NAMES.FEEDBACK_ON_END_MEETING]: {
    category: 'Meeting',
    action: 'feedback_on_end_meeting',
    label: 'User gave feedback after meeting ended.',
  },

  // NPS Mentor Events
  [ANALYTICS_EVENT_NAMES.NPS_MODAL_CLOSE]: {
    category: 'NPS',
    action: 'NPS_modal_close',
    label: 'User closed the NPS modal.',
  },
  [ANALYTICS_EVENT_NAMES.NPS_MODAL_SUBMIT]: {
    category: 'NPS',
    action: 'NPS_modal_submit',
    label: 'User submitted the NPS.',
  },
  [ANALYTICS_EVENT_NAMES.GLASSDOOR_REVIEW_MODAL_BUTTON_CLICK]: {
    category: 'NPS',
    action: 'glassdoor_review_modal_button_click',
    label: 'User clicked the "Write us a Glassdoor Review".',
  },
  [ANALYTICS_EVENT_NAMES.GLASSDOOR_REVIEW_MODAL_CLOSE]: {
    category: 'NPS',
    action: 'glassdoor_review_modal_close',
    label: 'User closed the Glassdoor review modal.',
  },

  // NPS Student Events
  [ANALYTICS_EVENT_NAMES.GOOGLE_REVIEW_MODAL_BUTTON_CLICK]: {
    category: 'NPS',
    action: 'google_review_modal_button_click',
    label: 'User clicked the "Write us a Google Review".',
  },
  [ANALYTICS_EVENT_NAMES.GOOGLE_REVIEW_MODAL_CLOSE]: {
    category: 'NPS',
    action: 'google_review_modal_close',
    label: 'User closed the Google review modal.',
  },

  // Session Details Events
  [ANALYTICS_EVENT_NAMES.SESSION_DETAILS_GOTO_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_details_goto_button_click',
    label: 'User clicked the go to session details button of an individual meeting.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_NAVIGATION_ARROW_CLICK]: {
    category: 'Session Details',
    action: 'session_navigation_arrow_click',
    label: 'User clicked the navigation arrow button.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_SUBMIT_FEEDBACK_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_submit_feedback_button_click',
    label: 'User clicked the "Submit Feedback" button.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_ADD_NOTES_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_add_notes_button_click',
    label: 'User clicked the "Add Notes" button.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_ADD_NOTES]: {
    category: 'Session Details',
    action: 'session_add_notes',
    label: 'User added notes.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_SHOW_ENTIRE_TRANSCRIPT_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_show_entire_transcript_button_click',
    label: 'User clicked the "Show Entire Transcript" button.',
  },
};

export const serviceTypeOptions = [
  { label: 'Webinar Host', value: ServiceType.WEBINAR_HOST },
  { label: 'Speaking Live Event', value: ServiceType.SPEAKING_LIVE_EVENT },
  { label: 'Referral', value: ServiceType.REFERRAL },
  { label: 'Marketing Action', value: ServiceType.MARKETING_ACTION },
  {
    label: 'Creative Writing Course',
    value: ServiceType.CREATIVE_WRITING_COURSE,
  },
  { label: 'Subscription', value: ServiceType.SUBSCRIPTION },
  { label: 'Extra Sessions', value: ServiceType.EXTRA_SESSIONS },
  { label: 'Resource Development', value: ServiceType.RESOURCE_DEVELOPMENT },
  {
    label: 'Extra Payment Per Session',
    value: ServiceType.EXTRA_PAYMENT_PER_SESSION,
  },
  { label: 'Mentor Training', value: ServiceType.MENTOR_TRAINING },
  { label: 'Other', value: ServiceType.OTHER },
];

export const MODEL_FLAG_TYPES: { [key: string]: any } = {
  [ModelFlagTypes.ENROLLMENT]: 'Enrollment',
  [ModelFlagTypes.MEETING]: 'Meeting',
};

export const FLAG_TYPES: { [key: string]: any } = {
  [FlagTypes.FIRST_MEETING_NOT_SCHEDULED_IN_3_DAYS]: 'First meeting not scheduled in 3 days',
  [FlagTypes.FIRST_MEETING_NOT_HELD_IN_7_DAYS]: 'First meeting not held in 7 days',
  [FlagTypes.NEXT_MEETING_FURTHER_THAN_62_DAYS]: 'Next meeting further than 62 days',
  [FlagTypes.NO_MEETING_SCHEDULED_AFTER_48_HOURS_SIENCE_LAST_MEETING_HELD]:
    'No meeting scheduled after 48 hours since last meeting held',
  [FlagTypes.NO_FEEDBACK_AFTER_48_HOURS_SIENCE_MEETING_HELD]:
    'No feedback after 48 hours since meeting held',
  [FlagTypes.MAX_DURATION_COURSE_EXCEEDED]: 'Max duration course exceeded',
  [FlagTypes.NO_ADMISSION_RESULTS_COMPLETED]: 'No admission results completed',
};

export const FLAG_TYPE = [
  'First meeting not scheduled in 3 days',
  'First meeting not held in 7 days',
  'Next meeting further than 62 days',
  'No meeting scheduled after 48 hours since last meeting held',
  'No feedback after 48 hours since meeting held',
  'Max duration course exceeded',
  'No admission results completed',
];

export const supportedLanguages = ['Romanian', 'English', 'Spanish', 'French', 'German', 'Italian'];

export const UniversitySizeTypes = {
  S: {
    name: 'Small',
    description: 'Fewer than 5,000',
  },
  M: {
    name: 'Medium',
    description: '>=5,000',
  },
  L: {
    name: 'Large',
    description: '>=12,000',
  },
  XL: {
    name: 'Extra Large',
    description: 'More than 30,000',
  },
};

export const UniversityFocusTypes = {
  FC: {
    name: 'Full comprehensive',
    description: 'All 5 faculty areas + medical school',
  },
  CO: {
    name: 'Comprehensive',
    description: 'All 5 faculty areas',
  },
  FO: {
    name: 'Focused',
    description: '3 or 4 faculty areas',
  },
  SP: {
    name: 'Specialist',
    description: '2 or fewer faculty areas',
  },
};

export const UniversityResearchIntensityTypes = {
  VH: {
    name: 'Very High',
  },
  HI: {
    name: 'High',
  },
  MD: {
    name: 'Medium',
  },
  LO: {
    name: 'Low',
  },
};

export const RESULTS_TIMELINE_PERIODS = ['Early', 'Mid', 'Late'];

export const statusTypes = [
  { label: 'All Statuses', value: null },
  {
    label: 'Pending',
    value: 0,
  },
  {
    label: 'Scheduled',
    value: 7,
  },
  {
    label: 'Ongoing',
    value: 1,
  },
  {
    label: 'Needs feedback',
    value: 2,
  },
  {
    label: 'Completed',
    value: 3,
  },
  {
    label: 'Pending review',
    value: 4,
  },
  {
    label: 'Paid',
    value: 5,
  },
  {
    label: 'Unpayable',
    value: 6,
  },
];

export const EDUCATION_LEVELS: { [key: string]: any } = {
  [EducationLevel.ELEMENTARY_SCHOOL]: 'Elementary School',
  [EducationLevel.MIDDLE_SCHOOL]: 'Middle School',
  [EducationLevel.HIGH_SCHOOL]: 'High School',
};

export const SCHOOL_TYPES: { [key: string]: any } = {
  [SchoolType.PRIVATE]: 'Private',
  [SchoolType.PUBLIC]: 'Public',
};

export enum EndMeetingNonBreakableErrors {
  COULDNT_SEND_EMAIL,
}

export const END_MEETING_NON_BREAKABLE_ERRORS_TYPE: { [key: string]: string } = {
  [EndMeetingNonBreakableErrors.COULDNT_SEND_EMAIL]: 'Missed Meeting email could not be sent.',
};
