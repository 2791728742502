import { majorApis } from '@shared/apis';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithIdParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useGetMajors = genericQuery<majorApis.GetMajors>(majorApis.getMajors);

export const useGetAllMajorNames = genericQuery<majorApis.GetAllMajorNames>(
  majorApis.getAllMajorNames
);

export const useGetMajor = genericQueryWithId<majorApis.GetMajor>(majorApis.getMajor);

export const useUpdateMajor = genericMutationWithId<majorApis.UpdateMajor>(
  majorApis.updateMajor,
  majorApis.searchMajors.endpoint,
  majorApis.getMajor.endpoint
);

export const useSearchMajors = genericQueryWithParams<majorApis.SearchMajors>(
  majorApis.searchMajors
);

export const useCreateMajor = genericMutation<majorApis.CreateMajor>(
  majorApis.createMajor,
  majorApis.searchMajors.endpoint,
  majorApis.getMajor.endpoint
);

export const useDeleteMajor = genericMutationWithIdParams<majorApis.DeleteMajor>(
  majorApis.deleteMajor,
  majorApis.searchMajors.endpoint,
  majorApis.getMajor.endpoint,
  majorApis.getAllMajorNames.endpoint
);

export const useRestoreMajor = genericMutationWithIdParams<majorApis.RestoreMajor>(
  majorApis.restoreMajor,
  majorApis.searchMajors.endpoint,
  majorApis.getMajor.endpoint,
  majorApis.getAllMajorNames.endpoint
);
