import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useSaveUserProfile } from '@actions';
import { SaveMentorProfileData, SaveStudentProfileData } from '@shared/clientTypes/misc';
import AgoraInput from '@utils/AgoraInput';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-users.styles.scss';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { countries } from 'country-codes-flags-phone-codes';
import { z } from 'zod';
import { phoneNumberValidation } from '@shared/validations/generics';
import { useController, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  handleCurrentPage: (page: string) => void;
  userId: string;
  userData: any;
  refetchUserData: () => void;
};

const contactSchema = z.object({
  fullName: z.string().min(1, { message: 'Full Name is required.' }),
  email: z.string().email({ message: 'Invalid email address.' }),
  countryCode: z.string().min(1, 'Country code is required.'),
  phone: phoneNumberValidation,
});

type FormData = z.infer<typeof contactSchema>;

const EditProfileDetailsPage = ({
  handleCurrentPage,
  userId,
  userData,
  refetchUserData,
}: Props) => {
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(contactSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      fullName: userData?.fullName ?? '',
      email: userData?.email ?? '',
      phone: userData?.phoneNumber?.number,
      countryCode: userData?.phoneNumber?.countryCode,
    },
  });

  const { field: countryCodeField, fieldState: countryCodeFieldState } = useController({
    name: 'countryCode',
    control,
  });

  const { field: phoneNumberField, fieldState: phoneNumberFieldState } = useController({
    name: 'phone',
    control,
  });

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const { mutate: saveUserProfile } = useSaveUserProfile(userId, {
    onSuccess: () => {
      refetchUserData();
      handleCurrentPage('user-profile');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const userDetailsToUpdate: SaveMentorProfileData | SaveStudentProfileData = {
        fullName: data.fullName,
        email: data.email,
        phoneNumber: {
          number: data.phone,
          countryCode: data.countryCode,
        },
      };

      saveUserProfile(userDetailsToUpdate);
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  return (
    <div className="mt-2">
      <div className="flex flex-col justify-between h-[60vh]">
        <div>
          <h3 className="modal-page-section-title mb-6 text-customGrey">Contact Info</h3>

          <Input
            type="fullName"
            autoFocus
            id="fullName"
            placeholder="John Doe"
            label="Full Name"
            isTouched={touchedFields.fullName}
            isValid={!errors.fullName}
            errorText={errors.fullName?.message}
            {...register('fullName', { required: true })}
          />

          <Input
            type="email"
            autoFocus
            id="email"
            placeholder="john.doe@gmail.com"
            label="Email"
            isTouched={touchedFields.email}
            isValid={!errors.email}
            errorText={errors.email?.message}
            {...register('email', { required: true })}
          />


          <div className="flex flex-row gap-2">
            <Select
              className="max-w-[33.33%]"
              value={countryCodeField.value}
              onSelect={(value) => countryCodeField.onChange(value)}
              onClear={() => countryCodeField.onChange('')}
              onBlur={countryCodeField.onBlur}
              options={countries.map((country, index) => ({
                label: `(${country.dialCode}) ${country.name}`,
                value: `${country.dialCode}#${index}`,
              }))}
              label="Country"
              size="large"
              isRequired
              isValid={!countryCodeFieldState.error}
              isTouched={countryCodeFieldState.isTouched}
              errorText={countryCodeFieldState.error?.message}
            />

            <Input
              type="tel"
              id="phone"
              label="Phone"
              isRequired
              name="countryCode"
              prefix={countryCodeField.value?.split('#')?.[0]}
              onChange={(value) => phoneNumberField.onChange(value)}
              onBlur={phoneNumberField.onBlur}
              value={phoneNumberField.value}
              isDisabled={!countryCodeField.value}
              isValid={!phoneNumberFieldState.error}
              isTouched={phoneNumberFieldState.isTouched}
              errorText={
                !!countryCodeField.value ? phoneNumberFieldState.error?.message : undefined
              }
            />
          </div>
        </div>

        <SecondaryButton
          onClick={submitHandler}
          backgroundColor={COLORS.WHITE}
          height="32px"
          padding="4px 10px 4px 10px"
          margin="12px 0 0 0"
          width="100%"
        >
          <span className="button-text" style={{ color: COLORS.BLACK }}>
            Save
          </span>
        </SecondaryButton>
      </div>
    </div>
  );
};

export default EditProfileDetailsPage;
