import { ReactComponent as ClearIcon } from '@assets/icons/new-close-icon.svg';
import { PlusOutlined } from '@ant-design/icons';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useModal } from '@hooks';
import ExtraServicesModal from '@modules/Admin/ExtraServices/ExtraServicesModal/CreateExtraServiceModal';
import ExtraServicesTable from '@modules/Admin/ExtraServices/ExtraServicesTable';
import { useGetAllMentors, useSearchExtraServices } from '@actions';
import styles from '@styles/components/calendar.module.scss';
import reportsStyles from '@styles/pages/admin/activity-report.module.scss';
import { Button, Pagination } from 'antd';
import Select from '@components/V4/Select/Select';
import { useEffect, useState } from 'react';
import useCalendar from '../../../hooks/useCalendar';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { serviceTypeOptions } from '@shared/constants';

const itemsPerPage = 10;

const ExtraServices = () => {
  const { goForward, goBackward, selected } = useCalendar();

  const [statusFilter, setStatusFilter] = useState('ALL');
  const [mentorFilter, setMentorFilter] = useState('ALL');
  const [serviceTypeFilter, setServiceTypeFilter] = useState('ALL');

  const {
    data: extraServices,
    isLoading: isLoadingExtraServices,
    refetch: refetchExtraServices,
  } = useSearchExtraServices({
    month: Number(selected.format('M')),
    year: Number(selected.format('YYYY')),
    page: 1,
    limit: 100,
    status: statusFilter === 'CREATED' ? 0 : statusFilter === 'PAID' ? 1 : '',
    mentorId: mentorFilter !== 'ALL' ? mentorFilter : '',
    type: serviceTypeFilter !== 'ALL' ? serviceTypeFilter : '',
  });

  const { data: mentors } = useGetAllMentors();
  const mentorsOptions = mentors?.map((mentor) => ({
    label: mentor?.fullName,
    value: mentor?._id,
  }));

  const statusOptions = ['CREATED', 'PAID'].map((status) => {
    return { label: status, value: status };
  });

  const allOption = { label: 'ALL', value: 'ALL' };

  const [ServicesModal, showModal] = useModal(ExtraServicesModal);

  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [renderKey, setRenderKey] = useState(0);
  const [rowTouched, setRowTouched] = useState(0);

  const [currentPage, setCurrentPage] = useState<number>(1);

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to display only the items for the current page
  const currentItems = extraServices?.slice(startIndex, endIndex);

  const totalPages = Math.ceil(extraServices?.length ?? 0 / itemsPerPage);

  const handleRowTouched = () => {
    setRowTouched((prevRowTouched) => prevRowTouched + 1);
  };

  const handleSelected = (report: any) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.includes(report);

      if (isSelected) {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== report);
      } else {
        return [...prevSelectedItems, report];
      }
    });
  };

  const handleSelectAll = (reports: any[]) => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      selectedStatus ? setSelectedItems(reports) : setSelectedItems(reports);
    }

    setSelectAll(!selectAll);
  };

  useEffect(() => {
    refetchExtraServices();
  }, [rowTouched]);

  return (
    <>
      <div className={mergeClassNames('items-center', reportsStyles.activityReportContainer)}>
        <h1 className={reportsStyles.title}>Extra Services</h1>
        <div className={reportsStyles.selectorContainer}>
          <span onClick={() => handleRowTouched()}>
            <ChevronLeftIcon
              className={`${styles.icon} ${reportsStyles.directionIcon}`}
              onClick={goBackward}
            />
          </span>
          <span className={reportsStyles.dateFormat}>{selected.format('MMMM YYYY')}</span>
          <span onClick={() => handleRowTouched()}>
            <ChevronRightIcon
              className={`${styles.icon} ${reportsStyles.directionIcon}`}
              onClick={goForward}
            />
          </span>
        </div>
        <Button
          type="primary"
          className="border-black border-2 mx-2.5"
          onClick={() => {
            showModal({
              handleRowTouched,
            });
          }}
        >
          <PlusOutlined />
          Add Service
        </Button>
      </div>
      <div className={reportsStyles.reportsContainer}>
        <div className="flex items-center gap-2">
          <Select
            className="min-w-48 mb-2.5"
            allowClear
            clearIcon={<ClearIcon className="text-customGrey w-3 h-3" />}
            showSearch
            label="Filter by Status"
            placeholder="Select Status"
            options={statusOptions}
            allOption={allOption}
            onSelect={(value) => setStatusFilter(value as string)}
          />

          <Select
            className="min-w-48 mb-2.5"
            allowClear
            clearIcon={<ClearIcon className="text-customGrey w-3 h-3" />}
            showSearch
            label="Filter by Mentor"
            placeholder="Select a Mentor"
            options={mentorsOptions}
            allOption={allOption}
            onSelect={(value) => setMentorFilter(value as string)}
          />

          <Select
            className="min-w-48 mb-2.5"
            allowClear
            clearIcon={<ClearIcon className="text-customGrey w-3 h-3" />}
            showSearch
            label="Filter by Type"
            placeholder="Select a Type"
            options={serviceTypeOptions}
            allOption={allOption}
            onSelect={(value) => setServiceTypeFilter(value as string)}
          />
        </div>
      </div>
      <div className="mt-10"></div>
      <div>
        {extraServices && (
          <ExtraServicesTable
            key={renderKey}
            selectedDate={selected}
            extraServicesRendered={currentItems}
            extraServices={extraServices}
            isLoading={isLoadingExtraServices}
            handleSelected={handleSelected}
            handleSelectAll={handleSelectAll}
            selectAll={selectAll}
            selectedItems={selectedItems}
            handleRowTouched={handleRowTouched}
          />
        )}
      </div>

      <div
        className={mergeClassNames('flex flex-row justify-end', reportsStyles.paginationContainer)}
      >
        <span className="text-white">Total: {extraServices?.length} &nbsp;</span>{' '}
        {/*{!showAll && (*/}
        <Pagination
          total={extraServices?.length}
          current={currentPage}
          defaultPageSize={10}
          defaultCurrent={1}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            // setCurrentPageSize(pageSize);
          }}
          // pageSizeOptions={[10, 20, 50, 100, allMonthlyReports?.total]}
        />
      </div>
      {ServicesModal}
    </>
  );
};

export default ExtraServices;
