import Button from '@components/V4/Button';
import { useState } from 'react';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import K12SchoolModalForm from './K12SchoolModalForm';
import { useGetK12School } from '@actions';
import { EDUCATION_LEVELS, SCHOOL_TYPES } from '@shared/constants';
import { getValueOrNA } from 'apps/agora/src/utils/helpers';

interface K12SchoolModalDetailsProps {
  schoolId: string;
}

const K12SchoolModalDetails = (props: K12SchoolModalDetailsProps) => {
  const { schoolId } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const { data: school, isLoading } = useGetK12School(schoolId);

  const k12SchoolDetailsArray = [
    [
      { label: 'School Name:', value: getValueOrNA(school?.name) },
      {
        label: 'Type:',
        value: !!school && !isNaN(school?.type) ? SCHOOL_TYPES[school.type] : 'n/a',
      },
      { label: 'City:', value: school?.cityRef?.name || school?.city },
    ],
    [
      {
        label: 'Country:',
        value: school?.country,
      },
      {
        label: 'Education Level:',
        value: school?.educationLevel?.map((level) => EDUCATION_LEVELS[level]).join(', '),
      },
    ],
  ];

  const editModeChangeHandler = () => {
    setIsEditMode((editMode) => !editMode);
  };

  if (isLoading) return <LoadingSpinner />;

  if (isEditMode)
    return (
      <K12SchoolModalForm isEditMode k12School={school} onCloseEditMode={editModeChangeHandler} />
    );

  return (
    <>
      <div className="flex justify-between mb-2">
        <p className="text-xs font-bold">School Details</p>
        <Button buttonText="Edit" onClick={editModeChangeHandler} />
      </div>
      <div className="w-full flex gap-x-8 justify-between">
        {k12SchoolDetailsArray.map((schoolDetails, index) => (
          <div key={`column-${index}`} className="flex flex-col gap-2 ">
            {schoolDetails.map((schoolDetail, index) => (
              <span key={`row-${index}`} className="flex text-customGrey font-raleway text-xs">
                <span className="min-w-max">{schoolDetail.label}</span>
                <span className="text-white ml-2 break-words">{schoolDetail.value ?? 'n/a'}</span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
export default K12SchoolModalDetails;
