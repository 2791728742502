import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import { zodResolver } from '@hookform/resolvers/zod';
import { Enrollment } from '@shared/common';
import { useUpdateEnrollment } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../DetailsModalFormWrapper';
import TextArea from '@components/V4/Inputs/Textarea';

interface EnrollmentDetailsFormProps {
  defaultData: Enrollment;
  onCancel: () => void;
}

const formDataSchema = z.object({
  status: z.boolean(),
  startDate: z.string().min(1, { message: 'Please enter a valid start date.' }),
  endDate: z.string().min(1, { message: 'Please enter a valid end date.' }),
  comment: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const EnrollmentDetailsForm = (props: EnrollmentDetailsFormProps) => {
  const { defaultData, onCancel } = props;

  const toast = useToast();

  const statusTypes = [
    {
      label: 'Active',
      value: false, // ended = false
    },
    {
      label: 'Ended',
      value: true, // ended = true
    },
  ];

  const { handleSubmit, trigger, setValue, control, getValues, register } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      status: defaultData?.ended,
      startDate: defaultData?.startDate ?? '',
      endDate: defaultData?.estimatedEndDate ?? '',
      comment: defaultData?.comment?.message ?? '',
    },
  });

  const { field: statusField, fieldState: statusFieldState } = useController({
    name: 'status',
    control,
  });

  const { field: startDateField, fieldState: startDateFieldState } = useController({
    name: 'startDate',
    control,
  });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const { mutate: updateEnrollment, isLoading } = useUpdateEnrollment({
    onSuccess: async () => {
      toast.success('Successfully updated the enrollment.');
      onCancel();
    },
    onError: () => {
      toast.error('Enrollment could not be updated.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateEnrollment({
        enrollmentId: defaultData._id,
        ended: data.status,
        startDate: data.startDate,
        estimatedEndDate: data.endDate,
        commentText: data.comment,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | boolean>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  return (
    <SessionModalFormWrapper
      title={'Details'}
      isLoading={isLoading}
      onCancel={onCancel}
      submitHandler={submitHandler}
    >
      <AgoraBigSelector
        value={statusField.value}
        onSelect={(value) => statusField.onChange(value)}
        options={statusTypes}
        onBlur={statusField.onBlur}
        labelKey="label"
        valueKey="value"
        label="Status"
        isValid={!statusFieldState.error}
        isTouched={statusFieldState.isTouched}
        errorText={statusFieldState.error?.message}
        isRequired
      />
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={startDateField.value ? moment(startDateField.value) : undefined}
          onChange={(value) => startDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={startDateField.onBlur}
          allowClear={false}
          isRequired
          label="Start Date"
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) => endDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={endDateField.onBlur}
          allowClear={false}
          isRequired
          label="End Date"
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
      <TextArea id="comment" label="Comment" className="h-40" {...register('comment')} />
    </SessionModalFormWrapper>
  );
};

export default EnrollmentDetailsForm;
