import mongoose from "mongoose";
export interface LibraryConfig {
  environment: string;
}

export const createRedisLibrary = (config: LibraryConfig) => {
  const REDIS_PREFIX = `agora:${config.environment}`;

  const REPORT_PDF_QUEUE_STREAM = `${REDIS_PREFIX}:streams:report_pdf_queue_stream`;
  const REPORT_PDF_STATUS_HASH = `${REDIS_PREFIX}:hashes:report_pdf_status_hash`;

  const COURSE_QUEUE_STREAM = `${REDIS_PREFIX}:streams:course_queue_stream`;
  const COURSE_STATUS_HASH = `${REDIS_PREFIX}:hashes:course_status_hash`;

  const REDIS_CONSUMER = `${REDIS_PREFIX}:consumer:${new mongoose.Types.ObjectId().toHexString()}`;
  const REDIS_CONSUMER_GROUP = `${REDIS_PREFIX}:consumer_group`;

  return {
    REDIS_PREFIX,
    REPORT_PDF_QUEUE_STREAM,
    REPORT_PDF_STATUS_HASH,
    COURSE_QUEUE_STREAM,
    COURSE_STATUS_HASH,
    REDIS_CONSUMER,
    REDIS_CONSUMER_GROUP,
  };
};

