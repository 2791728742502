import { useDeleteK12School, useGetK12Schools, useRestoreK12School } from '@actions';
import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import Table, { SortOrder } from '@components/V4/Table/Table';
import K12SchoolModal from '@modules/Admin/components/K12SchoolModal/K12SchoolModal';
import { EDUCATION_LEVELS, SCHOOL_TYPES } from '@shared/constants';
import { Popconfirm } from 'antd';
import useToast from 'apps/agora/src/hooks/useToast';
import { getValueOrNA } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useState } from 'react';

interface K12TableProps extends BasicProps {
  searchParams: any;
  page: string;
  sortBy: string | null;
  sortOrder: SortOrder | null;
  limit: string | null;
  debouncedSearch: string;
  setSearchParams: (data: any) => void;
}

const K12Table = (props: K12TableProps) => {
  const [showModal, setShowModal] = useState(false);
  const [schoolId, setSchoolId] = useState<string>();

  const { page, sortBy, sortOrder, limit, searchParams, debouncedSearch, setSearchParams } = props;

  const toast = useToast();

  const { data: k12SchoolData, isLoading: isLoadingK12SchoolData } = useGetK12Schools({
    limit: searchParams.limit,
    page: searchParams.page,
    sortBy: searchParams.sortBy,
    sortOrder: searchParams.sortOrder,
    city: searchParams.city,
    country: searchParams.country,
    search: debouncedSearch,
    educationLevel: searchParams.educationLevel,
    type: searchParams.type,
    isActive: searchParams.isActive,
  });

  const { mutate: deleteSchool } = useDeleteK12School({
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.error?.message ?? 'Failed to delete school. Please try again later.';

      toast.error(errorMessage);
    },
    onSuccess: () => toast.success('Successfully deleted school'),
  });

  const { mutate: restoreK12School } = useRestoreK12School({
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.error?.message ??
        'Failed to restore school. Please try again later.';

      toast.error(errorMessage);
    },
    onSuccess: () => toast.success('Successfully restore school'),
  });

  const currentPageChangeHandler = (page: number) => {
    setSearchParams({
      page,
    });
  };

  const pageSizeChangeHandler = (size: number) => {
    setSearchParams({
      limit: size,
      page: 1,
    });
  };

  const sortHandler = (field: string, order: SortOrder) => {
    setSearchParams({
      sortBy: order ? field : null,
      sortOrder: order || null,
      page: 1,
    });
  };

  const openModalHandler = (schoolId: string) => {
    setShowModal(true);
    setSchoolId(schoolId);
  };

  return (
    <div className="mb-20 mt-8">
      {isLoadingK12SchoolData ? (
        <div className="h-24 flex items-center justify-center text-customGrey">Loading...</div>
      ) : (
        <Table
          className="px-18"
          pagination={{
            pageSize: limit !== null ? parseInt(limit) : 10,
            total: k12SchoolData?.total,
            currentPage: page !== null ? parseInt(page) : 1,
            onCurrentPageChange: currentPageChangeHandler,
            onPageSizeChange: pageSizeChangeHandler,
          }}
        >
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell
                onSort={(order) => sortHandler('name', order)}
                sortOrder={sortBy === 'name' ? sortOrder : undefined}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell>Country</Table.HeaderCell>
              <Table.HeaderCell>City</Table.HeaderCell>
              <Table.HeaderCell>Education Level</Table.HeaderCell>
              <Table.HeaderCell
                onSort={(order) => sortHandler('type', order)}
                sortOrder={sortBy === 'type' ? sortOrder : undefined}
              >
                Type
              </Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!k12SchoolData?.schools?.length && (
              <tr>
                <td colSpan={100} className="h-64">
                  <EmptyStateCard
                    icon={<BlockIcon className="text-customGrey" />}
                    text={'No results.'}
                  />
                </td>
              </tr>
            )}
            {!!k12SchoolData?.schools?.length &&
              k12SchoolData?.schools?.map((school) => (
                <Table.Row key={school._id}>
                  <Table.Cell>{getValueOrNA(school.name)}</Table.Cell>
                  <Table.Cell>{getValueOrNA(school.country)}</Table.Cell>
                  <Table.Cell>{getValueOrNA(school.cityRef?.name || school.city)}</Table.Cell>
                  <Table.Cell>
                    {school.educationLevel.map((level) => EDUCATION_LEVELS[level]).join(', ')}
                  </Table.Cell>
                  <Table.Cell>{getValueOrNA(SCHOOL_TYPES[school.type])}</Table.Cell>
                  <Table.Cell>
                    <div className="flex gap-4">
                      {school.isDeleted ? (
                        <button
                          className="text-xs text-customGrey underline"
                          onClick={() => restoreK12School([school?._id], {})}
                        >
                          Restore
                        </button>
                      ) : (
                        <>
                          <button
                            className="text-xs text-customGrey underline"
                            onClick={() => openModalHandler(school._id)}
                          >
                            View
                          </button>
                          <Popconfirm
                            onConfirm={() => deleteSchool([school?._id], {})}
                            title={
                              <section className="max-w-110 flex flex-col gap-6">
                                <p>Are you sure you want to delete this School?</p>
                              </section>
                            }
                          >
                            <button className="text-xs text-customRed underline">Delete</button>
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}
      {showModal && !!schoolId && (
        <K12SchoolModal schoolId={schoolId} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default K12Table;
