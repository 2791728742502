import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import SessionModalFormWrapper from '@modules/Admin/components/DetailsModal/DetailsModalFormWrapper';
import { useGetAllExamNames, useGetExam, useSaveUserProfile } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';
import Select from '@components/V4/Select/Select';
import ExamInfoBar from '@components/V4/ExamInfoBar';
import { UserExamWithName } from '@shared/clientTypes/DTOs/UserDTO';

interface UpcomingExamsSectionFormProps extends BasicProps {
  isModeAddNew?: boolean;
  upcomingExam?: UserExamWithName;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  exam: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  targetGrade: z.string().optional().default(''),
  prevGrade: z.string().optional().default(''),
  date: z
    .string()
    .min(1, { message: 'Please enter a valid date.' })
    .refine((val) => moment(val).isSameOrAfter(moment(), 'day'), {
      message: 'Date cannot be in the past.',
    }),
  registrationDeadline: z
    .string()
    .min(1, { message: 'Please enter a valid date.' })
    .refine((val) => moment(val).isSameOrAfter(moment(), 'day'), {
      message: 'Date cannot be in the past.',
    }),
  areasOfImprovement: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const UpcomingExamsSectionForm = (props: UpcomingExamsSectionFormProps) => {
  const { isModeAddNew = false, className, upcomingExam, onCloseEditForm } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const toast = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(studentId, {
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');
      onCloseEditForm();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const hasNewExamType = !!upcomingExam?.newExam?._id;

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      exam: hasNewExamType ? upcomingExam?.newExam._id : upcomingExam?.exam,
      targetGrade: upcomingExam?.targetGrade,
      prevGrade: upcomingExam?.prevGrade,
      date: upcomingExam?.date ?? '',
      registrationDeadline: upcomingExam?.registrationDeadline ?? '',
      areasOfImprovement: upcomingExam?.areasOfImprovement,
    },
  });

  const { field: dateField, fieldState: dateFieldState } = useController({
    name: 'date',
    control,
  });

  const { field: registrationDeadlineField, fieldState: registrationDeadlineFieldState } =
    useController({
      name: 'registrationDeadline',
      control,
    });

  const { field: examField, fieldState: examFieldState } = useController({
    name: 'exam',
    control,
  });

  const { field: targetGradeField } = useController({
    name: 'targetGrade',
    control,
  });

  const { data: examOptions } = useGetAllExamNames();
  const { data: selectedExam } = useGetExam(examField.value, {
    enabled: (!!examField.value && isModeAddNew) || hasNewExamType,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const exam: any = {
        ...data,
        ...((isModeAddNew || upcomingExam?.newExam?._id) && {
          exam: undefined,
          newExam: data.exam,
        }),
        _id: upcomingExam?._id,
      };

      updateUserProfile({
        exams: [exam],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      touchAllFields(getValues());
    }

    if (selectedExam && isModeAddNew) {
      setValue('targetGrade', selectedExam?.targetScore ?? '');
      setValue('date', selectedExam?.examDate ?? '', { shouldTouch: true, shouldValidate: true });
      setValue('registrationDeadline', selectedExam?.registrationDeadline ?? '', {
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [selectedExam, isModeAddNew]);

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Upcoming Exam' : 'Edit Upcoming Exam'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText="Create Upcoming Exam"
    >
      {isModeAddNew || !!upcomingExam?.newExam ? (
        <Select
          value={examField.value}
          onSelect={(value) => examField.onChange(value)}
          onBlur={examField.onBlur}
          allowClear={false}
          options={examOptions?.map((exam) => ({
            label: exam.name,
            value: exam._id ?? '',
          }))}
          label="Exam"
          size="large"
          isRequired
          isValid={!examFieldState.error}
          isTouched={examFieldState.isTouched}
          errorText={examFieldState.error?.message}
        />
      ) : (
        <Input
          isRequired
          type="text"
          id="university"
          label="University"
          isTouched={touchedFields.exam}
          isValid={!errors.exam}
          errorText={errors.exam?.message}
          autoFocus
          {...register('exam')}
        />
      )}
      {!!selectedExam && <ExamInfoBar exam={selectedExam} />}
      <>
        <div className="gap-6 laptop:flex">
          <AgoraDatePicker
            value={dateField.value ? moment(dateField.value) : undefined}
            onChange={(value) => dateField.onChange(moment(value).format('YYYY-MM-DD'))}
            disabledDate={(date) => date < moment(moment().format('YYYY-MM-DD'))}
            onBlur={dateField.onBlur}
            allowClear={false}
            label="Date"
            isRequired
            isValid={!dateFieldState.error}
            isTouched={dateFieldState.isTouched}
            errorText={dateFieldState.error?.message}
          />
          <AgoraDatePicker
            value={
              registrationDeadlineField.value ? moment(registrationDeadlineField.value) : undefined
            }
            onChange={(value) =>
              registrationDeadlineField.onChange(moment(value).format('YYYY-MM-DD'))
            }
            disabledDate={(date) => date < moment(moment().format('YYYY-MM-DD'))}
            onBlur={registrationDeadlineField.onBlur}
            allowClear={false}
            label="Registration Deadline"
            isRequired
            isValid={!registrationDeadlineFieldState.error}
            isTouched={registrationDeadlineFieldState.isTouched}
            errorText={registrationDeadlineFieldState.error?.message}
          />
        </div>
        <div className="gap-6 laptop:flex">
          <Input
            type="text"
            id={'targetGrade'}
            placeholder={''}
            label={'Target Grade'}
            name={targetGradeField.name}
            value={targetGradeField.value}
            onChange={(value) => targetGradeField.onChange(value)}
            onBlur={targetGradeField.onBlur}
          />
          <Input
            type="text"
            id={'prevGrade'}
            placeholder={''}
            label={'Last Score'}
            {...register('prevGrade')}
          />
        </div>

        <TextArea
          id={'areasOfImprovement'}
          placeholder={''}
          className="hover:bg-opacity-50 focus:bg-opacity-50"
          label={'Ares Of Improvement'}
          {...register('areasOfImprovement')}
        />
      </>
    </SessionModalFormWrapper>
  );
};

export default UpcomingExamsSectionForm;
