import { Document, ObjectId } from 'mongoose';

// SOURCE: https://support.qs.com/hc/en-gb/articles/360021876820-QS-Institution-Classifications

export const UniversitySizeTypes = {
  S: {
    name: 'Small',
    description: 'Fewer than 5,000',
  },
  M: {
    name: 'Medium',
    description: '>=5,000',
  },
  L: {
    name: 'Large',
    description: '>=12,000',
  },
  XL: {
    name: 'Extra Large',
    description: 'More than 30,000',
  },
};

export const UniversityFocusTypes = {
  FC: {
    name: 'Full comprehensive',
    description: 'All 5 faculty areas + medical school',
  },
  CO: {
    name: 'Comprehensive',
    description: 'All 5 faculty areas',
  },
  FO: {
    name: 'Focused',
    description: '3 or 4 faculty areas',
  },
  SP: {
    name: 'Specialist',
    description: '2 or fewer faculty areas',
  },
};

export const UniversityResearchIntensityTypes = {
  VH: {
    name: 'Very High',
  },
  HI: {
    name: 'High',
  },
  MD: {
    name: 'Medium',
  },
  LO: {
    name: 'Low',
  },
};

export type ResultsMonthPeriod = 'Early' | 'Mid' | 'Late';

export interface UniversityRanking {
  score: number;
  rank: number;
  _id: string;
}

export interface UniversityAdmissionRound {
  name: string;
  deadline: Date;
  resultsTimeline: {
    period: ResultsMonthPeriod;
    month: string;
  };
  _id: string;
}

export interface UniversityRequirement {
  notationSystemName: string;
  minGPA: string;
  exam: ObjectId;
  minExamScore: string;
  _id: string;
}

export interface UniversityAdmissionData {
  _id: string;
  year: number;
  admissionRounds: UniversityAdmissionRound[];
  admissionRequirements: UniversityRequirement[];
}

export interface UniversityRankings {
  _id: string;
  year: number;
  importBatchId: string;
  overallRanking: UniversityRanking;
  academicReputation: UniversityRanking;
  employerReputation: UniversityRanking;
  facultyStudent: UniversityRanking;
  citationsPerFaculty: UniversityRanking;
  internationalFaculty: UniversityRanking;
  internationalStudents: UniversityRanking;
  internationalResearchNetwork: UniversityRanking;
  employmentOutcomes: UniversityRanking;
  sustainability: UniversityRanking;
}

export interface University {
  _id: string;
  name: string;
  country: string;
  region: string;
  isAddedByUser: boolean;
  classification: {
    size: keyof typeof UniversitySizeTypes;
    focus: keyof typeof UniversityFocusTypes;
    researchIntensity: keyof typeof UniversityResearchIntensityTypes;
  };
  createdAt?: string;
  updatedAt?: string;
  createdBy?: ObjectId;
  updatedBy?: ObjectId;
  isDeleted?: boolean;
  rankings: UniversityRankings[];
  admissionData: UniversityAdmissionData[];
}

export interface IUniversity extends University, Omit<Document, '_id'> {}
