import { zodResolver } from '@hookform/resolvers/zod';
import { useUniversityModalContext } from '@modules/Admin/contexts/UniversityModalContext';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import ModalFormWrapper from '../../ModalFormWrapper';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { useGetUniversityCountriesAndRegions, useUpdateUniversityDetails } from '@actions';
import {
  UniversitySizeTypes,
  UniversityFocusTypes,
  UniversityResearchIntensityTypes,
} from '@shared/constants';
import useServerErrors from 'apps/agora/src/hooks/useServerErrors';
import useToast from 'apps/agora/src/hooks/useToast';

interface UniversityDetailsFormProps extends BasicProps {
  onCloseEditMode: () => void;
}

const universityDetailsSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid name' }),
  country: z.string().min(1, { message: 'Please select a country' }),
  region: z.string().min(1, { message: 'Please select a region' }),
  size: z.string().optional(),
  focus: z.string().optional(),
  researchIntensity: z.string().optional(),
});

type FormData = z.infer<typeof universityDetailsSchema>;

const UniversityDetailsForm = (props: UniversityDetailsFormProps) => {
  const { onCloseEditMode } = props;

  const { university } = useUniversityModalContext();

  const { data: countriesAndRegions } = useGetUniversityCountriesAndRegions();

  const { countries, regions } = countriesAndRegions || {};

  const toast = useToast();

  const { clearServerErrors, updateServerErrors, isFieldValid, getServerErrorText } =
    useServerErrors();

  const { mutate: updateUniversity, isLoading } = useUpdateUniversityDetails(
    university?._id || '',
    {
      onSuccess: () => {
        toast.success('Successfully updated University');
        clearServerErrors();
        onCloseEditMode();
      },

      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error?.message ?? 'There was an error, please try again later.';

        toast.error(errorMessage);

        updateServerErrors(error);
      },
    }
  );

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(universityDetailsSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: university?.name,
      country: university?.country,
      region: university?.region,
      size: university?.classification?.size,
      focus: university?.classification?.focus,
      researchIntensity: university?.classification?.researchIntensity,
    },
  });

  const { field: countryField, fieldState: countryFieldState } = useController({
    name: 'country',
    control,
  });

  const { field: regionField, fieldState: regionFieldState } = useController({
    name: 'region',
    control,
  });

  const { field: sizeField, fieldState: sizeFieldState } = useController({
    name: 'size',
    control,
  });

  const { field: focusField, fieldState: focusFieldState } = useController({
    name: 'focus',
    control,
  });

  const { field: researchIntensityField, fieldState: researchIntensityFieldState } = useController({
    name: 'researchIntensity',
    control,
  });

  const touchAllFields = (fields: Partial<Record<keyof FormData, string | undefined>>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };
  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUniversity({
        name: data.name,
        country: data.country,
        region: data.region,
        size: data.size,
        focus: data.focus,
        researchIntensity: data.researchIntensity,
      });
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  return (
    <ModalFormWrapper
      title="Edit University Info"
      isLoading={isLoading}
      onCancel={onCloseEditMode}
      submitHandler={submitHandler}
    >
      <div className="flex justify-between gap-6">
        <Input
          isRequired
          type="text"
          id="name"
          label="Name"
          isTouched={touchedFields.name}
          isValid={!errors.name && isFieldValid('name')}
          errorText={errors.name?.message || getServerErrorText('name')}
          {...register('name', { required: true })}
        />
        <Select
          value={countryField.value}
          onChange={countryField.onChange}
          onClear={() => countryField.onChange(undefined)}
          onBlur={countryField.onBlur}
          options={countries?.map((language) => ({
            label: language,
            value: language,
          }))}
          size="large"
          label="Country"
          allowClear={false}
          isRequired
          isValid={!countryFieldState.error}
          isTouched={countryFieldState.isTouched}
          errorText={countryFieldState.error?.message}
        />
      </div>
      <div className="flex justify-between gap-6">
        <Select
          value={regionField.value}
          onChange={regionField.onChange}
          onClear={() => regionField.onChange(undefined)}
          onBlur={regionField.onBlur}
          options={regions?.map((language) => ({
            label: language,
            value: language,
          }))}
          size="large"
          label="Region"
          allowClear={false}
          isRequired
          isValid={!regionFieldState.error}
          isTouched={regionFieldState.isTouched}
          errorText={regionFieldState.error?.message}
        />
        <Select
          value={sizeField.value}
          onChange={sizeField.onChange}
          onClear={() => sizeField.onChange(undefined)}
          onBlur={sizeField.onBlur}
          options={Object.keys(UniversitySizeTypes).map((size: string) => ({
            label: `${size} (${
              UniversitySizeTypes[size as keyof typeof UniversitySizeTypes].description
            })`,
            value: size,
          }))}
          size="large"
          label="Size"
          isValid={!sizeFieldState.error}
          isTouched={sizeFieldState.isTouched}
          errorText={sizeFieldState.error?.message}
        />
      </div>
      <div className="flex justify-between gap-6">
        <Select
          value={focusField.value}
          onChange={focusField.onChange}
          onClear={() => focusField.onChange(undefined)}
          onBlur={focusField.onBlur}
          options={Object.keys(UniversityFocusTypes).map((focus: string) => ({
            label: `${UniversityFocusTypes[focus as keyof typeof UniversityFocusTypes].name} (${
              UniversityFocusTypes[focus as keyof typeof UniversityFocusTypes].description
            })`,
            value: focus,
          }))}
          size="large"
          label="Focus"
          isValid={!focusFieldState.error}
          isTouched={focusFieldState.isTouched}
          errorText={focusFieldState.error?.message}
        />
        <Select
          value={researchIntensityField.value}
          onChange={researchIntensityField.onChange}
          onClear={() => researchIntensityField.onChange(undefined)}
          onBlur={researchIntensityField.onBlur}
          options={Object.keys(UniversityResearchIntensityTypes).map(
            (researchIntensity: string) => ({
              label: `${researchIntensity} (${
                UniversityResearchIntensityTypes[
                  researchIntensity as keyof typeof UniversityResearchIntensityTypes
                ].name
              })`,
              value: researchIntensity,
            })
          )}
          size="large"
          label="Research Intensity"
          isValid={!researchIntensityFieldState.error}
          isTouched={researchIntensityFieldState.isTouched}
          errorText={researchIntensityFieldState.error?.message}
        />
      </div>
    </ModalFormWrapper>
  );
};

export default UniversityDetailsForm;
