import { universityApis } from '@shared/apis';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithIdParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useGetUniversities = genericQueryWithParams<universityApis.GetUniversities>(
  universityApis.getUniversities
);

export const useGetUniversitiesLight = genericQueryWithParams<universityApis.GetUniversitiesLight>(
  universityApis.getUniversitiesLight
);

export const useGetUniversity = genericQueryWithId<universityApis.GetUniversity>(
  universityApis.getUniversity
);

export const useGetUniversityAdmissionRounds =
  genericQueryWithParams<universityApis.GetUniversityAdmissionRounds>(
    universityApis.getUniversityAdmissionRounds
  );

export const useAddUniversity = genericMutation<universityApis.AddUniversity>(
  universityApis.addUniversity,
  universityApis.getUniversities.endpoint
);

export const useUpdateUniversityDetails =
  genericMutationWithId<universityApis.UpdateUniversityDetails>(
    universityApis.updateUniversityDetails,
    universityApis.getUniversities.endpoint,
    universityApis.getUniversity.endpoint
  );

export const useDeleteUniversity = genericMutationWithIdParams<universityApis.DeleteUniversity>(
  universityApis.deleteUniversity,
  universityApis.getUniversities.endpoint
);

export const useRestoreUniversity = genericMutationWithIdParams<universityApis.RestoreUniversity>(
  universityApis.restoreUniversity,
  universityApis.getUniversities.endpoint
);

export const useGetUniversityCountriesAndRegions =
  genericQuery<universityApis.GetUniversityCountriesAndRegions>(
    universityApis.getUniversityCountriesAndRegions
  );

export const useAddUniversityAdmissionRound =
  genericMutationWithId<universityApis.AddUniversityAdmissionRound>(
    universityApis.addUniversityAdmissionRound,
    universityApis.getUniversity.endpoint
  );

export const useUpdateAdmissionRoundInUniversity =
  genericMutationWithIdParams<universityApis.UpdateAdmissionRoundInUniversity>(
    universityApis.updateAdmissionRoundInUniversity,
    universityApis.getUniversity.endpoint
  );

export const useDeleteAdmissionRoundFromUniversity =
  genericMutationWithIdParams<universityApis.DeleteAdmissionRoundFromUniversity>(
    universityApis.deleteAdmissionRoundFromUniversity,
    universityApis.getUniversity.endpoint
  );

export const useAddUniversityAdmissionRequirement =
  genericMutationWithId<universityApis.AddUniversityAdmissionRequirement>(
    universityApis.addUniversityAdmissionRequirement,
    universityApis.getUniversity.endpoint
  );

export const useUpdateAdmissionRequirementInUniversity =
  genericMutationWithIdParams<universityApis.UpdateAdmissionRequirementInUniversity>(
    universityApis.updateAdmissionRequirementInUniversity,
    universityApis.getUniversity.endpoint
  );

export const useDeleteAdmissionRequirementFromUniversity =
  genericMutationWithIdParams<universityApis.DeleteAdmissionRequirementFromUniversity>(
    universityApis.deleteAdmissionRequirementFromUniversity,
    universityApis.getUniversity.endpoint
  );
