// Input based on AgoraInput that accepts REFS from react-hook-form

import { mergeClassNames } from 'apps/agora/src/utils/helpers';
// import './input-styles.scss';
import { forwardRef, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import InputError from './InputError';
import InputLabel from './InputLabel';

type Props = {
  type: string;
  name: string;
  id: string;
  size?: 'default' | 'large';
  placeholder?: string;
  label: string;
  value?: string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  rows?: number;
  isRequired?: boolean;
  className?: string;
  isDisabled?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
  autoFocus?: boolean;
  prefix?: string;
};

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type,
    name,
    id,
    label,
    placeholder,
    isRequired,
    isTouched,
    isValid,
    errorText,
    className,
    value,
    isDisabled = false,
    autoFocus,
    size = 'default',
    onChange,
    onBlur,
    prefix,
  } = props;

  const prefixRef = useRef<HTMLSpanElement | null>(null);
  const [prefixWidth, setPrefixWidth] = useState(0);

  useLayoutEffect(() => {
    if (prefixRef.current) {
      const width = prefixRef.current.getBoundingClientRect().width;
      setPrefixWidth(width);
    }
  }, [prefix]);

  const sizeStyles = {
    large: 'h-15',
    default: 'h-10',
  };

  return (
    <div className="w-full">
      {label && (
        <InputLabel
          label={label}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
          htmlFor={id}
        />
      )}
      <div className="relative">
        {!!prefix && (
          <div className="prefix absolute inset-y-0 left-2 flex items-center pointer-events-none">
            <span ref={prefixRef} className="text-xs leading-5 text-customGrey">
              {prefix}
            </span>
          </div>
        )}
        <input
          className={mergeClassNames(
            {
              'block w-full py-2 px-2.5 mt-2 bg-transparent text-customGrey border border-customGrey rounded-lg text-xs input-autofill hover:bg-inputHover focus:bg-inputHover focus:text-white focus:border-customGrey focus-visible:border-customGrey focus:ring-0 focus-visible:ring-0 placeholder:text-placeholderColor':
                true,
              'text-customPrimary border-customPrimary focus:!border-customPrimary focus:text-white input-autofill-valid':
                isValid && isTouched && !isDisabled,
              'text-customRed border-customRed focus:!border-customRed focus:text-white input-autofill-error':
                !isValid && isTouched && !isDisabled,
              'input-autofill-default': !isTouched,
              'hover:bg-transparent border-opacity-50': isDisabled,
            },
            sizeStyles[size],
            className
          )}
          style={{ paddingLeft: prefixWidth + 12 }}
          disabled={isDisabled}
          ref={ref}
          value={value}
          onChange={(e) => onChange?.(e)}
          type={type}
          autoFocus={autoFocus}
          name={name}
          id={id}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      </div>

      {!isValid && isTouched && errorText && <InputError errorText={errorText} />}
    </div>
  );
});

export default Input;
