import SkeletonBox from '@components/V4/SkeletonBox';
import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { useContext, useState } from 'react';
import { ProfileContext } from '../../../AccountProfile';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import { EducationalCardProps } from '../../../types';
import ProfileMainSection from '../../ResumeSection';
import ResumeTabSchoolForm from './ResumeTabSchoolForm';
import ResumeTabSchoolItem from './ResumeTabSchoolItem';

const ResumeSchoolSection = (props: EducationalCardProps) => {
  const { userId, eduList, isMentorProfile } = props;

  const { isMentorViewer, isLoading } = useContext(ProfileContext);

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'educationalBackground',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="School"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      <div>
        {isLoading ? (
          <SkeletonBox className="w-full h-[78px]" />
        ) : (
          <>
            {isAddNewItem && (
              <ResumeTabSchoolForm
                userId={userId}
                isModeAddNew
                setIsAddNewItem={setIsAddNewItem}
                onCancel={addNewItemCancelHandler}
              />
            )}

            {eduList?.map((school) => (
              <ResumeTabSchoolItem
                key={school._id}
                userId={userId}
                schoolDetails={school}
                onDeleteEntry={deleteEntryHandler}
              />
            ))}

            {!isAddNewItem && !eduList?.length && (!isMentorViewer || isMentorProfile) && (
              <StepBanner
                stepOption={'missingSchool'}
                onClick={() => setIsAddNewItem((prev) => !prev)}
              />
            )}
          </>
        )}
      </div>
    </ProfileMainSection>
  );
};

export default ResumeSchoolSection;
