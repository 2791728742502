import { useUniversityModalContext } from '@modules/Admin/contexts/UniversityModalContext';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useState } from 'react';
import UniversityDetailsForm from './UniversityDetailsForm';
import Button from '@components/V4/Button';
import {
  UniversitySizeTypes,
  UniversityFocusTypes,
  UniversityResearchIntensityTypes,
} from '@shared/constants';

interface UniversityDetailsSectionProps extends BasicProps {
  // university: University;
}

const UniversityDetailsSection = (props: UniversityDetailsSectionProps) => {
  const { university } = useUniversityModalContext();

  const [isEditMode, setIsEditMode] = useState(false);

  if (!university) return <></>;

  const universityDetailsArray = [
    [
      {
        label: 'Size:',
        value: university.classification?.size
          ? `${UniversitySizeTypes[university.classification.size].name} (${
              UniversitySizeTypes[university.classification.size].description
            })`
          : 'n/a',
      },
      {
        label: 'Focus:',
        value: university.classification?.focus
          ? `${UniversityFocusTypes[university.classification.focus].name} (${
              UniversityFocusTypes[university.classification.focus].description
            })`
          : 'n/a',
      },
      {
        label: 'Research Intensity:',
        value: university.classification?.researchIntensity
          ? UniversityResearchIntensityTypes[university.classification.researchIntensity].name
          : 'n/a',
      },
    ],

    [
      { label: 'Country:', value: university.country },
      { label: 'Region:', value: university.region },
    ],
  ];

  const editModeChangeHandler = () => {
    setIsEditMode((editMode) => !editMode);
  };

  return isEditMode && university.isAddedByUser ? (
    <UniversityDetailsForm onCloseEditMode={editModeChangeHandler} />
  ) : (
    <>
      <div className="flex justify-between mb-2">
        <p className="text-xs font-bold">University Info</p>
        {university.isAddedByUser && <Button buttonText="Edit" onClick={editModeChangeHandler} />}
      </div>
      <div className="w-full flex gap-x-8 justify-between">
        {universityDetailsArray.map((universityDetails, index) => (
          <div key={`column-${index}`} className="flex flex-col gap-2 ">
            {universityDetails.map((universityDetail, index) => (
              <span key={`row-${index}`} className="flex text-customGrey font-raleway text-xs">
                <span className="min-w-max">{universityDetail.label}</span>
                <span className="text-white ml-2 break-words">
                  {universityDetail.value ?? 'n/a'}
                </span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default UniversityDetailsSection;
