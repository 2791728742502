import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import Table from '@components/V4/Table/Table';
import useToast from 'apps/agora/src/hooks/useToast';
import { getEndDateValue, getStartDateValue } from 'apps/agora/src/utils/helpers';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import FlagsOverview from './components/FlagsOverview/FlagsOverview';
import FlagsTableHeader from './components/FlagsTableHeader';
import FlagsTableRow from './components/FlagsTableRow';
import { useCloseMultipleFlags, useSearchFlags } from 'apps/agora/src/actions/flag.actions';
import { useAuthState } from '@contexts/AuthContext';
import DetailsModal, { DetailsModalPage } from '../components/DetailsModal/DetailsModal';
import { StudentTab } from '../components/DetailsModal/SessionDetailsPage/SessionTabsSection/SessionTabsSection';
import { Enrollment, Flag, FlagTypes, MeetingWithSessionId, ModelFlagTypes } from '@shared/common';

export interface ModalFlagData {
  modelType: ModelFlagTypes;
  flagType: FlagTypes;
  ref: Flag['ref'];
  page: DetailsModalPage;
  tab?: StudentTab;
}

const Flags = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<'desc' | 'asc'>('desc');
  const [isActive, setIsActive] = useState('0');
  const [flagType, setFlagType] = useState<string | undefined>(undefined);
  const [startRange, setStartRange] = useState<string | undefined>();
  const [endRange, setEndRange] = useState<string | undefined>();
  const [courseId, setCourseId] = useState();
  const [studentId, setStudentId] = useState();
  const [mentorId, setMentorId] = useState();
  const [selectedFlags, setSelectedFlags] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState('Flags');
  const [userStatus, setUserStatus] = useState('indeterminate');
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(!showModal);
  };
  const [modalFlagData, setModalFlagData] = useState<ModalFlagData | undefined>();

  const defaultStartDate = moment().subtract(3, 'months').startOf('month').toISOString();
  const defaultEndDate = moment().toISOString();

  const toast = useToast();

  const {
    data: flags,
    isLoading: isLoadingSearchFlags,
    refetch: refetchSearchFlags,
  } = useSearchFlags({
    page: currentPage,
    limit: pageSize,
    sort: sort,
    search: search,
    isActive: isActive,
    flagType: flagType,
    startRange: startRange,
    endRange: endRange,
    courseId: courseId,
    studentId: studentId,
    mentorId: mentorId,
    userStatus: userStatus,
  });

  const { mutate: markAsClosed } = useCloseMultipleFlags({
    onSuccess: () => {
      toast.success('Selected flags are now marked as closed');
      setSelectedFlags([]);
      refetchSearchFlags();
    },
    onError: () => {
      toast.error("Couldn't mark selected flags as closed");
    },
  });

  const currentUser = useAuthState();

  const handleFlagType = (flagType: string | undefined) => {
    setFlagType(flagType);
    setCurrentPage(1);
  };

  const handleCourseId = (courseId: any) => {
    setCourseId(courseId);
    setCurrentPage(1);
  };

  const handleStudentId = (studentId: any) => {
    setStudentId(studentId);
    setCurrentPage(1);
  };

  const handleMentorId = (mentorId: any) => {
    setMentorId(mentorId);
    setCurrentPage(1);
  };

  const handleSearch = (search: string) => {
    setSearch(search);
    setCurrentPage(1);
  };

  const handleActiveFlags = (active: string) => {
    setIsActive(active);
    setCurrentPage(1);
  };

  const handleAddFlag = (flag: string) => {
    setSelectedFlags((prevSelectedFlags) => [...prevSelectedFlags, flag]);
  };

  const handleRemoveFlag = (flag: string) => {
    setSelectedFlags((prevSelectedFlags) => prevSelectedFlags.filter((item) => item !== flag));
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleUserStatus = (userStatus: string) => {
    setUserStatus(userStatus);
    setCurrentPage(1);
  };

  const getRelevantModal = (flagType: FlagTypes): [DetailsModalPage, StudentTab | undefined] => {
    let pageType: DetailsModalPage;
    let tabType: StudentTab | undefined;
    switch (flagType) {
      case FlagTypes.FIRST_MEETING_NOT_SCHEDULED_IN_3_DAYS:
      case FlagTypes.FIRST_MEETING_NOT_HELD_IN_7_DAYS:
      case FlagTypes.NEXT_MEETING_FURTHER_THAN_62_DAYS:
      case FlagTypes.NO_MEETING_SCHEDULED_AFTER_48_HOURS_SIENCE_LAST_MEETING_HELD:
      case FlagTypes.MAX_DURATION_COURSE_EXCEEDED:
        pageType = 'enrollment';
        break;

      case FlagTypes.NO_FEEDBACK_AFTER_48_HOURS_SIENCE_MEETING_HELD:
        pageType = 'session';
        tabType = 'feedback';
        break;
      default:
        pageType = 'enrollment';
        break;
    }
    return [pageType, tabType];
  };

  const showMoreButtonHandler = (
    modelType: ModelFlagTypes,
    flagType: FlagTypes,
    ref: Flag['ref']
  ) => {
    setShowModal(true);
    const [pageType, tabType] = getRelevantModal(flagType);

    setModalFlagData({
      modelType: modelType,
      ref: ref,
      flagType: flagType,
      page: pageType,
      tab: tabType,
    });
    handleShowModal();
  };

  const getRangePickerValue = (): [Moment, Moment] | null => {
    if (!startRange || !endRange) return null;

    return [moment(startRange), moment(endRange)];
  };

  const rangePickerChangeHandler = (dates: [Moment | null, Moment | null]) => {
    const start = dates?.[0] ? getStartDateValue(dates?.[0]) : null;
    const end = dates?.[1] ? getEndDateValue(dates?.[1]) : null;

    setStartRange(start ? start.toISOString() : undefined);
    setEndRange(end ? end.toISOString() : undefined);
  };

  return (
    <div className="bg-surfaceBackground text-white min-h-[calc(100vh-74px)]">
      {/*TABLE HEADER*/}
      <FlagsTableHeader
        handleFlagType={handleFlagType}
        rangePickerChangeHandler={rangePickerChangeHandler}
        handleCourseId={handleCourseId}
        handleStudentId={handleStudentId}
        handleMentorId={handleMentorId}
        handleSearch={handleSearch}
        handleActiveFlags={handleActiveFlags}
        handleSelectedTab={handleSelectedTab}
        handleUserStatus={handleUserStatus}
        selectedTab={selectedTab}
        flagType={flagType}
        courseId={courseId}
        studentId={studentId}
        mentorId={mentorId}
        getRangePickerValue={getRangePickerValue}
      />

      <section className="relative">
        {!!selectedFlags.length && (
          <Button
            className="absolute top-4 right-6"
            buttonText={'Mark selected as completed'}
            variant="secondary-red"
            onClick={() => {
              markAsClosed({
                flagIds: selectedFlags,
                closedBy: currentUser?.userId,
              });
            }}
          />
        )}
        {selectedTab === 'Flags' ? (
          <Table
            className="p-6 py-8"
            pagination={{
              pageSize: pageSize !== null ? pageSize : 10,
              total: flags?.total,
              currentPage: currentPage !== null ? currentPage : 1,
              onCurrentPageChange: (page) => setCurrentPage(page),
              onPageSizeChange: (size) => setPageSize(size),
            }}
          >
            <Table.Header>
              <Table.Row isHeader>
                <Table.HeaderCell>Flag</Table.HeaderCell>
                <Table.HeaderCell>Student</Table.HeaderCell>
                <Table.HeaderCell>Mentor</Table.HeaderCell>
                <Table.HeaderCell>Course</Table.HeaderCell>
                <Table.HeaderCell
                  onSort={() => setSort(sort === 'asc' ? 'desc' : 'asc')}
                  sortOrder={sort}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell>Access</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!isLoadingSearchFlags ? (
                flags?.flags?.map((item) => (
                  <FlagsTableRow
                    currentUserId={currentUser?.userId}
                    key={item?._id}
                    flagData={item}
                    refetchSearchFlags={refetchSearchFlags}
                    handleAddFlag={handleAddFlag}
                    handleRemoveFlag={handleRemoveFlag}
                    showMoreButtonHandler={showMoreButtonHandler}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="h-64">
                    <div className="w-full h-full flex justify-center items-center">
                      <LoadingSpinner className="w-5 h-5" />
                    </div>
                  </td>
                </tr>
              )}

              {!isLoadingSearchFlags && !flags?.flags?.length && (
                <tr>
                  <td colSpan={6} className="h-64">
                    <EmptyStateCard
                      icon={<BlockIcon className="text-customGrey" />}
                      text={'No results.'}
                    />
                  </td>
                </tr>
              )}
            </Table.Body>
          </Table>
        ) : (
          <div className="table-container min-h-full">
            <FlagsOverview
              search={search}
              isActive={isActive}
              flagType={flagType}
              startRange={startRange ?? defaultStartDate}
              endRange={endRange ?? defaultEndDate}
              studentId={studentId}
              mentorId={mentorId}
              courseId={courseId}
            />
          </div>
        )}
      </section>

      {showModal && (
        <DetailsModal
          mentorId={mentorId}
          studentId={studentId}
          enrollmentId={
            modalFlagData?.modelType === ModelFlagTypes.MEETING
              ? (modalFlagData.ref as MeetingWithSessionId).enrollment
              : (modalFlagData?.ref as Enrollment)._id
          }
          sessionId={
            modalFlagData?.modelType === ModelFlagTypes.MEETING
              ? (modalFlagData.ref as MeetingWithSessionId).sessionId
              : undefined
          }
          page={modalFlagData?.page}
          isOpen={true}
          onClose={handleShowModal}
          tab={modalFlagData?.tab}
        />
      )}
    </div>
  );
};

export default Flags;
