import { SortOrder } from '@components/V4/Table/Table';
import { useDebounce, useQueryParamsState } from '@hooks';
import { TriState } from '@shared/common';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import K12SchoolsHeader from './K12SchoolsHeader';
import K12SchoolsTable from './K12SchoolsTable';

const useQuery = () => new URLSearchParams(useLocation().search);

const K12Schools = () => {
  const [search, setSearch] = useState('');

  //TODO - replace this with useQueryParams when upgrading to react-router-dom v6
  const query = useQuery();

  const limit = query.get('limit') || '10';
  const page = query.get('page') || '1';
  const sortBy = query.get('sortBy') || null;
  const sortOrder = (query.get('sortOrder') || null) as SortOrder;
  const city = query.get('city') || null;
  const country = query.get('country') || null;
  const educationLevel = query.get('educationLevel') || null;
  const type = query.get('type') || null;
  const isActive = (query.get('isActive') || 'checked') as TriState;

  const debouncedSearch = useDebounce(search, 500);

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    page: page,
    limit: limit,
    sortBy: sortBy,
    sortOrder: sortOrder,
    city: city,
    country: country,
    educationLevel: educationLevel,
    type: type,
    isActive: isActive,
  });

  return (
    <div className="flex flex-col">
      <K12SchoolsHeader
        search={search}
        searchParams={searchParams}
        onSearch={setSearch}
        setSearchParams={setSearchParams}
      />
      <K12SchoolsTable
        searchParams={searchParams}
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
        limit={limit}
        debouncedSearch={debouncedSearch}
        setSearchParams={setSearchParams}
      />
    </div>
  );
};

export default K12Schools;
